import { useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import localStorage from 'utils/LocalStorage.utils';

import { ReactComponent as ImageSvgIconMyHome } from 'assets/img/svg/icon-myhome.svg';

import useUserLevels from 'hooks/useUserLevels.js';

/** 프로필 버튼을 누르면 노출되는 팝업 */
export default function UserProfilePopup({ handleLogout, setUserLayer, handleProfileSetting }) {
  const [userInfo, _] = useState(request.tokenDecoder());
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);
  // 로컬 스토리지
  const userSession = localStorage.getItemJsonParse('userSession');

  return (
    <div className='user_layer active'>
      {/* 프로필 설정 버튼 */}
      <S.Button className='btn' onClick={handleProfileSetting}>
        <i className='svg_icon icon_profile'>&nbsp;</i>Profile Settings
      </S.Button>
      {/* 도움말 및 피드백 링크 */}
      <S.Button className='btn' onClick={() => setUserLayer(false)}>
        <a href='https://gateplus.notion.site/Digital-SAT-2-0-FAQ-3575f21ae8bc4e44a5ef99b7ac188cc2' target='_blank' rel='noopener noreferrer'>
          <i className='svg_icon icon_help2'>&nbsp;</i>Help & feedback
        </a>
      </S.Button>
      {/* 로그아웃 버튼 */}
      <S.Button className='btn' onClick={() => handleLogout()}>
        <i className='svg_icon icon_logout'>&nbsp;</i>Logout
      </S.Button>
    </div>
  );
}

const S = {
  Button: styled.button`
    a {
      display: inline-flex;
      align-items: center;
    }
  `,
};
