import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import dayjsTZ from 'utils/functions/time/dayjs-config';

/**
 * 기간을 선택해서 날자를 업데이트해주는 캘린더 컴포넌트
 * @description
 * 🔍 검색 키워드 - #Calendar #com/tests/scheduled
 * @param {function} setSimpleAlert simpleAlertModal을 띄우기 위한 함수이며 인자로 받지않을시 제한없이 날자 선택 가능
 * @param {string} calendarType 달력 타입
 * @param {boolean} readOnly 읽기 전용 여부
 * @param {object} markData 달력에 표시할 데이터
 * @param {function} onClickDay 날짜 클릭 함수
 * @param {array} selectedDateState 선택된 날짜 상태
 * @param {function} setSelectedDateState 선택된 날짜 상태 설정 함수
 * @param {function} setCurrentDate 현재 날짜 설정 함수
 * @param {boolean} highlightToday 오늘 날짜 하이라이트 스타일 활성화 여부
 */
function EditModalCalendar({
  setSimpleAlert = null,
  calendarType = 'selectRange',
  readOnly = false,
  markData = null,
  onClickDay = null,
  selectedDateState,
  setSelectedDateState,
  setCurrentDate = null,
  highlightToday = false,
}) {
  /** 현재 활성화된 월 */
  const [activeMonth, setActiveMonth] = useState(dayjsTZ(selectedDateState[0]).month() + 1);
  const [selectedDates, setSelectedDates] = useState({ start: null, end: null });
  const [hoverDate, setHoverDate] = useState(null);
  /** 날짜 선택 핸들러 */
  const handleDateChange = (selectedDate) => {
    if (readOnly) return;

    const selectedDateTZ = dayjsTZ(selectedDate);

    // calendarType가 "singleDate"일 경우
    if (calendarType === 'singleDate') {
      setSelectedDateState(selectedDateTZ);
      setSelectedDates({ start: selectedDateTZ, end: null });
      return;
    }
    // calendarType가 "selectRange"일 경우
    // 만약 selectedDateState가 비어있거나(null), 둘 다 값이 있을 경우 ( 새로운 날짜 선택 )
    if (!selectedDates.start || (selectedDates.start && selectedDates.end)) {
      setSelectedDates({ start: selectedDateTZ, end: null });
      setSelectedDateState([selectedDateTZ, null]);
      // 만약 selectedDateState의 첫번째 값이 있을 경우 ( 두번째 날짜 선택 )
    } else {
      if (setSimpleAlert !== null) {
        const differenceInDays = selectedDateTZ.diff(selectedDates.start, 'days');
        // 선택된 날짜가 오늘보다 이전일 경우 ( 과거로 시험 세팅 경고 )
        if (dayjsTZ() >= selectedDateTZ) {
          setSimpleAlert({
            visible: true,
            modalText: 'Scheduling exams for past dates is not allowed.\nPlease try again.',
          });
          setSelectedDates({ start: null, end: null });
          setSelectedDateState([null, null]);
          return;
        }

        if (Math.abs(differenceInDays) > 90) {
          setSimpleAlert({
            visible: true,
            modalText: 'The maximum setting period is 90 days.',
          });
          setSelectedDates({ start: null, end: null });
          setSelectedDateState([null, null]);
          return;
        }
      }

      const [startDate, endDate] = selectedDateTZ.isBefore(selectedDates.start) ? [selectedDateTZ, selectedDates.start] : [selectedDates.start, selectedDateTZ];

      setSelectedDates({ start: startDate, end: endDate });
      setSelectedDateState([startDate, endDate.endOf('day')]);
    }
  };

  const isDateInRange = (date) => {
    const currentDate = dayjsTZ(date);
    if (!selectedDates.start || !selectedDates.end) return false;
    return currentDate.isBetween(selectedDates.start, selectedDates.end, 'day', '[]');
  };

  const isDateInHoverRange = (date) => {
    if (calendarType !== 'selectRange' || !selectedDates.start || selectedDates.end || !hoverDate) {
      return false;
    }

    const currentDate = dayjsTZ(date);
    const start = selectedDates.start;
    const end = dayjsTZ(hoverDate);

    // 시작일과 호버일 사이의 모든 날짜 포함
    return currentDate.isBetween(
      start.isBefore(end) ? start : end,
      start.isBefore(end) ? end : start,
      'day',
      '[]' // 시작일과 종료일 포함
    );
  };
  const getPositionInRange = (date) => {
    if (!selectedDates.start) return '';

    const currentDate = dayjsTZ(date);
    const start = selectedDates.start;

    // 선택 완료된 범위에 대해서만 position 리턴
    if (selectedDates.end) {
      if (currentDate.isSame(start, 'day')) return 'start';
      if (currentDate.isSame(selectedDates.end, 'day')) return 'end';
      if (currentDate.isBetween(start, selectedDates.end, 'day')) return 'middle';
      return '';
    }

    // 선택 진행중일 때는 시작점만 표시
    if (currentDate.isSame(start, 'day')) return 'start';
    return '';
  };

  useEffect(() => {
    setSelectedDates({
      start: selectedDateState[0] ? dayjsTZ(selectedDateState[0]) : null,
      end: selectedDateState[1] ? dayjsTZ(selectedDateState[1]) : null,
    });
  }, [selectedDateState]);

  // 주소에 ap 있으면 true
  const isAp = window.location.pathname.includes('/ap/');

  return (
    <article className='com_calendar_wrap'>
      <S.Calendar
        $isAp={isAp}
        onActiveStartDateChange={({ activeStartDate }) => {
          setActiveMonth(activeStartDate.getMonth() + 1);
          if (setCurrentDate !== null) setCurrentDate(dayjsTZ(activeStartDate));
        }}
        className='calendar'
        locale='en-EN'
        onClickDay={onClickDay}
        tileClassName={({ date }) => {
          const currentDate = dayjsTZ(date);
          const classes = [];

          // 1. 선택 완료된 범위에 대한 클래스
          if (selectedDates.start && selectedDates.end) {
            if (currentDate.isSame(selectedDates.start, 'day')) {
              classes.push('range-start');
            } else if (currentDate.isSame(selectedDates.end, 'day')) {
              classes.push('range-end');
            } else if (currentDate.isBetween(selectedDates.start, selectedDates.end, 'day')) {
              classes.push('range-middle');
            }
          }
          // 2. 선택 진행중(hover) 상태일 때의 클래스
          else if (selectedDates.start && !selectedDates.end && hoverDate) {
            const isReverse = dayjsTZ(hoverDate).isBefore(selectedDates.start);
            const hoverDateTZ = dayjsTZ(hoverDate);

            // 시작일과 호버일 사이의 범위 확인
            const [rangeStart, rangeEnd] = isReverse ? [hoverDateTZ, selectedDates.start] : [selectedDates.start, hoverDateTZ];

            if (currentDate.isBetween(rangeStart, rangeEnd, 'day', '[]')) {
              if (currentDate.isSame(selectedDates.start, 'day')) {
                classes.push(isReverse ? 'hover-end' : 'hover-start');
              } else if (currentDate.isSame(hoverDateTZ, 'day')) {
                classes.push(isReverse ? 'hover-start' : 'hover-end');
              } else {
                classes.push('hover-middle');
              }
            }
          }
          // 3. 단일 선택 상태일 때의 클래스
          else if (selectedDates.start && currentDate.isSame(selectedDates.start, 'day')) {
            classes.push('range-start');
          }

          return classes.join(' ');
        }}
        tileContent={({ date }) => {
          const tileDate = dayjsTZ(date);
          const isCurrentDateMonth = tileDate.month() + 1 === activeMonth;
          const formattedDate = tileDate.format('YYYY-MM-DD');
          const isToday = tileDate.isSame(dayjsTZ(), 'day');
          const position = getPositionInRange(date);

          return (
            <div
              className={`timezoneEl ${isCurrentDateMonth ? '' : 'neighboring-month-day'} ${
                isToday ? 'today' : ''
              } ${isToday && highlightToday ? 'highlight-today' : ''} ${position ? `position-${position}` : ''}`}
              onClick={() => handleDateChange(date)}
              onMouseEnter={() => {
                if (calendarType === 'selectRange' && selectedDates.start && !selectedDates.end) {
                  setHoverDate(date);
                }
              }}
              onMouseLeave={() => {
                if (calendarType === 'selectRange') {
                  setHoverDate(null);
                }
              }}>
              {tileDate.format('DD')}
              {/* markData가 있을때 달력에 점 표시 */}
              {markData && markData[formattedDate] && (
                <div className='icon_schedule__wrap'>
                  {markData[formattedDate].slice(0, 3).map((color, index) => (
                    <div key={index} className={`icon_schedule ${color}`}></div>
                  ))}
                </div>
              )}
            </div>
          );
        }}
      />
    </article>
  );
}

export default EditModalCalendar;

// --- --- --- styled-components --- --- ---
const S = {
  Calendar: styled(Calendar)`
    width: 100%;
    pointer-events: ${(props) => (props.$isAp ? 'none' : 'auto')};

    .react-calendar__viewContainer {
      padding: 8px;
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;
    }

    .react-calendar__month-view__days {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      gap: 0;
    }

    .react-calendar__tile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      background: none;
      text-align: center;
      line-height: 16px;
      height: 54px;

      abbr {
        display: none;
      }

      /* 공통 범위 배경 스타일 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        height: 38px;
        transform: translateY(-50%);
        z-index: 0;
        background-color: rgba(18, 148, 242, 0.1);
        opacity: 0;
      }

      /* 선택된 범위 스타일 */
      &.range-start,
      &.range-end,
      &.range-middle {
        &::before {
          opacity: 1;
        }
      }

      &.range-start::before {
        left: 50%;
        right: 0;
      }

      &.range-middle::before {
        left: 0;
        right: 0;
      }

      &.range-end::before {
        left: 0;
        right: 50%;
      }

      /* 호버 범위 스타일 */
      &.hover-start,
      &.hover-middle,
      &.hover-end {
        &::before {
          opacity: 1;
        }
      }

      &.hover-start::before {
        left: 50%;
        right: 0;
      }

      &.hover-middle::before {
        left: 0;
        right: 0;
      }

      &.hover-end::before {
        left: 0;
        right: 50%;
      }

      .timezoneEl {
        position: relative;
        z-index: 1;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 600;
        width: 38px;
        height: 38px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;
        transition: all 0.15s ease;

        &.position-start,
        &.position-end {
          background-color: #1294f2;
          color: white;
          z-index: 2;
        }

        &.neighboring-month-day {
          color: #9e9e9e;
        }

        &.today {
          background-color: #f2f1f1;
          color: #9e9e9e;
          flex-direction: column;
          line-height: 1;

          &:after {
            content: 'TODAY';
            font-size: 6px;
            margin-top: 2px;
          }

          &.highlight-today {
            background-color: rgb(18, 148, 242);
            color: white;
          }
        }

        .icon_schedule__wrap {
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 2px;
          justify-content: center;
          z-index: 3;

          .icon_schedule {
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
      }

      /* 호버 효과 */
      &:hover {
        background: none;

        .timezoneEl:not(.position-start):not(.position-end) {
          background-color: #e2f3ff;
        }
      }
    }

    .react-calendar__navigation {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      button {
        min-width: 44px;
        margin: 0;
        border: 0;
        outline: none;
        background: none;

        &:disabled {
          opacity: 0.5;
        }

        &:enabled:hover {
          background-color: #e2f3ff;
        }
      }
    }
  `,
};
