import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { nvl, nvlNumber } from 'utils/Common.utils';

import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';

import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';

/** Test list popup */
export default function PopTestList({ close, testTakenList, clickEvent }) {
  usePressESC(() => close());
  useScrollLock();

  const handleTrClick = (e, seq) => {
    if (nvl(seq[1]) === '') clickEvent(seq);
    else clickEvent(seq[0], seq[1], seq[2]);

    close();
  };

  return (
    <S.Wrap className='com_popup type_dim pop_test_list active'>
      <div className='pop_container l'>
        <article className='com_sec_tit'>
          <h2 className='tit'>Your Tests</h2>
          <button type='button' title='Close' onClick={close} className='button_close'>
            <ImgSvgX />
          </button>
        </article>
        <section className='pop_body'>
          <article className='com_list_box'>
            <div className='custom_scroll'>
              <table className='list'>
                <colgroup>
                  <col />
                  <col />
                  <col width='25%' />
                  <col width='12%' />
                  <col width='12%' />
                  <col width='12%' />
                </colgroup>
                <thead>
                  <tr className='sticky top'>
                    <th className='alignC'>Test set </th>
                    <th className='alignC'>Class</th>
                    <th className='alignC'>Date</th>
                    <th className='alignC'>RW</th>
                    <th className='alignC'>Math</th>
                    <th className='alignC'>View</th>
                  </tr>
                </thead>
                <tbody>
                  {testTakenList?.map((item, idx) => {
                    return (
                      <tr
                        key={idx}
                        onClick={(e) => handleTrClick(e, nvlNumber(item.uthSeq) === 0 ? [item.classSeq, item.customSetSeq, item.subject] : [item.uthSeq])}>
                        <td className='test_name'>{item.testName}</td>
                        <td className='alignC'>
                          {item.className ? (
                            item.className
                          ) : (
                            <Tooltip01 text='This is a test you have set for yourself.'>
                              <ImageSVGIconStar />
                            </Tooltip01>
                          )}
                        </td>
                        <td className='alignC'>
                          {nvl(item.uthSeqStr) === '' ? moment(item.testEndDate).format('MMM D, YYYY') : moment(item.trhRegDate).format('MMM D, YYYY')}
                        </td>
                        <td className='alignC'>{item.rwPoint === 0 ? '-' : item.rwPoint}</td>
                        <td className='alignC'>{item.mathPoint === 0 ? '-' : item.mathPoint}</td>
                        <td className='alignC'>
                          <Link
                            target='_new'
                            to={`/test/test/view?viewTest=${nvl(item.uthSeqStr) === '' ? encodeURIComponent(item.customSeqStr) : encodeURIComponent(item.uthSeqStr)}`}
                            className='svg_icon icon_view black'>
                            &nbsp;
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </article>
        </section>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_sec_tit {
      border-bottom: none;
      padding-bottom: 0;
    }
    .button_close {
      color: #111111;
      svg {
        width: 1.5rem;
      }
    }
    .com_list_box {
      .custom_scroll {
        max-height: 276px;
      }
      .list {
        tr {
          border-bottom: 1px solid #f4f4fc;
          th {
            padding: 0.875rem 0.75rem;
            background-color: #f8f8f9;
          }
          &:hover {
            td {
              background-color: #f5f8ff;
            }
          }
        }
      }
    }
  `,
};
