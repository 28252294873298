import ExamAnswerItem from './ExamAnswerItem';
import styled from 'styled-components';

/** 문제 List 컴포넌트 */
export default function ExamAnswerList({ questionOptions = [], selectedAnswer, setSelectedAnswer, undoAnswer, setUndoAnswer }) {
  return (
    <S.ExamAnswerList>
      {questionOptions.map((item, index) => (
        <ExamAnswerItem
          key={index}
          answerNum={index + 1}
          setSelectedAnswer={setSelectedAnswer}
          isSelected={selectedAnswer === index + 1}
          isUndo={false}
          answerContent={item.content}
        />
      ))}
    </S.ExamAnswerList>
  );
}

const S = {
  ExamAnswerList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
};
