import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

import CollapsibleList from 'components/com/tests/_components/_common/_CollapsibleList/_CollapsibleList';
import CollapsibleContentItemContainer from 'components/com/tests/_components/_common/_CollapsibleList/CollapsibleContentItemContainer';
import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';

/** ByClass에서 클래스 별 테스트 리스트 컴포넌트 - Class 단위 */
export default function ByClassListSection({ testData, classEditClick, classDeleteClick, setSimpleAlert }) {
  const [isShowPdfPopup, setIsShowPdfPopup] = useState(false);
  const [selectedCustomSetSeqs, setSelectedCustomSetSeqs] = useState([]);
  const selectedTestInfoRef = useRef({
    pdfData: '',
    editData: {
      testName: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
    deleteData: [],
  });
  const [selectedClassInfo, setSelectedClassInfo] = useState({
    0: {
      headerInfo: {
        classSeq: 0,
        className: '',
        customSetSeq: 0,
        testName: '',
        testStartDate: '',
        testEndDate: '',
        customSetSeqStr: '',
      },
      contentInfo: [
        {
          userName: '',
          userSeq: 0,
          upFileSeq: 0,
          testDate: '',
          uthSeq: 0,
        },
      ],
    },
  });

  const getFormattedDate = (date) => isoTimeToDayjsTZ(date).format('YYYY.MM.DD');

  /** 편집 버튼 클릭 핸들러 - 편집 페이지로 이동 ( Test 미선택시 alert ) */
  // const handleClickEditBtn = () => {
  //   if (selectedCustomSetSeqs.length === 0) {
  //     setSimpleAlert({
  //       visible: true,
  //       modalText: 'Please check the test you want to edit.',
  //     });
  //   } else if (selectedCustomSetSeqs.length > 1) {
  //     setSimpleAlert({
  //       visible: true,
  //       modalText: 'You can only edit one test at a time.',
  //     });
  //   } else {
  //     classEditClick(selectedTestInfoRef.current.editData);
  //   }
  // };
  /** 삭제 버튼 클릭 핸들러 - 삭제 페이지로 이동 ( Test 미선택시 alert ) */
  const handleClickDeleteBtn = () => {
    const selectedClassInfoList = Object.values(selectedClassInfo);
    if (selectedClassInfoList.length === 0) {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the test you want to delete.',
      });
    }
    // 해당 class에 속한 학생들의 uth 추출
    else {
      const toDeleteUthSeqs = selectedClassInfoList.flatMap((classInfo) => classInfo.contentInfo).map((content) => content.uthSeq);

      classDeleteClick(toDeleteUthSeqs);
    }
  };

  // 고유 키 생성 함수
  const generateUniqueKey = (item) => {
    return `${item.customSetSeq}_${item.testStartDate}-${item.testEndDate}`; // 시간 정보를 포함한 고유 키 생성
  };

  useEffect(() => {
    setSelectedClassInfo({});
  }, [testData]);

  return (
    <S.ClassTestListSection>
      <div className='class_header'>
        <h2 className='class_header_title'>{testData.className}</h2>

        <div className='class_header_btn_wrap'>
          {/* 1차 배포에서 Edit 버튼 사용 안함 */}
          <CommonTestBtn hoverText='Edit' onClick={() => {}} iconBtn disabled>
            <i className='svg_icon icon_write'>&nbsp;</i>
          </CommonTestBtn>
          <CommonTestBtn hoverText='Delete' onClick={handleClickDeleteBtn} iconBtn>
            <i className='svg_icon icon_trash'>&nbsp;</i>
          </CommonTestBtn>
        </div>
      </div>

      <CollapsibleList
        className='class_list_wrap custom_scroll'
        collapsibleListId={testData.classSeq} // input, label의 고유 id 생성을 위한 고유 값
        items={testData.testSetList}
        itemId='testSetSeq'
        expandedContentInfo={{
          contentKey: 'studentList',
          itemId: 'uthSeq',
        }}
        headerSelectable={true}
        contentSelectable={true}
        selectedDataInfo={selectedClassInfo}
        setSelectedDataInfo={setSelectedClassInfo}
        getUniqueKey={generateUniqueKey} // 고유 키 생성 함수 전달
        renderHeader={(itemInfo) => (
          <div className='title_wrapper'>
            <p className='title'>{itemInfo.testName}</p>
            <p className='sub_title'>{`${getFormattedDate(itemInfo.testStartDate)} - ${getFormattedDate(itemInfo.testEndDate)}`}</p>
          </div>
        )}
        renderContent={(studentInfo) => {
          return (
            <CollapsibleContentItemContainer
              userInfo={{
                userName: studentInfo.userName,
                userSeq: studentInfo.userSeq,
                upFileSeq: studentInfo.upFileSeq,
              }}
              title={studentInfo.userName}
              subTitle={isoTimeToDayjsTZ(studentInfo.testDate).format('YYYY.MM.DD hh:mm A')}
            />
          );
        }}
      />
    </S.ClassTestListSection>
  );
}

const S = {
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    max-width: calc(50% - 0.5rem);
    min-width: 0;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn_wrap {
        display: flex;
        gap: 0.5rem;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
};
