import ProfileImage from 'components/_common/ProfileImage';
import styled from 'styled-components';
import { USER_LEVELS } from 'utils/constants.js';

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
/** 사용자 유형 문자열 리터럴 */
const memberType = {
  [USER_LEVELS.STUDENT]: 'Student',
  [USER_LEVELS.TEACHER]: 'Academy Teacher', // 선생님
  [USER_LEVELS.TUTOR]: 'Private Tutor', // 강사
  [USER_LEVELS.ACADEMY]: 'Academy', // 학원 관리자
  [USER_LEVELS.PARENT]: 'Parent', // 학부모
  [USER_LEVELS.TUBLET_ADMIN]: 'Company Admin', // 투블릿 관리자
  [USER_LEVELS.TUBLET_TUTOR]: 'Tutor', // 투블릿 강사
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 프로필 좌상단 유저 정보를 보여주는 카드 */
export default function ProfileCard({ accountInfo }) {
  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  const userType = memberType[accountInfo.userLevel] || 'Unknown';
  /** 아카데미 프로필인지 확인 */
  const isAcademy = userType === memberType[USER_LEVELS.ACADEMY];
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  // 아카데미에 속하면서 개인 사용자 일때 Academy Admin 외에는 userType
  const displayUserType = isAcademy
    ? (accountInfo.infoMode === 'personal' && isAcademy) || accountInfo.companyName === ''
      ? 'Academy Admin'
      : userType
    : userType;

  const displayProfileName = accountInfo.infoMode === 'personal' ? accountInfo.userName : accountInfo.companyName;

  const renderUserInfo =
    displayUserType === 'Academy'
      ? {
          academyName: displayProfileName,
          ecSeq: accountInfo.ecSeq,
          upFileSeq: accountInfo.academyUpFileSeq,
        }
      : {
          userName: displayProfileName,
          userSeq: accountInfo.userSeq,
          upFileSeq: accountInfo.upFileSeq,
        };
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.ProfileCardWrap>
      <ProfileImage userInfo={renderUserInfo} isB2B={isAcademy} isAcademyImg={isAcademy} />
      <div className='user_info_section'>
        <p className='user_authority_text'>{displayUserType}</p>
        <p className='user_name'>{displayProfileName}</p>
      </div>
    </S.ProfileCardWrap>
  );
}

const S = {
  ProfileCardWrap: styled.div`
    display: flex;
    align-items: center;
    height: 5rem;
    gap: 0.9375rem;
    padding: 0 1.25rem;

    .user_info_section {
      display: flex;
      gap: 0.125rem;
      flex-direction: column;
      .user_authority_text {
        color: #0068bd;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: capitalize;
      }
      .user_name {
        color: #000;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  `,
};
