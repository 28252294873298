import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils.js';

//components
import QuestionsStatisticsSection from './_components/advancedReportComponents/section/QuestionsStatisticsSection';
import Box from '../../common/Box';
import AnswerRateGraphsSection from './_components/advancedReportComponents/section/AnswerRateGraphsSection';
import ExamStatisticsGraphSection from './_components/advancedReportComponents/section/ExamStatisticsGraphSection';

import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';
import CheckViewAboutAverageOfGateplus from 'components/dashboard/_components/layouts/sections/advancedReport/_components/CheckViewAboutAverageOfGateplus';

const currentTestModuleListInitData = [
  {
    // 선택된 모듈의 Test에 대한 정보 ( TEST > MODULES )
    setSubject: '', // "F" | "E" | "M"       => 모듈들의 시험 과목, FullSet(F) 혹은 단일 과목일때(adaptive) ( 영어("E") | 수학("M") )
    setDifficulty: '', // "F" | "E" | "H"    => FullSet(F) 혹은 단일 과목일때(adaptive) ( Easy("E") | Hard("H") )
    // 해당 모듈에 대한 정보
    testModuleSeq: 0, // number              => 선택된 모듈의 시퀀스
    tmSubject: '', // "E" | "M"              => 모듈 과목 ( 영어(E) | 수학(M) )
    tmDifficulty: null, // null | "E" | "H"  => 모듈 난이도 ( Easy("E") | Hard("H") ), moduleNum이 "1" 일때는 null
    moduleNum: '', // "1" | "2"              => 모듈 번호
    isTested: false, // boolean              => 해당 모듈을 풀었는지 여부
  },
];

/** "/com/score" 페이지의 "Advanced Report" 영역 컴포넌트 */
export default function AdvancedReport({ mode }) {
  /** 현재 studentSeq - Route Parameter */
  const { classSeq: encClassSeq, studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_TEST);
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  /** "View GATEPLUS Average" 체크박스 상태 */
  const [checkViewAboutAverageOfGateplus, setCheckViewAboutAverageOfGateplus] = useState(false);
  const [activeSatBox, setActiveSatBox] = useState('RW');
  /** 현재 Test의 모듈들에 대한 정보 */
  const [currentTestModuleList, setCurrentTestModuleList] = useState(currentTestModuleListInitData);
  /** 현재 선택된 모듈 정보 */
  const [selectedModuleInfo, setSelectedModuleInfo] = useState({
    // 선택된 모듈의 Test에 대한 정보 ( TEST > MODULES )
    setSubject: '', // "F" | "E" | "M"       => 모듈들의 시험 과목, FullSet(F) 혹은 단일 과목일때(adaptive) ( 영어("E") | 수학("M") )
    setDifficulty: '', // "F" | "E" | "H"    => FullSet(F) 혹은 단일 과목일때(adaptive) ( Easy("E") | Hard("H") )
    // 해당 모듈에 대한 정보
    testModuleSeq: 0, // number              => 선택된 모듈의 시퀀스
    tmSubject: '', // "E" | "M"              => 모듈 과목 ( 영어(E) | 수학(M) )
    tmDifficulty: null, // null | "E" | "H"  => 모듈 난이도 ( Easy("E") | Hard("H") ), moduleNum이 "1" 일때는 null
    moduleNum: '', // "1" | "2"              => 모듈 번호
    isTested: false, // boolean              => 해당 모듈을 풀었는지 여부
  });

  useEffect(() => {
    /** 선택된 Test의 모듈 정보 가져오기 */
    const getTestModules = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedModuleInfoList = response.result.moduleInfoList;
          setCurrentTestModuleList(fetchedModuleInfoList);

          // F: fullSet, E: RW, M: Math
          // E(영어) 거나 F(FullSet) 이면 RW 과목의 첫번째 모듈 선택, M(수학) 이면 Math의 첫번째 모듈 선택
          if (fetchedModuleInfoList[0]?.setSubject === 'E' || fetchedModuleInfoList[0]?.setSubject === 'F') {
            setSelectedModuleInfo(fetchedModuleInfoList.find((i) => i.tmSubject === 'E' && i.moduleNum === '1'));
            setActiveSatBox('RW');
          } else if (fetchedModuleInfoList[0]?.setSubject === 'M') {
            setSelectedModuleInfo(fetchedModuleInfoList.find((i) => i.tmSubject === 'M' && i.moduleNum === '1'));
            setActiveSatBox('MATH');

            // 모듈 정보가 없을 경우 (시험이 삭제됨 or 기타 이유로 정보가 없음)
          } else {
            setSelectedModuleInfo(currentTestModuleListInitData[0]);
            console.error('Error: 해당 시험에 모듈 정보 없음', fetchedModuleInfoList);
          }
        } else {
          console.error('Error: ', response);
        }
      };

      // mode에 따라 apiURL 설정
      let apiURL = '';
      if (mode === 'student') apiURL = `/api/dsat/customsets/${queryCurTestSeq}/modules?uthSeq=${queryCurUthSeq}`;
      if (mode === 'class' || mode === 'lecture') apiURL = `/api/dsat/customsets/${queryCurScoreTestSeq}/modules?classSeq=${classSeq}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('', error));
    };

    if (mode === 'student' && queryCurTestSeq) getTestModules();
    if ((mode === 'class' || mode === 'lecture') && queryCurScoreTestSeq) getTestModules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurTestSeq, queryCurScoreTestSeq, studentSeq]);

  /** 과목 변경시 선택된 모듈까지 같이 변경해주는 핸들러 */
  const handleSelectSubject = (subject) => {
    setActiveSatBox(subject);
    const subjectValue = subject === 'RW' ? 'E' : 'M';

    setSelectedModuleInfo(currentTestModuleList.find((item) => item.tmSubject === subjectValue && item.moduleNum === '1'));
  };
  /** "View GATEPLUS Average" 체크박스 핸들러 */
  const handleCheckViewAboutAverageOfGateplus = () => {
    setCheckViewAboutAverageOfGateplus((current) => !current);
  };

  /** 현재 선택된 모듈의 시험 과목 */
  const currentModuleSubject = selectedModuleInfo?.setSubject; // "F" | "E" | "M"

  if (!queryCurScoreTestSeq) return null;

  return (
    <S.Wrap className='full com_container'>
      <Box className='main_box'>
        <div className='top_area view_average_gateplus'>
          <h4 className='section_title'>Advanced Report</h4>
          <CheckViewAboutAverageOfGateplus
            className='show_gateplus_avg_checkbox'
            checkState={checkViewAboutAverageOfGateplus}
            handleChange={handleCheckViewAboutAverageOfGateplus}
          />
        </div>

        <S.GraphContainer>
          <S.GraphBoxContainer>
            <AnswerRateGraphsSection
              mode={mode}
              activeSatBox={activeSatBox}
              setActiveSatBox={setActiveSatBox}
              handleSelectSubject={handleSelectSubject}
              checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
              currentModuleSubject={currentModuleSubject}
            />

            {/* 241129 Trello 카드 "335. 관리자쪽 Score 추가 요청사항" 참고 */}
            {/* <ExamStatisticsGraphSection mode={mode} selectedSubject={activeSatBox} checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} /> */}
          </S.GraphBoxContainer>

          {mode === 'student' // 학생 모드일때는 queryCurScoreTestSeq가 있을때만 QuestionsStatisticsSection 랜더링
            ? queryCurTestSeq &&
              selectedModuleInfo && (
                <QuestionsStatisticsSection
                  activeSatBox={activeSatBox}
                  setActiveSatBox={setActiveSatBox}
                  mode={mode}
                  currentTestModuleList={currentTestModuleList}
                  selectedModuleInfo={selectedModuleInfo}
                  setSelectedModuleInfo={setSelectedModuleInfo}
                  handleSelectSubject={handleSelectSubject}
                />
              )
            : // 그 외의 모드일때는 QuestionsStatisticsSection 제한 없이 랜더링
              selectedModuleInfo && (
                <QuestionsStatisticsSection
                  activeSatBox={activeSatBox}
                  setActiveSatBox={setActiveSatBox}
                  mode={mode}
                  currentTestModuleList={currentTestModuleList}
                  selectedModuleInfo={selectedModuleInfo}
                  setSelectedModuleInfo={setSelectedModuleInfo}
                  handleSelectSubject={handleSelectSubject}
                />
              )}
        </S.GraphContainer>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex-wrap: wrap;
    margin-top: 0;

    .main_box {
      padding: 1.25rem 1.5rem;
      width: 100%;
      .view_average_gateplus {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        padding: 0 0.5rem;
        .section_title {
          color: #111111;
          font-weight: 600;
          font-size: 1.25rem;
        }
        .show_gateplus_avg_checkbox {
          input {
            display: none;
          }
        }
      }
    }

    .com_sec_tit {
      width: 100%;
    }

    .boxes_wrap {
      display: flex;
      gap: 1rem;
    }
  `,

  GraphContainer: styled.section`
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
  `,

  GraphBoxContainer: styled.section`
    display: flex;
    gap: 1.25rem;
    flex-direction: column;

    .average_time_container {
      display: flex;
      flex-direction: row;
      gap: 1.25rem;
    }
  `,
};
