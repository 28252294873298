import React, { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';
import dayjsTZ from 'utils/functions/time/dayjs-config';

// hook
import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';

// Components
import PopClassesRow from './_PopClassesRow';
import ClassDetails from './_ClassDetails';
import EditModalCalendar from 'components/com/tests/scheduled/_components/EditModalCalendar';

import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';

/**
 * Classes에서 class 정보를 생성 & 편집하는 모달 (생성 & 편집 공통 사용)
 * @description
 * 🔍 검색 키워드 - #modal #com/students/classes
 */
export default function PopClassManage({ handleClassManageOpen, editClassData, searchClasses, setAlert }) {
  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [userInfo, _] = useState(request.tokenDecoder());

  /** 프로필 모달 State */
  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean  => 프로필 모달 노출 여부
    userSeq: 0, // number       => 학생 시퀀스
  });

  /** 검색 된 학생 목록 */
  const [searchStudentData, setSearchStudentData] = useState([]);
  /** 선택 된 학생 목록 */
  const [checkedStudents, setCheckedStudents] = useState(editClassData ? editClassData.studentList : []);
  const [isAllChecked, setIsAllChecked] = useState(editClassData ? true : false);

  /** 입력 요소 값 및 해당 값에 대한 에러 메세지 */
  const [classInfo, setClassInfo] = useState(
    editClassData && editClassData.classId
      ? {
          className: {
            value: editClassData.classTitle,
            error: null,
          },
          date: {
            value: {
              start: editClassData.startDate,
              end: editClassData.endDate,
            },
            error: null,
          },
          studentList: {
            value: editClassData.studentList,
            error: null,
          },
          classId: editClassData.classId,
        }
      : {
          className: {
            value: null,
            error: null,
          },
          date: {
            value: {
              start: null,
              end: null,
            },
            error: null,
          },
          studentList: {
            value: [],
            error: null,
          },
          classId: null,
        }
  );
  const [selectedRange, setSelectedRange] = useState([]);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const classTitleInputRef = useRef(null);
  const searchInputRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /** 프로필 자세히 보기 핸들러 */
  const handleShowProfile = (userSeq) => setProfileModalInfo({ visible: true, userSeq: userSeq });

  /** "Class Name" Input 창 Key Down 핸들러 */
  const handleEnterDown = ({ event, ref }) => {
    if (event.type === 'keyup') {
      setClassInfo((current) => {
        return {
          ...current,
          className: {
            ...current.className,
            value: ref.current.value,
            error: ref.current.value.length > 0 ? null : current.className.error,
          },
        };
      });
    }
  };

  /** 컴포넌트에 인자로 보낼 날짜 변경 핸들러 */
  const handleSelectDate = (dateArr) => {
    setSelectedRange(dateArr);
    if (dateArr.length === 2) {
      setClassInfo((current) => ({
        ...current,
        date: {
          ...current.date,
          value: {
            start: dateArr[0],
            end: dateArr[1],
          },
          error: dateArr[0] && dateArr[1] ? null : current.date.error,
        },
      }));
    }
  };

  /** 학생 검색 핸들러 */
  const handleSearchStudents = (searchKeyword = '') => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const searchData = response.result.studentList;

        setSearchStudentData(searchData.sort((a, b) => a.userName.localeCompare(b.userName)));
      }
    };

    if (searchKeyword.length !== 0) {
      request.get(`/api/academy/${userInfo.ecSeq}/students?searchKeyword=${searchKeyword}`, null, successHandler);
    } else {
      request.get(`/api/academy/${userInfo.ecSeq}/students`, null, successHandler);
    }
  };

  /** 학생 목록 선택 핸들러 */
  const handleCheckboxChange = (student, isChecked) => {
    if (isChecked) {
      setCheckedStudents((prev) => {
        const isAlreadyIncluded = prev.some((item) => item.userSeq === student.userSeq);
        if (!isAlreadyIncluded) return [...prev, student];
        else return prev;
      });
    } else setCheckedStudents((prev) => prev.filter((data) => data.userSeq !== student.userSeq));
  };

  /** 학생 목록 "전체" 선택 핸들러 */
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);

    if (isChecked) {
      const newCheckedStudents = [...checkedStudents];
      searchStudentData.forEach((student) => {
        if (!newCheckedStudents.some((checkedStudent) => checkedStudent.userSeq === student.userSeq)) {
          newCheckedStudents.push(student);
        }
      });
      setCheckedStudents(newCheckedStudents);
    } else {
      const filteredCheckedStudents = checkedStudents.filter(
        (checkedStudent) => !searchStudentData.some((student) => student.userSeq === checkedStudent.userSeq)
      );
      setCheckedStudents(filteredCheckedStudents);
    }
  };

  /** Form 제출 핸들러 */
  const handleSubmitBtn = () => {
    if (!classInfo?.className) {
      // "Class Title" 값을 입력하지 않고 제출 했을 경우.
      setClassInfo((current) => {
        return {
          ...current,
          className: {
            ...current.className,
            error: 'Please enter the class name',
          },
        };
      });
    }
    if (!classInfo?.startDate || !classInfo?.endDate) {
      // "날짜" 값을 입력하지 않고 제출 했을 경우.
      setClassInfo((current) => {
        return {
          ...current,
          date: {
            ...current.date,
            error: 'Please select the start and end dates',
          },
        };
      });
    }
    if (classInfo?.studentList.value.length <= 0) {
      // "학생 목록" 값을 입력하지 않고 제출 했을 경우.
      setClassInfo((current) => {
        return {
          ...current,
          studentList: {
            ...current.studentList,
            error: 'Please select a student',
          },
        };
      });
    }

    if (classInfo.className.error || classInfo.date.error || classInfo.studentList.error) {
      // 존재하는 인풋 창에 하나라도 에러가 발생 한다면, 제출 하지 않음
      return;
    }

    let params = {
      ecSeq: userInfo.ecSeq,
      className: classInfo.className.value,
      classStartDate: dayjsTZ(classInfo.date.value.start.format('YYYY-MM-DD')).format(),
      classEndDate: dayjsTZ(classInfo.date.value.end.format('YYYY-MM-DD')).endOf('day').format(),
      seqStr: checkedStudents.length === 0 ? '0' : checkedStudents.map((student) => student.userSeq).join('|'),
      ...(editClassData ? { modiUserSeq: userInfo.ecSeq } : { regUserSeq: userInfo.userSeq }),
    };

    const updatedStudentList = checkedStudents.map((student) => student.userName);

    if (editClassData) {
      // "편집" 버튼을 통해서 진입 했다면..
      const successHandler = (response) => {
        if (response.code === 200) {
          handleClassManageOpen(false);
          searchClasses();
          setAlert({
            active: true,
            message: `You have moved ${updatedStudentList.join(', ')} from their original class`,
          });
        }
      };
      request.put(`/api/academy/classes/${classInfo.classId}`, params, successHandler);
    } else {
      // "생성" 버튼을 통해서 진입 했다면..
      const successHandler = (response) => {
        if (response.code === 200) {
          handleClassManageOpen(false);
          searchClasses();
          setAlert({
            active: true,
            message: `New class successfully created.\nYou have moved ${updatedStudentList.join(', ')} from their original class.`,
          });
        }
      };
      request.post('/api/academy/classes', params, successHandler);
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  usePressESC(() => handleClassManageOpen(false));
  useScrollLock();
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    handleSearchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClassInfo((prev) => ({
      ...prev,
      studentList: {
        ...prev.studentList,
        value: checkedStudents,
      },
    }));
  }, [checkedStudents]);

  useEffect(() => {
    const isAllChecked = searchStudentData.every((student) => checkedStudents.some((checkedStudent) => checkedStudent.userSeq === student.userSeq));
    setIsAllChecked(isAllChecked);
    if (searchStudentData.length === 0) setIsAllChecked(false);
  }, [searchStudentData, checkedStudents]);

  useEffect(() => {
    setClassInfo((current) => ({
      ...current,
      studentList: {
        ...current.studentList,
        value: checkedStudents,
        error: checkedStudents.length > 0 ? null : current.studentList.error,
      },
    }));
  }, [checkedStudents]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.Wrap className='com_popup type_dim pop_manage active'>
        <div className='pop_container'>
          <section className='pop_body custom_scroll'>
            <div className='left'>
              {/*1.class name*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_1 black'>&nbsp;</i>
                  Class Name
                </h2>
              </article>
              <article className='search_sec'>
                <div className='search'>
                  <input
                    type='text'
                    className='input'
                    placeholder='Enter class name'
                    defaultValue={editClassData?.className}
                    ref={classTitleInputRef}
                    onKeyUp={(event) => handleEnterDown({ event, ref: classTitleInputRef })}
                  />
                </div>
                <div className='error_guide'>{classInfo?.className?.error && classInfo.className.error}</div>
              </article>
              {/*2.start date*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_2 black'>&nbsp;</i>
                  Start and End Date
                </h2>
              </article>
              <article className='com_calendar_wrap'>
                <EditModalCalendar selectedDateState={selectedRange} setSelectedDateState={handleSelectDate} />
                <div className='error_guide'>{classInfo?.date?.error && classInfo.date.error}</div>
              </article>
            </div>
            <div className='center'>
              {/*3.Student*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_3 black'>&nbsp;</i>
                  Total Students
                </h2>
              </article>
              <article className='search_sec'>
                <div className='search'>
                  <input
                    type='text'
                    className='input'
                    placeholder='Search students'
                    ref={searchInputRef}
                    onKeyDown={(e) => {
                      e.key === 'Enter' && handleSearchStudents(searchInputRef.current.value);
                    }}
                  />
                  <button className='com_btn black' onClick={() => handleSearchStudents(searchInputRef.current.value)}>
                    SEARCH
                  </button>
                </div>
              </article>
              <article className='com_list'>
                <div className='scroll custom_scroll'>
                  <table className='list'>
                    <colgroup>
                      <col width='40' />
                      <col />
                      <col width='100' />
                    </colgroup>
                    <thead>
                      <tr className='sticky top'>
                        <th>
                          <input type='checkbox' id='chkAll' className='com_chk' checked={isAllChecked} onChange={handleAllCheckboxChange} />
                          <label htmlFor='chkAll'>&nbsp;</label>
                        </th>
                        <th>Name</th>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchStudentData.map((studentData, idx) => {
                        return (
                          <Fragment key={idx}>
                            <PopClassesRow
                              keyIdx={idx}
                              student={studentData}
                              checked={checkedStudents.some((student) => student.userSeq === studentData.userSeq)}
                              onCheckboxChange={handleCheckboxChange}
                              handleShowProfile={handleShowProfile}
                            />
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='error_guide'>{classInfo?.studentList?.error && classInfo.studentList.error}</div>
              </article>
            </div>
            <div className='right'>
              <button className='btn_pop_close svg_icon black' onClick={() => handleClassManageOpen(false)}></button>
              <ClassDetails
                classInfo={classInfo}
                checkedStudents={checkedStudents}
                onCheckboxChange={handleCheckboxChange}
                handleShowProfile={handleShowProfile}
              />
              <article className='com_btn_wrap bottom'>
                <button type='button' className='com_btn point l full' onClick={handleSubmitBtn}>
                  {editClassData ? 'Save Changes' : 'Create New Class'}
                </button>
              </article>
            </div>
          </section>
        </div>
      </S.Wrap>

      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    .pop_container {
      width: auto;
      /* max-width: 1104px; */
      overflow: hidden;
    }
    .pop_body {
      overflow-x: hidden;
      display: flex;
      justify-content: space-between;
      min-height: 41.75rem;
      .error_guide {
        color: #f24b4b;
        font-size: 0.8125rem;
        font-weight: 400;
        margin-top: 0.125rem;
      }
      .com_sec_tit {
        margin-top: 0;
        margin-bottom: 0;
      }
      .search_sec + .com_sec_tit {
        margin-top: 0;
      }
      .com_list_box {
        .list {
          .com_btn {
            padding-inline: 0.5rem;
          }
        }
      }
      .com_list {
        .list {
          .com_btn {
            padding-inline: 12px;
            color: #7f8998;
            background: #f2f2f2;
            border-radius: 0.25rem;
            font-weight: 400;
          }
        }
      }
      & > .left {
        width: 38%;
        border-right: 1px solid #f6f6f6;
        padding: 20px;
        .search_sec {
          padding: 1.25rem;
          margin-bottom: 0;
        }
        .com_calendar_wrap {
          margin-top: 30px;
        }
      }
      & > .center {
        width: 20rem;
        padding: 20px;
        border-right: 1px solid #ebebeb;
        .search_sec {
          padding: 1.25rem 0;
          margin-bottom: 0;
          .com_btn {
            min-height: auto;
            height: 100%;
            border-radius: 0;
          }
        }
        .com_list {
          min-height: auto;
          .scroll {
            height: 475px;
            overflow-y: auto;
          }
        }
      }
      & > .right {
        width: 20rem;
        position: relative;
        padding: 20px;
        border-radius: 0 10px 10px 0;
        .class_info {
          padding: 0.94rem 0;
        }
        .com_list_box {
          .scroll {
            height: 342px;
            background: #fff;
          }
          .list {
            th {
              &.table_tit {
                font-size: 12px;
                font-weight: 700;
                .wrap {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .num {
                    display: inline-flex;
                    align-items: center;
                    gap: 2px;
                    font-weight: 400;
                    .svg_icon {
                      position: relative;
                      right: auto;
                      top: auto;
                      background-size: 16px auto;
                    }
                  }
                }
              }
            }
          }
        }
        &.com_profile {
          margin-right: -1px;
        }
      }

      & .pop_profile {
        position: relative;
        width: auto;
        height: auto;
        .pop_container {
          transform: translate(0, 0);
          .com_profile {
            width: 100%;
          }
        }
      }
    }
  `,
};
