import styled from 'styled-components';

import AnswerChoiceItem from './AnswerChoiceItem';

/** 답안 선택지 (Answer Choice) 리스트 컴포넌트 */
export default function AnswerChoiceList({ answerChoiceInfo, setAnswerChoiceInfo, onSaveAll }) {
  console.log(`\n┏━━━ 💡 💡 answerChoiceInfo 💡 💡 ━━━\n`, answerChoiceInfo, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 답안 content 변경 이벤트 핸들러 */
  const handleEditorChange = (answerId, value) => {
    setAnswerChoiceInfo((prev) => ({
      ...prev,
      answerChoices: prev.answerChoices.map((choice) => (choice.id === answerId ? { ...choice, content: value } : choice)),
    }));
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  return (
    <S.AnswerChoiceContainer>
      <S.SectionTitle>Answer Choice</S.SectionTitle>

      <S.AnswerChoiceList>
        {answerChoiceInfo.answerChoices.map((item) => (
          <AnswerChoiceItem
            key={item.id}
            answerChoice={item}
            editorChange={handleEditorChange}
            answerChoiceInfo={answerChoiceInfo}
            setAnswerChoiceInfo={setAnswerChoiceInfo}
            onSaveAll={onSaveAll}
          />
        ))}
      </S.AnswerChoiceList>
    </S.AnswerChoiceContainer>
  );
}

const S = {
  AnswerChoiceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,
  AnswerChoiceList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
