/** "Grading" 목록 페이지의 순서 정렬 관련 파라미터 문자열 */
export const GRADING_LIST_ORDER_FILTER_PARAMS = {
  status: {
    KEY: 'statusOrder', // 시험 진행 상태
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  subjectName: {
    KEY: 'subjectNameOrder', // 시험 과목 이름
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  testName: {
    KEY: 'testNameOrder', // 시험 이름
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  teacherName: {
    KEY: 'teacherNameOrder', // 선생님 이름
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  studentName: {
    KEY: 'studentNameOrder', // 학생 이름
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  score: {
    KEY: 'scoreOrder', // 점수
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
  testDate: {
    KEY: 'testDateOrder', // 시험 종료 날짜
    value: {
      ASCENDING: 'ascending', // 오름차순
      DESCENDING: 'descending', // 내림차순
    },
  },
};
