import React, { useEffect } from 'react';
import styled from 'styled-components';

import FavoriteButton from './FavoriteButton';
import TestLabel from './TestLabel';
import { getColorBySeq } from 'components/ap/_utils/functions/getColorBySeq';
import { AP_SUBJECT_CATEGORY } from 'components/ap/_utils/constants/subjectsCategoryData';

const { useSelector } = require('react-redux');

/** 시험 모듈 버튼 컴포넌트 */
export default function TestButton({ isB2c, itemData, setCustomSetInfo, selectTest, setSelectTest }) {
  const { isLiked, testSetSeq, testSetName, subjectName, participantCount, participantUsers } = itemData;

  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /** 시험 모듈 선택 핸들러 */
  const handleToggleActive = (item) => {
    if (!selectTest.includes(item)) {
      const updateSelectList = [...selectTest, item];
      setSelectTest(updateSelectList);
    } else {
      const updateSelectList = selectTest.filter((key) => key !== item);
      setSelectTest(updateSelectList);
    }
  };

  return (
    <S.Wrap className={`${selectTest.includes(itemData) ? 'active' : ''}`}>
      <div className='top_info'>
        <div className='label_wrap'>
          <TestLabel
            displayText={'Conflict'}
            studentsNumber={isB2c ? null : stateSetTest.selectedStudents.length}
            isB2c={isB2c}
            takenUserDataArray={participantUsers}
          />
        </div>
        <FavoriteButton active={isLiked} seq={testSetSeq} isB2c={isB2c} setCustomSetInfo={setCustomSetInfo} />
      </div>
      <button className='text_area' onClick={() => handleToggleActive(itemData)}>
        <S.LabelByApSubject bgColor={getColorBySeq({ apSubjectCategory: AP_SUBJECT_CATEGORY, seq: String(testSetSeq) })}>{subjectName}</S.LabelByApSubject>
        <span className='tit_field'>{testSetName}</span>
      </button>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border: 1px solid #d2dbe2;
    padding: 0.75rem 0.75rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
    border-radius: 10px;
    align-self: stretch;
    width: calc((100% - 30px) / 3);
    min-width: calc((100% - 30px) / 3);
    max-width: calc((100% - 30px) / 3);
    position: relative;
    &:hover {
      border-color: #8cabf0;
      background-color: #fbfbff;
      color: #11456f;
    }
    .top_info {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      min-height: 1.25rem;
      width: 100%;
      .label_wrap {
        display: flex;
        gap: 0.25rem;
      }
    }
    .text_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      text-align: center;
    }
    .tit_field {
      color: #111;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem;
    }
    .txt_field {
      font-size: 0.625rem;
      line-height: 1rem;
      color: $emphasis;
      & + .com_chk_wrap {
        margin-top: 0.625rem;
      }
    }
    &.active {
      border-color: #477bff;
      background-color: #f7f8ff;
      & :is(.tit_field, .txt_field) {
        color: #0068bd;
      }
      .layer_pop_area {
        color: #2c85cf;
        background-color: #fff;
        border-color: #d1deff;
        &:hover {
          border-color: var(--point-color);
          color: var(--point-color);
        }
      }
    }
  `,

  LabelByApSubject: styled.span`
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#efefef')};
    border-radius: 0.25rem;
    display: flex;
    padding: 0.125rem 0.25rem;
    justify-content: center;
    align-items: center;
    color: #505050;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1rem;
  `,
};
