import styled from 'styled-components';
import { useState } from 'react';
import { ReactComponent as ImageSvgIconExamMore } from 'assets/img/svg/exam_more.svg';
import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';

import DirectionsPopupBtn from 'components/ap/_components/examLayout/examHeader/_components/directionsPopup/DirectionsPopupBtn';

/** ap/question-bank/test-editor 에서 사용될 header */
export default function TestEditorHeader({ currentQuestion }) {
  const [isShowDirectionsPopup, setIsShowDirectionsPopup] = useState(false);

  const channel = new BroadcastChannel('editor-channel');

  const handleClickPreview = () => {
    const previewWindow = window.open('/ap/question-bank/test-preview', '_blank');

    if (previewWindow) {
      // 자식 창에서 준비 완료 신호를 보내면 데이터 전송
      channel.onmessage = (event) => {
        if (event.data === 'PREVIEW_WINDOW_READY') {
          const data = {
            currentQuestion,
          };
          channel.postMessage(data);
        }
      };
    }
  };

  const leftSection = (
    <S.LeftArea>
      <p className='module_title'>
        {currentQuestion.section_name} : {currentQuestion.part_name}
      </p>
      <DirectionsPopupBtn isShowDirectionsPopup={isShowDirectionsPopup} setIsShowDirectionsPopup={setIsShowDirectionsPopup} />
    </S.LeftArea>
  );

  const centerSection = (
    <S.CenterArea>
      <p className='test_name'>{currentQuestion.test_name}</p>
      <p className='subject_name'>{currentQuestion.subject_name}</p>
    </S.CenterArea>
  );

  const rightSection = (
    <S.RightArea>
      <button className='preview_btn' onClick={handleClickPreview}>
        Preview
      </button>
      <button className='exam_more_btn'>
        <ImageSvgIconExamMore />
      </button>
    </S.RightArea>
  );

  return <ApExamHeaderBase leftSection={leftSection} centerSection={centerSection} rightSection={rightSection} />;
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .module_title {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  CenterArea: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    text-align: center;
    .test_name {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .subject_name {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.75rem;
    }
  `,

  RightArea: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    padding-right: 3.875rem;

    .preview_btn {
      font-size: 1.125rem;
      font-weight: 500;
      padding: 0.5rem 1.25rem;
      border-radius: 6.25rem;
      background-color: #ffde88;
      cursor: pointer;
      line-height: 1.75rem;
    }

    .exam_more_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      cursor: pointer;
      position: relative;

      .svg_icon {
        width: 0.9375rem;
        height: 0.9375rem;
        margin-bottom: 0.3125rem;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: 0.2s;
      }
    }
  `,
};
