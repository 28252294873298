import styled from 'styled-components';
import ExamAnswerItem from './ExamAnswerItem';

/** 문제 List 컴포넌트 */
export default function ExamAnswerList({ questionTextList = [], correctAnswerArray = [], selectAnswerArray = [] }) {
  return (
    <S.ExamAnswerList>
      {questionTextList.map((item, index) => {
        /** 해당 답이 정답인가? */
        const isCorrect = Boolean(correctAnswerArray.find((item) => item === index + 1));
        /** 해당 답이 학생이 선택한 답인가? */
        const isSelected = Boolean(selectAnswerArray.find((item) => item === index + 1));
        return <ExamAnswerItem key={index} answerNum={index + 1} isCorrect={isCorrect} isSelected={isSelected} isUndo={false} answerContent={item} />;
      })}
    </S.ExamAnswerList>
  );
}

const S = {
  ExamAnswerList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
};
