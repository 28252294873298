import React from 'react';
import IconDel from 'assets/img/icon_del.svg';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import styled from 'styled-components';

/** 삭제 확인 모달 */
export default function DeleteModal({ setIsViewModal, callback }) {
  return (
    <S.Wrap>
      <SimpleModalBase w='35rem' onEscape={() => setIsViewModal(false)}>
        <h2>
          Are you sure you want to
          <br />
          remove the file?
        </h2>
        <div className='modal_img_wrapper'>
          <img src={IconDel} alt='이미지_설명' />
        </div>

        <div className='modal_btn_container'>
          <button onClick={() => setIsViewModal(false)}>Cancel</button>
          <button className='btn_fill' onClick={callback}>
            Delete
          </button>
        </div>
      </SimpleModalBase>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
  `,
};
