import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';
import CustomSelectBox from './_common/CustomSelectBox';

/** 해당 모달 Style 설정 */
const timeModalBackgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

/** 시간대 선택 옵션 */
const timeOptions = Array.from({ length: 48 }, (_, index) => {
  const hour = Math.floor(index / 2);
  const minute = index % 2 === 0 ? '00' : '30';
  const ampm = hour < 12 || hour === 24 ? 'AM' : 'PM';
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;
  const label = `${hour12 < 10 ? '0' + hour12 : hour12}:${minute} ${ampm}`;
  const value = `${hour < 10 ? '0' + hour : hour}:${minute}`;
  return { label, value };
});

/** 시험 기간 옵션 */
const PERIOD_OPTIONS = [
  {
    label: '7 days',
    value: 7,
  },
  {
    label: '14 days',
    value: 14,
  },
  {
    label: '30 days',
    value: 30,
  },
  {
    label: '60 days',
    value: 60,
  },
  {
    label: '90 days',
    value: 90,
  },
];

/** 달력 세팅 모달 컴포넌트 */
export default function CalendarSetTimeModal({ close, confirm, btnType, setTimeLayer, setSelectedTime, setSelectedPeriod }) {
  const layerRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (layerRef.current && !layerRef.current.contains(event.target)) {
        setTimeLayer(false);
        setSelectedTime('00:00');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setTimeLayer, setSelectedTime]);

  const [timeSelect, setTime] = useState(false);
  const [positionStyle, setPositionStyle] = useState({});
  /** 현재 선택 된 "시험 시간" */
  const [selectTime, setSelectTime] = useState({ label: '12:00 AM', value: '00:00' });
  /** 현재 선택 된 "시험 기간" */
  const [selectPeriod, setSelectPeriod] = useState({ label: PERIOD_OPTIONS[0].label, value: PERIOD_OPTIONS[0].value });

  /** 시간대 선택 핸들러 */
  const handleSetTime = (label, value) => {
    setSelectTime({ label, value });
    setSelectedTime(value);
  };
  /** 시험 기산 선택 핸들러 */
  const handleSetPeriod = (label, value) => {
    setSelectPeriod({ label, value });
    setSelectedPeriod(value);
  };

  return (
    <S.Wrap style={timeModalBackgroundStyle}>
      <div className='com_time_layer active' onClick={(e) => e.stopPropagation()} style={positionStyle} ref={layerRef}>
        <div className='top_area'>
          {btnType === 'skip' && (
            <button type='button' title='Close' className='button_close' onClick={close}>
              <ImgSvgIconX />
            </button>
          )}
        </div>
        <ul className='selects'>
          <li>
            <h5 className='layer_tit'>Start Time</h5>
            <CustomSelectBox optionsArray={timeOptions} defaultLabel={selectTime.label} handleOptionClick={handleSetTime} />
          </li>
          <li>
            <h5 className='layer_tit'>Exam period</h5>
            <CustomSelectBox optionsArray={PERIOD_OPTIONS} defaultLabel={selectPeriod.label} handleOptionClick={handleSetPeriod} />
          </li>
        </ul>

        <div className='com_btn_wrap bottom'>
          <button className='com_btn point m full' onClick={confirm}>
            Confirm
          </button>
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_time_layer {
      width: 12.375rem;
    }
    .top_area {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 1rem;
    }
    .button_close {
      svg {
        color: #111111;
        width: 1rem;
      }
    }
    .selects {
      & > li {
        & + li {
          margin-top: 0.75rem;
        }
      }
    }
    .layer_tit {
      font-weight: 400;
      font-size: 0.8125rem;
      color: #7f8998;
      margin-top: 0;
      margin-bottom: 0.25rem;
    }
    .com_btn_wrap.bottom {
      justify-content: right;
      .com_btn {
        width: 5.375rem;
        text-align: center;
        font-family: Roboto;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  `,
};
