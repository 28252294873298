import styled from 'styled-components';

/** Report Card - NotiBox */
export default function NotiBox() {
  return (
    <S.NotiBox>
      <h3 className='noti_title'>Your Answers</h3>
      <p className='txt'>Check which question you have gotten correct or wrong and view the correct answer choice. </p>
    </S.NotiBox>
  );
}

const S = {
  NotiBox: styled.section`
    background-color: #f5f5f5;
    padding: 1.25rem;
    font-weight: 400;

    .noti_title {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 0.3125rem;
    }

    .noti_txt {
      font-size: 0.875rem;
    }
  `,
};
