import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

import useOutsideClick from 'hooks/useOutsideClick';

export default function ApSettingSelectBox({ options, onSelect, className = '', defaultValue = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find((option) => option.value === defaultValue) || options[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const selectedNode = dropdownRef.current.querySelector('[data-selected="true"]');
      if (selectedNode) {
        selectedNode.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [isOpen, selectedOption]);

  useEffect(() => {
    const newSelectedOption = options.find((option) => option.value === defaultValue) || options[0];
    setSelectedOption(newSelectedOption);
  }, [defaultValue, options]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false), isOpen);

  return (
    <S.SelectWrapper className={className} ref={dropdownRef}>
      <S.SelectTrigger onClick={toggleDropdown}>
        <S.SelectValueSection className={`${selectedOption.value === null ? 'none' : ''}`}>{selectedOption.label}</S.SelectValueSection>
        <S.SelectArrowSection>
          <ArrowIconSelect className={`arrow_icon ${isOpen ? 'open' : ''}`} />
        </S.SelectArrowSection>
      </S.SelectTrigger>
      {isOpen && (
        <S.Options className='custom_scroll'>
          {options.map((option, index) => (
            <S.Option key={index} data-selected={option === selectedOption} onClick={() => handleOptionClick(option)}>
              {option.label}
            </S.Option>
          ))}
        </S.Options>
      )}
    </S.SelectWrapper>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  SelectWrapper: styled.div`
    position: relative;
    user-select: none;
    width: 10.875rem;
  `,

  SelectTrigger: styled.div`
    height: 2.125rem;
    border: 1px solid #d7dadf;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  `,
  SelectValueSection: styled.div`
    padding: 0.4375rem 0;
    padding-left: 0.625rem;
    color: black;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.none {
      color: #999999;
    }
  `,
  SelectArrowSection: styled.div`
    position: relative;
    border-left: 1px solid #d7dadf;
    min-height: 2.125rem;
    min-width: 2.125rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow_icon {
      color: #767676;
      transition: transform 0.1s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  `,

  Options: styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    z-index: 1000;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
  `,

  Option: styled.li`
    padding: 0.6rem 1rem;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  `,
};
