import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconAlert } from 'assets/img/svg/icon_alert_circle_01.svg';
import { ReactComponent as ImgSvgIconUser } from 'assets/img/svg/icon_user_01.svg';
import { styledCssEllipsis } from 'styles/styledComponents/globalStyles';

/** Set Tests : "시험 모듈 선택" 페이지의 각 시험 버튼 상단 레이블 컴포넌트 */
export default function TestLabel({ subject = '', studentsNumber = null, isB2c = false, takenUserDataArray = null }) {
  return (
    <S.Wrap>
      <ImgSvgIconAlert className='alert' /> {subject}
      {studentsNumber !== null && (
        <>
          <ImgSvgIconUser className='user' />
          {studentsNumber}
        </>
      )}
      <div className='layer_pop_area'>
        <p className='layer_tit'>RW</p>
        {!isB2c && <p className='txt'>Go back to previous step to change the selected student list</p>}
        <div className='student_list scroll custom_scroll'>
          {takenUserDataArray &&
            takenUserDataArray.map((item, index) => {
              return (
                <div className='item' key={`inner_${index}`}>
                  <p className='name'>{item.userName}</p>
                  <div className='btn_area'>
                    {item.isTaken > 0 && <span className='com_btn xxs lightgray taken'>Taken</span>}
                    {item.isScheduled > 0 && <span className='com_btn xxs lightgray scheduled'>Scheduled</span>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
    background-color: #f9e2e2;
    border-radius: 0.25rem;
    display: flex;
    padding: 0.125rem 0.25rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.375rem;
    color: #505050;
    font-weight: 400;
    font-size: 0.8125rem;
    height: 1.25rem;
    svg {
      &.alert {
        width: 1rem;
        color: #ed1b1b;
      }
      &.user {
        width: 0.875rem;
        color: #505050;
      }
    }

    &::after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      top: 70%;
      width: 150%;
      height: 1rem;
    }

    .layer_pop_area {
      position: absolute;
      left: 0;
      top: 1.5rem;
      box-shadow: 0px 2px 6px -3px #00000026;
      background-color: #fff;
      text-align: left;
      z-index: 3;
      border-radius: 4px;
      display: none;
      padding: 0.63rem;
      width: 15.5rem;
      &_tit {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .txt {
        color: var(--SubText-color);
        font-size: 10px;
        line-height: 11.72px;
        margin-bottom: 15px;
      }
      .student_list {
        background-color: #fafafa;
        border-radius: 8px;
        max-height: 246px;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
          gap: 4px;
          .name {
            font-size: 12px;
            color: #4b5563;
            flex: 1;
            ${styledCssEllipsis}
          }
          .btn_area {
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 4px;
            .com_btn {
              border: 1px solid var(--c-border);
              &.taken {
                color: var(--c-red);
              }
              &.scheduled {
                color: var(--point-color);
              }
            }
          }
        }
      }

      .scroll {
        overflow-y: auto;
      }
    }
    &:hover {
      border-color: #888888 !important;
      color: #4b5563 !important;
      &::after {
        display: block;
      }
      .layer_pop_area {
        display: block;
      }
    }
  `,
};
