import React, { useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';

import { ReactComponent as ImgSvgIconHeartColor } from 'assets/img/svg/icon_heart_01_color.svg';
import { ReactComponent as ImgSvgIconHeartLine } from 'assets/img/svg/icon_heart_01_line.svg';

/** 즐겨찾기 버튼 컴포넌트 */
export default function FavoriteButton({ active, seq, isB2c, setCustomSetInfo }) {
  /** 로딩 중 여부 상태 */
  const [isLoading, setIsLoading] = useState(false);

  /** 즐겨찾기 버튼 핸들러 */
  const handler = ({ event, seq, active, isB2c }) => {
    event.stopPropagation(); // 버튼 요소가 겹쳐 있기 때문에, 이벤트 전파 막기
    setIsLoading(true);

    /** 해당 커스텀 시험 세트 "좋아요 목록에 추가" API 요청 */
    const postFetch = (seq) => {
      const params = {};

      const successHandler = (response) => {
        if (response.code === 201) {
          // setIsActive(true);
          setCustomSetInfo((current) => {
            const newArray = current.list.map((item) => (item.customSetSeq === seq ? { ...item, isLike: true, likeDate: new Date() } : item));
            return { list: newArray };
          }); // API를 다시 요청하지 않고, 실시간으로 동작을 반영하여 랜더링만 다시 하기 위하여 상태 갱신 (매번 목록 API를 호출 할 수 없기 때문에, 클라이언트 단에서만 리랜더링 해놓는다.)
          setIsLoading(false);
        }
      };
      request.post(`/api/v2/test${isB2c ? '/b2c' : ''}/like/${seq}`, params, successHandler).catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    };
    /** 해당 커스텀 시험 세트 "좋아요 목록에서 제거" API 요청 */
    const deleteFetch = (seq) => {
      const params = {};

      const successHandler = (response) => {
        if (response.code === 200) {
          // setIsActive(false);
          setCustomSetInfo((current) => {
            const newArray = current.list.map((item) => (item.customSetSeq === seq ? { ...item, isLike: false, likeDate: null } : item));
            return { list: newArray };
          }); // API를 다시 요청하지 않고, 실시간으로 동작을 반영하여 랜더링만 다시 하기 위하여 상태 갱신 (매번 목록 API를 호출 할 수 없기 때문에, 클라이언트 단에서만 리랜더링 해놓는다.)
          setIsLoading(false);
        }
      };
      request.del(`/api/v2/test${isB2c ? '/b2c' : ''}/like/${seq}`, params, successHandler).catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    };

    if (active) {
      deleteFetch(seq);
    } else {
      postFetch(seq);
    }
  };

  return (
    <S.Button onClick={(event) => handler({ event, seq, active: active, isB2c })} disabled={isLoading} title='Favorites'>
      {isLoading ? 'Loading...' : active ? <ImgSvgIconHeartColor className='color' /> : <ImgSvgIconHeartLine className='line' />}
    </S.Button>
  );
}

const S = {
  Button: styled.button`
    padding: 0.5rem;
    border-radius: 0.5rem;
    svg {
      width: 1.25rem;
      &.color {
        color: #f24b4b;
      }
      &.line {
        color: #000000;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
};
