import styled from 'styled-components';

import MemberListItem from './_components/MemberListItem';
import SectionTitle from '../../common/SectionTitle';
import Box from '../../common/Box';

/** "/com/score" 페이지의 "Student List" 영역 컴포넌트 */
export default function StudentListSection({ studentList, mode = null }) {
  return (
    <S.Wrap>
      <Box className='main_box student_list'>
        <SectionTitle>Student List</SectionTitle>
        <div className='com_list_box custom_scroll'>
          <table className='list'>
            <colgroup>
              <col width='56px' />
              <col />
              <col width='44px' />
              <col width='44px' />
              <col width='81px' />
            </colgroup>
            <thead>
              <tr className='sticky top'>
                <th>&nbsp;</th>
                <th>Name</th>
                <th className='alignC'>RW</th>
                <th className='alignC'>Math</th>
                <th className='alignC'>Report Card</th>
              </tr>
            </thead>
            <tbody>
              {studentList[0].userSeq > 0 ? (
                studentList.length > 0 &&
                studentList.map((item, idx) => {
                  return <MemberListItem key={`student-list${idx}`} studentInfo={item} mode={mode} />;
                })
              ) : (
                <tr>
                  <td colSpan='5'>
                    <p className='alignC'>No student</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: auto;
    flex: 3.6;
    display: flex;
    .main_box {
      padding: 1.25rem 1.5rem;
      display: flex;
      flex-direction: column;
    }
    .com_list_box {
      flex: 1;
      overflow-y: auto;
    }
  `,
};
