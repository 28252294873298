export default function AssignStudent({ keyIdx, student, checked, onCheckboxChange, handleShowProfile }) {
  const handleCheckboxChange = (event) => {
    onCheckboxChange(student, event.target.checked);
  };

  return (
    <tr key={keyIdx}>
      <td>
        <input type='checkbox' id={`chk${keyIdx}`} className='com_chk' checked={checked} onChange={handleCheckboxChange} />
        <label htmlFor={`chk${keyIdx}`}></label>
      </td>
      <td className='alignL'>
        <p className='ellipsis'>{student.userName}</p>
      </td>
      <td className='alignC'>
        <button className='com_btn s' onClick={() => handleShowProfile(student.userSeq)}>
          View
        </button>
      </td>
    </tr>
  );
}
