import React, { useRef, useState, useEffect } from 'react';
import dayjsTZ, { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
// hook
import useScrollLock from 'hooks/useScrollLock';
//img
import IconDel from 'assets/img/icon_del.svg';
import ProfilePopup from 'components/_common/modals/PopupProfile';
//component
import EditModalCalendar from './EditModalCalendar';

import styled from 'styled-components';
import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';

/**
 * 세팅 된 테스트의 일정을 수정하는 모달 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 * @param {Object} editTestModalInfo
 * @param {Object} editTestModalInfo.editData.testStartDate
 * @param {Object} editTestModalInfo.editData.testEndDate
 * @param {Object} editTestModalInfo.editData.studentList
 * @param {Object} editTestModalInfo.editData.studentList.uthSeq
 * @param {Object} editTestModalInfo.type

 * @param {Function} closeEditTestModal
 * @param {Function} setSimpleAlert
 * @param {Function} setIsLoading
 * @param {Object} activeTest
 * @param {Function} setMiniAlert
 * @param {Function} setReloadData
 */
export default function EditTestScheduleModal({
  editTestModalInfo,
  closeEditTestModal,
  setSimpleAlert,
  setIsLoading,
  activeTest,
  setMiniAlert,
  setReloadData,
}) {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const isClass = editTestModalInfo.type === 'class';
  const isTest = editTestModalInfo.type === 'test';
  const toEditData = isClass ? editTestModalInfo.editByClassData : editTestModalInfo.editByTestData;
  /** 프로필 영역 */
  const [ProfileState, setProfileState] = useState(false);
  /** 선택된 달력 날짜 */
  const [selectedDateState, setSelectedDateState] = useState([null, null]);
  /** time select box */
  const [timeSelect, setTimeSelect] = useState(false);
  const [testStartTime, setTestStartTime] = useState(null);
  /** 프로필 */
  const [profileInfo, setProfileInfo] = useState([]);
  /** edit check */
  const [deSelectCheckBox, setDeSelectCheckBox] = useState({
    seqStr: '',
    deSelectedKeys: [],
  });
  /** save change */
  const [editSeqStr, setEditSeqStr] = useState('');
  const [saveChanges, setSaveChanges] = useState(false);
  /** 학생 검색 키워드 */
  const [searchStudentName, setSearchStudentName] = useState('');

  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean  => 프로필 모달 노출 여부
    userSeq: 0, // number       => 학생 시퀀스
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const searchStudentNameRef = useRef();
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 프로필 상세 모달 호출 */
  const handleClickViewProfile = (e, userSeq) => {
    e.stopPropagation();

    setProfileModalInfo({ visible: true, userSeq: userSeq });
  };
  /** 일정 수정 API 요청 */
  const putSchedule = (editSeqStr) => {
    let params = {
      seqStr: editSeqStr,
      testStartDate: selectedDateState[0].hour(testStartTime.hour()).minute(testStartTime.minute()).format(),
      testEndDate: selectedDateState[1].endOf('day').format(),
    };

    const successHandler = (response) => {
      // console.log(response);
      if (response.code === 200) {
        closeEditTestModal();
        setMiniAlert((prev) => {
          return {
            ...prev,
            active: true,
            text: 'Edit Successful!',
            timeOut: 1500,
          };
        });
        setIsLoading(true);

        setReloadData((prev) => !prev);
      }
    };
    request.put('/api/test/schedules', params, successHandler);
  };

  const delUserTestSchedule = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        if (nvl(editSeqStr) === '') {
          closeEditTestModal();
          setSaveChanges(false);
        } else {
          putSchedule(editSeqStr);
        }

        setReloadData((prev) => !prev);
      }
    };

    request.del(`/api/test/schedules?seqStr=${encodeURI(deSelectCheckBox.seqStr)}`, null, successHandler);
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const deleteUth = (e, uthSeq) => {
    const { seqStr, deSelectedKeys } = deSelectCheckBox;

    if (e.target.checked) {
      const updatedSelectedKeys = deSelectedKeys.filter((key) => key !== uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setDeSelectCheckBox({
        ...deSelectCheckBox,
        deSelectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = [...deSelectedKeys, uthSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setDeSelectCheckBox({
        ...deSelectCheckBox,
        deSelectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  const TimeActive = () => {
    setTimeSelect((prevState) => !prevState);
  };

  const handleTimeSelect = (e, time) => {
    e.stopPropagation();

    setTimeSelect(false);
    setTestStartTime(time);
  };
  /** 저장 버튼 핸들러 */
  const saveBtn = () => {
    const { seqStr, deSelectedKeys } = deSelectCheckBox;

    let editUth;
    let editStr;

    if (isClass) {
      if (toEditData.studentList.length > 0) {
        editUth = toEditData.studentList.map((i) => i.uthSeq);

        editUth = editUth.filter((uthSeq) => !deSelectedKeys.includes(uthSeq));
        editStr = editUth.join('|');
      } else {
        editUth = toEditData.studentList.uthSeq;
        editStr = editUth;
      }
    } else if (isTest) {
      editUth = toEditData.uthSeq;
      editStr = editUth;
    }

    setEditSeqStr(editStr);

    if (nvl(selectedDateState[0]) !== '' && nvl(selectedDateState[1]) !== '') {
      if (deSelectCheckBox.deSelectedKeys > 0) {
        setSaveChanges(true);
      } else if (nvl(editStr) !== '') {
        putSchedule(editStr);
      }
    } else {
      setSimpleAlert({
        visible: true,
        modalText: 'Please select a date.',
      });
    }
  };

  /** 학생 검색 버튼 핸들러 ( useHandleKeyDown 훅에 연결되어 있음 ) */
  const searchStudentNameBtn = () => {
    if (searchStudentNameRef.current.value !== '') {
      setSearchStudentName(searchStudentNameRef.current.value);
    } else {
      setSearchStudentName('');
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    if (toEditData) {
      setSelectedDateState([isoTimeToDayjsTZ(toEditData.testStartDate), isoTimeToDayjsTZ(toEditData.testEndDate)]);
      setTestStartTime(isoTimeToDayjsTZ(toEditData.testStartDate));
    }
  }, [toEditData]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useScrollLock(editTestModalInfo.active);
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const generateTimeOptions = () => {
    const timeOptions = [];
    const startTime = dayjsTZ().startOf('day');
    for (let i = 0; i < 24; i++) {
      const time = startTime.add(i, 'hours');
      timeOptions.push(
        <p key={time.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, time)}>
          {time.format('A hh : mm')}
        </p>
      );

      const halfHour = time.add(30, 'minutes');
      timeOptions.push(
        <p key={halfHour.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, halfHour)}>
          {halfHour.format('A hh : mm')}
        </p>
      );
    }
    return timeOptions;
  };

  // 필터링된 학생 목록을 바로 계산
  // const filteredStudentList = toEditData?.studentList.filter((student) => !searchStudentName || student.userName.includes(searchStudentName));
  const getFilteredStudentList = () => {
    // type이 class 일 떄 ( 여러명의 학생이 있어 검색어와 일치하는 학생을 찾음 )
    if (isClass) {
      return toEditData.studentList.filter((student) => !searchStudentName || student.userName.includes(searchStudentName));

      // type이 test 일 떄 ( 한명의 학생이기 떄문에 그냥 보여주면 됨 )
    } else if (isTest) {
      return [
        {
          userName: toEditData.userName,
          userSeq: toEditData.userSeq,
          uthSeq: toEditData.uthSeq,
        },
      ];
    }
  };

  const studentData = getFilteredStudentList();

  // 활성 여부에 따라 모달 랜더링
  if (!editTestModalInfo.active) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Wrap>
      {/* <div className='com_popup pop_edit_test pop_manage type_dim active' onClick={() => setEditTest((prev) => {return { ...prev, active: false }})}>
        <div className='pop_container xl' onClick={e => e.stopPropagation()}> */}
      <div className='com_popup pop_edit_test pop_manage type_dim active'>
        <div className='pop_container xl'>
          <section className='pop_body'>
            <button className='btn_pop_close svg_icon black' onClick={closeEditTestModal}></button>
            <div className='left'>
              {/*1.Change Date*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_1 black'>&nbsp;</i>
                  Change Date
                </h2>
              </article>
              <EditModalCalendar setSimpleAlert={setSimpleAlert} selectedDateState={selectedDateState} setSelectedDateState={setSelectedDateState} />
            </div>

            <div className='right test_details'>
              {/*2.Test Details*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_2 black'>&nbsp;</i>
                  Test Details
                </h2>
              </article>
              <article className='class_info'>
                <div className='item col2'>
                  <div className='tit'>Test</div>
                  <div className='info'>{toEditData.testName !== '' ? toEditData.testName : activeTest.testName}</div>
                </div>
                <div className='item col2'>
                  <div className='tit'>Class</div>
                  <div className='info'>{toEditData.className}</div>
                </div>
                <div className='item'>
                  <div className='tit'>Date</div>
                  <div className='info'>
                    <input
                      type='text'
                      value={selectedDateState[0] ? selectedDateState[0].format('MM / DD / YYYY') : 'select a date'}
                      className='input'
                      readOnly
                    />
                    -
                    <input
                      type='text'
                      value={selectedDateState[1] ? selectedDateState[1].format('MM / DD / YYYY') : 'select a date'}
                      className='input'
                      readOnly
                    />
                  </div>
                  <div className='info'>
                    <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => TimeActive()}>
                      <section className='selected_wrap'>
                        <S.StyledSelectedInput placeholder={testStartTime?.format('A hh : mm') || 'select a time'} />
                      </section>
                      <section className='layer custom_scroll'>{generateTimeOptions()}</section>
                    </div>
                  </div>
                </div>
              </article>

              {isClass && (
                <article className='search_sec'>
                  <div className='search'>
                    <input
                      type='text'
                      className='input'
                      placeholder='Search students'
                      ref={searchStudentNameRef}
                      onKeyDown={(e) => e.key === 'Enter' && searchStudentNameBtn()}
                    />
                    <S.StyledSearchBtn onClick={searchStudentNameBtn}>SEARCH</S.StyledSearchBtn>
                  </div>
                </article>
              )}

              <article className='com_list'>
                <div className='custom_scroll'>
                  <table className='list'>
                    <colgroup>
                      <col width='40' />
                      <col />
                      <col width='100' />
                    </colgroup>
                    <thead>
                      <tr className='sticky top'>
                        <th colSpan={3} className='table_tit black'>
                          {editTestModalInfo.type === 'class' ? `Student List : ${toEditData.studentList.length}` : 'Student List'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentData.length > 0 &&
                        studentData.map((i, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <input
                                  type='checkbox'
                                  id={`chk${idx}`}
                                  className='com_chk'
                                  checked={!deSelectCheckBox.deSelectedKeys.includes(i.uthSeq)}
                                  onChange={(e) => deleteUth(e, i.uthSeq)}
                                />
                                <label htmlFor={`chk${idx}`}></label>
                              </td>
                              <td className='alignL'>
                                <p className='ellipsis'>{i.userName}</p>
                              </td>
                              <td className='alignC'>
                                <button className='com_btn s line gray' onClick={(e) => handleClickViewProfile(e, i.userSeq)}>
                                  View
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {studentData.length === 0 && (
                        <tr>
                          <td colSpan={3} className='table_tit black'>
                            No student available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </article>
              <article className='com_btn_wrap bottom'>
                <button className='com_btn l point full' onClick={() => saveBtn()}>
                  Save Changes
                </button>
              </article>
            </div>

            {/* ProfileState && (
                //프로필
                <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={profileInfo} />
              ) */}
          </section>
        </div>
      </div>
      {/*변경내용 저장 시 팝업*/}
      <div className={`com_popup type_alert ${saveChanges ? 'active' : ''}`}>
        <div className='pop_container'>
          <div className='pop_body ok_contents'>
            <p className='alert_title'>
              Are you sure you want to remove
              <br /> selected student(s)?
            </p>
            <div className='icon'>
              <img src={IconDel} alt='icon' />
            </div>
            <div className='alert_text'>
              You are removing student(s) from this test. <br /> Your Gate Cash will be refunded.
            </div>
          </div>
          <div className='pop_footer com_btn_wrap many'>
            <button className='com_btn point line xl' onClick={() => setSaveChanges(false)}>
              Back
            </button>
            <button className='com_btn point xl' onClick={() => delUserTestSchedule()}>
              Delete
            </button>
          </div>
        </div>
      </div>

      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_list {
      .custom_scroll {
        max-height: 284px;
      }
    }
  `,

  StyledSelectedInput: styled.input`
    box-sizing: border-box;
    width: 100%;
    border-radius: 0.5rem;
    border-color: #000;
    height: 1.5rem;
    outline: none;
    display: block;
    border: 1px solid #000;
    overflow: hidden;

    padding: 1rem 1rem;
    /* padding-left: 0.5rem; */

    &::placeholder {
      color: #000;
      opacity: 1;
    }
  `,

  StyledSearchBtn: styled.button`
    font-size: 12px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4.825rem;
    height: 100%;
  `,
};
