import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

/** ap/com/score/grading/submission 페이지의 "Raw 점수 영역" 의 각 "Part" 영역 컴포넌트 */
export default function PartArea({ partInfo, dropdownChildren = <div>컴포넌트를 전달해 주세요</div>, ...rest }) {
  /** 드롭다운 영역 노출 여부 */
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  /** 드롭다운 버튼 토글 핸들러 */
  const handleToggleDropdown = () => {
    setIsShowDropdown((current) => !current);
  };
  return (
    <S.Wrap className={`part_area ${isShowDropdown ? 'show' : ''}`} {...rest}>
      <button type='button' title='' onClick={handleToggleDropdown} className='cover_area'>
        <div className='inner_wrap'>
          <h3 className='part_name'>Part {partInfo.partNum}</h3>
          <div className='score_area'>
            <h4>Raw Score</h4>
            <div className='score'>
              <strong className='value'>{partInfo.rawScore != null ? partInfo.rawScore : '-'}</strong> / {partInfo.totalScore}
            </div>
          </div>
          <i className='arrow_icon_wrap'>
            <ArrowIconSelect />
          </i>
        </div>
      </button>
      <div className={`dropdown_area`}>{dropdownChildren}</div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    background: #fff;
    overflow: hidden;

    .cover_area {
      width: 100%;
      height: 3.75rem;
      box-sizing: border-box;
      padding: 0 1.25rem;
      .inner_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }
      .part_name {
        padding: 1rem 1.25rem 1rem 0;
        color: #111;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75rem;
      }
      .score_area {
        flex: 1 0 auto;
        width: auto;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 1rem;
        padding: 0 1.25rem;
        color: #111;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        .score {
          color: #505050;
          .value {
            color: #111;
            font-weight: 500;
          }
        }
      }
      .arrow_icon_wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 1.5rem;
          color: #999999;
          transition: 0.2s;
        }
      }
    }

    .dropdown_area {
      background-color: #eaeaea;
      overflow: hidden;
      height: 0;
      padding-top: 0;
      transition: 0.2s;
    }

    &.show {
      & > .cover_area {
        .arrow_icon_wrap {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }
      & > .dropdown_area {
        height: auto;
        padding-top: 0.625rem;
      }
    }
  `,
};
