import styled from 'styled-components';

//////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const ANSWER_NUM = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
};
////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 시험 페이지에서 객관식 문제 컴포넌트 */
export default function ExamAnswerItem({ answerNum = 0, isCorrect = false, isSelected = false, isUndo = false, answerContent = '' }) {
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  ////////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** 틀린 답변인가? (정답은 아니지만, 사용자의  */
  const isWrong = !isCorrect && isSelected;
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const dangerouslyToggle = (e, no) => {
    console.log(e, no);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- 랜더링 보조 --- START ////////////////////
  const answerLabel = ANSWER_NUM[answerNum]; // 'A' | 'B' | 'C' | 'D' | 'E'
  const answerInputId = `answer_${answerNum}_${answerLabel}`;
  ////////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Wrap>
      <S.ExamAnswerItem className={`${isUndo ? 'undo_selected' : ''} ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
        <S.AnswerInput type='radio' name={`answer_${answerNum}`} id={answerInputId} value={answerLabel} checked={true} readOnly />
        <S.AnswerLabel className={`${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`} htmlFor={answerInputId}>
          <S.AnswerNumBtn className={`${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>{answerLabel}</S.AnswerNumBtn>
          <div
            dangerouslySetInnerHTML={{
              __html: answerContent,
            }}
          />
        </S.AnswerLabel>
        <S.UndoBtn className={`undo_btn ${isUndo ? 'show' : ''}`} onClick={(e) => dangerouslyToggle(e, answerNum)} value={answerLabel}>
          {answerLabel}
        </S.UndoBtn>
      </S.ExamAnswerItem>

      <div className={`guide_area ${isCorrect ? 'correct' : ''} ${isWrong ? 'wrong' : ''}`}>
        {isCorrect && 'CORRECT ANSWER'}
        {isWrong && 'YOUR CHOICE'}
      </div>
    </S.Wrap>
  );
}

/** 답안 번호 버튼 Styled-component (style 확장을 위하여 따로 선언) */
const AnswerNumBtn = styled.span`
  line-height: 0;
  border: 1px solid black;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  &.correct {
    background-color: #384cc0;
    color: white;
  }
  &.wrong {
    background-color: #ff4c4c;
    color: #111111;
  }
`;

/** Styled-components */
const S = {
  Wrap: styled.div`
    .guide_area {
      margin-top: 0.25rem;
      text-align: right;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.625rem;
      text-transform: uppercase;
      &.correct {
        color: #0068bd;
      }
      &.wrong {
        color: #f24b4b;
      }
    }
  `,

  ExamAnswerItem: styled.div`
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    &.correct {
      border: 4px solid rgba(0, 74, 158, 0.6);
    }
    &.wrong {
      border: 4px solid rgba(242, 75, 75, 0.2);
    }
  `,

  AnswerNumBtn,

  // AnswerNumBtn 확장 undo 버튼
  UndoBtn: styled(AnswerNumBtn)`
    display: none;
    margin-left: 0.875rem;
    position: relative;

    &.show {
      display: flex;
    }

    &::before {
      content: '';
      background-color: black;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -5px;
      right: -5px;
      transform: translateY(-50%);
    }
  `,

  AnswerLabel: styled.label`
    border: 1px solid #7b7b7b;
    border-radius: 0.375rem;
    padding: 1.25rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: default;
    width: 100%;

    &.correct {
      border: 3px solid #384cc0;
    }
    &.wrong {
      border: 3px solid #ff4c4c;
    }
  `,

  AnswerInput: styled.input`
    width: 0;
    height: 0;
    padding: 0;
    border: none;
  `,
};
