import React from 'react';
import SessionStorage from 'utils/SessionStorage.utils.js';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';

/** "/com/score" 페이지의 "Student List" 의 목록 개별 요소 컴포넌트 */
function MemberListItem({ studentInfo, mode = null }) {
  const { navigateWithParams } = useObfuscatedSearchParam();

  /** 선택 된 학생 Report 카드 Open */
  const reportCardOpen = (uthSeq, userSeq) => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', userSeq);

    window.open(`/com/reports`, '_blank');
  };

  /** 학생 선택 핸들러 - /com/score/student/:userSeq 이동 및 기존 파라미터 유지 */
  const handleSelectStudent = (studentInfo) => {
    const pathToMove = `/com/score/student/${obfuscateUrlParam(String(studentInfo.userSeq))}`;
    navigateWithParams(pathToMove, {
      keepParams: [SCORE_PARAMS.CUR_SCORE_TEST_SEQ, SCORE_PARAMS.CUR_SCORE_CLASS_SEQ],
      newParams: {
        [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ]: String(studentInfo.userSeq),
      },
      replace: true,
    });
  };

  const isLecture = mode === 'lecture';

  // P 일때만 점수, report card 노출
  const isTestEnd = studentInfo.testStatus === 'P';

  return (
    <tr onClick={() => handleSelectStudent(studentInfo)}>
      <td>
        <div className='com_profile_img'>
          {studentInfo.upFileSeq > 0 ? (
            <img
              className='profile-img-background'
              src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}`}
              alt='profile'
            />
          ) : (
            <span className='name'>{studentInfo.userName.charAt(0)}</span>
          )}
        </div>
      </td>
      <td>
        <span className='name'>{studentInfo.userName}</span>
      </td>

      {isTestEnd ? (
        <>
          <td className='alignC data_field'>{isLecture ? '-' : studentInfo.rwScore}</td>
          <td className='alignC data_field'>{isLecture ? '-' : studentInfo.mathScore}</td>
        </>
      ) : (
        <td className='alignC' colSpan='2'>
          <p>Not yet taken</p>
        </td>
      )}

      <td className='alignC'>
        {isTestEnd && (
          <button
            className='com_btn_report'
            onClick={(event) => {
              event.stopPropagation();
              reportCardOpen(studentInfo.uthSeq, studentInfo.userSeq);
            }}>
            <i className='svg_icon icon_score_report s black'>&nbsp;</i>
          </button>
        )}
      </td>
    </tr>
  );
}

export default MemberListItem;
