export const getPartData = (sectionMetadata) => {
  // sections 배열이 없을때 빈 배열 반환
  if (!sectionMetadata?.sections) return [];

  // 파트 데이터 반환 ( 배열 Depth가 있기 때문에 평탄화 추가 )
  return sectionMetadata.sections.flatMap((section) => {
    return section.parts.map((part) => ({
      label: `${section.section_name} - ${part.name} (Seq ${part.part_seq})`,
      value: {
        sectionSeq: section.section_seq,
        partSeq: part.part_seq,
      },
    }));
  });
};

/** 프론트에서 쓰이는 데이터 형식을 백엔드로 보내주기 위한 변환 코드 */
export const formatOptionData = (answerChoiceInfo) => {
  return answerChoiceInfo.answerChoices.reduce(
    (acc, item) => ({
      ...acc,
      [`option${item.id}`]: item.content,
    }),
    {}
  );
};