import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import styled from 'styled-components';
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';

/**
 * 프로젝트 내부에 있는 CustomAlert을 대신해서 사용한 모달
 * 
 * @typedef {Object} AlertInfo
 * @property {string} modalText - 모달에 표시될 메인 텍스트
 * @property {boolean} visible - 모달 표시 여부
 * @property {string?} modalBtnText - 확인 버튼에 표시될 텍스트 (기본값: 'Confirm')
 * @property {boolean} isConfirm - Confirm 타입의 모달인지 여부 (버튼 2개로 close와 callback을 받아야함)
 * @property {Function?} callback - 확인 버튼 클릭 시 실행될 콜백 함수
 * 
 * @param {Object} props
 * @param {AlertInfo} props.alertInfo - 모달에 표시될 정보
 * @param {Function} props.closeModal - 모달을 닫는 함수
 * 
 * @example
 * const [simpleAlert, setSimpleAlert] = useState({
 *   modalText: 'SOME_MODAL_TEXT',
 *   modalBtnText: 'CUSTOM_BTN_TEXT',
 *   visible: false,
 *   isConfirm: false,
 *   callback: () => console.log('모달_클릭'),
 * });
 * 
 * <SimpleAlertModal 
 *   alertInfo={simpleAlert}
 *   closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))}
 * />
 */
export default function SimpleAlertModal({ alertInfo, closeModal }) {
  if (!alertInfo?.visible) return null;

  return (
    <S.Wrap>
      <SimpleModalBase onEscape={() => closeModal()} gap='0' noPad>
        <div className='modal_title_container'>
          <button className='modal_title_btn' onClick={() => closeModal()}>
            <ImgSvgX />
          </button>
        </div>

        <div className='modal_content'>
          <h2 className='modal_text'>{alertInfo.modalText}</h2>

          <div className='modal_btn_container'>
            <button className='btn_fill' onClick={alertInfo?.callback ? () => alertInfo.callback() : () => closeModal()}>
              {alertInfo?.modalBtnText || 'Confirm'}
            </button>
            {alertInfo?.isConfirm ? <button onClick={() => closeModal()}>Cancel</button> : null}
          </div>
        </div>
      </SimpleModalBase>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;

    .modal_title_container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;
      padding-bottom: 0.5rem;

      > button {
        width: 2.75rem;
        height: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        border: 1px solid transparent; // 호버 안했을떄
        svg {
          width: 2.5rem;
          height: 2.5rem;
          color: #616161;
        }
        &:hover {
          svg {
            transform: scale(1.05);
            transition: all 0.2s ease-in-out;
            color: black;
          }
          border-color: #0068bd;
        }
      }
    }

    .modal_content {
      padding: 4rem;
      padding-top: 0;
      padding-bottom: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2.5rem;

      .modal_text {
        width: 100%;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0; // 기존 scss 덮어쓰기
        white-space: pre-line; // \n으로 줄바꿈
      }
    }

    .modal_btn_container {
      button {
        max-height: 2.75rem;
      }
    }
  `,
};
