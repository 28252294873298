import layer2 from 'assets/img/exam/layer2.png';
import { ReactComponent as ImgSvgIconUndo } from 'assets/img/svg/exam-icon/undo.svg';
import { useState } from 'react';
import styled from 'styled-components';

/**  */
export default function UndoBtn({ callback }) {
  const [isShow, setIsShow] = useState(false);

  const handleClickBtn = () => {
    callback();
  };

  return (
    <S.UndoBtnContainer>
      <button className='undo_btn' onClick={handleClickBtn}>
        <ImgSvgIconUndo />
      </button>
      <div className='undo_tooltip'>Cross out answer choices you think are wrong.</div>
    </S.UndoBtnContainer>
  );
}

const S = {
  UndoBtnContainer: styled.div`
    position: relative;

    .undo_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 26px;
    }

    .undo_tooltip {
      word-break: keep-all;
      display: none;
      position: absolute;
      width: 12rem;
      right: -10px;
      z-index: 999;
      margin-top: 10px;
      color: white;
      background: #505050;
      border-radius: 10px;
      padding: 9px 12px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      text-align: left;
      &::after {
        content: '';
        background-image: url(${layer2});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 11px;
        top: -14px;
        transform: rotate(180deg);
        width: 23px;
        height: 15px;
      }
    }
    // 호버 시 툴팁 표시
    &:hover .undo_tooltip {
      display: block;
    }
  `,
};
