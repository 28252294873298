import styled from 'styled-components';
import lineBg from 'assets/img/exam/line_bg.png';

/**
 * ap의 시험에서 사용될 header base
 * 좌측 모듈, 중앙 모듈, 우측 모듈을 받을수 있게 구성 ( 재사용성 높게 )
 */
export default function ApExamHeaderBase({ leftSection = null, centerSection = null, rightSection = null }) {
  return (
    <S.Container>
      <S.LeftArea>{leftSection}</S.LeftArea>

      <S.CenterArea>{centerSection}</S.CenterArea>

      <S.RightArea>{rightSection}</S.RightArea>
    </S.Container>
  );
}

const S = {
  Container: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 9.375rem;
    background: #f4f8ff;
    position: relative;
    z-index: 100;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${lineBg});
      width: 100%;
      height: 0.1875rem;
      background-repeat: repeat-x;
    }
  `,

  LeftArea: styled.div`
    width: 30%;
    text-align: left;
  `,
  CenterArea: styled.div`
    flex: 1;
    text-align: center;
  `,

  RightArea: styled.div`
    width: 30%;
    text-align: right;
  `,
};
