import styled from 'styled-components';

import GradingFooter from './_components/layouts/GradingFooter';
import GradeSubmissionBody from './_components/layouts/gradeSubmissionBody/GradeSubmissionBody';
import GradingHeader from './_components/layouts/header/GradingHeader';

/** ap/com/score/grading/submission 페이지 */
export default function ApComScoreGradingSubmissionPage() {
  return (
    <S.Wrap>
      <GradingHeader />
      <GradeSubmissionBody />
      <GradingFooter />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
};
