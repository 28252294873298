import { useState } from 'react';
import EditModalCalendar from 'components/com/tests/scheduled/_components/EditModalCalendar';
import styled from 'styled-components';

/** 팝업 달력 컴포넌트 */
export default function PopupCalendarPeriod({ setSearchInfo, tit, size }) {
  /** 달력 팝업 View - State */
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const [selectedDateState, setSelectedDateState] = useState([]);

  const handleSelectDate = (dateArr) => {
    setSelectedDateState(dateArr);
    if (dateArr[1] !== null) {
      updateDates(dateArr);
    }
  };

  /** 선택된 날짜 ([시작일, 종료일]) 범위 업데이트 및 검색 정보 설정 */
  const updateDates = ([start, end]) => {
    // 검색 정보 설정
    setSearchInfo((prev) => {
      return {
        ...prev,
        isDateRangeSelected: true,
        searchStartDate: start.startOf('day').format(),
        searchEndDate: end.endOf('day').format(),
      };
    });

    setIsShowCalendar(false);
  };

  return (
    <S.Wrap className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker search_area_input'
        onClick={() => setIsShowCalendar(true)}
        readOnly
        placeholder={tit}
        value={
          selectedDateState.length === 2 && selectedDateState[1] !== null
            ? `${selectedDateState[0].format('MM.DD.YYYY')} ~ ${selectedDateState[1].format('MM.DD.YYYY')}`
            : ''
        }
        size={size}
      />
      {isShowCalendar && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button type='button' className='svg_icon btn_pop_close black' onClick={() => setIsShowCalendar(false)}>
              &nbsp;
            </button>
          </div>

          <EditModalCalendar selectedDateState={selectedDateState} setSelectedDateState={handleSelectDate} />
        </div>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border-radius: 0.5rem;
    border: 1px solid #d2dbe2;
    overflow: hidden;
    display: flex;
    height: 2.625rem;
    padding: 0.5rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .input.search_area_input.datepicker {
      padding: 0.5rem 0.5rem;
      padding-left: 2.5rem;
      border: none;
    }
  `,
};
