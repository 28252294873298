// !!!! components/classes/_PopClassManage.js, components/test/_PopAddStudents.js, components/user/academy/Profile.js,  components/test/Schedule.js 에 사용됨 !!!!!
import { React } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import { nvl } from 'utils/Common.utils.js';
import usePressESC from 'hooks/usePressESC';
import DraggableModal from './DraggableModal';

// API 버전별 Key 정보
const CLASS_KEYS = {
  V1: {
    name: 'class_name',
    seq: 'class_seq'
  },
  V2: {
    name: 'className',
    seq: 'classSeq'
  }
};

/**
 * 공용 프로필 모달 컴포넌트
 * @param {string} type - 프로필 타입 (student, teacher)
 * @param {function} setProfileState - 프로필 상태 업데이트 함수
 * @param {object} studentInfo - 학생 정보
 */
export default function ProfilePopup({ type, setProfileState, studentInfo }) {
  usePressESC(() => setProfileState(false));

  // 버전 확인 (classLists가 있으면 V2, 없으면 V1)
  const version = studentInfo?.classLists ? 'V2' : 'V1';
  const classKeys = CLASS_KEYS[version];
  // 버전에 맞는 데이터 가져오기
  const renderClassList = studentInfo?.classLists ||
    (studentInfo?.classList && JSON.parse(studentInfo?.classList)) || [{ class_name: 'individual', class_seq: 1 }];

  if (!studentInfo) {
    return (
      <DraggableModal>
        <h3>Oops</h3>
        <h4>There's no profile data on the server</h4>
        <p> Something's wrong with this</p>
        <p>Try refreshing or contact your administrator</p>
      </DraggableModal>
    );
  }

  const displayRegDate = isoTimeToDayjs(studentInfo.mchRegDate ? studentInfo.mchRegDate.split(' ')[0] : studentInfo.regDate.split(' ')[0]).format('YYYY-MM-DD');

  return (
    <div className='com_popup active pop_profile student'>
      <DraggableModal>
        <S.Wrap className='pop_container'>
          <div className={`right com_profile ${type} `}>
            {/*화면 구성 상(학생, 선생 항목 노출용) ${props.type} 적용 →  개발 시 수정 가능*/}
            {/* {studentInfo.profile && <img src={studentInfo.profile} alt="프로필이미지" className="bg" />} */}
            {nvl(studentInfo.profile) !== '' && <img src={studentInfo.profile} alt='프로필이미지' className='bg profile-img-background' />}
            <article className='top_area'>
              <button className='btn_pop_close svg_icon white' onClick={() => setProfileState(false)}></button>
              <p className='tit'>Profile</p>
            </article>
            <div className='wrap'>
              <article className='profile_area'>
                <div className={`com_profile_img xl ${type === 'student' ? '' : 'T'}`}>
                  {studentInfo.profile ? (
                    <img className='profile-img-background' src={studentInfo.profile} alt='프로필이미지' />
                  ) : (
                    <span className='name'>{studentInfo.userName.substring(0, 1)}</span>
                  )}
                </div>
                {/*선생 일 경우 addClass T*/}
                <p className='name'> {studentInfo.userName} </p>
              </article>
              <div className='custom_scroll'>
                {/*학생 프로필일 경우에만 노출 start*/}
                {renderClassList &&
                  renderClassList.map((item, index) => {
                    return (
                      <article className='class_area' key={`class_${index}`}>
                        <p className='tit'>CLASS{index + 1}</p>
                        <p className='name'>{item[classKeys.name]}</p>
                      </article>
                    );
                  })}

                <article className='school_area'>
                  <div className='item'>
                    <p className='tit_field'>School Type</p>
                    <p className='info_field'>{studentInfo.schoolTypeName}</p>
                  </div>
                  <div className='item'>
                    <p className='tit_field'>Grade</p>
                    <p className='info_field'>{studentInfo.studentGrade}</p>
                  </div>
                  <div className='item'>
                    <p className='tit_field'>School Location</p>
                    <p className='info_field'>{studentInfo.schoolLocationName}</p>
                  </div>
                </article>
                {/*학생 프로필일 경우에만 노출 end*/}
                <article className='info_area'>
                  <div className='item'>
                    <i className='svg_icon icon_email blue'>&nbsp;</i>
                    <p className='tit_field'>Email</p>
                    <p className='info_field'>{studentInfo.userEmail}</p>
                  </div>
                  {/*학생 프로필일 경우에만 노출 start*/}
                  <div className='item student'>
                    <i className='svg_icon icon_school_building blue'>&nbsp;</i>
                    <p className='tit_field'>School</p>
                    <p className='info_field'>{studentInfo.studentSchool}</p>
                  </div>
                  <div className='item student'>
                    <i className='svg_icon icon_tel blue'>&nbsp;</i>
                    <p className='tit_field'>Students</p>
                    <p className='info_field'>{studentInfo.userMobile}</p>
                  </div>
                  <div className='item student'>
                    <i className='svg_icon icon_tel blue'>&nbsp;</i>
                    <p className='tit_field'>Parents</p>
                    <p className='info_field'>{studentInfo.parentMobile}</p>
                  </div>
                  {/*학생 프로필일 경우에만 노출 end*/}
                  {/*선생 프로필일 경우에만 노출 start*/}
                  <div className='item teacher'>
                    <i className='svg_icon icon_tel blue'>&nbsp;</i>
                    <p className='tit_field'>Phone Number</p>
                    <p className='info_field'>{studentInfo.userMobile}</p>
                  </div>
                  {/*학생 프로필일 경우에만 노출 end*/}
                  <div className='item'>
                    <i className='svg_icon icon_class blue'>&nbsp;</i>
                    <p className='tit_field'>Registration Date</p>
                    <p className='info_field'>{displayRegDate}</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </S.Wrap>
      </DraggableModal>
    </div>
  );
}

const S = {};

S.Wrap = styled.div`
  max-height: 728px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  .custom_scroll {
    max-height: 26rem;
  }
`;
