import styled from 'styled-components';
import lineBg from 'assets/img/exam/line_bg.png';
import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';

const examInfo = {
  userName: 'USER_NAME',
  questionCount: 8,
  currentQuestion: 2,
};

export default function ApExamFooter({ saveHandler, backHandler, nextHandler, currentQuestionIndex }) {
  return (
    <S.Container>
      <S.UserInfoArea>{/* <p className='user_name'>{examInfo.userName}</p> */}</S.UserInfoArea>

      <S.TestInfoArea>
        {/* <button className='question_nav_btn'>
          <p>
            Question <span>{examInfo.currentQuestion}</span> of <span>{examInfo.questionCount}</span>
          </p>
          <span className='question_nav_btn_arrow'>
            <ImageSvgIconArrowBlack02 />
          </span>
        </button> */}
        <p>{currentQuestionIndex}</p>
      </S.TestInfoArea>

      <S.ButtonArea>
        <button onClick={saveHandler}>Save</button>
        <button className={`${currentQuestionIndex === 1 ? 'disabled' : ''}`} disabled={currentQuestionIndex === 1} onClick={backHandler}>
          Back
        </button>
        <button onClick={nextHandler}>Next</button>
      </S.ButtonArea>
    </S.Container>
  );
}

const S = {
  Container: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 9.375rem;
    background: #f4f8ff;
    position: relative;
    z-index: 100;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${lineBg});
      width: 100%;
      height: 0.1875rem;
      background-repeat: repeat-x;
    }
  `,

  UserInfoArea: styled.div`
    padding-left: 4.375rem;
    width: 30%;

    .user_name {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  TestInfoArea: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    justify-content: center;
    .question_nav_btn {
      font-size: 1.375rem;
      font-weight: 600;
      padding: 0.875rem 1.5rem;
      border-radius: 0.625rem;
      background-color: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      .question_nav_btn_arrow {
        width: 1.125rem;
        height: 1.125rem;
        transform: rotate(180deg);
      }
    }
  `,

  ButtonArea: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    padding-right: 4.375rem;
    width: 30%;

    button {
      background-color: #004a9e;
      padding: 0.875rem 1.5rem;
      border-radius: 1.875rem;
      color: white;
      font-size: 1.375rem;
      font-weight: 600;

      &.disabled {
        background-color: #ccc;
        color: #666;
      }
    }
  `,
};

