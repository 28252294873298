import { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import ToastMessage from './ToastMessage';

/** 아카데미 대시보드 - Student 페이지의 학생 프로필 모달에서 열고 닫을 수 있는 "Memo" 영역 컴포넌트 */
export default function ProfileMemoArea({ profileDetail, setIsShowMemoArea, setIsEditMemo }) {
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 제출 결과 상태 알림 노출 여부 */
  const [isShowSuccessSubmit, setIsShowSuccessSubmit] = useState(false);
  /** textarea 에 실시간 입력 되는 값 */
  const [textareaValue, setTextareaValue] = useState('');
  /** textarea 에 최초 입력 되는 값(최초 API에서 얻어오는 값) */
  const [textareaGetValue, setTextareaGetValue] = useState('');

  /** 학생 메모 수정 API 요청 */
  const putMemo = ({ userSeq, userEmail, memo }) => {
    if (!userSeq || !userEmail || !memo) return;

    const param = {
      userSeq,
      userEmail,
      memo: String(memo).trim(),
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        // 제출 성공
        setIsShowSuccessSubmit(true);
        setIsEditMemo(false);
      } else {
        // 제출 실패
        console.error(response);
      }
    };

    request
      .put(`/api/v2/academy/student/memo`, param, successHandler)
      // 기타 응답 에러
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  /** textarea 입력 핸들러 */
  const handleTextarea = (event) => {
    const textValue = event.target.value;

    if (textValue.length > 1000) return;

    setTextareaValue(textValue);
    if (textareaGetValue === textareaValue) {
      setIsEditMemo(true);
    }
  };

  /** Form 제출 핸들러 */
  const handleSubmit = ({ event, profileDetail, textareaValue }) => {
    event.preventDefault();
    putMemo({ userSeq: profileDetail.userSeq, userEmail: profileDetail.userEmail, memo: textareaValue });
  };

  /** "Cancel" 버튼 핸들러 */
  const handleCancelButton = () => {
    setIsShowMemoArea(false);
  };

  useEffect(() => {
    setTextareaGetValue(profileDetail.memo);
    setTextareaValue(profileDetail.memo);
  }, [profileDetail]);

  return (
    <S.Wrap>
      <div className='draggable_handle' />
      <div>
        <h4 className='title'>Memo</h4>
      </div>
      {isLoading ? (
        'Loading ...'
      ) : (
        <form
          className='form_area'
          onSubmit={(event) => {
            handleSubmit({ event, profileDetail, textareaValue });
          }}>
          <div className='textarea_wrap custom_scroll'>
            <label htmlFor='userProfileMemo'>Memo</label>
            <textarea
              className='custom_scroll'
              name='userProfileMemo'
              id='userProfileMemo'
              value={textareaValue ? textareaValue : ''}
              onChange={handleTextarea}
              placeholder='Only you can see this memo. Write notes about the student.'
            />
            {textareaValue && (
              <div className={`display_character_count ${textareaValue?.length >= 1000 ? 'text_length_error' : ''}`}>{textareaValue?.length} / 1000</div>
            )}
            {isShowSuccessSubmit && (
              <ToastMessage isShowSuccessSubmit={isShowSuccessSubmit} setShowThisComponent={setIsShowSuccessSubmit}>
                Memo saved
              </ToastMessage>
            )}
          </div>
          <div className='button_wrap'>
            <button type='button' onClick={handleCancelButton} className='line'>
              Cancel
            </button>
            <button type='submit' className='color'>
              Save
            </button>
          </div>
        </form>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 24rem;

    .draggable_handle {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 8%;
      z-index: 1;
    }

    .title {
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    .form_area {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }

    .textarea_wrap {
      position: relative;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      width: 21.5rem;
      padding: 1rem;
      border-radius: 0.625rem;
      border: 1px solid var(--input-stroke, #d2dbe2);
      background: #fff;
      label {
        display: none;
      }
      textarea {
        flex: 1 0 auto;
        width: 100%;
        padding: 0;
        border: none;
        resize: none;
      }
      .display_character_count {
        text-align: right;
        color: #a5aab1;
        font-weight: 400;
        font-size: 0.875rem;
        margin-top: 0.25rem;
        &.text_length_error {
          display: block;
          color: #f24b4b;
        }
      }
    }

    .button_wrap {
      margin-top: 0.75rem;
      display: flex;
      justify-content: right;
      gap: 0.75rem;
      button {
        display: flex;
        height: 2.375rem;
        padding: 0.75rem 1.125rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
        text-transform: uppercase;
        &.color {
          border-color: #0068bd;
          background-color: #0068bd;
          color: #fff;
        }
        &.line {
          border-color: #0068bd;
          background-color: #ffffff;
          color: #0068bd;
        }
      }
    }
  `,
};
