import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useUserLevels from 'hooks/useUserLevels';
import request from 'utils/Request.utils';

import { ReactComponent as ImgSvgTextAP } from 'assets/img/svg/test_AP.svg';
import { ReactComponent as ImgSvgTextSAT } from 'assets/img/svg/test_SAT.svg';
import MiniAlert from 'components/_common/alerts/MiniAlert';
import useOutsideClick from 'hooks/useOutsideClick';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

/** 해당 컴포넌트의 버튼 */
const BUTTONS_DATA_ARRAY = {
  SAT: {
    displayTitle: 'SAT',
    svgIconComponent: <ImgSvgTextSAT />,
  },
  AP: {
    displayTitle: 'AP',
    svgIconComponent: <ImgSvgTextAP />,
  },
};

/** 프로젝트의 메인 헤더 영역 메뉴 중 "AP" 페이지에 접근 할 수 있는 셀렉트 버튼 컴포넌트 */
export default function MainApSelectButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const apAcademySeq = Number(process.env.REACT_APP_AP_ACADEMY); // ap가 보이는 학원 seq

  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  // console.log(userInfo);
  if (userInfo?.ecSeq !== apAcademySeq) {
    return null;
  }

  /** 드롭다운 영역 노출 여부 */
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  /** 미니 알림 창 노출용 상태 */
  const [alert, alertSet] = useState({
    isView: true,
    text: `You are now in ${pathname.includes('/ap/') ? 'AP' : 'SAT'} Dashboard.`,
  });

  const dropdownRef = useRef(null);

  /** 드롭다운 여/닫기 핸들러 */
  const handleToggleDropdown = () => {
    setIsOpenDropdown((current) => !current);
  };

  /** 링크 이동 핸들러 */
  const handleLink = ({ type }) => {
    const SCORE_PATH = `/ap/com/score/${obfuscateUrlParam('0')}`;

    if (type === BUTTONS_DATA_ARRAY.AP.displayTitle) {
      if (useLevels.isAcademy)
        // navigate('/ap/academy/dashboard'); // 학원 관리자
        navigate(SCORE_PATH);
      else if (useLevels.isStudent)
        navigate('/ap/student/dashboard'); // 학생
      else if (useLevels.isTeacher)
        // navigate('/ap/academy/dashboard'); // 선생님
        navigate(SCORE_PATH);
      else if (useLevels.isTutor)
        // navigate('/ap/academy/dashboard'); // 강사
        navigate(SCORE_PATH);
      else navigate('/ap/parent/dashboard'); // 학부모
    } else {
      // SAT로 이동 시,
      if (useLevels.isAcademy)
        navigate('/academy/dashboard'); // 학원 관리자
      else if (useLevels.isStudent)
        navigate('/student/dashboard'); // 학색
      else if (useLevels.isTeacher)
        navigate('/academy/dashboard'); // 선생님
      else if (useLevels.isTutor)
        navigate('/academy/dashboard'); // 강사
      else navigate('/parent/dashboard'); // 학부모
    }
  };

  // 드롭다운 영역 외부를 클릭 했을 경우, 드롭다운 영역 닫기
  useOutsideClick(dropdownRef, () => setIsOpenDropdown(false), isOpenDropdown);

  return (
    !useLevelsIsLoading && (
      <>
        <S.Wrap>
          <button type='button' title='Drop down' onClick={handleToggleDropdown} className='button_ap sat'>
            <div className='icon_wrap'>{pathname.includes('/ap/') ? BUTTONS_DATA_ARRAY.AP.svgIconComponent : BUTTONS_DATA_ARRAY.SAT.svgIconComponent}</div>
            <span>{pathname.includes('/ap/') ? BUTTONS_DATA_ARRAY.AP.displayTitle : BUTTONS_DATA_ARRAY.SAT.displayTitle}</span>
          </button>
          {isOpenDropdown && (
            <div className='dropdown_wrap' ref={dropdownRef}>
              <ul>
                {Object.values(BUTTONS_DATA_ARRAY).map((item, index) => {
                  return (
                    <li key={`key_${index}`}>
                      <button type='button' title='Move to another Page' onClick={() => handleLink({ type: item.displayTitle })}>
                        {item.displayTitle}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </S.Wrap>
        {alert.isView && (
          <MiniAlert
            text={alert.text}
            type=''
            active={alert.isView}
            inactive={() =>
              alertSet((current) => {
                return { ...current, isView: false };
              })
            }
            timeOut={1500}
          />
        )}
      </>
    )
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    .button_ap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      .icon_wrap {
        border-radius: 100%;
        width: 1.875rem;
        height: 1.875rem;
        background-color: #ffffff;
        color: #111111;
        font-family: 'Roboto Serif';
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 0.625rem;
        }
      }
      span {
        font-weight: 400;
        font-size: 0.625rem;
      }
    }

    .dropdown_wrap {
      position: absolute;
      top: 4rem;
      left: 0;
      border-radius: 0.25rem;
      overflow: hidden;
      ul {
        li {
          & + li {
            border-top: 1px solid #d2dbe2;
          }
        }
      }
      button {
        width: 10.5rem;
        height: 2rem;
        padding: 0 1rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        background-color: #ffffff;
        &:hover {
          filter: brightness(0.98);
        }
      }
    }
  `,
};
