export default function AssignedStudent({ className, checkedStudents, onCheckboxChange }) {
  return (
    <>
      {/*4.Class Details*/}
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon bullet_3 black'>&nbsp;</i>
          Assigned Student(s)
        </h2>
      </article>
      <article className='class_info'>
        <div className='item'>
          <div className='tit'>Class</div>
          <div className='info'>
            <p className='name'> {className} </p>
          </div>
        </div>
      </article>
      <article className='com_list_box'>
        <div className='scroll'>
          <table className='list'>
            <colgroup>
              <col width='8%' />
              <col />
              <col width='6.25rem' />
            </colgroup>
            <thead>
              <tr className='sticky top'>
                <th colSpan={3} className='table_tit black'>
                  <div className='wrap'>
                    Selected Student List
                    <p className='num'>
                      <i className='svg_icon icon_person white'>&nbsp;</i>
                      {checkedStudents.length}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {checkedStudents &&
                checkedStudents.length > 0 &&
                checkedStudents.map((studentInfo, idx) => {
                  return (
                    <tr key={idx}>
                      <td className='alignC' style={{ paddingLeft: '.625rem' }}>
                        <input
                          type='checkbox'
                          id={`chk12-${idx}`}
                          className='com_chk'
                          checked={checkedStudents.includes(studentInfo)}
                          onChange={(e) => onCheckboxChange(studentInfo, !checkedStudents.includes(studentInfo))}
                        />
                        <label htmlFor={`chk12-${idx}`}></label>
                      </td>
                      <td className='alignL'>
                        <p className='ellipsis'>{studentInfo.userName}</p>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </article>
    </>
  );
}
