import styled from 'styled-components';
import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';
import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';

const examInfo = {
  moduleTitle: 'Section 2, Module 2: Math',
  testName: 'TEST_NAME',
  subjectName: 'SUBJECT_NAME',
};

/** ap/question-bank/test-preview 에서 사용될 header */
export default function TestPreviewHeader() {
  const leftSection = (
    <S.LeftArea>
      <p className='module_title'>{examInfo.moduleTitle}</p>
      <button className='direction_button'>
        Directions
        <ImageSvgIconArrowBlack02 />
      </button>
    </S.LeftArea>
  );

  return <ApExamHeaderBase leftSection={leftSection} />;
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .module_title {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }

    .direction_button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: 500;
      cursor: pointer;

      svg {
        color: black;
        width: 1.125rem;
        height: 1.125rem;
        transition: 0.2s;
      }
    }
  `,
};
