import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

// section components
import NotiBox from './_components/section/NotiBox';
import UserInfoSection from './_components/section/UserInfoSection';

import useUserLevels from 'hooks/useUserLevels';
import { useParams } from 'react-router-dom';
import request from 'utils/Request.utils';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import Section from './_components/section/Section';

/**
 * AP 프로젝트 - report 페이지
 * 🔍 검색 키워드 - #ap #report #ap/com/report
 */
export default function ApReportPage() {
  // console.log('window.location.pathname', window.location.pathname);
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isB2B, isLoading } = useUserLevels(userInfo?.userLevel);

  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  const [reportUserInfo, setReportUserInfo] = useState({
    name: '', // string => 프로필에 보여질 이름
    userSeq: 0, // number => 유저 시퀀스
    grade: 0, // number => 학년
    testedOn: '', // string => 시험을 본 시간
    subject: '', // string => 과목
    testName: '', // string => 시험 이름
    academyName: '', // string => 아카데미 이름
    academyImageURL: '', // string => 아카데미 프로필 이미지

    scaledScore: 1, // number => 총 점수
  });

  const [reportSectionInfo, setReportSectionInfo] = useState([
    {
      sectionName: '',
      sectionNum: 0,
      maxScore: 0,
      receivedScore: 0,
      parts: [
        {
          typeOfQuestion: '', // MCQ, FRQ
          partName: '',
          questions: [
            {
              questionType: '', //  (M: 객관식, S: 주관식, E: 서술형)
              questionNum: 0,
              tryAnswer: '',
              correctAnswer: '',
              isCorrect: true,
              question: '',
              maxScore: 0,
              rawScore: 0.0,
            },
          ],
        },
      ],
    },
  ]);

  const [scores, setScores] = useState({
    section1: 0,
    section2: 0,
    section1Max: 0,
    section2Max: 0,
  });

  useEffect(() => {
    document.body.classList.add('p_report_cart');

    if (isLoading) return; // 권한 적용중 데이터 호출 방지

    const userInfoSuccessHandler = (response) => {
      if (response.code === 200) {
        setReportUserInfo(response.result.reportUserInfo);
      }
    };

    const reportUserApi = isB2B ? `/api/v1/academy/score/report-user?uthSeq=${uthSeq}` : `/api/v1/student/dashboard/report-user?uthSeq=${uthSeq}`;

    request.apGet(reportUserApi, null, userInfoSuccessHandler);

    const sectionInfoSuccessHandler = (response) => {
      console.log('response', response);
      if (response.code === 200) {
        const sectionData = response.result.reportSectionData;

        setReportSectionInfo(sectionData);

        // section 1 find
        const section1 = sectionData.find((section) => section.sectionNum === 1);
        // section 2 find
        const section2 = sectionData.find((section) => section.sectionNum === 2);

        setScores({
          section1: section1?.receivedScore,
          section2: section2?.receivedScore,
          section1Max: section1?.maxScore,
          section2Max: section2?.maxScore,
        });
      }
    };

    const reportSectionApi = isB2B ? `/api/v1/academy/score/report-section?uthSeq=${uthSeq}` : `/api/v1/student/dashboard/report-section?uthSeq=${uthSeq}`;
    request.apGet(reportSectionApi, null, sectionInfoSuccessHandler);

    return () => document.body.classList.remove('p_report_cart');
  }, [isB2B, uthSeq, isLoading]);

  return (
    <S.ApStudentReportPageContainer>
      <h2 className='page_title'>Report Card</h2>
      <UserInfoSection reportUserInfo={reportUserInfo} scores={scores} />

      <NotiBox />

      {reportSectionInfo &&
        reportSectionInfo.length > 0 &&
        reportSectionInfo.map((section, index) => {
          return (
            <Fragment key={`part-${index}`}>
              <Section section={section} />
            </Fragment>
          );
        })}

      <S.Divider />
    </S.ApStudentReportPageContainer>
  );
}

const S = {
  ApStudentReportPageContainer: styled.div`
    background-color: white;
    max-width: 1194px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding-bottom: 5.75rem;

    .page_title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      padding-top: 6.25rem;
    }
  `,

  Divider: styled.hr`
    width: 100%;
    height: 0.3125rem;
    background: #000;
    border: none;
  `,
};
