import { useEffect, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';
import dayjsTZ, { dateStringToDayjs } from 'utils/functions/time/dayjs-config';

//img
import imgLogo from 'assets/img/logo.png';

import useUserLevels from 'hooks/useUserLevels';
import { nvlNumber } from 'utils/Common.utils.js';
import request from 'utils/Request.utils.js';
import SessionStorage from 'utils/SessionStorage.utils';

import ChartWithScoreContainer from './_components/ChartWithScoreContainer';

import GateplusLog from 'assets/img/logo.png';
import styled from 'styled-components';

import ProfileImage from 'components/_common/ProfileImage';

/**
 * 특정 시험에 대한 정보를 보여주는 페이지 - pdf 인쇄 기능 있음 (Student Dashboard, Score 페이지 등에서 공통으로 사용)
 * @description
 * 🔍 검색 키워드 - #com/reports #Report #Card #PDF
 */
export default function ReportCardPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const todayDate = dayjsTZ().format('YYYYMMDD');

  const reportCard = SessionStorage.getItemJsonParse('reportCard');

  const uthSeq = reportCard?.uthSeq || 0;
  const userSeq = reportCard?.userSeq || 0;
  const classSeq = reportCard?.classSeq || 0;
  const isB2c = reportCard?.isB2c || false;

  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, useLevelsIsLoading, isB2B } = useUserLevels(userInfo?.userLevel);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 자식 시퀀스 */
  const [childSeq, setChildSeq] = useState(0);
  /** Report Card data */
  const [testResult, setTestResult] = useState({
    userName: '',
    studentGrade: '',
    questionList: [],
    questionAccuracyList: [],
    trhDate: '',

    academyUpFileSeq: 0,
    className: '',
    classSeq: 0,
    customSetSeq: 0,
    difficulty: '',
    ecSeq: 0,
    subject: '',
    testName: '',
    userSeq: 0,
  });

  const [barChartData, setBarChartData] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);
  const [barChartData2, setBarChartData2] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);
  // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  // const [timePerQuestionData, setTimePerQuestionData] = useState({
  //   series: [
  //     {
  //       name: 'You',
  //       data: [],
  //       color: '#0068BD',
  //     },
  //     {
  //       name: 'GATE⁺',
  //       data: [],
  //       color: '#E3E7ED',
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: '55%',
  //         endingShape: 'rounded',
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent'],
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //     tooltip: {
  //       custom: function ({ seriesIndex, dataPointIndex, w }) {
  //         const xAxisValue = w.globals.labels[dataPointIndex];
  //         return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       horizontalAlign: 'center',
  //       fontFamily: 'Roboto, Arial, sans-serif',
  //       markers: {
  //         width: 25,
  //         height: 8,
  //         radius: 8,
  //       },
  //       itemMargin: {
  //         horizontal: 15,
  //       },
  //       onItemClick: {
  //         toggleDataSeries: false,
  //       },
  //       onItemHover: {
  //         highlightDataSeries: false,
  //       },
  //     },
  //     states: {
  //       hover: {
  //         filter: {
  //           type: 'darken',
  //           value: 0.7,
  //         },
  //       },
  //     },
  //   },
  // });

  // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  // const [filterValue, setFilterValue] = useState({
  //   uthSeq: 0,
  //   userSeq: 0,
  //   customSetSeq: 0,
  //   classSeq: 0,
  //   moduleNum: '',
  //   tmSubject: '',
  //   tmDifficulty: '',
  //   color: '#008CFF',
  //   subjectOption: false,
  //   moduleNumOption: false,
  //   className: 'custom-tooltip',
  // });
  const [sortedQuestionList, setSortedQuestionList] = useState([]);
  // const [perSubject, setPerSubject] = useState(false); // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  // const [perModuleNum, setPerModuleNum] = useState(false); // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  const [v2ScorePercentile, setV2ScorePercentile] = useState({
    total: 0, // number  => 전체 백분위
    math: 0, // number  => 수학 백분위
    rw: 0, // number    => 영어 백분위
  });
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const componentRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const cas = Math.floor(barChartData[0].data[0] / 14);
  const iai = Math.floor(barChartData[0].data[1] / 14);
  const sec = Math.floor(barChartData[0].data[2] / 14);
  const eoi = Math.floor(barChartData[0].data[3] / 14);

  const alg = Math.floor(barChartData2[0].data[0] / 14);
  const am = Math.floor(barChartData2[0].data[1] / 14);
  const psada = Math.floor(barChartData2[0].data[2] / 14);
  const gat = Math.floor(barChartData2[0].data[3] / 14);

  /** 선택된 학생의 개별 시험의 점수를 불러오는 함수 - RW, MATH ( 구 API 문제로 새로운 데이터 사용 ) */
  const getTestScoreAverage = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedStudentTestScorePercentile = response.result.studentTestScorePercentile[0];

        setV2ScorePercentile({
          total: fetchedStudentTestScorePercentile.totalPercentile,
          math: fetchedStudentTestScorePercentile.mathPercentile,
          rw: fetchedStudentTestScorePercentile.rwPercentile,
        });
      }
    };

    // 권한에 따라 다른 api 호출
    let apiURL = '';
    if (isB2B)
      apiURL = `/api/v2/score/student/average-score?userSeq=${userSeq}&uthSeq=${uthSeq}`; // 관리자 계정
    // 학부모일 경우 자식 시퀀스 추가, 학생일때는 빈 문자열
    else apiURL = `/api/v2/student/dashboard/tests/average-score?uthSeq=${uthSeq}&childStudentSeq=${childSeq !== 0 ? childSeq : ''}`;

    request.get(apiURL, null, successHandler).catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
  };
  /** 시험 점수 정오답 데이터 얻어오기 API 요청 */
  const getTestPointOX = ({ uthSeq, useLevels }) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        // 보지 않은 과목은 undefined 값이 옴 ( 영어, 수학 둘 중 하나만 풀었을 경우 )
        const { testInfo, eDiff, mDiff, mathPoint, mathRank, rwPoint, rwRank } = response.result;
        setTestResult((prev) => {
          return {
            ...prev,
            ...testInfo,
            mathDiff: mDiff,
            engDiff: eDiff,
            mathCurrentScore: mathPoint ?? '-',
            mathCurrentRank: mathRank !== undefined ? 100 - mathRank : '-',
            rwCurrentScore: rwPoint ?? '-',
            rwCurrentRank: rwRank !== undefined ? 100 - rwRank : '-',
          };
        });
        // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
        // setFilterValue((prev) => {
        //   return { ...prev, uthSeq: uthSeq, color: '#008CFF' };
        // });
        if (isB2B)
          getCategoriesRateInAcademyDashboardScore(uthSeq); // 아카데미 회원 일 경우
        else getCategoriesRateInStudentDashboard(uthSeq); // 학생 회원 일 경우

        setIsLoading(false);
      }
    };

    request.get(`/api/dsat/history/test/score?uthSeq=${uthSeq}`, null, successHandler);
  };
  /** 학생 대시보드에서 접근 했을 시, 카테고리 별 점수 통계 얻어오기 API 요청 */
  const getCategoriesRateInStudentDashboard = (uthSeq) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const personal = response.result.studentTestTypeCorrectRate;
        const overall = response.result.gateplusTestTypeCorrectRate;

        if (personal != null && personal?.length > 0) {
          // 학생 개인 통계
          /** 학생 영어 통계 */
          const personalReadingAndWriting = personal.filter((item) => item.subject === 'E')[0];
          /** 학생 수학 통계 */
          const personalMath = personal.filter((item) => item.subject === 'M')[0];
          /** 전체 평균 영어 통계 */
          const overallReadingAndWriting = overall.filter((item) => item.subject === 'E')[0];
          /** 전체 평균 수학 통계 */
          const overallMath = overall.filter((item) => item.subject === 'M')[0];

          const CAS = personalReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS') ?? [];
          const IAI = personalReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI') ?? [];
          const SEC = personalReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC') ?? [];
          const EOI = personalReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI') ?? [];

          const ALG = personalMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg') ?? [];
          const AM = personalMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM') ?? [];
          const PSADA = personalMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA') ?? [];
          const GAT = personalMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT') ?? [];

          const CAS_ = overallReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS') ?? [];
          const IAI_ = overallReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI') ?? [];
          const SEC_ = overallReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC') ?? [];
          const EOI_ = overallReadingAndWriting?.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI') ?? [];

          const ALG_ = overallMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg') ?? [];
          const AM_ = overallMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM') ?? [];
          const PSADA_ = overallMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA') ?? [];
          const GAT_ = overallMath?.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT') ?? [];

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.correctRate), nvlNumber(IAI[0]?.correctRate), nvlNumber(SEC[0]?.correctRate), nvlNumber(EOI[0]?.correctRate)],
              gatePlusData: [
                nvlNumber(CAS_[0]?.correctRate),
                nvlNumber(IAI_[0]?.correctRate),
                nvlNumber(SEC_[0]?.correctRate),
                nvlNumber(EOI_[0]?.correctRate),
              ],
            },
          ]);
          if (ALG && AM && PSADA && GAT && ALG_ && AM_ && PSADA_ && GAT_) {
            setBarChartData2((prev) => [
              {
                ...prev,
                data: [nvlNumber(ALG[0]?.correctRate), nvlNumber(AM[0]?.correctRate), nvlNumber(PSADA[0]?.correctRate), nvlNumber(GAT[0]?.correctRate)],
                gatePlusData: [
                  nvlNumber(ALG_[0]?.correctRate),
                  nvlNumber(AM_[0]?.correctRate),
                  nvlNumber(PSADA_[0]?.correctRate),
                  nvlNumber(GAT_[0]?.correctRate),
                ],
              },
            ]);
          }
        }

        getModuleAnswer(uthSeq);
      }
    };

    let apiURL = '';
    if (isB2B)
      apiURL = `/api/v2/student/dashboard/test/score-by-category?uthSeq=${uthSeq}`; // 관리자 계정
    else apiURL = `/api/v2/student/dashboard/test/score-by-category?uthSeq=${uthSeq}&childStudentSeq=${childSeq !== 0 ? childSeq : ''}`; // 학부모 계정

    request.get(apiURL, null, successHandler);
  };
  /** 아카데미 대시보드 - Score에서 접근 했을 시, 카테고리 별 점수 통계 얻어오기 API 요청 */
  const getCategoriesRateInAcademyDashboardScore = (uthSeq) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const personal = response.result.studentTestTypeCorrectRate;
        const overall = response.result.gateplusTestTypeCorrectRate;

        if (personal != null && personal?.length > 0) {
          // 학생 개인 통계
          /** 학생 영어 통계 */
          const personalReadingAndWriting = personal.filter((item) => item.subject === 'E')[0];
          /** 학생 수학 통계 */
          const personalMath = personal.filter((item) => item.subject === 'M')[0];
          /** 전체 평균 영어 통계 */
          const overallReadingAndWriting = overall.filter((item) => item.subject === 'E')[0];
          /** 전체 평균 수학 통계 */
          const overallMath = overall.filter((item) => item.subject === 'M')[0];

          const isTestedMath = personalMath?.categoriesRate?.length > 0;
          const isTestedRW = personalReadingAndWriting?.categoriesRate?.length > 0;

          // 수학 통계 그래프 데이터 세팅 ( 수학 시험 데이터가 있을 경우만 )
          if (isTestedMath) {
            const ALG = personalMath.categoriesRate?.find((item) => item.dispContentDomainName === 'Alg');
            const AM = personalMath.categoriesRate?.find((item) => item.dispContentDomainName === 'AM');
            const PSADA = personalMath.categoriesRate?.find((item) => item.dispContentDomainName === 'PSADA');
            const GAT = personalMath.categoriesRate?.find((item) => item.dispContentDomainName === 'GAT');

            const ALG_ = overallMath.categoriesRate?.find((item) => item.dispContentDomainName === 'Alg');
            const AM_ = overallMath.categoriesRate?.find((item) => item.dispContentDomainName === 'AM');
            const PSADA_ = overallMath.categoriesRate?.find((item) => item.dispContentDomainName === 'PSADA');
            const GAT_ = overallMath.categoriesRate?.find((item) => item.dispContentDomainName === 'GAT');

            setBarChartData2((prev) => [
              {
                ...prev,
                data: [nvlNumber(ALG?.correctRate), nvlNumber(AM?.correctRate), nvlNumber(PSADA?.correctRate), nvlNumber(GAT?.correctRate)],
                gatePlusData: [nvlNumber(ALG_?.correctRate), nvlNumber(AM_?.correctRate), nvlNumber(PSADA_?.correctRate), nvlNumber(GAT_?.correctRate)],
              },
            ]);
          }
          // 영어 통계 그래프 데이터 세팅 ( 영어 시험 데이터가 있을 경우만 )
          if (isTestedRW) {
            const CAS = personalReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'CAS');
            const IAI = personalReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'IAI');
            const SEC = personalReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'SEC');
            const EOI = personalReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'EOI');

            const CAS_ = overallReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'CAS');
            const IAI_ = overallReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'IAI');
            const SEC_ = overallReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'SEC');
            const EOI_ = overallReadingAndWriting.categoriesRate?.find((item) => item.dispContentDomainName === 'EOI');

            setBarChartData((prev) => [
              {
                ...prev,
                data: [nvlNumber(CAS?.correctRate), nvlNumber(IAI?.correctRate), nvlNumber(SEC?.correctRate), nvlNumber(EOI?.correctRate)],
                gatePlusData: [nvlNumber(CAS_?.correctRate), nvlNumber(IAI_?.correctRate), nvlNumber(SEC_?.correctRate), nvlNumber(EOI_?.correctRate)],
              },
            ]);
          }
        }

        getModuleAnswer(uthSeq);
      }
    };

    request.get(`/api/v2/score/student/category-rate?uthSeq=${uthSeq}`, null, successHandler);
  };

  const getModuleAnswer = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.questionList;

        setTestResult((prev) => {
          return {
            ...prev,
            questionList: questionList,
            trhDate: questionList[questionList.length - 1]?.trhRegDate,
            userName: questionList[0]?.userName,
            studentGrade: questionList[0]?.studentGrade,
          };
        });
        accuracyByQuestion(uthSeq);
      }
    };

    let apiURL = '';
    if (isB2B)
      apiURL = `/api/exam/moduleAnswer?uthSeq=${uthSeq}`; // 관리자 계정
    else apiURL = `/api/exam/moduleAnswer?uthSeq=${uthSeq}&childStudentSeq=${childSeq !== 0 ? childSeq : ''}`; // 학부모 계정

    request.get(apiURL, null, successHandler);
  };

  const accuracyByQuestion = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionAccuracyList = response.result.questionAccuracyList;

        setTestResult((prev) => {
          return { ...prev, questionAccuracyList: questionAccuracyList };
        });
        setIsLoading(false);
      }
    };

    request.get(`/api/dsat/accuracy/question?uthSeq=${uthSeq}`, null, successHandler);
  };

  // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  // const timePerQuestion = (uthSeq) => {
  //   let pms = {
  //     userSeq: userSeq,
  //     moduleNum: filterValue.moduleNum,
  //     subject: filterValue.tmSubject,
  //     difficulty: nvl(filterValue.moduleNum === '1' ? '' : filterValue.tmSubject !== 'E' ? testResult.mathDiff : testResult.engDiff),
  //   };

  //   const successHandler = (response) => {
  //     if (response.code === 200) {
  //       let gatePlusDurationList = response.result.personalDurationListAll;
  //       let personalDurationList = response.result.personalDurationList;

  //       const youUseSeconds = personalDurationList?.map((item) => item.useSeconds);
  //       const gateUseSeconds = gatePlusDurationList?.map((item) => item.secAvg);

  //       if (youUseSeconds && youUseSeconds != null) {
  //         setTimePerQuestionData((prevData) => ({
  //           ...prevData,
  //           series: [
  //             { name: 'You', data: youUseSeconds, color: filterValue.color },
  //             { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
  //           ],
  //           options: {
  //             ...prevData.options,
  //             xaxis: {
  //               categories: youUseSeconds?.map((_, index) => index + 1),
  //             },
  //             tooltip: {
  //               custom: function ({ seriesIndex, dataPointIndex, w }) {
  //                 const xAxisValue = w.globals.labels[dataPointIndex];
  //                 const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

  //                 if (seriesName === 'YOU' && seriesIndex === 0) {
  //                   return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
  //                 } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
  //                   return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
  //                 }
  //               },
  //             },
  //           },
  //         }));
  //       }
  //     }
  //   };

  //   request.get(
  //     `/api/dsat/duration/question?uthSeq=${uthSeq}&userSeq=${pms.userSeq}&moduleNum=${pms.moduleNum}&subject=${pms.subject}&difficulty=${pms.difficulty}`,
  //     null,
  //     successHandler
  //   );
  // };

  // const perSubActive = () => {
  //   setPerSubject((prev) => !prev);
  //   setPerModuleNum(false);
  // };
  // const perNumActive = () => {
  //   setPerSubject(false);
  //   setPerModuleNum((prev) => !prev);
  // };
  // const selectedTimeSubject = () => {
  //   return filterValue.tmSubject === 'E' ? 'RW' : filterValue.tmSubject === 'M' ? 'MATH' : '-';
  // };
  // const selectedTimeModuleNum = () => {
  //   return filterValue.moduleNum === '1' ? 'Module 1' : filterValue.moduleNum === '2' ? 'Module 2' : '-';
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${testResult.testName}성적표_${todayDate}_${testResult?.userName}`,
  });
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (!useLevelsIsLoading && uthSeq) {
      // 사용자 유형 얻어오기 로딩이 완료 되면 실행
      getTestPointOX({ uthSeq, useLevels });
      getTestScoreAverage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading, useLevels, childSeq]);

  useEffect(() => {
    if (testResult.questionList != null && testResult.questionList.length > 0) {
      setSortedQuestionList(testResult.questionList.sort((a, b) => a.questionOrder - b.questionOrder));
    }

    // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
    // if (testResult.subject === 'M') setFilterValue((prev) => ({ ...prev, tmSubject: 'M', moduleNum: '1' }));
    // else {
    //   setFilterValue((prev) => ({
    //     ...prev,
    //     tmSubject: 'E',
    //     moduleNum: '1',
    //     color: '#008CFF',
    //   }));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResult]);

  // 🧰 Time per Question - 임시 주석 처리 ( 241206 )
  // useEffect(() => {
  //   if (filterValue.uthSeq > 0) timePerQuestion(filterValue.uthSeq);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterValue.tmSubject, filterValue.uthSeq, filterValue.moduleNum]);

  // 학부모 계정일 경우 자식 시퀀스 얻어오기
  useEffect(() => {
    if (!useLevelsIsLoading && useLevels.isParent) {
      /** 학생(자식) 시퀀스 얻어오기 API 요청 */
      const getChildSeq = () => {
        const successHandler = (response) => {
          if (response.code === 200) {
            const childInfo = response.result.childInfo;

            if (childInfo) setChildSeq(childInfo.familySeq);
          }
        };

        request
          .get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler)
          .catch((error) => console.error('Report Card - 자식 시퀀스 얻어오기 API 실패', error));
      };

      getChildSeq();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevels, useLevelsIsLoading]);

  useEffect(() => {
    document.body.classList.add('p_report_cart'); //현재페이지에서만 addClass

    return () => document.body.classList.remove('p_report_cart'); //다른 페이지에선 removeClass

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  // 점수 계산 헬퍼 함수들
  /** 총 점수 계산 함수 */
  function calculateTotalScore(rwScore, mathScore) {
    if (rwScore === '-' && mathScore === '-') return '-';
    if (mathScore === '-') return rwScore;
    if (rwScore === '-') return mathScore;
    return mathScore + rwScore;
  }
  /** 총 순위(%) 계산 함수 */
  function calculateTotalRank(subject, mathRank, rwRank) {
    if (subject === 'F') return (mathRank + rwRank) / 2;

    if (mathRank === 0 && rwRank === 0) return '-';
    if (mathRank === 0) return rwRank;
    if (rwRank === 0) return mathRank;
  }
  /** 점수 범위 계산 함수 */
  function getScoreRange(subject) {
    const isFullTest = subject === 'F';
    return [isFullTest ? '400' : '200', isFullTest ? '1600' : '800'];
  }
  /** 순위(%) 레이블 포맷 함수 */
  function formatRankLabel(mathRank, rwRank) {
    if (mathRank === '-' && rwRank === '-') return '-';
    if (mathRank === '-') return `${Math.min(rwRank, 99)}%`;
    if (rwRank === '-') return `${Math.min(mathRank, 99)}%`;
    return `${Math.min((mathRank + rwRank) / 2, 99)}%`;
  }

  /** 이미지 랜더링에 사용할 프로필 정보 */
  const profileInfo = isB2c
    ? {
        userName: userInfo.userName,
        userSeq: userInfo.userSeq,
        upFileSeq: userInfo.upFileSeq,
      }
    : {
        academyName: testResult.companyName,
        ecSeq: testResult.ecSeq,
        upFileSeq: testResult.academyUpFileSeq,
      };

  return isLoading ? (
    'Loading ...'
  ) : (
    <div className='pop_report com_center_wrap'>
      <div className='pop_report_print' ref={(el) => (componentRef.current = el)}>
        {/*상단 로고*/}
        <h1 className='top'>Report Card</h1>
        <div className='box'>
          {/*시험응시자 정보*/}
          <section className='student_sec'>
            <h2 className='tit'>Your Scores</h2>
            <ul className='info_list'>
              <li className='tit_field'>NAME</li>
              <li className='info_field'>{testResult?.userName}</li>
              <li className='tit_field'>Grade</li>
              <li className='info_field'>{testResult?.studentGrade}</li>
              <li className='tit_field'>Tested on</li>
              <li className='info_field'>{dateStringToDayjs(testResult.trhDate, 'YYYY-MM-DD HH:mm:ss').format('MMM D, YYYY')}</li>
              <li className='tit_field'>Test Set Name</li>
              <li className='info_field'>{testResult.testName}</li>
            </ul>
            <ProfileImage size='9.375rem' round='0' userInfo={profileInfo} isB2B={!isB2c} isAcademyImg={!isB2c} />
          </section>
          {/*점수*/}
          <section className='score_sec'>
            <article className='container left'>
              <h3 className='tit'>SAT Scores</h3>
              <div className='info'>
                <h4 className='tit_field'>Section Scores</h4>
                <p>* Percentiles represent the percent of SAT test takers in Gate+ who scored equal to or lower than you.</p>
                <ul className='info_area'>
                  <li className='field'>
                    <p className='subject'>Reading and Writing</p>
                    <ChartWithScoreContainer
                      testResultCurrentScore={testResult.rwCurrentScore}
                      testResultCurrentRank={v2ScorePercentile.rw === 0 ? '-' : v2ScorePercentile.rw} // API 업데이트로 해당 부분만 수정
                      ScoreRange={['200', '800']}
                      CustomLabel={
                        <S.ChartLabel>
                          <p className='chart-label'>{v2ScorePercentile.rw !== 0 ? `${Math.min(v2ScorePercentile.rw, 99)}%` : '-'}</p>
                        </S.ChartLabel>
                      }
                    />
                  </li>
                  <li className='field'>
                    <p className='subject'>Math</p>
                    <ChartWithScoreContainer
                      testResultCurrentScore={testResult.mathCurrentScore}
                      testResultCurrentRank={v2ScorePercentile.math === 0 ? '-' : v2ScorePercentile.math} // API 업데이트로 해당 부분만 수정
                      ScoreRange={['200', '800']}
                      CustomLabel={
                        <S.ChartLabel>
                          <p className='chart-label'>{v2ScorePercentile.math !== 0 ? `${Math.min(v2ScorePercentile.math, 99)}%` : '-'}</p>
                        </S.ChartLabel>
                      }
                    />
                  </li>
                </ul>
              </div>
            </article>
            <article className='container right'>
              <div className='total'>
                <h3 className='tit'>Total Score</h3>
                <div className='info'>
                  <ul className='info_area'>
                    <li className='field'>
                      <ChartWithScoreContainer
                        testResultCurrentScore={calculateTotalScore(testResult.rwCurrentScore, testResult.mathCurrentScore)}
                        testResultCurrentRank={v2ScorePercentile.total}
                        ScoreRange={getScoreRange(testResult.subject)}
                        CustomLabel={
                          <S.ChartLabel>
                            <p className='chart-label'>{v2ScorePercentile.total !== 0 ? `${Math.min(v2ScorePercentile.total, 99)}%` : '-'}</p>
                          </S.ChartLabel>
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/*안내글*/}
              <div className='notice_box'>
                <h3 className='tit_field'>Knowledge and Skills</h3>
                <p className='txt'>View your performance across the 8 content domains measured on the SAT.</p>
              </div>
              {/*skills*/}
              <div className='skill_sec'>
                <section className='inner'>
                  <h4 className='subject'>Reading and Writing</h4>
                  <article className='item'>
                    <h5 className='skill'>Craft and Structure</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < cas ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade1'>{barChartData[0].data[0]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Information and Ideas</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < iai ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade1'>{barChartData[0].data[1]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Standard English Conventions</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < sec ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade3'>{barChartData[0].data[2]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Expression of Ideas</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < eoi ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade4'>{barChartData[0].data[3]}%</p>
                  </article>
                </section>
                <section className='inner'>
                  <h4 className='subject'>Math</h4>
                  <article className='item'>
                    <h5 className='skill'>Algebra</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < alg ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade5'>{barChartData2[0].data[0]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Advanced Math</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < am ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade6'>{barChartData2[0].data[1]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Problem-Solving and Data Analysis</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < psada ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade7'>{barChartData2[0].data[2]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Geometry and Trigonometry</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < gat ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade4'>{barChartData2[0].data[3]}%</p>
                  </article>
                </section>
              </div>
            </article>
          </section>
        </div>
        {/*안내글*/}
        <section className='notice_box'>
          <h3 className='tit_field'>Your Answers</h3>
          <p className='txt'>Check which question you have gotten correct or wrong and view the correct answer choice. </p>
        </section>
        {/*정답여부*/}
        <section className='answer_sec'>
          {testResult.subject !== 'M' && (
            <>
              <h4 className='subject'>Reading and Writing</h4>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='first'>Module1</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                  <tr>
                    <th className='first type2'>Module 2</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {testResult.subject !== 'E' && (
            <>
              <h4 className='subject'>Math</h4>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='first'>Module1</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                  <tr>
                    <th className='first type2'>Module 2</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </section>
        <section className='copy'>
          <p className='txt'>Copyright © 2023 Gate+. All rights reserved.</p>
          <img src={imgLogo} alt='logo' className='logo' />
        </section>
      </div>

      {/* 🧰 Time per Question 있던 자리 - 임시 주석 처리 ( 241206 ) */}

      {/*하단버튼*/}
      <section className='com_btn_wrap bottom'>
        <button className='com_btn l point' onClick={handlePrint}>
          Print
        </button>
      </section>
    </div>
  );
}

const S = {
  ChartLabel: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .chart-label {
      display: flex;
      font-size: 1.125rem;
      font-weight: 600;
    }
  `,
};

// 🧰 Time per Question - 임시 주석 처리 ( 241206 )
// {/*차트*/}
// <section className='chart_sec'>
//   <article className='tit_box'>
//     <h3 className='tit'>Time per Question</h3>
//     <div className={`com_select_layer ${perSubject ? 'active' : ''}`} onClick={perSubActive}>
//       <section className='selected__wrap'> {/*클래스 active 포함 시 옵션 노출*/}
//         <input type='text' className='selected' placeholder={selectedTimeSubject()} size='5' />
//       </section>
//       <section className='layer'>
//         {testResult.subject === 'F' && (
//           <>
//             <p
//               className='option'
//               onClick={() =>
//                 setFilterValue((prev) => {
//                   return {
//                     ...prev,
//                     tmSubject: 'E',
//                     moduleNum: '1',
//                     color: '#008CFF',
//                     className: 'custom-tooltip',
//                   };
//                 })
//               }>
//               RW
//             </p>
//             <p
//               className='option'
//               onClick={() =>
//                 setFilterValue((prev) => {
//                   return {
//                     ...prev,
//                     tmSubject: 'M',
//                     moduleNum: '1',
//                     color: '#1FDF9F',
//                     className: 'custom-tooltip-gate',
//                   };
//                 })
//               }>
//               MATH
//             </p>
//           </>
//         )}
//         {testResult.subject === 'E' && <p className='option'>RW</p>}
//         {testResult.subject === 'M' && <p className='option'>MATH</p>}
//       </section>
//     </div>
//     <div className={`com_select_layer type_tit ${perModuleNum ? 'active' : ''}`} onClick={perNumActive}>
//       {/*클래스 active 포함 시 옵션 노출*/}
//       <section className='selected__wrap'>
//         <input type='text' className='selected' placeholder={selectedTimeModuleNum()} size='8' />
//       </section>
//       <section className='layer'>
//         <p
//           className='option'
//           onClick={() =>
//             setFilterValue((prev) => {
//               return { ...prev, moduleNum: '1' };
//             })
//           }>
//           Module 1
//         </p>
//         <p
//           className='option'
//           onClick={() =>
//             setFilterValue((prev) => {
//               return { ...prev, moduleNum: '2' };
//             })
//           }>
//           Module 2
//         </p>
//       </section>
//     </div>
//   </article>
//   <article>
//     <BasicColumnChart timePerQuestionData={timePerQuestionData} />
//   </article>
// </section>
