import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils.js';

import useUserLevels from 'hooks/useUserLevels';

import ProfilePopup from 'components/_common/modals/PopupProfile';
import OtherUserId from './_OtherUserId';

const StudentInfoList = ({ info, userLevel, userInfo, reloadData }) => {
  const dispatch = useDispatch();
  const [idLayer, setIdLayer] = useState(false); //상세 정보 노출 toggle
  const layerToggle = () => {
    setIdLayer((prev) => !prev);
  };
  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  const [deleteAcademy, setDeleteAcademy] = useState(false); //Academy 계정 삭제 팝업
  const [profileState, setProfileState] = useState(false); //프로필 팝업

  const [studentInfo, setStudentInfo] = useState(info);

  const isStudent = useLevels.isStudent ? true : false;
  const isTeacher = useLevels.isTeacher ? true : false;

  const accountInfo = {
    ...{ userName: studentInfo.userName },
    userSeq: studentInfo.userSeq,
    userLevel: userLevel,
    upFileSeq: studentInfo.upFileSeq,
    academyUpFileSeq: studentInfo.academyUpFileSeq,
  };

  // 활성화 자식 변경
  const changeActiveChild = (userSeq) => {
    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        reloadData();
      }
    };

    request.put(`/api/v2/tublet/tutor/activity/${userSeq}`, null, successHandler);
  };

  return (
    <>
      <div className={`id_manage ${idLayer ? 'active' : ''}`}>
        <article className='id_sec'>
          <div onClick={layerToggle} className='id_wrap'>
            <OtherUserId accountInfo={accountInfo} />
          </div>

          <div className='com_toggle_wrap'>
            <input type='checkbox' id='btnToggle1' onChange={() => changeActiveChild(info.userSeq)} checked={info.isActive} />
          </div>

          <button className='com_btn s line btn_profile' onClick={() => setProfileState(true)}>
            View Profile
          </button>
        </article>
        <article className='information_sec'>
          <ul className='input_area'>
            <li className='item'>
              <div className='tit_field'>Linked Date</div>
              <div className='input_field'>
                <input type='text' defaultValue={isoTimeToDayjs(info.regDate).format('MM / DD / YYYY')} readOnly />
              </div>
            </li>

            <>
              <li className='item'>
                <div className='tit_field'>Email</div>
                <div className='input_field'>
                  <input type='text' defaultValue={info.userEmail} readOnly />
                </div>
              </li>
              <li className='item'>
                <div className='tit_field'>Phone</div>
                <div className='input_field'>
                  <input type='text' defaultValue={info.userMobile} readOnly />
                </div>
              </li>
            </>
          </ul>
        </article>
      </div>

      <hr className='divider' />

      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={studentInfo} />}
    </>
  );
};

export default StudentInfoList;
