import dayjsTZ from 'utils/functions/time/dayjs-config';

const timeLineDateText = {
  1: 'Registration Deadline',
  2: 'Deadline for Changes, Regular Cancellation,\nand Late Registration',
  3: 'SAT Test Date',
};

const timeLineDate = [
  { date: '2025-02-21', textIndex: 1 },
  { date: '2025-02-25', textIndex: 2 },
  { date: '2025-03-08', textIndex: 3 },

  { date: '2025-04-18', textIndex: 1 },
  { date: '2025-04-22', textIndex: 2 },
  { date: '2025-05-03', textIndex: 3 },

  { date: '2025-05-22', textIndex: 1 },
  { date: '2025-05-27', textIndex: 2 },
  { date: '2025-06-07', textIndex: 3 },

  { date: '2025-08-23', textIndex: 3 },
  { date: '2025-09-13', textIndex: 3 },
  { date: '2025-10-04', textIndex: 3 },
  { date: '2025-11-08', textIndex: 3 },
  { date: '2025-12-06', textIndex: 3 },
];

/** 학생 대시보드 페이지의 "SAT Timeline" Section 컴포넌트 */
export default function TimeLineSection() {
  return (
    <article className='time_line custom_scroll'>
      {timeLineDate.map((date, i) => (
        <TimeLineItem key={i} date={date.date} txt={timeLineDateText[date.textIndex]} />
      ))}
    </article>
  );
}

function TimeLineItem({ date, txt }) {
  const formattedDate = dayjsTZ(date);

  return (
    <div className='item'>
      <div className='date'>
        <b className='point'>{formattedDate.format('MMM')}</b>.<b className='point'>{formattedDate.format('D')}</b>.{formattedDate.format('YYYY')}
      </div>
      <div className='txt'>{txt}</div>
    </div>
  );
}
