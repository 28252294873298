import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import SimpleEditorBtn from 'components/ap/com/ap-components/_components/SimpleEditorBtn';
import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';

import QuestionSettingPopup from 'components/ap/com/ap-components/_components/SourceSettingPopup/QuestionSettingPopup';

import useOutsideClick from 'hooks/useOutsideClick';

/**  */
export default function QuestionEditor({ questionInfo, setQuestionInfo }) {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [editorContentState, setEditorContentState] = useState(questionInfo.questionContent);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const popupWrap = useRef(null);
  useOutsideClick(popupWrap, () => setIsShowPopup(false));
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleClickEditor = (event) => {
    event.stopPropagation();
    setIsShowPopup(true);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    const handleSaveContent = () => setQuestionInfo((prev) => ({ ...prev, questionContent: editorContentState }));

    handleSaveContent();
  }, [editorContentState, setQuestionInfo]);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.QuestionContainer>
      <QuestionTopArea order={questionInfo.customQuestionOrder} />
      <div className='question_title_container'>
        <S.SectionTitle>Question</S.SectionTitle>
        <input
          type='text'
          value={questionInfo.customQuestionOrder}
          onChange={(e) => setQuestionInfo((prev) => ({ ...prev, customQuestionOrder: e.target.value }))}
        />
      </div>

      <div className='question_editor_container'>
        <div className='editor_wrapper' onClick={handleClickEditor} ref={popupWrap}>
          <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} setEditorContentState={setEditorContentState} />
          <QuestionSettingPopup isOpen={isShowPopup} pos='left' questionInfo={questionInfo} setQuestionInfo={setQuestionInfo} />
        </div>
        {/* <div className='btn_container'>
          <SimpleEditorBtn className='filled' onClick={handleSaveContent}>
            Save
          </SimpleEditorBtn>
        </div> */}
      </div>
    </S.QuestionContainer>
  );
}

const S = {
  QuestionContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;

    .question_title_container {
      display: flex;
      gap: 0.625rem;
      align-items: center;

      input {
        border-radius: 4px;
        border: 1px solid #d7dadf;
        padding: 0.4375rem 0.75rem;
        height: 2.125rem;
        width: 6.375rem;
        font-size: 14px;
        color: black;
      }
    }

    .question_editor_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .editor_wrapper {
        position: relative;
      }

      .btn_container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    // quill editor 커스텀 스타일 적용
    .quill_editor {
      .ql-editor {
        height: 10.125rem;
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
