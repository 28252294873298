import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import StudentList from './_components/StudentToolTip';

const textLeftStyle = {
  textAlign: 'left',
};

/** tutor main - Table_MainContent */
export default function TutorMainTable({ tutorInfo, checkedTutor, setCheckedTutor, setSelectedTutor, setEditTutorModal, pagingInfo }) {
  // ==========================
  // ① 전체 체크 여부 상태
  // ==========================
  const [allChecked, setAllChecked] = useState(false);

  // ==========================
  // ② 개별 체크 핸들러
  // ==========================
  const handleCheck = (e) => {
    const { checked, value } = e.target;
    // 체크되면 userSeq 추가, 해제되면 제거
    if (checked) {
      setCheckedTutor((prev) => [...prev, value]);
    } else {
      setCheckedTutor((prev) => prev.filter((id) => id !== value));
    }
  };

  // ==========================
  // ③ 전체 체크 핸들러
  // ==========================
  const handleCheckAll = (e) => {
    const { checked } = e.target;
    setAllChecked(checked);

    if (checked) {
      // tutorInfo.tutorList 내의 모든 tutor.userSeq를 추가
      const allTutorIds = tutorInfo.filteredTutorList.map((tutor) => String(tutor.userSeq));
      setCheckedTutor(allTutorIds);
    } else {
      // 전체 해제
      setCheckedTutor([]);
    }
  };

  // ==========================
  // ④ checkedTutor 변화 시 전체 체크 상태 동기화
  // ==========================
  useEffect(() => {
    if (!tutorInfo.filteredTutorList || tutorInfo.filteredTutorList.length === 0) return;

    // tutorList 갯수와 checkedTutor의 수가 같다면 전체 체크
    if (tutorInfo.filteredTutorList.length === 0) {
      setAllChecked(false);
    } else if (tutorInfo.filteredTutorList.every((t) => checkedTutor.includes(String(t.userSeq)))) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [tutorInfo, checkedTutor]);

  // double click event edit tutor
  const handleDoubleClick = (tutor) => {
    // 더블 클릭으로 edit 창 열 때 checkedTutor 초기화
    setCheckedTutor([String(tutor.userSeq)]);

    // tutor 정보를 edit 창에 전달
    setSelectedTutor(tutor);
    setEditTutorModal(true);
  };

  return (
    <>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'>
              <input type='checkbox' className='com_chk' id='checkAllTutor' onChange={handleCheckAll} checked={allChecked} />
              <label htmlFor='checkAllTutor'>&nbsp;</label>
            </th>
            <th className='col_02'>No.</th>
            <th className='col_03'>Name</th>
            <th className='col_04'>Email</th>
            <th className='col_05'>Phone</th>
            <th className='col_06'>Registration Date</th>
            <th className='col_07'>Student(s) Assigned</th>
          </tr>
        </thead>

        <tbody>
          {/* 🧩 tutor main - Table_MainContent_테이블바디 🧩 */}
          {tutorInfo.filteredTutorList && tutorInfo.filteredTutorList.length > 0 ? (
            tutorInfo.filteredTutorList.map((tutor, index) => (
              <tr key={`tutor_${index}`} onDoubleClick={() => handleDoubleClick(tutor)}>
                <td>
                  <input
                    type='checkbox'
                    className='com_chk'
                    id={`chk0${index + 2}`}
                    onChange={handleCheck}
                    value={tutor.userSeq}
                    checked={checkedTutor.includes(String(tutor.userSeq))}
                  />
                  <label htmlFor={`chk0${index + 2}`}>&nbsp;</label>
                </td>
                <td>{tutorInfo.tutorList.length - ((pagingInfo.currentPage - 1) * pagingInfo.numPerPage + index)}</td>
                <td style={textLeftStyle}>
                  <div className='table_data_inner_box'>
                    <span>{tutor.userName}</span>
                  </div>
                </td>
                <td>{tutor.userEmail}</td>
                <td>{tutor.userMobile}</td>
                <td>{isoTimeToDayjs(tutor.regDate).format('YYYY-MM-DD')}</td>
                <td className='col_08 table_data_btn_area'>
                  <StudentList students={tutor.students} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='7'>No data available</td>
            </tr>
          )}
        </tbody>
      </S.Table>

      {/* 학생 삭제 확인 Toast Alert
      {alert.visible && (
        <MiniAlert text={alert.alertMessage} active={alert.visible} inactive={() => alertSet({ visible: false, alertMessage: '' })} timeOut={2000} />
      )} */}
    </>
  );
}

const S = {
  Table: styled.table`
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.625rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 2.25rem;
        }
        &.col_03 {
          width: 13rem;
        }
        &.col_04 {
          width: 11rem;
        }
        &.col_05 {
          width: 11rem;
        }
        &.col_06 {
          width: 20%;
        }
        &.col_07 {
          width: auto;
        }
        &.col_08 {
          width: 5rem;
        }
        &.col_09 {
          width: 7rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 0.8125rem;
        word-break: keep-all;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 700;
          font-size: 0.8125rem;
          &::after {
            content: '-';
          }
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
            display: none;
          }
          &.up {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
            }
          }
          &.down {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.col_03,
        &.col_06,
        &.col_07 {
          text-align: left;
        }
        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        & > button,
        .table_data_inner_box > button {
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.2s;
          &:hover {
            filter: brightness(95%);
          }

          &.btn_view {
            width: auto;
            height: auto;
            background-color: #f2f2f2;
            color: #7f8998;
            font-weight: 400;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            padding: 0.125rem 0.5rem;
          }

          &.btn_change_class {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
            svg {
              width: 1rem;
              color: #a6b3be;
            }
          }
        }

        &.table_data_btn_area {
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f0ff;
            border-radius: 0.25rem;
            svg {
              width: 1.125rem;
              color: #0068bd;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
