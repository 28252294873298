import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// hook
import useUserLevels from 'hooks/useUserLevels';
import useResponsiveFontSize from 'hooks/useResponsiveFontSize';

import request from 'utils/Request.utils';

// Images
import ImgVisual01 from 'assets/img/main_bg.png';

import MainLink from 'components/_common/button/_MainLinks';
import styled from 'styled-components';

import ProfileImage from 'components/_common/ProfileImage';

/** 아카데미 대시보드 상단 비주얼 영역 컴포넌트 */
export default function MainVisualArea() {
  /** useResponsiveFontSize 사용 코드(학원 이름 크기 조절) */
  const textRef = useRef(null);
  const fontSize = useResponsiveFontSize(textRef, 48);

  const userInfo = request.tokenDecoder();
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);

  const displayName = useLevels.isAcademy ? userInfo.companyName : userInfo.userName;

  const profileUserInfo = {
    academyName: displayName,
    ecSeq: userInfo.ecSeq,
    upFileSeq: userInfo.academyUpFileSeq,
  };

  return (
    <S.Wrap>
      <section className='com_visual_area'>
        <div className='com_center_wrap'>
          <div className='right'>
            <img src={ImgVisual01} alt='illustration' />
          </div>
        </div>
      </section>
      <div className='com_center_wrap'>
        <section className='com_visual_text'>
          <div className='tit'>
            <ProfileImage className='dashboard_profile_img' userInfo={profileUserInfo} isB2B={useLevels.isAcademy} isAcademyImg size='7rem' />
            Welcome,
            <span className='dashboard-text-section company-name-section'>
              <b
                className='point name'
                ref={textRef}
                style={{
                  fontSize: `${fontSize}px`,
                  visibility: fontSize ? 'visible' : 'hidden',
                }}>
                &nbsp;{displayName}!
              </b>
            </span>
          </div>
        </section>
        <MainLink />
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    overflow: hidden;

    .dashboard_profile_img {
      margin-right: 1.25rem;
    }
  `,
};
