import { useEffect, useState } from 'react';

import { USER_LEVELS } from '../utils/constants';

/** 사용자 유형 얻어오는 Hook */
export default function useUserLevels(userLevel) {
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    isStudent: false,
    isTeacher: false,
    isTutor: false,
    isAcademy: false,
    isParent: false,
    isTubletAdmin: false,
    isTubletTutor: false,
  });

  useEffect(() => {
    const newPermissions = {
      isStudent: userLevel === USER_LEVELS.STUDENT,
      isTeacher: userLevel === USER_LEVELS.TEACHER,
      isTutor: userLevel === USER_LEVELS.TUTOR,
      isAcademy: userLevel === USER_LEVELS.ACADEMY,
      isParent: userLevel === USER_LEVELS.PARENT,
      isTubletAdmin: userLevel === USER_LEVELS.TUBLET_ADMIN,
      isTubletTutor: userLevel === USER_LEVELS.TUBLET_TUTOR,
      /** 사용자의 현재 레벨을 문자열로 반환 */
      userType: Object.keys(USER_LEVELS).find((key) => USER_LEVELS[key] === userLevel), // "STUDENT" | "TEACHER" | "TUTOR" | "ACADEMY" | "PARENT"
    };

    setPermissions(newPermissions);
    setIsLoading(false);
  }, [userLevel]);

  const isB2B = permissions.isAcademy || permissions.isTutor || permissions.isTeacher || permissions.isTubletAdmin;

  return { permissions, isLoading, isB2B };
}
