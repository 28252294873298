import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';

// hook
import useScrollLock from 'hooks/useScrollLock';

// Components
import usePressESC from 'hooks/usePressESC';
import AssignStudent from './AssignStudent';
import AssignedStudent from './AssignedStudent';

import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';

export default function PopEdit({ selectedTutor, setEditTutorModal, callback }) {
  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [userInfo, _] = useState(request.tokenDecoder());

  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean  => 프로필 모달 노출 여부
    userSeq: 0, // number       => 학생 시퀀스
  });

  /** 검색 된 학생 목록 */
  const [searchStudentData, setSearchStudentData] = useState([]);
  const [checkedStudents, setCheckedStudents] = useState(selectedTutor ? JSON.parse(selectedTutor.students).map((student) => student.studentSeq) : []);
  const [isAllChecked, setIsAllChecked] = useState(false);

  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const searchInputRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /** 프로필 자세히 보기 핸들러 */
  const handleShowProfile = (userSeq) => {
    setProfileModalInfo((prev) => ({ ...prev, visible: true, userSeq }));
  };

  /** 학생 검색 핸들러 */
  const handleSearchStudents = (searchKeyword = '') => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const searchData = response.result.studentList;

        setSearchStudentData(searchData.sort((a, b) => a.userName.localeCompare(b.userName)));
      }
    };

    if (searchKeyword.length !== 0) {
      request.get(`/api/academy/${userInfo.ecSeq}/students?searchKeyword=${searchKeyword}`, null, successHandler);
    } else {
      request.get(`/api/academy/${userInfo.ecSeq}/students`, null, successHandler);
    }
  };

  /** 학생 목록 선택 핸들러 */
  const handleCheckboxChange = (student, isChecked) => {
    if (isChecked) {
      setCheckedStudents((prev) => {
        const isAlreadyIncluded = prev.some((item) => item === student.userSeq);
        if (!isAlreadyIncluded) return [...prev, student.userSeq];
        else return prev;
      });
    } else setCheckedStudents((prev) => prev.filter((data) => data !== student.userSeq));
  };

  /** 학생 목록 "전체" 선택 핸들러 */
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);

    if (isChecked) {
      const newCheckedStudents = [...checkedStudents];
      searchStudentData.forEach((student) => {
        if (!newCheckedStudents.some((checkedStudent) => checkedStudent === student.userSeq)) {
          newCheckedStudents.push(student.userSeq);
        }
      });
      setCheckedStudents(newCheckedStudents);
    } else {
      const filteredCheckedStudents = checkedStudents.filter((checkedStudent) => !searchStudentData.some((student) => student.userSeq === checkedStudent));
      setCheckedStudents(filteredCheckedStudents);
    }
  };

  // tutor 학생 연결 api
  const handleSaveChanges = () => {
    const params = {
      tutorSeq: selectedTutor.userSeq,
      studentSeqList: checkedStudents,
    };

    const successHandler = (response) => {
      if (response.code === 201) {
        callback();
        setEditTutorModal(false);
      }
    };

    request.post('/api/v2/tublet/tutor/student', params, successHandler);
  };

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  // 키보드 ESC 키 이벤트 ( 프로필 모달이 열려있으면 프로필 모달이 우선적으로 닫힘 )
  usePressESC(() => setEditTutorModal(false), !profileModalInfo.visible);
  useScrollLock();
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    handleSearchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.Wrap className='com_popup type_dim pop_manage active'>
        <div className='pop_container'>
          <section className='pop_body custom_scroll'>
            <div className='pop_body_header'>
              <div className='pop_title'>
                <p className='pop_title_text'>Assign Student(s) to Tutor</p>
                <button className='btn_pop_close svg_icon black' onClick={() => setEditTutorModal(false)} />
              </div>
              <p className='pop_title_desc'>Assign students to tutors to grant them access to view their grades.</p>
            </div>
            <div className='pop_body_wrap'>
              <div className='left'>
                {/*1.tutor name*/}
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon bullet_1 black'>&nbsp;</i>
                    Tutor Name
                  </h2>
                </article>
                <article className='search_sec'>
                  <div className='search disabled'>
                    <div className='input'>{selectedTutor?.userName}</div>
                  </div>
                </article>
              </div>

              <div className='center'>
                {/*2.Student*/}
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon bullet_2 black'>&nbsp;</i>
                    Assign Student(s)
                  </h2>
                </article>
                <article className='search_sec'>
                  <div className='search'>
                    <input
                      type='text'
                      className='input'
                      placeholder='Search students'
                      ref={searchInputRef}
                      onKeyDown={(e) => {
                        e.key === 'Enter' && handleSearchStudents(searchInputRef.current.value);
                      }}
                    />
                    <button className='com_btn black' onClick={() => handleSearchStudents(searchInputRef.current.value)}>
                      SEARCH
                    </button>
                  </div>
                </article>
                <article className='com_list'>
                  <div className='scroll custom_scroll'>
                    <table className='list'>
                      <colgroup>
                        <col width='40' />
                        <col />
                        <col width='100' />
                      </colgroup>
                      <thead>
                        <tr className='sticky top'>
                          <th>
                            <input type='checkbox' id='checkAllAssignStudent' className='com_chk' checked={isAllChecked} onChange={handleAllCheckboxChange} />
                            <label htmlFor='checkAllAssignStudent'>&nbsp;</label>
                          </th>
                          <th>Name</th>
                          <th>Profile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchStudentData.map((studentData, idx) => {
                          return (
                            <Fragment key={idx}>
                              <AssignStudent
                                keyIdx={idx}
                                student={studentData}
                                checked={checkedStudents.some((student) => student === studentData.userSeq)}
                                onCheckboxChange={handleCheckboxChange}
                                handleShowProfile={handleShowProfile}
                              />
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <p className={`select_student_error ${checkedStudents.length === 0 ? 'active' : ''}`}>Please select a student</p>
                </article>
              </div>

              <div className='right'>
                <AssignedStudent
                  className={selectedTutor?.userName}
                  checkedStudents={searchStudentData.filter((item) => checkedStudents.includes(item.userSeq))}
                  onCheckboxChange={handleCheckboxChange}
                />
                <article className='com_btn_wrap bottom'>
                  <button type='button' className='com_btn point l full' onClick={handleSaveChanges}>
                    Save Changes
                  </button>
                </article>
              </div>
            </div>
          </section>
        </div>
      </S.Wrap>

      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    .pop_container {
      width: auto;
      /* max-width: 1104px; */
      overflow: hidden;
    }
    .pop_body {
      width: 64.5625rem;
      display: flex;
      flex-direction: column;

      .pop_body_header {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 1.0594rem;
        padding-bottom: 0.625rem;
        .pop_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: Roboto;
          .pop_title_text {
            font-size: 1.5rem;
            font-weight: 500;
          }
          .pop_title_desc {
            font-size: 0.875rem;
            color: #7f8998;
          }
          .btn_pop_close {
            position: static;
          }
        }
      }

      .pop_body_wrap {
        overflow-x: hidden;
        display: flex;
        justify-content: space-between;
        & > .left {
          width: 38%;
          border-right: 1px solid #f6f6f6;
          padding: 20px;
          .search_sec {
            padding: 1.25rem;
            margin-bottom: 0;
            .search {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              padding-left: 0.875rem;
              &.disabled {
                background: #b6b6b6;
                color: black;
              }
              .input {
                display: flex;
                align-items: center;
              }
            }
          }
          .com_calendar_wrap {
            margin-top: 30px;
          }
        }
        & > .center {
          width: 20rem;
          padding: 20px;
          padding-bottom: 0;
          border-right: 1px solid #ebebeb;
          .search_sec {
            padding: 1.25rem 0;
            margin-bottom: 0;
            .com_btn {
              min-height: auto;
              height: 100%;
              border-radius: 0;
            }
          }
          .com_list {
            min-height: auto;
            .select_student_error {
              &.active {
                display: block;
              }
              display: none;
              color: #f24b4b;
              font-size: 0.75rem;
              font-family: Roboto;
              margin-top: 0.3125rem;
            }
            .scroll {
              height: 385px;
              overflow-y: auto;
            }
          }
        }
        & > .right {
          width: 20rem;
          position: relative;
          padding: 20px;
          border-radius: 0 10px 10px 0;
          .class_info {
            padding-top: 0.75rem;
          }
          .com_list_box {
            .scroll {
              height: 342px;
              background: #fff;
            }
            .list {
              th {
                &.table_tit {
                  font-size: 12px;
                  font-weight: 700;
                  .wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .num {
                      display: inline-flex;
                      align-items: center;
                      gap: 2px;
                      font-weight: 400;
                      .svg_icon {
                        position: relative;
                        right: auto;
                        top: auto;
                        background-size: 16px auto;
                      }
                    }
                  }
                }
              }
            }
          }
          &.com_profile {
            margin-right: -1px;
          }
        }
      }
      .error_guide {
        color: #f24b4b;
        font-size: 0.8125rem;
        font-weight: 400;
        margin-top: 0.125rem;
      }
      .com_sec_tit {
        margin-top: 0;
        margin-bottom: 0;
      }
      .search_sec + .com_sec_tit {
        margin-top: 0;
      }
      .com_list_box {
        .list {
          .com_btn {
            padding-inline: 0.5rem;
          }
        }
      }
      .com_list {
        .list {
          .com_btn {
            padding-inline: 12px;
            color: #7f8998;
            background: #f2f2f2;
            border-radius: 0.25rem;
            font-weight: 400;
          }
        }
      }

      & .pop_profile {
        position: relative;
        width: auto;
        height: auto;
        .pop_container {
          transform: translate(0, 0);
          .com_profile {
            width: 100%;
          }
        }
      }
    }
  `,
};
