import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** ap/com/score/grading/submission 페이지에서 유저가 “Complete” 버튼을 눌렀을때 나오는 모달 */
export default function CompleteModal({ setShowModal, rawScore, scaledScore }) {
  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  /** 총 점 제출 API 요청 */
  const submitTotalScore = ({ uthSeq, rawScore, scaledScore }) => {
    if (uthSeq && (rawScore != '' || rawScore != null || rawScore != undefined) && (scaledScore != '' || scaledScore != null || scaledScore != undefined)) {
      const param = {
        rawScore,
        scaledScore,
      };
      const successHandler = (response) => {
        if (response.code === 200) {
          setShowModal(false);
          window.opener.location.reload();
          window.close();
        }
      };

      request.apPut(`/api/v1/academy/score/grading/${uthSeq}`, param, successHandler);
    } else {
      window.alert('Please enter the score.');
    }
  };

  /** "Cancel" 버튼 핸들러 */
  const handleCancel = () => {
    setShowModal(false);
  };
  /** "Complete" 버튼 핸들러 */
  const handleComplete = () => {
    submitTotalScore({ uthSeq, rawScore, scaledScore });
  };

  return (
    <S.Wrap>
      <SimpleModalBase onEscape={() => setShowModal(false)} w='38.125rem' p='1.5rem 1.25rem'>
        <div className='modal_title_container'>
          <div className='modal_title_area'>
            <h2>Complete the Grading for this test?</h2>
          </div>
          <button className='modal_title_btn' onClick={() => setShowModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>

        <div className='modal_text_container'>
          <p>
            Once you click <strong>Complete</strong>, every score you have entered will be saved and shown on Score Report.
          </p>
          <p className='sub'>You can always come back later to change your grade.</p>
        </div>
        <div className='modal_btn_container'>
          <button type='button' title='' onClick={handleCancel}>
            Cancel
          </button>
          <button
            type='button'
            title=''
            onClick={() => {
              handleComplete();
            }}
            className='btn_fill'>
            Complete
          </button>
        </div>
      </SimpleModalBase>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .modal_text_container {
      & > p {
        text-align: left;
        &.sub {
          margin-top: 0.5rem;
          color: #505050;
        }
      }
    }
    .modal_btn_container {
      justify-content: flex-end;
      button {
        width: 8.625rem;
        height: 3rem;
        padding: 0;
      }
    }
  `,
};
