import styled from 'styled-components';

import iconDel from 'assets/img/icon_del.svg';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';

/** 삭제 모달 공통 컴포넌트 */
export default function TubletDeleteModal({ deleteModalInfo, closeModal, isLoading }) {
  if (!deleteModalInfo?.visible) return null;

  return (
    <S.DeleteModalContainer>
      <SimpleModalBase onEscape={closeModal} className='simple_modal_base'>
        <div className='modal_img_wrapper'>
          <img src={iconDel} />
        </div>

        <div className='modal_content'>
          <div className='modal_title_container'>
            <div className='modal_title_area'>
              <h2>
                Are you sure you delete <strong>{deleteModalInfo.checkedTutorLength}</strong> account(s)?
              </h2>
            </div>
          </div>

          <div className='modal_text_container'>
            <p className='description_text'>To continue, please click on ‘Delete’.</p>

            <div className='warning_text_container'>
              <p>
                <strong>Warning</strong>
              </p>
              <p>Once the account deletion is complete,</p>
              <p>you will no longer be able to access the information .</p>
            </div>
          </div>
        </div>

        <div className='modal_btn_container'>
          <button onClick={closeModal}>Cancel</button>
          <button onClick={deleteModalInfo.callback} className='btn_fill'>
            Delete
          </button>
        </div>

        {isLoading && <CommonLoadingAnimation />}
      </SimpleModalBase>
    </S.DeleteModalContainer>
  );
}

const S = {
  DeleteModalContainer: styled.div`
    .simple_modal_base {
      position: relative;
    }
    .modal_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }

    .modal_text_container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .description_text {
        color: #7f8998;
      }

      .warning_text_container {
        color: #f00;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .modal_btn_container {
      button {
        max-width: none;
        height: 3.5rem;
      }
    }
  `,
};
