import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';
import ClassListModal from 'components/student/_components/modals/ClassListModal';

import usePressESC from 'hooks/usePressESC';

/** 아카데미 대시보드 - Students - Main Table - "class" 열 목록 펼치기 버튼 */
export default function ClassListButton({ classLists }) {
  /** "class list" 모달 노출 여부 */
  const [isShowClassListModal, setIsShowClassListModal] = useState(false);
  const handleOpenClassList = ({ event }) => {
    event.stopPropagation();
    setIsShowClassListModal((current) => !current);
  };

  usePressESC(() => {
    setIsShowClassListModal(false);
  }, isShowClassListModal);

  return (
    <S.ClassListModalContainer>
      <S.Button type='button' onClick={(event) => handleOpenClassList({ event })} className={`${isShowClassListModal ? 'on' : ''}`}>
        <ImgSvgIconSelect />
      </S.Button>
      {isShowClassListModal && <ClassListModal classLists={classLists} setShowModal={setIsShowClassListModal} />}
    </S.ClassListModalContainer>
  );
}

const S = {
  ClassListModalContainer: styled.span`
    position: relative;
  `,
  Button: styled.button`
    margin-left: 1rem;
    svg {
      width: 0.75rem;
      color: #4b5563;
      transition: 0.2s;
      transform-origin: center;
    }
    &.on {
      svg {
        transform: rotate(180deg);
      }
    }
  `,
};
