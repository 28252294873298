/** AP_SUBJECT_GROUP 데이터에서 seq 값을 사용해 상위 과목의 COLOR를 반환하는 함수 */
export function getColorBySeq({ apSubjectCategory, seq = '' }) {
  // 각 상위 과목을 순회하면서 seq 값을 찾기
  for (const subjectGroupKey in apSubjectCategory) {
    const subjectGroup = apSubjectCategory[subjectGroupKey];

    for (const detailKey in subjectGroup.detailSubjects) {
      const detailSubject = subjectGroup.detailSubjects[detailKey];
      if (detailSubject.seq === seq) {
        return subjectGroup.COLOR; // COLOR 반환
      }
    }
  }

  return null; // seq 값이 없는 경우 null 반환
}
