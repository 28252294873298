import styled from 'styled-components';

/** 유저 프로필 이미지 컴포넌트
 * @description 유저 프로필 이미지가 없을 경우 유저 이름의 첫글자를 대문자로 표시, 있을 경우 이미지를 표시함 + size에 따라 글씨 크기가 자동으로 조절됨
 * @param {Object} userInfo 유저 정보
 * @param {String} userInfo.userName 유저 이름
 * @param {Number} userInfo.userSeq 유저 시퀀스
 * @param {Number} userInfo.upFileSeq 유저 프로필 이미지 시퀀스
 * @param {String} size 이미지 크기
 * @param {Boolean} isB2B B2B 여부
 * @param {String} round round 값
 * @example
 * <ProfileImage 
 *   size='3.125rem'
 *   round='50%'
 *   userInfo={{
 *     userName: 'user_name',
 *     userSeq: 0,
 *     upFileSeq: 0,
 *   }}
 *   or
 *   userInfo={{
 *     academyName: 'academy_name',
 *     ecSeq: 0,
 *     upFileSeq: 0,
 *   }}
 *   isAcademyImg
 *   isB2B
 * />
 */
export default function ProfileImage({ userInfo, size = '3.125rem', isB2B = false, round = '50%', className = '', isAcademyImg = false, imgUrl = '' }) {
  const isDevEnvironmental = process.env.REACT_APP_ENVIRONMENT === 'localhost' || process.env.REACT_APP_ENVIRONMENT === 'development';

  // 아카데미 이미지를 노출할떄는 다른 API 호출
  const profileInfo =
    imgUrl !== ''
      ? {
          displayName: userInfo.academyName,
          imgUrl: imgUrl,
        }
      : isAcademyImg
        ? {
            displayName: userInfo.academyName,
            imgUrl: `${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${userInfo.ecSeq}?${Date.now()}`,
          }
        : {
            displayName: userInfo.userName,
            imgUrl: `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${userInfo.userSeq}?${Date.now()}`,
          };

  return (
    <S.ProfileImageWarper $size={size} $isB2B={isB2B} $round={round} className={className}>
      {imgUrl !== '' || (!isDevEnvironmental && userInfo.upFileSeq > 0) ? (
        <img src={profileInfo.imgUrl} alt='profile image' />
      ) : (
        <span className='user_name'>{profileInfo.displayName?.charAt(0)}</span>
      )}
    </S.ProfileImageWarper>
  );
}

// --- --- ---

const getProfileSize = (props) => props.$size;
const getProfileTextSize = ({ $size }) => {
  const value = parseFloat($size);
  const unit = $size.replace(/[\d.]/g, '');

  return `${value / 2}${unit}`;
};
const getBackgroundColor = (props) => (props.$isB2B ? '360deg, #d2c1ff 0%, #a98aff 100%' : '360deg, #c8cdff 0%, #008cff 100%');

const S = {
  ProfileImageWarper: styled.div`
    min-width: ${getProfileSize};
    max-width: ${getProfileSize};
    min-height: ${getProfileSize};
    max-height: ${getProfileSize};
    border-radius: ${(props) => props.$round};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    span {
      &.user_name {
        color: white;
        min-width: ${getProfileSize};
        max-width: ${getProfileSize};
        min-height: ${getProfileSize};
        max-height: ${getProfileSize};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${getProfileTextSize};
        background: linear-gradient(${getBackgroundColor});
        opacity: 0.8;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  `,
};


