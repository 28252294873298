import styled from 'styled-components';
import UserInfoList from '../sectionComponents/UserInfoList';

import ProfileImage from 'components/_common/ProfileImage';

/** AP 프로젝트 - 학생 report 페이지 - 유저 정보 섹션 */
export default function UserInfoSection({ reportUserInfo, scores }) {
  return (
    <S.ContentWrapper>
      <S.Frame>
        <UserInfoList reportUserInfo={reportUserInfo} />

        <ProfileImage
          size='9.375rem'
          round='0'
          userInfo={{
            userName: reportUserInfo.name,
            userSeq: reportUserInfo.userSeq,
            upFileSeq: 0,
          }}
          isB2B
          imgUrl={reportUserInfo.academyImageURL}
        />
      </S.Frame>
      <S.Divider />
      <S.ScoresSection>
        <div className='scores-flex-container'>
          <div className='score-flex-item-container'>
            <h2 className='scores-title'>Raw Scores</h2>
            <div className='section_score_container'>
              {scores.section1 !== undefined && (
                <div className='score-item'>
                  <div className='score-label'>Section 1</div>
                  <div className='score-value'>
                    <span className='number'>{scores.section1}</span>
                    <span className='range'>/ {scores.section1Max}</span>
                  </div>
                </div>
              )}

              {scores.section2 !== undefined && (
                <div className='score-item'>
                  <div className='score-label'>Section 2</div>
                  <div className='score-value'>
                    <span className='number'>{scores.section2}</span>
                    <span className='range'>/ {scores.section2Max}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <S.VerticalDivider />

          <div className='scaled_score_container'>
            <h2 className='scores-title'>Scaled Scores</h2>
            <div className='scaled_score_item'>
              <div className='score-label'>Total</div>
              <div className='score-value'>
                <span className='number'>{reportUserInfo.scaledScore}</span>
                <span className='range'>/ 1-5</span>
              </div>
            </div>
          </div>
        </div>
      </S.ScoresSection>
    </S.ContentWrapper>
  );
}

const S = {
  ContentWrapper: styled.div`
    border: 0.125rem solid #000000;
    border-radius: 0.625rem;
    padding: 1.25rem;
    width: 74.625rem;

    .info-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0.625rem 0;
    }
  `,

  Frame: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,

  InfoProfile: styled.div`
    display: flex;
    gap: 3.125rem;
  `,

  ProfileImage: styled.img`
    width: 9.375rem;
    height: 9.375rem;
  `,

  Divider: styled.hr`
    width: 100%;
    height: 0.3125rem;
    background: #000;
    border: none;
    margin: 1.25rem 0;
  `,

  VerticalDivider: styled.div`
    width: 0;
    height: 64px;
    border-left: 2px dashed #111111;
    margin: 0 1.25rem;
  `,

  ScoresSection: styled.div`
    margin-top: 1.25rem;

    .scores-title {
      color: #111111;
      font-size: 1.5rem;
      font-weight: 700;
    }
    .scores-flex-container {
      display: flex;
      align-items: flex-end;
      .score-flex-item-container {
        display: flex;
        width: 34.375rem;
        gap: 1.3125rem;
        justify-content: center;
        flex-direction: column;

        .section_score_container {
          display: flex;
          justify-content: space-between;
          padding: 0 1.25rem;
          align-items: center;
        }
        .score-item {
          display: flex;
          justify-content: center;
          gap: 1.25rem;
          flex: 1;
        }
      }
    }

    .score-label {
      background-color: #f8f8fa;
      border-radius: 6.25rem;
      padding: 0.25rem 0.6875rem;
      font-size: 1rem;
      font-weight: 500;
      width: fit-content;
      height: fit-content;
    }
    .score-value {
      display: flex;
      align-items: end;

      .number {
        background: linear-gradient(180deg, rgb(70.66, 87.77, 255) 0%, rgb(0, 140.32, 255) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3rem;
        font-weight: 700;
      }

      .range {
        font-size: 1rem;
        color: #111111;
        padding-bottom: 0.5rem;
      }
    }

    .scaled_score_container {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1.3125rem;

      .scaled_score_item {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 1.25rem;
      }
    }
  `,
};
