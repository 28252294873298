import React from 'react';
import styled from 'styled-components';

/** TestEditor 에서 사용할 버튼 컴포넌트 */
export default function SimpleEditorBtn({ clickHandler, className = 'normal', ...rest }) {
  return <S.SimpleEditorBtn className={className} onClick={clickHandler} {...rest}></S.SimpleEditorBtn>;
}

const S = {
  SimpleEditorBtn: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.normal {
      background-color: white;
      border: 1px solid #0068bd;
      color: #0068bd;
      border-radius: 0.375rem;
      width: 5.375rem;
      height: 2rem;
      font-size: 0.875rem;
      font-family: 'Noto Sans';
    }

    &.filled {
      background-color: #0068bd;
      color: white;
      border-radius: 0.375rem;
      width: 5.375rem;
      height: 2rem;
      font-size: 0.875rem;
      font-family: 'Noto Sans';
    }
  `,
};
