import React from 'react';
import styled from 'styled-components';

import useEscapeKey from 'hooks/usePressESC';

const defaultChildren = () => console.log('컴포넌트를 전달해주세요');

/**
 * @component
 * 기본 모달 베이스 컴포넌트 - 일반적인 모달로 사용하거나, 닫기 버튼이 있는 모달로도 사용 가능
 * @example
 * ```jsx
 *  <SimpleModalBase onEscape={() => setShowModal(false)} w='10rem' h='10rem' noPad >
 *    // 🧰 제목만 있는 모달
 *    <h2>모달_제목</h2>
 *    // 🧰 상단에 타이틀 + 버튼 모달
 *    <div className='modal_title_container'>
 *      <div className='modal_title_area'>
 *        <h2>버튼_모달_제목</h2>
 *      </div>
 *      <button className='modal_title_btn' onClick={() => setShowModal(false)}>
 *        <i className='svg_icon btn_pop_close black'>&nbsp;</i>
 *      </button>
 *    </div> 
 *    // 🧰 이미지 있는 모달
 *    <div className='modal_img_wrapper'>
 *      <img src='img_path' alt='이미지_설명' />
 *    </div>
 *    // 🧰 모달 text
 *    <div className='modal_text_container'>
 *      <p>모달_내용</p>
 *    </div>
 *    // 🧰 모달 버튼 영역
 *    <div className='modal_btn_container'>
 *      <button onClick={() => console.log('악!')}>버튼_텍스트</button>
 *    </div>
 *  </SimpleModalBase>
 * ```
 *
 * @param {React.ReactNode} children - 모달 내부에 표시할 content / default: 필수 요소가 없음을 알리는 console
 * @param {function|null} onEscape - ESC를 눌렀을 때 실행할 함수 / default: null(ESC 키 이벤트 없음)
 * @param {string|null} w - 모달의 너비(width) / default: null(명시적 width 없음)
 * @param {string|null} h - 모달의 높이(height) / default: null(명시적 height 없음)
 * @param {string|null} p - 모달의 padding값 / default: null (3rem)
 * @param {string|null} gap - 모달 내부에 요소들간의 간격 / default: null (2rem)
 * @param {string|null} titleEllipsisWidth - 말줄임표 CSS 적용시 해당 요소의 너비 / default: null
 * @param {boolean} noPad - 패딩을 제거 여부 결정하는 boolean / default: false
 * @returns {JSX.Element} 모달 컴포넌트 반환
 */

function SimpleModalBase({
  children = defaultChildren,
  onEscape = null,
  noPad = false,
  p = null,
  w = null,
  h = null,
  gap = null,
  titleEllipsisWidth = null,
  className = null,
}) {
  useEscapeKey(onEscape, onEscape ? true : false);

  return (
    <ModalBaseLayer>
      <ModalBaseContainer noPad={noPad} w={w} h={h} p={p} gap={gap} titleEllipsisWidth={titleEllipsisWidth} className={className}>
        {children}
      </ModalBaseContainer>
    </ModalBaseLayer>
  );
}

export default SimpleModalBase;

// --- --- ---

const ModalBaseLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalBaseContainer = styled.div`
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.gap ?? '2rem'};

  // noPad일때 패딩 제거, p값이 있으면 적용, 없으면 3rem
  padding: ${(props) => (props.noPad ? '0' : (props.p ?? '3rem'))} !important; // 기존 scss 덮어쓰기

  width: ${(props) => props.w ?? 'auto'};
  height: ${(props) => props.h ?? 'auto'};

  // modal title
  > h2 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0; // 기존 scss 덮어쓰기
    white-space: pre-line; // \n으로 줄바꿈
  }
  // 줄바꿈 관련 코드 추가
  h2.title-ellipsis {
    white-space: nowrap;
  }
  h2 span.text-ellipsis {
    display: inline-block;
    max-width: ${(props) => props.titleEllipsisWidth ?? 'auto'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  // modal 상단에 타이틀 + 버튼 일때
  .modal_title_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .modal_title_area {
      margin-right: auto;
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
        white-space: pre-line;
      }
    }
    .modal_title_btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // modal image
  div.modal_img_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // modal content
  .modal_text_container {
    > p {
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      white-space: pre-line; // \n으로 줄바꿈
    }
  }

  // modal buttons
  .modal_btn_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 1.25rem;
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 2.75rem;
      max-width: 10rem;
      border-radius: 6px;
      border: 1px solid #0068bd;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      background-color: white;
      color: #0068bd;

      &:hover {
        filter: brightness(95%);
      }

      &.btn_fill {
        background-color: #0068bd;
        color: white;
      }

      // 버튼이 하나일때
      &:only-child {
        margin: 0 12.5%;
      }
    }
  }
`;
