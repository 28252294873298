import styled from 'styled-components';

/** ap/com/score/grading/submission 페이지의 "Multiple Choice Question" 의 각 문제 아이템 컴포넌트
* props :
number : 문제 번호
isEnter : 문제를 풀었는가?
isCorrect : 문제 맞췄는가?
*/
export default function MultipleChoiceQuestionItem({ number, isEnter = '[]', isCorrect = false, ...rest }) {
  const isEmpty = isEnter == null || !(typeof isEnter === 'string' && (isEnter.trim() === '' || isEnter.trim() === '[]')); // 빈 문자열 또는 '[]'

  return (
    <S.Wrap {...rest}>
      <div className={`number_wrap ${isEmpty ? '' : 'not_taken'}`}>{number}</div>{' '}
      <div className={`correct_or_incorrect_wrap ${isCorrect ? 'correct' : ''}`}>{isCorrect ? 'O' : 'X'}</div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border: 1px dashed #111;
    display: flex;
    flex-direction: column;
    width: fit-content;
    .number_wrap {
      width: 2.75rem;
      height: 2.75rem;
      padding: 0.5625rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #384cc0;
      color: #ffffff;
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.75rem;
      &.not_taken {
        background-color: #ffffff;
        color: #384cc0;
      }
    }
    .correct_or_incorrect_wrap {
      border-top: 1px dashed #111;
      width: 2.75rem;
      height: 2.75rem;
      padding: 0.5625rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      color: #f00;
      text-align: center;
      font-family: Roboto;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.75rem;
      &.correct {
        color: #111;
      }
    }
  `,
};
