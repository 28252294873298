import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import dayjsTZ from 'utils/functions/time/dayjs-config';

import useOutsideClick from 'hooks/useOutsideClick';

import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import SvgIconTextBtn from 'components/_common/button/SvgIconTextBtn';
import sessionStorage from 'utils/SessionStorage.utils';
import AddStudentAccount from 'components/_common/modals/AddStudentAccount';

import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgIconDownload } from 'assets/img/svg/icon_download_01.svg';
import { ReactComponent as ImgSvgIconPrinter } from 'assets/img/svg/icon_printer_01.svg';
import { ReactComponent as ImgSvgIconTrash } from 'assets/img/svg/icon_trash_01.svg';
import PopBack from 'components/_common/popups/_PopBack';

/** student main - Table_Header_버튼영역 */
export default function TopBtnArea({ selectCheckBox, setDeleteStudent, setSimpleAlert, searchInfo, studentInfo, studentListReload }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  /** 다운로드 버튼 */
  const [btnDownload, setBtnDownload] = useState(false);
  /** 프린트 버튼 */
  const [btnPrint, setBtnPrint] = useState(false);
  // 학원의 학생 회원가입 모달 관련
  const [isBack, setBack] = useState(false);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);

  const printBox = useRef(null);
  useOutsideClick(printBox, () => setBtnPrint(false), btnPrint);

  // 외부 클릭시 닫히는 훅
  const downloadBox = useRef(null);
  useOutsideClick(downloadBox, () => setBtnDownload(false), btnDownload);

  /** 오늘 날짜를 문자열로 return 하는 함수 */
  const getTodayDate = (value) => {
    const now = dayjsTZ();
    const formats = {
      time: 'YYYY. MM. DD. HH : mm',
      hyphen: 'YYYY-MM-DD',
      default: 'YYYY년 MM월 DD일',
    };
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    const formatted = now.format(formats[value] || formats.default);
    return value === 'default' ? `${formatted} (${week[now.day()]})` : formatted;
  };

  const handleDeletePopupOpen = () => {
    if (userInfo.userLevel === USER_LEVELS.TEACHER) return;
    if (selectCheckBox.selectedKeys.length > 0) {
      setDeleteStudent(true);
    } else {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the student you want to delete.',
      });
    }
  };

  /** "Download" 드롭다운 여/닫기 버튼 핸들러 */
  const toggleBtnDownload = () => {
    setBtnDownload((prevState) => !prevState);
  };

  /** "All Students Download" 버튼 핸들러 */
  const excelDownloadAll = () => {
    toggleBtnDownload();

    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'user_mobile',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      orderBy: searchInfo.orderBy === 'desc' ? 'student_grade DESC, MCH.reg_date DESC' : searchInfo.orderBy,
    };

    let filename = 'All Students ' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명

    request.downloadFile(
      `/api/member/student/excel?userSeq=${pms.userSeq}&orderBy=${pms.orderBy}&searchField=${pms.setSearchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&startDate=${pms.searchStartDate}&endDate=${pms.searchEndDate}`,
      filename
    );
  };

  /** "Selected Students Download" 버튼 핸들러 */
  const excelDownloadSelect = () => {
    if (selectCheckBox.selectedKeys.length > 0) {
      let filename = 'Selected Students ' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명

      request.downloadFile(`/api/member/student/excel?userSeq=${encodeURI(userInfo?.userSeq)}&seqStr=${encodeURI(selectCheckBox.seqStr)}`, filename);
    } else {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the student you want to download.',
      });
    }
    toggleBtnDownload();
  };

  /** "Print" 드롭다운 여/닫기 버튼 핸들러 */
  const toggleBtnPrint = () => {
    setBtnPrint((prevState) => !prevState);
  };

  /** "Selected Student Print" 버튼 핸들러 */
  const printSelect = () => {
    const { selectedKeys, seqStr } = selectCheckBox;
    if (selectedKeys.length > 0) {
      sessionStorage.clearItem('printCard');
      const selectStudentList = studentInfo.studentList.filter((i) => selectedKeys.includes(i.userSeq));
      sessionStorage.setItem('printCard', 'studentList', selectStudentList);
      window.open('/com/students/print', '_blank');
    } else {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the student you want to print.',
      });
    }
    toggleBtnPrint();
  };

  /** "All Students Print" 버튼 핸들러 */
  const printAll = () => {
    sessionStorage.clearItem('printCard');

    const pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'user_mobile',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      orderBy: searchInfo.orderBy === 'desc' ? 'student_grade DESC, MCH.reg_date DESC' : searchInfo.orderBy,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let studentList = response.result.studentInfo;

        sessionStorage.setItem('printCard', 'studentList', studentList);
        window.open('/com/students/print', '_blank');

        toggleBtnPrint();
      }
    };

    request.get(
      `/api/member/students?orderBy=${pms.orderBy}${pms.setSearchField ? `&searchField=${pms.setSearchField}` : ''}${encodeURIComponent(pms.searchKeyword) ? `&searchKeyword=${encodeURIComponent(pms.searchKeyword)}` : ''}${encodeURIComponent(pms.searchStartDate) ? `&startDate=${encodeURIComponent(pms.searchStartDate)}` : ''}${encodeURIComponent(pms.searchEndDate) ? `&endDate=${encodeURIComponent(pms.searchEndDate)}` : ''}`,
      null,
      successHandler
    );
  };

  return (
    <>
      <S.Wrap className='btn_area'>
        <button type='button' title='Add Student' className='btn_add_student' onClick={() => setShowAddStudentModal(true)}>
          <ImgSvgIconPlus />
          Add Student
        </button>
        {/* <SvgIconTextBtn
          text='Add Student'
          svgClassName='svg_icon icon_person_add blue'
          bgColor='#DFF1FF'
          color='#4692D1'
          borderColor='none'
          onClick={() => setShowAddStudentModal(true)}
        /> */}
        <div className='com_btn_drop' ref={downloadBox}>
          <button type='button' title='Download' onClick={toggleBtnDownload} className='btn_icon'>
            <ImgSvgIconDownload />
          </button>
          {/* <MiniSvgIconBtn text='Download' svgClassName='svg_icon icon_download' radius='medium' dataHover /> */}
          <div className={`layer ${btnDownload ? 'active' : ''}`}>
            <button className='btn' onClick={excelDownloadSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Students
            </button>
            <button className='btn' onClick={excelDownloadAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Students
            </button>
          </div>
        </div>
        <div className='com_btn_drop' ref={printBox}>
          <button type='button' title='Print' onClick={toggleBtnPrint} className='btn_icon'>
            <ImgSvgIconPrinter />
          </button>
          {/* <MiniSvgIconBtn text='Print' svgClassName='svg_icon icon_print' onClick={toggleBtnPrint} radius='medium' dataHover /> */}
          <div className={`layer ${btnPrint ? 'active' : ''}`}>
            <button className='btn' onClick={printSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Students
            </button>
            <button className='btn' onClick={printAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Students
            </button>
          </div>
        </div>
        {userInfo.userLevel !== USER_LEVELS.TEACHER && (
          <button type='button' title='Delete' onClick={handleDeletePopupOpen} className='btn_icon'>
            <ImgSvgIconTrash />
          </button>
          //   <MiniSvgIconBtn text='Delete' svgClassName='svg_icon icon_trash' onClick={handleDeletePopupOpen} radius='medium' dataHover />
        )}
      </S.Wrap>

      {/* 🪟 student main - 학생 회원가입 모달 🪟 */}
      {showAddStudentModal && <AddStudentAccount setBack={setBack} setShowAddStudentModal={setShowAddStudentModal} callback={studentListReload} />}
      {isBack && <PopBack closePopBack={() => setBack(false)} confirmCallback={() => setShowAddStudentModal(false)} />}
    </>
  );
}

const S = {
  Wrap: styled.article`
    button {
      transition: 0.2s;
      &:hover {
        filter: brightness(95%);
      }
    }
    .btn_add_student {
      background-color: #f4f8ff;
      color: #0068bd;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      svg {
        width: 1.125rem;
      }
    }
    .btn_icon {
      border: 1px solid #eaeaea;
      border-radius: 0.5rem;
      width: 2rem;
      height: 2rem;
      transition: 0.2s;
      background-color: #ffffff;
      svg {
        width: 1rem;
        color: #4b5563;
      }
    }
  `,
};
