import { useState } from 'react';
import styled from 'styled-components';

//////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const ANSWER_NUM = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
};
////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 시험 페이지에서 객관식 문제 컴포넌트 */
export default function ExamAnswerItem({ answerNum = 0, setSelectedAnswer = () => {}, isSelected = false, isUndo = false, answerContent = '' }) {
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  ////////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleRadioAnswerChange = (e, no) => {
    console.log(e, no);
  };
  const dangerouslyToggle = (e, no) => {
    console.log(e, no);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  //////////////////// START --- 랜더링 보조 --- START ////////////////////
  const answerLabel = ANSWER_NUM[answerNum]; // 'A' | 'B' | 'C' | 'D' | 'E'
  const answerInputId = `answer_${answerNum}_${answerLabel}`;
  ////////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.ExamAnswerItem className={isUndo ? 'undo_selected' : ''}>
      <S.AnswerInput
        type='radio'
        name={`answer_${answerNum}`}
        id={answerInputId}
        value={answerLabel}
        onChange={() => setSelectedAnswer(answerNum)}
        checked={isSelected}
      />
      <S.AnswerLabel className={`${isSelected ? 'selected' : ''}`} htmlFor={answerInputId}>
        <S.AnswerNumBtn className={`${isSelected ? 'selected' : ''}`}>{answerLabel}</S.AnswerNumBtn>
        <div
          dangerouslySetInnerHTML={{
            __html: answerContent?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
          }}
        />
      </S.AnswerLabel>
      <S.UndoBtn className={`undo_btn ${isUndo ? 'show' : ''}`} onClick={(e) => dangerouslyToggle(e, answerNum)} value={answerLabel}>
        {answerLabel}
      </S.UndoBtn>
    </S.ExamAnswerItem>
  );
}

const AnswerNumBtn = styled.span`
  line-height: 0;
  border: 1px solid black;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.selected {
    background-color: #384cc0;
    color: white;
  }
`;

const S = {
  ExamAnswerItem: styled.div`
    display: flex;
    align-items: center;
  `,

  // 답안 번호 버튼
  AnswerNumBtn,

  // AnswerNumBtn 확장 undo 버튼
  UndoBtn: styled(AnswerNumBtn)`
    display: none;
    margin-left: 0.875rem;
    position: relative;

    &.show {
      display: flex;
    }

    &::before {
      content: '';
      background-color: black;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -5px;
      right: -5px;
      transform: translateY(-50%);
    }
  `,

  AnswerLabel: styled.label`
    border: 1px solid #7b7b7b;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    width: 100%;

    &.selected {
      border: 3px solid #384cc0;
    }
  `,

  AnswerInput: styled.input`
    width: 0;
    height: 0;
    padding: 0;
    border: none;
  `,
};
