import styled from 'styled-components';

/** 배열을 원하는 크기로 자르는 함수 */
const chunkArray = (array, chunkSize) => {
  const result = [];
  const chunkLength = Math.ceil(array.length / chunkSize);

  for (let i = 0; i < chunkLength; i++) {
    result.push(array.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return result;
};

/** Report Card - Section1 */
export default function MultiChoiceSection({ part }) {
  const mapOptionToLetter = (option) => {
    const mapping = {
      option1: 'A',
      option2: 'B',
      option3: 'C',
      option4: 'D',
      option5: 'E',
      option6: 'F',
      option7: 'G',
      option8: 'H',
      option9: 'I',
      option10: 'J',
      option11: 'K',
      option12: 'L',
      option13: 'M',
      option14: 'N',
      option15: 'O',
      option16: 'P',
      option17: 'Q',
      option18: 'R',
      option19: 'S',
      option20: 'T',
      option21: 'U',
      option22: 'V',
      option23: 'W',
      option24: 'X',
      option25: 'Y',
      option26: 'Z',
    };

    option = option.toLowerCase(); // 소문자로 변환
    return mapping[option] || option; // 매핑되지 않은 경우 원본 반환
  };

  const chunkedQuestions = chunkArray(part.questions, 20);

  return (
    <>
      {chunkedQuestions.map((chunk, index) => (
        <S.AnswerResultTableWrapper key={`${part.partName}-${index}`}>
          <table>
            <thead>
              <tr>
                <th className='first type2'>{part.partName}</th>
                {/* 🧰 개발중 작성된 코드로 일단 지우지 말것 - 241223 */}
                {/* <th className={`first ${sectionDataKey === 'partB' ? 'type2' : 'type1'}`}>{value.title}</th> */}
                {chunk.map((item, index) => (
                  <th key={index}>{item.questionNum}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Answer</td>
                {chunk.map((item, index) => (
                  <td key={index}>
                    {item.correctAnswer &&
                      JSON.parse(item.correctAnswer)
                        .map((answer) => mapOptionToLetter(answer)) // 매핑 함수로 변환
                        .join(', ')}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Your Choice</td>
                {chunk.map((item, index) => (
                  <td key={index}>
                    {item.tryAnswer &&
                      JSON.parse(item.tryAnswer)
                        .map((answer) => mapOptionToLetter(answer)) // 매핑 함수로 변환
                        .join(', ')}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Result</td>
                {chunk.map((item, index) => (
                  <td key={index}>{item.isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </S.AnswerResultTableWrapper>
      ))}
    </>
  );
}

// --- --- ---

const S = {
  Section1: styled.div`
    .section_title {
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: 700;
    }
  `,

  AnswerResultTableContainer: styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  `,

  AnswerResultTableWrapper: styled.div`
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      /* width: fit-content; */
      table-layout: fixed;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #ededed;
        text-align: center;
        height: 35px;
        color: #383838;
        box-sizing: border-box;
        white-space: nowrap;
      }

      th {
        background: #f2f2f2;
        font-weight: 500;

        &.first {
          background: #3698e7;
          color: #fff;
          &.type2 {
            background: #0068bd;
          }
        }
        &:nth-child(1) {
          width: 114px;
        }
        &:not(:first-child) {
          /* 이후 기획이 바뀔것을 대비해서 각 요소별 스타일 주석으로 남겨둠 - 241212 */
          /* width: 40px; */
          /* width: calc((100% - 114px) / 20); */
        }
      }

      td {
        word-break: keep-all;
      }
    }
  `,
};
