import axios from 'axios';
import jsonp from 'jsonp';
import jwt_decode from 'jwt-decode';

import LocalStorage from './LocalStorage.utils';

import { nvl, nvlNumber } from './Common.utils';

import 'whatwg-fetch';

import dayjs, { getClientTimezone, setUserTimezone } from 'utils/functions/time/dayjs-config';

let protocol = window.location.protocol;
let hostname = window.location.hostname;
let port = window.location.port === '80' || window.location.port === '443' || window.location.port === '' ? '' : ':' + window.location.port;

let redirectUrl = process.env.REACT_APP_WEB_URL + '/....'; // 외부 api 사용 때 redirect 필요 시 사용
let base = process.env.REACT_APP_API_URL; // api 주소
const apBase = process.env.REACT_APP_AP_API_URL; // ap api 주소
const nodebase = process.env.REACT_APP_NODE_API_URL; // node api 주소
console.log('🚀 ~ nodebase:', nodebase);

// added by psk 20230328 - page 이동 시 이전 요청 모두 끊을 때 사용
const source = axios.CancelToken.source();
const requestInstance = axios.create();

const userSession = LocalStorage.getItemJsonParse('userSession');

// 토큰 만료 여부 확인 함수
const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedToken = jwt_decode(token);
  const exp = decodedToken.exp * 1000; // 초 단위로 되어 있으므로 밀리초로 변환
  return Date.now() >= exp;
};

let refreshTokenPromise = null;

// 토큰 갱신 함수
const refreshAccessToken = async () => {
  const userToken = LocalStorage.getItemJsonParse('userSession');

  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      try {
        const response = await axios.post(`${base}/api/member/token/refresh`, {
          refreshToken: userToken?.refreshToken,
        });

        if (response.status === 200) {
          const { accessToken, refreshToken } = response.data.result;
          LocalStorage.setItem('userSession', 'accessToken', accessToken);
          LocalStorage.setItem('userSession', 'refreshToken', refreshToken);

          return accessToken;
        } else {
          console.error('리프레시 토큰 요청 실패');
          logout();
          return null;
        }
      } catch (refreshError) {
        console.error('리프레시 토큰 요청 중 오류 발생:', refreshError);
        logout();
        return null;
      } finally {
        refreshTokenPromise = null; // 작업이 끝나면 상태 초기화
      }
    })();
  }
  return refreshTokenPromise; // 이미 존재하는 Promise 반환
};

// Axios 인터셉터 설정
requestInstance.interceptors.request.use(
  async (config) => {
    const userToken = LocalStorage.getItemJsonParse('userSession');

    const skipUrls = [
      '/api/common',
      '/api/member/login',
      '/api/member/refresh',
      '/api/member/logout',
      '/api/member/terms/agree',
      '/v2/auth',
      // apiUris.auth.signinEmail,
      // apiUris.auth.signinGoogle,
      // apiUris.auth.agreeTeams,
      // apiUris.common.getSchoolLocation,
      // apiUris.auth.signupAcademy,
      // apiUris.auth.signupStudent,
      // apiUris.auth.signupTutor,
      // apiUris.auth.academyUniqueCode,
      // apiUris.common.getTimezone,
      // apiUris.auth.joinAcademy,
    ];

    const shouldSkip = skipUrls.some((url) => {
      return config.url.includes(url);
    });

    if (shouldSkip) {
      return config;
    }

    let token = nvl(userToken?.accessToken);

    // 토큰이 만료되었거나 갱신 작업이 필요할 경우 대기
    if (isTokenExpired(token)) {
      token = await refreshAccessToken(); // 갱신 작업이 완료될 때까지 대기
      if (!token) {
        // eslint-disable-next-line no-undef
        return Promise.reject(new Error('토큰이 만료되었습니다.'));
      }
    }

    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  }
);

requestInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (error.response.data.message === '유효하지 않은 토큰') {
        try {
          const newToken = await refreshAccessToken(); // 토큰 갱신 시도

          if (newToken) {
            // 갱신된 토큰으로 요청을 재시도
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

            // 요청 재시도
            return axios(originalRequest)
              .then((response) => {
                if (originalRequest.successHandler && typeof originalRequest.successHandler === 'function') {
                  originalRequest.successHandler(response.data);
                }
                return response; // 재시도 성공 시 원래의 성공 응답을 반환
              })
              .catch((err) => {
                if (originalRequest.failHandler && typeof originalRequest.failHandler === 'function') {
                  originalRequest.failHandler(err, err.response);
                }
                // eslint-disable-next-line no-undef
                return Promise.reject(err); // 실패 시 에러를 반환
              });
          } else {
            console.error('토큰 갱신 실패');
            logout();
            // eslint-disable-next-line no-undef
            return Promise.reject(error);
          }
        } catch (refreshError) {
          console.error('토큰 갱신 중 오류 발생:', refreshError);
          logout();
          // eslint-disable-next-line no-undef
          return Promise.reject(refreshError);
        }
      } else {
        console.error('인증이 필요합니다.');
        logout();
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
      }
    }

    // eslint-disable-next-line no-undef
    return Promise.reject(error); // 다른 모든 에러 처리
  }
);

const tokenDecoder = () => {
  if (nvl(userSession?.accessToken) === '') return null;

  return jwt_decode(userSession?.accessToken);
};

const userInfo = tokenDecoder();
setUserTimezone(userInfo?.timeZone ?? '');

const axiosConfig = {
  timeout: 60 * 3 * 1000, // 15000
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${nvl(userSession?.accessToken)}`,
    'Content-Type': 'application/json',
    'Time-Zone': userInfo?.timeZone === '' ? getClientTimezone() : userInfo?.timeZone,
  },
  // cancelToken: source.token
};

// ---------------------------------------------

const axiosMultipartConfig = {
  timeout: 30000, // 15000
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${nvl(userSession?.accessToken)}`,
    'content-type': 'multipart/form-data; charset=UTF-8',
  },
  // cancelToken: source.token
};

const axiosDownloadConfig = {
  timeout: 30000, // 15000
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${nvl(userSession?.accessToken)}`,
    'Content-Type': 'application/json; charset=UTF-8',
    responseType: 'blob',
  },
  // cancelToken: source.token
};

const getBase = () => {
  return base;
};

const getRedirectUrl = () => {
  return redirectUrl;
};

const currentTimeStamp = () => {
  return Date.now().toString();
};

/** 현재 환경이 개발 환경인지, 프로덕션 환경인지 확인 */
const checkEnvironmental = {
  isDevelopment: () => process.env.REACT_APP_ENVIRONMENT === 'development',
  isProduction: () => process.env.REACT_APP_ENVIRONMENT === 'production',
  isStaging: () => process.env.REACT_APP_ENVIRONMENT === 'staging',
};

/** API 요청 결과 응답 성공 시 동작 함수 */
const processSuccess = (response, successHandler) => {
  // console.log(response)
  if (successHandler && typeof successHandler === 'function') {
    try {
      successHandler(response.data);
    } catch (e) {
      console.error(e);
      if (checkEnvironmental.isDevelopment()) {
        alert('스크립트 동작 중 오류가 발생했습니다.');
      }
    }
  }
};

/** API 요청 결과 에러 발생 시 동작 함수 */
const processError = (error, failHandler, goPrecessError) => {
  // console.log('error : ', error)
  if (!error.response || !error.response.status) {
    // setInterval 으로 알림을 지속 요청 중인데, 서버 쪽 토큰이 만료 됐을 경우 등.
    console.error('NETWORK ERROR', error);
    // logout();
    if (checkEnvironmental.isDevelopment()) {
      // alert('NETWORK ERROR');
    }
  } else if (failHandler && typeof failHandler === 'function' && !goPrecessError) {
    failHandler(error, error.response);
  } else if (error && error.response) {
    if (goPrecessError && failHandler && typeof failHandler === 'function') {
      failHandler(error, error.response);
    }
    switch (error.response.status) {
      case 400: {
        console.error('요청이 올바르지 않습니다.');
        console.log(error.response.data);
        if (checkEnvironmental.isDevelopment()) {
          alert('요청이 올바르지 않습니다.');
        }
        break;
      }

      case 401: {
        console.error('인증 필요');
        if (checkEnvironmental.isDevelopment()) {
          alert('인증 필요');
        }
        break;
      }

      case 404: {
        console.error('404 NOT FOUND');
        if (checkEnvironmental.isDevelopment()) {
          alert('404 NOT FOUND');
        }
        break;
      }

      case 409: {
        console.error('이미 존재하는 값. 중복된 내용이 있는지 확인필요');
        if (checkEnvironmental.isDevelopment()) {
          alert('이미 존재하는 값. 중복된 내용이 있는지 확인필요');
        }
        break;
      }

      default: {
        console.error('오류');
        if (checkEnvironmental.isDevelopment()) {
          alert('오류');
        }
        break;
      }
    }
  } else {
    console.error('알 수 없는 오류가 발생했습니다.');
    if (checkEnvironmental.isDevelopment()) {
      alert('알 수 없는 오류가 발생했습니다.');
    }
  }
};

/** API 요청 최종 완료 */
const afterRequest = (finallyHandler) => {
  // console.log("응답 받고 실행~!! 끝");
};

const get = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  const targetBase = params?.isNew ? nodebase : base;
  const targetUri = params?.isNew ? uri + `?${new URLSearchParams(params).toString()}` : uri;
  return requestInstance
    .get(`${targetBase}${targetUri}`, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const post = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  const targetBase = params?.isNew ? nodebase : base;
  return requestInstance
    .post(`${targetBase}${uri}`, params, axiosConfig)
    .then((response) => {
      // console.log('🚀 ~ .then ~ response:', response);
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

// 첨부 파일용
const postMultipart = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .post(`${base}${uri}`, params, axiosMultipartConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const put = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .put(`${base}${uri}`, params, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const patch = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .patch(`${base}${uri}`, params, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const del = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return (
    requestInstance
      .delete(`${base}${uri}`, axiosConfig, { data: params })
      // .delete(`${base}${uri}`, axiosConfig, { params })
      .then((response) => {
        processSuccess(response, successHandler);
      })
      .catch((error) => {
        processError(error, failHandler, goPrecessError);
      })
      .finally(() => {
        afterRequest();
      })
  );
};

const jsonpFunc = async (uri, callback, successHandler) => {
  jsonp(uri, { name: callback }, (error, response) => {
    if (error) processError(error);
    else successHandler(response);
  });
};

const downloadFile = async (uri, filename) => {
  fetch(`${base}${uri}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${nvl(userSession?.accessToken)}`,
      'content-type': 'application/json',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      console.log('', blob);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    });
  });
};

const commonFileDownload = async (uri, params, failHandler, goPrecessError) => {
  return requestInstance
    .get(`${base}${uri}`, axiosDownloadConfig)
    .then((response) => {
      const blob = new Blob([response.data]);
      const fileObjectUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = fileObjectUrl;
      link.style.display = 'none';

      const extractDownloadFilename = (response) => {
        // 파일 이름을 추출
        console.log('모냐', response);

        const disposition = response.headers['Content-Disposition'];

        console.log('이게 없나?', disposition);

        const fileName = decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, ''));

        return fileName;
      };

      // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정
      link.download = extractDownloadFilename(response);

      // 다운로드 파일 이름을 직접 지정
      // link.download = params.fileName;

      // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행
      document.body.appendChild(link);

      link.click();
      link.remove();

      // 다운로드가 끝난 리소스(객체 URL)를 해제
      window.URL.revokeObjectURL(fileObjectUrl);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const logout = () => {
  LocalStorage.clearItem('managerSession');
  LocalStorage.clearItem('userSession');
  LocalStorage.clearItem('sendParams');

  if (tokenDecoder?.userId) request.post('/api/member/logout', { userId: tokenDecoder?.userId }, null);

  window.location.href = '/signin';
};

const tokenVerify = (pathname) => {
  try {
    const userToken = tokenDecoder();

    if (userToken && nvlNumber(userToken?.userSeq) > 0) {
      console.log('만료시간 체크', dayjs(userToken.exp * 1000).format('YYYY-MM-DD HH:mm:ss'), dayjs(userToken.exp * 1000).valueOf() - dayjs().valueOf());

      // AccessToken 만료 확인
      if (dayjs(userToken.exp * 1000).valueOf() - dayjs().valueOf() < 0) {
        // 이 부분에서 refresh token 작업을 추가해야 함
        // return false;
      }

      return true; // AccessToken 이 유효한 상태
    }
  } catch (error) {
    console.error('토큰 검증 중 오류 발생:', error);
  }

  return false;
};

export function waitFor(promises, callback) {
  return axios.all(promises).then(callback);
}

const apGet = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .get(`${apBase}${uri}`, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const apPost = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .post(`${apBase}${uri}`, params, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const apPut = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .put(`${apBase}${uri}`, params, axiosConfig)
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

const apDel = async (uri, params, successHandler, failHandler, goPrecessError, useBase64Auth = false) => {
  return requestInstance
    .delete(`${apBase}${uri}`, axiosConfig, { data: params })
    .then((response) => {
      processSuccess(response, successHandler);
    })
    .catch((error) => {
      processError(error, failHandler, goPrecessError);
    })
    .finally(() => {
      afterRequest();
    });
};

export const apiUris = {
  auth: {
    signinEmail: '/v2/auth/signin/email',
    signinGoogle: '/v2/auth/signin/google',
    agreeTeams: '/v2/auth/terms/agree',
    // refreshToken: '/api/member/token/refresh',
    signupAcademy: '/v2/auth/signup/academy',
    signupStudent: '/v2/auth/signup/student',
    signupTutor: '/v2/auth/signup/tutor',
    signupParent: '/v2/auth/signup/parent',
    signupAcademyTeacher: '/v2/auth/signup/academyteacher',
    academyUniqueCode: '/v2/auth/academy/uniquecode/check',
    joinAcademy: '/v2/auth/join/academy',
  },
  ap: {
    score: {
      class: '/api/ap/score/class',
    },
    subject: {
      list: '/api/ap/subject',
      // detail: '/api/ap/score/subject/detail',
    },
    questionBank: {
      templete: '/api/ap/question-bank/test/template',
      createTest: '/api/ap/question-bank/test',
      deleteTest: '/api/ap/question-bank/test/delete',
    },
  },
  common: {
    getSchoolLocation: '/api/common/code/schoollocaion',
    duplicateMobile: '/api/common/duplicate/mobile/:number',
    getTimezone: '/api/common/timezone',
  },
};

const request = {
  get,
  post,
  put,
  patch,
  del,
  getBase,
  processError,
  waitFor,
  getRedirectUrl,
  postMultipart,
  tokenVerify,
  tokenDecoder,
  currentTimeStamp,
  jsonpFunc,
  downloadFile,
  commonFileDownload,

  // ap
  apGet,
  apPost,
  apPut,
  apDel,
  apiUris,
};

export default request;
