import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import request from 'utils/Request.utils';

import { clearApTestEditor } from 'reducers/apTestEditor/action';

import { useDispatch } from 'react-redux';

// import SelectBox from 'components/_common/inputs/SelectBox';

/** AP / "Question Bank" 페이지 */
export default function ApQuestionBankCreateQuestionPage() {
  const navigate = useNavigate();

  /** subject list */
  const [selectSubject, setSelectSubject] = useState([{ value: '0', label: '선택해주세요' }]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [testName, setTestName] = useState('');
  const [subjectTemmplate, setSubjectTemmplate] = useState([]);

  const dispatch = useDispatch();

  const getSelectSubject = () => {
    // api 호출
    const successHandler = (response) => {
      const clear_data = response.result.map((item) => {
        return { value: item.subject_seq, label: item.subject_name };
      });
      setSelectSubject(clear_data);
      getTemplate(clear_data);
    };
    const errorHandler = (error) => {};
    const params = { isNew: true };
    request.get(request.apiUris.ap.subject.list, params, successHandler, errorHandler);
  };

  const getTemplate = (parent_data) => {
    const successHandler = (response) => {
      const result = parent_data.map((item) => {
        return {
          ...item,
          template: response.result[item.value - 1],
        };
      });
      setSubjectTemmplate(result);
    };
    const errorHandler = (error) => {};
    const params = { isNew: true };
    request.get(request.apiUris.ap.questionBank.templete, params, successHandler, errorHandler);
  };

  const submitHandler = () => {
    if (!testName) {
      alert('Plesae enter the fields1');
      return;
    }
    if (selectedSubject < 1) {
      alert('Plesae enter the fields2');
      return;
    }
    console.log(subjectTemmplate);
    console.log(selectedSubject);
    const template = subjectTemmplate.find((item) => {
      console.log(item);
      console.log(selectedSubject);
      console.log(item.value == selectedSubject);
      return item.value == selectedSubject;
    });
    const params = {
      test_name: testName,
      subject_seq: selectedSubject,
      datas: template,
      isNew: true,
    };

    const successHandler = (response) => {
      console.log(response);
      const { test_set_seq } = response.result;
      console.log('🚀 ~ successHandler ~ test_seq:', test_set_seq);

      dispatch(clearApTestEditor());
      navigate(`/ap/question-bank/test-editor?test_seq=${test_set_seq}`);
    };
    const errorHandler = (error) => {
      console.log(error);
    };
    request.post(request.apiUris.ap.questionBank.createTest, params, successHandler, errorHandler);
  };

  const handleChange = (e) => {
    setSelectedSubject(e.target.value); // 선택된 값 업데이트
  };

  useEffect(() => {
    getSelectSubject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(selectSubject);

  return (
    <S.Wrap>
      <S.CreateTestContainer>
        <div className='create_test_header'>
          <h3 className='title'>CREATE TEST</h3>
          <p className='title_description'>Select Subject first, then enter the test name.</p>
        </div>

        <S.CreateTestInputContainer>
          <S.InputContainer>
            <p className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <div className='input_label_text'>Subject</div>
            </p>

            {/* <SelectBox
              className='text_input_field'
              handleChange={(e) => {
                console.log('🚀 ~ QuestionBankRootPage ~ e:', e);
                // setSelectedSubject(e);
              }}
              placeholder='Select Subject'
              optionList={selectSubject} // individual 제외
              value={selectedSubject}
              fixedValue='Select Class'
            /> */}
            {/* <CustomSelect
              className='creeate-test-select'
              options={selectSubject}
              onSelect={(value) => {
                setSelectedSubject(value);
              }}
            /> */}
            <select id='subject-select' className='input-field' value={selectedSubject} onChange={handleChange}>
              <option value='' disabled>
                Select a subject
              </option>
              {selectSubject.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </S.InputContainer>

          <S.InputContainer>
            <p className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <div className='input_label_text'>Test Name</div>
            </p>

            <input
              type='text'
              id='test_name'
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
              className='text_input_field test_input'
              placeholder='Enter'
            />
          </S.InputContainer>
        </S.CreateTestInputContainer>

        <S.CreateTestButtonContainer>
          <button className='bottom_btn cancel_button' onClick={() => navigate(-1)}>
            Cancel
          </button>
          <Link onClick={submitHandler} className='bottom_btn next_button'>
            Next
          </Link>
        </S.CreateTestButtonContainer>
      </S.CreateTestContainer>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12.875rem;
  `,

  CreateTestContainer: styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    height: 26rem;
    width: 74.625rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 20px #00000029;

    padding: 2rem 2.5rem;
    padding-top: 4.375rem;

    .create_test_header {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
      }
      .title_description {
        color: #757575;

        font-size: 14px;
        font-weight: 400;

        line-height: 20px;
      }
    }
  `,

  CreateTestInputContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: flex-end;
    width: 31.5625rem;
  `,

  InputContainer: styled.div`
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;

    .input_label_wrapper {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      gap: 5px;
      justify-content: center;

      .required_mark {
        font-size: 16px;
        font-weight: 400;
        color: #ff5858;
      }
      .input_label_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #7f8998;
      }
    }
    .text_input_field {
      width: 380px;
      /* align-items: flex-start;
      display: flex;
      height: 44px;
      border: 1px solid #d2dbe2;
      border-radius: 4px; */

      &.test_input {
        border: 1px solid #d2dbe2;
      }
    }

    select {
      width: 23.75rem;
      height: 2.75rem;
      padding: 0.75rem 1rem;
      border: 1px solid #d2dbe2;
    }
  `,

  CreateTestButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0.75rem;

    .bottom_btn {
      padding: 0.625rem 1.25rem;
      border-radius: 0.375rem;
      width: 8.625rem;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;

      &.cancel_button {
        background-color: white;
        color: #0068bd;
        border: 1px solid #0068bd;
      }
      &.next_button {
        background-color: #0068bd;
        color: white;
      }
    }
  `,
};

//   /** tab */
//   const [tabState, setTabState] = useState(0);

//   /** 텍스트 에디터에 입력하는 상태 */
//   const [testValue, setTextValue] = useState('');

//   return (
//     <S.Wrap>
//       <MainLink />
//       <div className='center_wrap'>
//         <div className='container'>
//           <ReactQuillEditor editorContentState={testValue} setEditorContentState={setTextValue} />
//           <div>
//             <h2>리액트 상태 값으로 관리 되고 있는 텍스트 에디터 값 : </h2>
//             <p>{testValue}</p>
//           </div>
//         </div>
//       </div>
//     </S.Wrap>
//   );
// }

// const S = {
//   Wrap: styled.div`
//     .center_wrap {
//       width: 90rem;
//       margin: auto;
//     }
//   `,
