import React from 'react';
import styled from 'styled-components';
import PrintFooter from '../layouts/PrintFooter';

/** 출력 될 영역 단위 컴포넌트 */
export default function PrintPage({ children, isSingleCol, isHorizontal }) {
  return (
    <S.Wrap className={`${isSingleCol ? 'isSingleCol' : isHorizontal ? 'isHorizontal' : ''}`}>
      {children}
      <PrintFooter />
    </S.Wrap>
  );
}

const S = {};
S.Wrap = styled.div`
  font-family: 'Noto Sans', serif;
  position: relative;
  width: 100%;
  /* height: 297mm; */
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; */
  column-count: 2;
  column-gap: 1rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #999999;
  }

  &.isSingleCol {
    column-count: 1;
    &::after {
      display: none;
    }
    & .question_sec.right::after {
      display: none;
    }
  }
  &.isHorizontal {
    column-count: 1;
    display: grid;
    grid-template-columns: 50% 50%;
    &::after {
      display: none;
    }
    & .question_sec.right::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #999999;
    }
  }

  @media print {
    @page {
      margin-bottom: 2rem;
    }
  }
`;
