import styled from 'styled-components';

import CustomCheckbox from 'components/_common/CustomCheckbox';
import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';
import ApSettingInput from './_components/ApSettingInput';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

export default function QuestionSettingPopup({ isOpen, pos = 'left', questionInfo, setQuestionInfo }) {
  /** question setting data 변경 핸들러 */
  const handleChangeQuestionSettingData = (key, value) => {
    setQuestionInfo((prev) => {
      const newData = { ...prev };
      newData[key] = value;
      return newData;
    });
  };

  if (!isOpen) return null;

  return (
    <S.Container $pos={pos}>
      <div className='popup_header'>
        <h2>Question Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>Question Number</span>
          <ApSettingInput
            className='ap_input number_input'
            value={questionInfo.customQuestionOrder}
            onChange={(e) => handleChangeQuestionSettingData('customQuestionOrder', e.target.value)}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Context</span>

          <CustomCheckbox
            size='1.25rem'
            checkBoxId={`questionContext_checkbox`}
            isChecked={questionInfo.questionContext}
            onChange={() => handleChangeQuestionSettingData('questionContext', !questionInfo.questionContext)}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Type</span>
          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'Multiple Choice', value: 'MCQ' },
                { label: 'Free Response', value: 'FRQ' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionType', value)}
            />
          </div>
        </div>

        <div className='control_row inner_row'>
          <span className='label'>Multiple choice type</span>
          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'Single-select', value: 'Single-select' },
                { label: 'Multi-select', value: 'Multi-select' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionType', value)}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Difficulty</span>

          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'None', value: null },
                { label: 'Easy', value: 'Easy' },
                { label: 'Medium', value: 'Medium' },
                { label: 'Hard', value: 'Hard' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionDifficulty', value)}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Possible Score</span>

          <ApSettingStepper
            number={questionInfo.score}
            onIncrease={() => {
              handleChangeQuestionSettingData('score', questionInfo.score + 1);
            }}
            onDecrease={() => {
              handleChangeQuestionSettingData('score', questionInfo.score - 1);
            }}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Code</span>

          <ApSettingInput
            className='ap_input code_input'
            value={questionInfo.questionCode}
            onChange={(e) => handleChangeQuestionSettingData('questionCode', e.target.value)}
          />
        </div>
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const getPopupPosition = (pos) => {
  switch (pos) {
    case 'left':
      return `
        right: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          right: -3%;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      `;
    case 'right':
      return `
        left: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          top: 50%;
          transform: translateY(-50%);
        }
      `;
    case 'top':
      return `
        bottom: 115%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          bottom: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(270deg);
        }
      `;
    case 'bottom':
      return `
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          top: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      `;
    default:
      return `
        left: 116%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          transform: translateY(-50%);
        }
      `;
  }
};

const S = {
  Container: styled.div`
    position: absolute;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;

    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));
    z-index: 1000;
    ${({ $pos }) => getPopupPosition($pos)}

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      color: #ffffff;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.inner_row {
        background-color: #fafafa;
        padding: 0.625rem 0.75rem;
        border-radius: 0.25rem;
      }

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      .ap_input {
        width: 8.75rem;
        padding: 0 10px;

        &.number_input {
          max-height: 2.375rem;
        }
        &.code_input {
          max-height: 2.125rem;
        }
      }
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,

  SectionDivider: styled.div`
    height: 2px;
    background-color: #505050;
    width: 100%;
  `,
};
