import React, { useEffect } from 'react';
import SessionStorage from 'utils/SessionStorage.utils';
import request from 'utils/Request.utils.js';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
//img
import LogoImg from 'assets/img/logo.png';

/**
 * "Classes Print" 페이지
 * @description
 * 🔍 검색 키워드 - #com/classes/print
 */
function ClassesPrintPage() {
  const userInfo = request.tokenDecoder();
  const printCard = SessionStorage.getItemJsonParse('printCard');
  const classes = printCard?.classes;

  useEffect(() => {
    document.body.classList.add('p_student_print'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('p_student_print'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <>
      <h2 className='tit'>Classes list</h2>
      <h3 className='sub_tit'>{userInfo.companyName}</h3>
      <div className='com_list'>
        <table className='list'>
          <colgroup>
            <col width='3%' />
            <col width='14%' />
            <col width='7%' />
            <col width='7%' />
            <col width='5%' />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>Class Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>No. of Students</th>
            </tr>
          </thead>
          <tbody>
            {classes.map((item, index) => {
              return (
                <tr>
                  <td>{classes.length - index}</td>
                  <td>
                    <p className='name'>{item.classTitle}</p>
                  </td>
                  <td>{isoTimeToDayjsTZ(item.startDate).format('MM/DD/YYYY')}</td>
                  <td>{isoTimeToDayjsTZ(item.endDate).format('MM/DD/YYYY')}</td>
                  <td>{item.studentList.length}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='logo'>
        <img src={LogoImg} alt='logo' />
      </div>
    </>
  );
}

export default ClassesPrintPage;
