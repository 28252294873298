import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

import IconEmpty from 'assets/img/icon_empty.png';
import useOutsideClick from 'hooks/useOutsideClick';

import ButtonBase from 'components/_common/button/_ButtonBase';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';

import Paging from 'layout/Paging';
import styled from 'styled-components';
import ByTestClassListSection from './ByTestClassListSection';

import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';
import { ReactComponent as ImgSvgTrashcan3 } from 'assets/img/svg/icon_trashcan_3.svg';

import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';

/**
 * tests/scheduled 페이지의 "By Test" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */

export default function ByTest({ setSimpleAlert, handleEditTest, classDeleteClick, reloadData }) {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });
  const [activeTest, setActiveTest] = useState({
    customSetSeq: 0,
    classMemberList: [],
    classMemberListAll: [],
    totalCnt: 0,
    testName: '',
  });

  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });
  /** 프로필 모달 State */
  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean  => 프로필 모달 노출 여부
    userSeq: 0, // number       => 학생 시퀀스
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 시험 일정 얻어오기 API 호출 */
  const getSchedules = () => {
    const pms = {
      ecSeq: userInfo?.ecSeq,
      searchField: searchInfo.setSearchField === 'Name' ? 'userName' : 'testName',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: 10,
      offset: (scheduleInfo.currentPage - 1) * scheduleInfo.numPerPage,
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        const scheduleList = response.result.scheduleList;
        const totalCnt = response.result.totalCnt;

        setScheduleInfo((prev) => {
          return { ...prev, uthList: scheduleList ?? [], totalCount: totalCnt };
        });

        if (totalCnt > 0 && !activeTest.customSetSeq) {
          setActiveTest((prev) => {
            return {
              ...prev,
              customSetSeq: scheduleList[0].customSetSeq,
              testName: scheduleList[0].testName,
            };
          });
        }
      }
    };

    if (userInfo?.ecSeq > 0) {
      const sendSearchKeyword = pms.searchKeyword?.trim() ? encodeURIComponent(pms.searchKeyword) : '';
      request
        .get(
          `/api/test/schedules/pre?ecSeq=${pms.ecSeq}&limit=${pms.limit}&offset=${pms.offset}&searchField=${pms.searchField}&searchKeyword=${sendSearchKeyword}&searchStartDate=${encodeURIComponent(pms.searchStartDate)}&searchEndDate=${encodeURIComponent(pms.searchEndDate)}`,
          null,
          successHandler
        )
        .catch((error) => console.error('tests/scheduled 페이지의 "/api/test/schedules/pre" API 응답 에러', error));
    }
  };

  /** 반 별 예정 시험 조회 API 호출 */
  const getTestClassMember = () => {
    if (activeTest.customSetSeq === 0) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        let scheduleList = response.result.scheduleListc;

        let deduplicationClass = removeDuplicatesOne(scheduleList, 'classSeq');
        let sameClassList = [];
        for (let i = 0; i < deduplicationClass.length; i++) {
          const classSeq = deduplicationClass[i].classSeq;
          const filteredSchedule = scheduleList.filter((i) => i.classSeq === classSeq);
          sameClassList.push(filteredSchedule);
        }

        let courseList = [];
        let deduplicationTestDate = [];
        for (let i = 0; i < sameClassList.length; i++) {
          deduplicationTestDate = removeDuplicatesTwo(sameClassList[i], 'testStartDate', 'testEndDate');

          for (let j = 0; j < deduplicationTestDate.length; j++) {
            const startDate = deduplicationTestDate[j].testStartDate;
            const endDate = deduplicationTestDate[j].testEndDate;

            const filteredTestSchedule = sameClassList[i].filter((i) => i.testStartDate === startDate && i.testEndDate === endDate);
            courseList.push(filteredTestSchedule);
          }
        }

        const sortList = courseList.sort((a, b) => isoTimeToDayjsTZ(a[0].testStartDate) - isoTimeToDayjsTZ(b[0].testStartDate));
        setActiveTest((prev) => {
          return { ...prev, classMemberList: sortList };
        });
      }
    };

    request
      .get(`/api/test/schedules/pre/exams/${activeTest.customSetSeq}?ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "반 별 예정 시험 조회" API 응답 에러', error));
  };

  /** 프로필 불러오기 API 요청 */
  const handleClickViewProfile = (e, userSeq) => {
    e.stopPropagation();

    setProfileModalInfo({ visible: true, userSeq: userSeq });
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 중복 제거 1 */
  const removeDuplicatesOne = (arr, prop) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };
  /** 중복 제거 2 */
  const removeDuplicatesTwo = (arr, prop1, prop2) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]);
    });
  };

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = () => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const scheduleTrClick = (customSetSeq, testName) => {
    // 중복 제거2
    setActiveTest((prev) => {
      return { ...prev, customSetSeq: customSetSeq, testName: testName };
    });
  };

  /** 체크 박스 변경 핸들러 */
  const handleCheckboxChange = (e, uthSeq) => {
    e.stopPropagation();

    const { selectedKeys, seqStr } = selectCheckBox;

    if (e.target.checked) {
      const updatedSelectedKeys = [...selectedKeys, uthSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const schedulesListReload = (page) => {
    if (page) {
      setScheduleInfo((prev) => {
        return { ...prev, currentPage: page };
      });
    }

    getSchedules();
    getTestClassMember();
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate, reloadData]);
  useEffect(() => {
    getTestClassMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.customSetSeq]);

  useEffect(() => {
    schedulesListReload(scheduleInfo.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.currentPage]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  /** 삭제 버튼 클릭 핸들러 - 삭제 모달 활성화 ( Test 미선택시 alert ) */
  const handleClickDeleteBtn = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length === 0) {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the test you want to delete.',
      });
    }
    // 해당 class에 속한 학생들의 uth 추출
    else classDeleteClick(selectedKeys);
  };

  return (
    <>
      <S.ByTestWrap>
        <S.TableContainer className='com_list'>
          <S.TableTopController className='top'>
            <article className='btn_area'>
              <CommonTestBtn className='delete_test_btn' hoverText='Delete' onClick={handleClickDeleteBtn} iconBtn>
                <ImgSvgTrashcan3 />
              </CommonTestBtn>
            </article>
            <article className='search_area'>
              <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
              <div
                className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                onClick={() =>
                  setSearchInfo((prev) => {
                    return {
                      ...prev,
                      searchFieldActive: !searchInfo.searchFieldActive,
                    };
                  })
                }
                ref={searchFiledRef}>
                {/*클래스 active 포함 시 옵션 노출*/}
                <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                <div className='layer'>
                  <p className='option' onClick={() => searchFiledClick('Name')}>
                    Name
                  </p>
                  <p className='option' onClick={() => searchFiledClick('Test')}>
                    Test
                  </p>
                </div>
              </div>
              <div className='search'>
                <input
                  type='text'
                  placeholder='Enter keywords'
                  className='input search_area_input'
                  ref={searchKeywordRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') keywordSearchDo();
                  }}
                />
                <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                  <i className='svg_icon icon_search blue before'></i>
                  SEARCH
                </button>
              </div>
            </article>
          </S.TableTopController>
          <S.Table className='list'>
            <colgroup>
              <col width='38px' />
              <col width='172px' />
              <col width='' />
              <col width='84px' />
              <col width='105px' />
              <col width='105px' />
              <col width='67px' />
            </colgroup>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Test</th>
                <th>Name</th>
                <th>Time</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Profile</th>
              </tr>
            </thead>
            <tbody>
              {scheduleInfo.uthList.length > 0 ? (
                scheduleInfo.uthList.map((item, idx) => {
                  return (
                    <tr
                      className={activeTest.customSetSeq === item.customSetSeq ? 'active' : ''}
                      key={`scheduleTr_${idx}`}
                      onClick={() => scheduleTrClick(item.customSetSeq, item.testName)}>
                      <td>
                        <input
                          type='checkbox'
                          className='com_chk'
                          id={`schedule_${idx}`}
                          checked={selectCheckBox.selectedKeys.includes(item.uthSeq)}
                          onChange={(e) => handleCheckboxChange(e, item.uthSeq)}
                        />
                        <label htmlFor={`schedule_${idx}`}>&nbsp;</label>
                      </td>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>
                          <button onClick={() => handleEditTest(item, 'test')}>
                            <i className='svg_icon icon_annotate gray before'>&nbsp;</i>
                          </button>
                          {item.testName}
                        </p>
                      </td>
                      <td className='alignL'>{item.userName}</td>
                      <td className='no_pad'>{isoTimeToDayjsTZ(item.testStartDate).format('h : mm A')}</td>
                      <td>{isoTimeToDayjsTZ(item.testStartDate).format('MM/DD/YYYY')}</td>
                      <td>{isoTimeToDayjsTZ(item.testEndDate).format('MM/DD/YYYY')}</td>
                      <td>
                        <ButtonBase type='tableBtn' onClick={(e) => handleClickViewProfile(e, item.userSeq)}>
                          View
                        </ButtonBase>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='7'>
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </S.Table>
          <Paging
            totalCount={scheduleInfo.totalCount}
            currentPage={scheduleInfo.currentPage}
            numPerPage={scheduleInfo.numPerPage}
            pagePerBlock={scheduleInfo.pagePerBlock}
            nextPageMove={nextPageMove}
          />
        </S.TableContainer>
        <ByTestClassListSection testData={activeTest} setSimpleAlert={setSimpleAlert} handleEditTest={handleEditTest} classDeleteClick={classDeleteClick} />
      </S.ByTestWrap>
      {/* 학생 상세 프로필 팝업 */}
      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

// --- --- ---

const S = {
  ByTestWrap: styled.div`
    display: flex;
    background: #fff;
    gap: 1rem;
    padding: 1.25rem 1rem;

    border: 1px solid #f1f1f5;
    border-top: none;
    border-radius: 0rem 0rem 0.625rem 0.625rem;
  `,

  TableContainer: styled.div``,
  TableTopController: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search_area {
      display: flex;
      gap: 0.5rem;

      .com_select_layer {
        width: 6.25rem;
        height: 2.5rem;

        .selected.search_area_input {
          border-radius: 0.5rem;
          min-width: 6.25rem;
          padding: 0.625rem 0.75rem;
          box-sizing: border-box;
          height: 2.5rem;
        }

        &:after {
          right: 1rem;
        }
      }

      .com_calendar_wrap {
        input {
          width: 11rem;
          border-radius: 0.5rem !important;
          padding-left: 2.5rem;
          background-position: 0.75rem center;
          text-align: right;
          padding-inline: 0.75rem !important;
        }
      }

      .search {
        .search_area_input {
          border-radius: 0.5rem;
          padding: 0.625rem 0.75rem;
          width: 12rem;
          height: 2.5rem;
          box-sizing: border-box;
        }

        .svg_icon {
          margin-right: 0.5rem;
          width: 1.125rem;
          height: 1.125rem;
        }

        .btn_search {
          min-height: 2.5rem;
        }
      }
    }

    .delete_test_btn {
      width: 2rem;
      height: 2rem;
    }
  `,
  Table: styled.table`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    white-space: nowrap;

    .no_pad {
      padding: 0;
      padding-inline: 0 !important;
    }
  `,
};



