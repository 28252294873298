import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

dayjs.extend(isBetween);

/** 학생 대시보드 페이지의 "Upcoming Test" section 컴포넌트 */
export default function UpcomingTestSection() {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const childWindowRef = useRef(null);

  const [upComingTestList, setUpComingTestList] = useState([]);

  useEffect(() => {
    getUpcomingTestList();
  }, []);

  useEffect(() => {
    // 자식 창이 열릴 때만 `getUpcomingTestList`를 전역 함수로 할당
    if (childWindowRef.current) {
      window.getUpcomingTestList = getUpcomingTestList;
    }

    // Cleanup: 컴포넌트 언마운트 시 함수 제거
    return () => {
      if (window.getUpcomingTestList) {
        getUpcomingTestList();
        delete window.getUpcomingTestList;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childWindowRef.current]);

  // Upcoming Test List 불러오기
  const getUpcomingTestList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        setUpComingTestList(response.result.upcomingTests);
      }
    };

    request.apGet('/api/v1/student/dashboard/upcoming-test', null, successHandler);
  };

  // ap 시험 시작 함수
  const apTestStart = (uthSeq) => {
    // 열려있는 시험 창이 있을경우 해당 창을 닫음
    if (childWindowRef.current !== null) childWindowRef.current.close();

    // 시험 정보 불러오기
    getTestInfo(uthSeq, 'start');
  };

  // 진행중인 시험 재진입 함수
  const inProgressTest = (uthSeq, testStatus) => {
    // 열려있는 시험 창이 있을경우 해당 창을 닫음
    if (childWindowRef.current !== null) childWindowRef.current.close();

    if (testStatus === 'I') {
      // 진행중인 시험일 경우
      getTestInfo(uthSeq, 'progress');
    }
    if (testStatus === 'B') {
      // 쉬는 시간일 경우
      localStorage.setItem('breakTime', 'isBreak', true);
      getTestInfo(uthSeq, 'breakTime');
    }

    const currentOrder = localStorage.getItemJsonParse(`apTest_${uthSeq}`).sessionOrder;
  };

  // 시험 정보 불러오는 API
  const getTestInfo = (uthSeq, mode) => {
    const params = {
      userId: userInfo.userId,
    };

    // 팝업 창 열기 및 설정 함수
    const openPopupWindow = (url) => {
      const popupWindow = window.open(
        `${window.location.origin}${url}`,
        'ap',
        `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
      );

      // 자식 창 참조 저장
      childWindowRef.current = popupWindow;

      // 자식 창에서 부모 함수 호출 가능하도록 전역 함수 설정
      if (popupWindow) {
        window.getUpcomingTestList = getUpcomingTestList;
      }
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const testInfo = response.result.getTestSetInfo;
        localStorage.setItem(`currentTest`, 'uthSeq', uthSeq);

        // 시험 정보 저장
        const testKey = `apTest_${uthSeq}`;
        localStorage.setItem(testKey, 'testInfo', testInfo);

        // 모드별 처리
        switch (mode) {
          case 'start':
            openPopupWindow('/ap/exam/tutorial');
            break;
          case 'progress':
            openPopupWindow('/ap/exam/inprogress');
            break;
          case 'breakTime':
            localStorage.setItem(testKey, 'startTime', Date.now());
            openPopupWindow('/ap/exam/break');
            break;
          default:
            console.error('Unknown mode:', mode);
        }
      }
    };

    request.apGet(`/api/v1/student/test/set?uthSeq=${uthSeq}`, params, successHandler);
  };

  return (
    <article className='com_list custom_scroll'>
      <table className='list'>
        <colgroup>
          <col width='100px' />
          <col width='200px' />
          <col />
          <col width='100px' />
          <col width='115px' />
        </colgroup>
        <thead className='sticky top'>
          <tr>
            <th>No.</th>
            <th>Subject</th>
            <th>Test</th>
            <th>Date</th>
            <th>Test start</th>
          </tr>
        </thead>
        <tbody>
          {upComingTestList &&
            upComingTestList.map((item, index) => (
              <S.Tr key={`upcoming-${index}`}>
                <td>
                  <S.TableTdInnerBox>{index + 1}</S.TableTdInnerBox>
                </td>
                <td>
                  <S.TableTdInnerBox>{item.apSubjectName}</S.TableTdInnerBox>
                </td>
                <td>
                  <S.TableTdInnerBox>{item.testSetName}</S.TableTdInnerBox>
                </td>
                <td>
                  <S.TableTdInnerBox>{dayjs(item.testStartDate).format('MM/DD')}</S.TableTdInnerBox>
                </td>
                <td>
                  {/* dayjs().isBetween(dayjs(item.date).subtract(1, 'day'), dayjs(item.date), null, '[]') ? null : */}
                  {item.testStatus === 'E' ? (
                    <button className='com_btn s black' onClick={() => apTestStart(item.uthSeq)}>
                      Start
                    </button>
                  ) : (
                    <button className='com_btn s red' onClick={() => inProgressTest(item.uthSeq, item.testStatus)}>
                      IN PROGRESS
                    </button>
                  )}
                </td>
              </S.Tr>
            ))}
        </tbody>
      </table>
    </article>
  );
}

const S = {
  /** Styled-component : <td> 태그 안에서 정렬 용 */
  TableTdInnerBox: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  `,
  /** hover 용 */
  Tr: styled.tr`
    &:hover {
      .btn-more {
        visibility: visible;
      }
    }
  `,
};
