import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'utils/LoadingBar';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import usePressESC from 'hooks/usePressESC';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

import { ReactComponent as ImgAlertTriangle } from 'assets/img/svg/icon_alert_triangle_02.svg';

/** AP 시험 페이지의 "Exit" 팝업 컴포넌트 */
export default function ExitModal({ setIsExitModal }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // ESC 키 입력 시 모달 닫기
  usePressESC(() => setIsExitModal(false));

  /** 시험 그만 하기 버튼 핸들러 */
  const exitTest = () => {
    setIsLoading(true);
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    const currentTestKey = `apTest_${uthSeq}`;

    const param = {
      partInfo: localStorage.getItemJsonParse(currentTestKey).testInfo.partInfo,
      questions: localStorage.getItemJsonParse(currentTestKey).questionList,
    };

    const successHandler = (response) => {
      setIsLoading(false);
      if (response.code === 201) {
        // window.opener.getUpcomingTestList();
        navigate('/ap/exam/finish', { replace: true, state: { giveUp: true } });
      }
    };

    request.apPost(`/api/v1/student/test/${uthSeq}/exit`, param, successHandler);
  };

  return (
    <>
      <SimpleModalBase p='1.25rem' gap='0'>
        <div className='modal_title_container'>
          <button className='modal_title_btn' onClick={() => setIsExitModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>
        <S.ExitPopupContainer>
          <S.Content>
            <S.MainContent>
              <div className='warning-text-container'>
                <S.WarningIcon role='img' aria-label='Warning' title='Warning'>
                  <ImgAlertTriangle />
                </S.WarningIcon>
                <div className='warning-text'>
                  <h2>Do you really want to exit the exam?</h2>
                  <p>
                    If you exit now, you will be graded on what you have solved until this point, <br />
                    and will <strong>NOT</strong> be able to return to the test.
                  </p>
                </div>
              </div>

              <S.NoticeBox>
                <p>
                  <span className='highlight'>NOTE:</span> All Gate Key that have been used for this test will <strong>NOT BE REFUNDED</strong> <br />
                  once you click on "I understand. Exit."
                </p>
              </S.NoticeBox>
            </S.MainContent>
          </S.Content>

          <S.ButtonGroup>
            <button className='red' onClick={exitTest}>
              I understand. Exit.
            </button>
            <button onClick={() => setIsExitModal(false)}>Continue the exam</button>
          </S.ButtonGroup>
        </S.ExitPopupContainer>
      </SimpleModalBase>
      {isLoading && <LoadingBar />}
    </>
  );
}

const S = {
  ExitPopupContainer: styled.div`
    background-color: #ffffff;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.25rem;
    padding-top: 0;
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  `,

  WarningIcon: styled.div`
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem;
    border-radius: 50%;
    background-color: #fdf4f4;
    color: #d14443;
  `,

  MainContent: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .warning-text-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .warning-text {
        text-align: center;

        h2 {
          color: #111111;
          font-family: 'Roboto-SemiBold', Helvetica;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
        }

        p {
          color: #505050;
          font-family: 'Roboto-Regular', Helvetica;
          font-size: 0.875rem;
          line-height: 1.25rem;

          strong {
            font-family: 'Roboto-Bold', Helvetica;
            font-weight: 700;
          }
        }
      }
    }
  `,

  NoticeBox: styled.div`
    background-color: #fdf4f4;
    border-radius: 0.625rem;
    padding: 0.75rem;
    text-align: center;

    p {
      color: #505050;
      font-family: 'Roboto-Regular', Helvetica;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .highlight {
      color: #d14443;
      font-weight: 700;
    }
    strong {
      font-weight: 700;
    }
  `,

  ButtonGroup: styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 520px;

    button {
      width: 10.625rem;
      height: 3rem;
      border-radius: 0.375rem;
      border: 1px solid #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 1rem;

      &.red {
        background-color: #d14443;
        color: #ffffff;
      }
    }
  `,
};
