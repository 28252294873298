import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

import IconEmpty from 'assets/img/icon_empty.png';
import useOutsideClick from 'hooks/useOutsideClick';

import ButtonBase from 'components/_common/button/_ButtonBase';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';

import Paging from 'layout/Paging';
import styled from 'styled-components';
import ByTestClassListSection from './ByTestClassListSection';

import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';
import IconTrashcan2 from 'assets/img/svg/icon_trashcan_2.svg';
import ProfilePopup from 'components/_common/modals/PopupProfile';

/**
 * tests/scheduled 페이지의 "By Test" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function ByTest({ classEditClick, reloadData, classDeleteClick, setSimpleAlert }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  // 시험 일정 리스트
  const [apScheduleList, setApScheduleList] = useState([
    {
      // user
      userName: '', // string  => 학생 이름
      userSeq: 0, // number  => 학생 고유 seq 번호
      // subject
      subjectName: '', // string  => 과목 이름
      subjectSeq: 0, // number  => 과목 고유 seq 번호
      // testSet
      testSetName: '', // string  => 시험 이름
      testSetSeq: 0, // number  => 시험 고유 seq 번호
      testStartDate: '', // string  => 시험이 시작되는 날짜 ( 백엔드에서 전송되는 string 타입 날짜 )
      uthSeq: 0, // number  => 시험기록 고유 seq 번호
    },
  ]);

  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });

  const [selectedTest, setSelectedTest] = useState({
    // user
    userName: '', // string  => 학생 이름
    userSeq: 0, // number  => 학생 고유 seq 번호
    // subject
    subjectName: '', // string  => 과목 이름
    subjectSeq: 0, // number  => 과목 고유 seq 번호
    // testSet
    testSetName: '', // string  => 시험 이름
    testSetSeq: 0, // number  => 시험 고유 seq 번호
    testStartDate: '', // string  => 시험이 시작되는 날짜 ( 백엔드에서 전송되는 string 타입 날짜 )
    uthSeq: 0, // number  => 시험기록 고유 seq 번호
  });

  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });
  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);
  // 추가: 선택된 테스트 관리를 위한 state와 ref
  const [selectedCustomSetSeqs, setSelectedCustomSetSeqs] = useState([]);
  const selectedTestInfoRef = useRef({
    pdfData: '',
    editData: {
      testName: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
    deleteData: [],
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();

  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );
  //#endregion //////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** AP 시험 일정 얻어오기 API */
  const getApSchedules = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedTestScheduleList = response.result.testScheduleList;

        setApScheduleList(fetchedTestScheduleList);
      }
    };

    request
      .apGet(`/api/v1/academy/exam/schedule/by-test`, null, successHandler)
      .catch((error) => console.error('tests/scheduled - "/api/v1/academy/exam/schedule/by-test" API 응답 에러', error))
      .finally(() => console.log('데이터 호출 완료'));
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 삭제 버튼 클릭 핸들러 - 데이터 추출 및 삭제 호출 */
  const handleClickDeleteBtn = () => {
    if (selectedCustomSetSeqs.length === 0) {
      setSimpleAlert({
        visible: true,
        modalText: 'Please check the test you want to delete.',
      });
    } else {
      // 선택된 학생들의 uthSeq 추출 - 삭제 데이터로 전달
      const uthSeqStr = selectedTestInfoRef.current.deleteData.map((item) => item.uthSeq).join(',');

      classDeleteClick(uthSeqStr);
    }
  };

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  /** 체크 박스 변경 핸들러 */
  const handleCheckboxChange = (e, item) => {
    e.stopPropagation();

    const isSelected = e.target.checked;

    if (isSelected) {
      // 선택된 테스트 추가
      setSelectedCustomSetSeqs((prev) => [...prev, item.uthSeq]);
      selectedTestInfoRef.current.pdfData = item.testSetSeq;
      selectedTestInfoRef.current.editData = {
        testName: item.testSetName,
        testStartDate: item.testStartDate,
        testEndDate: item.testEndDate,
        studentList: [item], // 학생 정보 저장
      };
      selectedTestInfoRef.current.deleteData = [...selectedTestInfoRef.current.deleteData, item];
    } else {
      // 선택 해제
      setSelectedCustomSetSeqs((prev) => prev.filter((seq) => seq !== item.uthSeq));
      selectedTestInfoRef.current.pdfData = '';
      selectedTestInfoRef.current.editData = {
        testName: '',
        testStartDate: '',
        testEndDate: '',
        studentList: [],
      };
      selectedTestInfoRef.current.deleteData = selectedTestInfoRef.current.deleteData.filter((seq) => seq !== item.uthSeq);
    }

    // 기존 체크박스 state도 업데이트
    const { selectedKeys } = selectCheckBox;
    if (isSelected) {
      setSelectCheckBox({
        selectedKeys: [...selectedKeys, item.uthSeq],
        seqStr: [...selectedKeys, item.uthSeq].join('|'),
      });
    } else {
      const updatedKeys = selectedKeys.filter((key) => key !== item.uthSeq);
      setSelectCheckBox({
        selectedKeys: updatedKeys,
        seqStr: updatedKeys.join('|'),
      });
    }
  };

  /** Table의 시험 일정 클릭시 */
  const handleScheduleTrClick = (item) => setSelectedTest(item);

  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const schedulesListReload = () => {
    getApSchedules();
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate, reloadData]);
  // 데이터 변경시 삭제 데이터 초기화
  useEffect(() => {
    selectedTestInfoRef.current.deleteData = [];
    setSelectedCustomSetSeqs([]);
  }, [apScheduleList]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <>
      <S.ByTestWrap>
        <S.TableContainer className='com_list'>
          <S.TableTopController className='top'>
            <article className='btn_area'>
              <CommonTestBtn className='delete_test_btn' hoverText='Delete' onClick={handleClickDeleteBtn} iconBtn>
                <img src={IconTrashcan2} alt='delete' />
              </CommonTestBtn>
            </article>
            <article className='search_area'>
              <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
              <div
                className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                onClick={() =>
                  setSearchInfo((prev) => {
                    return {
                      ...prev,
                      searchFieldActive: !searchInfo.searchFieldActive,
                    };
                  })
                }
                ref={searchFiledRef}>
                <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                <div className='layer'>
                  <p className='option' onClick={() => searchFiledClick('Name')}>
                    Name
                  </p>
                  <p className='option' onClick={() => searchFiledClick('Test')}>
                    Test
                  </p>
                </div>
              </div>
              <div className='search'>
                <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} />
                <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                  <i className='svg_icon icon_search blue before'></i>
                  SEARCH
                </button>
              </div>
            </article>
          </S.TableTopController>
          <S.Table className='list'>
            <colgroup>
              <col width='38px' />
              <col width='172px' />
              <col width='' />
              <col width='84px' />
              <col width='105px' />
              <col width='105px' />
            </colgroup>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Subject</th>
                <th>Test Name</th>
                <th>Name</th>
                <th>Time</th>
                <th>Start Date</th>
              </tr>
            </thead>
            <tbody>
              {apScheduleList.length > 0 ? (
                apScheduleList.map((item, idx) => {
                  return (
                    <tr className={selectedTest.uthSeq === item.uthSeq ? 'active' : ''} key={`scheduleTr_${idx}`} onClick={() => handleScheduleTrClick(item)}>
                      <td>
                        <input
                          type='checkbox'
                          className='com_chk'
                          id={`schedule_${idx}`}
                          checked={selectCheckBox.selectedKeys.includes(item.uthSeq)}
                          onChange={(e) => handleCheckboxChange(e, item)}
                        />
                        <label htmlFor={`schedule_${idx}`}>&nbsp;</label>
                      </td>
                      <td>{item.subjectName}</td>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>
                          {/* <button onClick={() => uthEditClick(item)}>
                            <i className='svg_icon icon_annotate gray before'>&nbsp;</i>
                          </button> */}
                          {item.testSetName}
                        </p>
                      </td>
                      <td className='alignL'>{item.userName}</td>
                      <td>{isoTimeToDayjs(item.testStartDate).format('hh : mm A')}</td>
                      <td className='no_pad'>{isoTimeToDayjs(item.testStartDate).format('MM / DD / YYYY')}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='7'>
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </S.Table>
          {/* 🧰 급한 QA 요청으로 일단 해당 부분 기능 포기 */}
          {/* <Paging
            totalCount={scheduleInfo.totalCount}
            currentPage={scheduleInfo.currentPage}
            numPerPage={scheduleInfo.numPerPage}
            pagePerBlock={scheduleInfo.pagePerBlock}
            nextPageMove={nextPageMove}
          /> */}
        </S.TableContainer>
        {/* 🧰 급한 QA 요청으로 일단 해당 부분 기능 포기 */}
        {/* <ByTestClassListSection testData={activeTest} setAlertLayerPopup={setAlertLayerPopup} /> */}
      </S.ByTestWrap>
      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
    </>
  );
}

// --- --- ---

const S = {
  ByTestWrap: styled.div`
    display: flex;
    background: #fff;
    gap: 1rem;
    padding: 1.25rem 1rem;
  `,

  TableContainer: styled.div``,
  TableTopController: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search_area {
      /* display: flex; */
      display: none !important; // 🧰 phase1 에서는 검색-페이징 기능은 제외
      gap: 0.5rem;

      .com_select_layer {
        width: 6.25rem;
        height: 2.5rem;

        .selected.search_area_input {
          border-radius: 0.5rem;
          min-width: 6.25rem;
          padding: 0.625rem 0.75rem;
          box-sizing: border-box;
          height: 2.5rem;
        }

        &:after {
          right: 1rem;
        }
      }

      .com_calendar_wrap {
        input {
          width: 13.4375rem;
          border-radius: 0.5rem !important;
          padding-left: 2.5rem;
          background-position: 0.75rem center;
          text-align: right;
          padding-inline: 0.75rem !important;
        }
      }

      .search {
        .search_area_input {
          border-radius: 0.5rem;
          padding: 0.625rem 0.75rem;
          width: 12rem;
          height: 2.5rem;
          box-sizing: border-box;
        }

        .svg_icon {
          margin-right: 0.5rem;
          width: 1.125rem;
          height: 1.125rem;
        }

        .btn_search {
          min-height: 2.5rem;
        }
      }
    }

    .delete_test_btn {
      width: 2rem;
      height: 2rem;
    }
  `,
  Table: styled.table`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    white-space: nowrap;

    .no_pad {
      padding: 0;
      padding-inline: 0 !important;
    }
  `,
};
