import styled from 'styled-components';

import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

//////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
// 상수 그룹화
const SETTINGS = {
  MULTIPLE_CHOICE: [
    { label: 'Single-select', value: 'single' },
    { label: 'Multi-select', value: 'multiple' },
  ],
  CORRECT_ANSWERS: [
    { label: 'Select', value: null },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
  ],
  DEFAULT_CHOICE: [{ label: 'Text Input', value: 'Text Input' }],
};
////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 답안 선택 설정을 위한 팝업 컴포넌트 */
export default function AnswerChoiceSettingPopup({ isOpen, pos = 'left', answerChoiceInfo, setAnswerChoiceInfo }) {
  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** 현재 선택된 모드가 single 인지 */
  const isSingleSelect = answerChoiceInfo.currentMode === 'single';
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 모드 업데이트 - 단일정답/다중정답 */
  const handleUpdateMode = (mode) => setAnswerChoiceInfo((prev) => ({ ...prev, currentMode: mode }));
  /** 답안 선택지 개수 조절 */
  const handleNoOfAnswerChoices = (isIncrease) => {
    setAnswerChoiceInfo((prev) => {
      let newAnswerChoices = [];

      if (isIncrease) {
        // 최대 갯수 5개 까지만
        if (prev.answerChoices.length >= 5) return prev;
        newAnswerChoices = [
          ...prev.answerChoices,
          {
            id: prev.answerChoices.length + 1,
            choiceType: 'Text Input',
            content: '',
          },
        ];
      } else {
        newAnswerChoices = prev.answerChoices.slice(0, -1);
      }

      return {
        ...prev,
        answerChoices: newAnswerChoices,
      };
    });
  };
  /** answerChoice의 입력 타입 데이터 업데이트 - 현재는 Text Input 만 지원 */
  const updateAnswerChoiceType = (index, value) => {
    setAnswerChoiceInfo((prev) => ({
      ...prev,
      answerChoices: prev.answerChoices.map((choice, i) => (i === index ? { ...choice, choiceType: value } : choice)),
    }));
  };
  /** 정답 데이터 업데이트 */
  const updateCorrectAnswer = (index, value) => {
    if (isSingleSelect) {
      setAnswerChoiceInfo((prev) => ({ ...prev, single: { ...prev.single, correctAnswer: [value] } }));
    } else {
      setAnswerChoiceInfo((prev) => {
        const newCorrectAnswers = [...prev.multiple.correctAnswer];
        newCorrectAnswers[index] = value;
        return { ...prev, multiple: { ...prev.multiple, correctAnswer: newCorrectAnswers } };
      });
    }
  };
  /** 증가/감소를 받아 답안 선택지 개수를 조절하는 함수 */
  const handleChoicesCount = (isIncrease) => {
    if (isIncrease) {
      handleNoOfAnswerChoices(true);
    } else {
      handleNoOfAnswerChoices(false);
    }
  };
  /** 증가/감소를 받아 정답 개수를 조절하는 함수 (다중 선택 모드 전용) */
  const handleCorrectAnswerCount = (isIncrease) => {
    setAnswerChoiceInfo((prev) => {
      const newCorrectAnswers = [...prev.multiple.correctAnswer];
      if (isIncrease) {
        newCorrectAnswers.push(null);
      } else {
        newCorrectAnswers.pop();
      }
      return { ...prev, multiple: { ...prev.multiple, correctAnswer: newCorrectAnswers } };
    });
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- 랜더링 보조 --- START ////////////////////
  if (!isOpen) return null;
  ////////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Container $pos={pos}>
      <div className='popup_header'>
        <h2>Answer Choice Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>Multiple choice type</span>
          <ApSettingSelectBox options={SETTINGS.MULTIPLE_CHOICE} onSelect={(value) => handleUpdateMode(value)} />
        </div>

        <div className='divider' />

        <div>
          <div className='control_row has_inner_row'>
            <span className='label'>No. of Answer Choices</span>
            <ApSettingStepper
              number={answerChoiceInfo.answerChoices.length}
              onIncrease={() => handleChoicesCount(true)}
              onDecrease={() => handleChoicesCount(false)}
            />
          </div>

          <div className='inner_row'>
            {answerChoiceInfo.answerChoices.map((_, index) => (
              <div className='control_row' key={index}>
                <span className='label'>Choice {index + 1}</span>
                <ApSettingSelectBox
                  options={SETTINGS.DEFAULT_CHOICE}
                  onSelect={(value) => updateAnswerChoiceType(index + 1, value)}
                  defaultValue={answerChoiceInfo.answerChoices[index].choiceType}
                />
              </div>
            ))}
          </div>
        </div>

        <div className='divider' />

        {isSingleSelect ? (
          <div className='control_row'>
            <span className='label'>Correct answer</span>
            <ApSettingSelectBox
              className='correct_answer_select'
              options={SETTINGS.CORRECT_ANSWERS.slice(0, answerChoiceInfo.answerChoices.length + 1)}
              onSelect={(value) => updateCorrectAnswer(0, value)}
              defaultValue={answerChoiceInfo.single.correctAnswer[0]}
            />
          </div>
        ) : (
          <div>
            <div className='control_row has_inner_row'>
              <span className='label'>No. of Correct Answer</span>
              <ApSettingStepper
                number={answerChoiceInfo.multiple.correctAnswer.length}
                onIncrease={() => handleCorrectAnswerCount(true)}
                onDecrease={() => handleCorrectAnswerCount(false)}
              />
            </div>

            <div className='inner_row'>
              {answerChoiceInfo.multiple.correctAnswer.map((_, index) => (
                <div className='control_row' key={index}>
                  <span className='label'>Correct Answer {index + 1}</span>
                  <ApSettingSelectBox
                    className='correct_answer_select'
                    options={SETTINGS.CORRECT_ANSWERS}
                    onSelect={(value) => updateCorrectAnswer(index, value)}
                    defaultValue={answerChoiceInfo.multiple.correctAnswer[index]}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const getPopupPosition = (pos) => {
  switch (pos) {
    case 'left':
      return `
        right: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          right: -3%;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      `;
    case 'right':
      return `
        left: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          top: 50%;
          transform: translateY(-50%);
        }
      `;
    case 'top':
      return `
        bottom: 116%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          bottom: -6%;
          left: 50%;
          transform: translateX(-50%) rotate(270deg);
        }
      `;
    case 'bottom':
      return `
        top: 116%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          top: -6%;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      `;
    default:
      return `
        left: 116%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          transform: translateY(-50%);
        }
      `;
  }
};

const S = {
  Container: styled.div`
    position: absolute;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));
    z-index: 1000;
    ${({ $pos }) => getPopupPosition($pos)}

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      color: #ffffff;
    }
  `,
  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      &.has_inner_row {
        margin-bottom: 0.5rem;
      }

      .correct_answer_select {
        width: 6rem;
      }
    }

    .inner_row {
      background-color: #fafafa;
      padding: 0.625rem 0.75rem;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,
};
