import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
//components
import Footer from 'layout/Footer'; //Footer
import Gnb from 'layout/Gnb'; //Gnb
import Lnb from 'layout/Lnb'; //Lnb
import request from 'utils/Request.utils';

import AcademyDashboardPage from 'components/academy/dashboard/Page';
import DashboardPage from 'components/dashboard/Page';
import TutorListPage from 'components/tublet/tutor/Page';
import Login from 'components/user/sign-in/Page';

const TubletRoute = () => {
  const { pathname } = useLocation();
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname);

  console.log('pathname', pathname);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        { path: '/', element: <Navigate to='/tublet/dashboard' /> },
        { path: '/dashboard', element: <AcademyDashboardPage /> },
        { path: '/tutor', element: <TutorListPage /> },

        { path: '/tutor/dashboard', element: <DashboardPage /> },

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  const renderLnb = () => {
    // 경로에 따른 Lnb 노출 설정
    if (pathname.toLowerCase().startsWith('/tublet/tutor')) {
      return <Lnb />;
    }
    return null;
  };

  return (
    <>
      {renderLnb()}
      <Gnb />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default TubletRoute;
