import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { nvl } from 'utils/Common.utils.js';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import LocalStorage from 'utils/LocalStorage.utils.js';
import request from 'utils/Request.utils.js';
import SessionStorage from 'utils/SessionStorage.utils.js';

import useUserLevels from 'hooks/useUserLevels';

// component
import CustomAlert from 'components/_common/alerts/CustomAlert';

import ProfilePopup from 'components/_common/modals/PopupProfile';
import { clearReduxStateSetTests } from 'reducers/setTests/action.js';
import OtherUserId from './_OtherUserId';

const AcademyInformation = ({ info, userLevel, student, handleClickCheckbox, userInfo, viewProfile }) => {
  const dispatch = useDispatch();
  const [idLayer, setIdLayer] = useState(false); //상세 정보 노출 toggle
  const layerToggle = () => {
    setIdLayer((prev) => !prev);
  };
  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  const [deleteAcademy, setDeleteAcademy] = useState(false); //Academy 계정 삭제 팝업
  const [profileState, setProfileState] = useState(false); //프로필 팝업

  const [studentInfo, setStudentInfo] = useState(info);

  const isStudent = useLevels.isStudent ? true : false;
  const isTeacher = useLevels.isTeacher ? true : false;

  const accountInfo = {
    ...(Object.hasOwn(studentInfo, 'companyName') ? { companyName: studentInfo.companyName, ecSeq: studentInfo.ecSeq } : { userName: studentInfo.userName }),
    userSeq: studentInfo.userSeq,
    userLevel: userLevel,
    upFileSeq: studentInfo.upFileSeq,
    academyUpFileSeq: studentInfo.academyUpFileSeq,
  };

  // 학생이 학원 연결 해제
  const disConnection = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        console.log('delete success');
      }
    };

    request.del(
      `/api/member/profile/academy/${userInfo.ecSeq}/student/${info?.mcSeq}/${userInfo.userSeq}?userLevel=${userInfo.userLevel}`,
      null,
      successHandler
    );
  };

  // 삭제 성공 후 버튼
  const logout = () => {
    if (userInfo.userId) request.post('/api/member/logout', { userId: userInfo.userId }, () => console.log('logout'));

    SessionStorage.clearItem('windowManager');
    LocalStorage.clearItem('managerSession');
    LocalStorage.clearItem('userSession');
    LocalStorage.clearItem('sendParams');

    dispatch({ type: 'clearCustomer' });
    dispatch({ type: 'clearExamInfo' });
    dispatch({ type: 'clearExamComponents' });
    dispatch({ type: 'clearMenuInfo' });
    dispatch(clearReduxStateSetTests());

    window.location.href = '/signin';
  };

  const handleClickRemove = () => {
    if (isStudent || isTeacher) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          alertType: 'confirm',
          visible: true,
          activateMode: 'deleteAcademy',
          alertMessage: 'Are you sure you want to delete Academy?',
        };
      });
    } else setDeleteAcademy(true);
  };

  // 삭제 확인 모달
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (alertLayerPopup.activateMode === 'deleteAcademy') {
        setDeleteAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: `You have successfully removed link with ${info.companyName}. Please sign in again.`,
          };
        });
        disConnection();
      }
    }
  };
  // 삭제 완료 모달
  const deletionCompleteAlertAttribute = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [deleteAlertLayerPopup, setDeleteAlertLayerPopup] = useState(deletionCompleteAlertAttribute);
  const closeDeleteAlert = () => {
    setDeleteAlertLayerPopup((prev) => {
      return { ...deletionCompleteAlertAttribute };
    });
    logout();
  };

  return (
    <>
      <div className={`id_manage ${idLayer ? 'active' : ''}`}>
        <article className='id_sec'>
          <div onClick={layerToggle} className='id_wrap'>
            <OtherUserId accountInfo={accountInfo} />
          </div>
          {!useLevels.isParent && ( // 부모는 학생 삭제 불가능
            <div className='btn_more'>
              <i className='svg_icon icon_more gray'>&nbsp;</i>
              <div className='layer'>
                <button className='com_btn' onClick={handleClickRemove}>
                  <i className='svg_icon icon_trashcan2 gray'>&nbsp;</i>Remove
                </button>
              </div>
            </div>
          )}

          {useLevels.isParent && ( // 관리자
            <div className='com_toggle_wrap'>
              <input
                type='checkbox'
                id='btnToggle1'
                onChange={() => handleClickCheckbox(userInfo.userSeq, info.fhSeq)}
                checked={info.activateYn === 'Y' ? true : false}
              />
            </div>
          )}
          {isStudent && ( // 학생
            <div className='com_toggle_wrap'>
              <input
                style={{ display: 'none' }} // 추후 멀티 기능 도입시 style 삭제
                type='checkbox'
                id='btnToggle1'
                onChange={() => handleClickCheckbox(userInfo.userSeq, info.mcSeq)}
                checked={info.activateYn === 'Y' ? true : false}
              />
            </div>
          )}
          {isTeacher && ( // 선생님
            <div className='com_toggle_wrap'>
              <input
                type='checkbox'
                id='btnToggle1'
                onChange={() => handleClickCheckbox(userInfo.userSeq, info.mcSeq)}
                checked={info.activateYn === 'Y' ? true : false}
              />
            </div>
          )}

          {viewProfile && (
            //tutor, parent>Child information : child 정보
            <button className='com_btn s line btn_profile' onClick={() => setProfileState(true)}>
              View Profile
            </button>
          )}
        </article>
        <article className='information_sec'>
          <ul className='input_area'>
            <li className='item'>
              <div className='tit_field'>Linked Date</div>
              <div className='input_field'>
                <input type='text' defaultValue={isoTimeToDayjs(info.regDate).format('MM / DD / YYYY')} readOnly />
              </div>
            </li>

            {student ? (
              //parent>child Information : student 정보
              <>
                <li className='item'>
                  <div className='tit_field'>Email</div>
                  <div className='input_field'>
                    <input type='text' defaultValue={info.userEmail} readOnly />
                  </div>
                </li>
                <li className='item'>
                  <div className='tit_field'>Phone</div>
                  <div className='input_field'>
                    <input type='text' defaultValue={info.userMobile} readOnly />
                  </div>
                </li>
              </>
            ) : (
              //academy, tutor 정보
              <>
                <li className='item'>
                  <div className='tit_field'>{info.uniqueCode[0] === 'A' ? 'Academy code' : 'Tutor Code'}</div>
                  <div className='input_field'>
                    <input type='text' defaultValue={info.uniqueCode} readOnly />
                  </div>
                </li>
                <li className='item'>
                  <div className='tit_field'>Office Number</div>
                  <div className='input_field'>
                    <input type='text' defaultValue={info.officeNumber} readOnly />
                  </div>
                </li>
              </>
            )}
          </ul>
        </article>
      </div>

      <hr className='divider' />

      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={studentInfo} />}

      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      )}
      {deleteAlertLayerPopup.visible && (
        <CustomAlert
          onClose={closeDeleteAlert}
          alertType={deleteAlertLayerPopup.alertType}
          alertMessage={deleteAlertLayerPopup.alertMessage}
          returnValue={deleteAlertLayerPopup.returnValue}
        />
      )}
    </>
  );
};

export default AcademyInformation;
