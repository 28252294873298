import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import ReactQuill from 'react-quill-new';
import Quill from 'quill'; // Quill 객체 가져오기
// import ImageResize from 'quill-image-resize-module'; // 모듈 가져오기
// Quill.register('modules/imageResize', ImageResize); // 모듈 등록
import 'react-quill-new/dist/quill.snow.css'; // Quill 에디터 스타일
import 'mathquill/build/mathquill.css'; // MathQuill 스타일
import 'katex/dist/katex.min.css';
import request from 'utils/Request.utils';
const userInfo = request.tokenDecoder();

/** "Quill" 을 사용하여 구현 한 텍스트 에디터 컴포넌트 */
export default function ReactQuillEditor({ editorContentState, setEditorContentState, className }) {
  const quillRef = useRef(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('uploadFiles', file); // 파일을 FormData에 추가
        const successHandler = (response) => {
          console.log('🚀 ~ fileTest ~ response:', response);
          const imageUrl = response.result.uploadImagesUrl;
          const editor = quillRef.current.getEditor(); // Quill 인스턴스 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, 'image', imageUrl); // Quill에 이미지 삽입
        };
        const errorHandler = (error) => {
          console.error('🚀 ~ fileTest ~ error:', error);
        };
        request.postMultipart(`/api/common/upload/images/teacher/${userInfo.userSeq}`, formData, successHandler, errorHandler);
      }
    };
  };
  // Quill 모듈 설정
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }], // 제목 스타일
        ['bold', 'italic', 'underline', 'strike'], // 텍스트 스타일
        [{ list: 'ordered' }, { list: 'bullet' }], // 목록
        [{ indent: '-1' }, { indent: '+1' }], // 들여쓰기
        [{ align: [] }], // 정렬
        ['formula'], // 수식 입력 버튼 추가
        ['clean'], // 서식 제거
        ['table'],
        ['image', 'link'],
      ],
      handlers: {
        // image: handleImageUpload, // 이미지 버튼 클릭 시 커스텀 핸들러 호출
      },
    },
  };

  // 지원하는 포맷 설정
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'indent',
    'align',
    'formula', // 수식 포맷 추가
    'table',
    'image',
    'link',
  ];
  useEffect(() => {
    // DOM이 준비된 후 에디터 렌더링
    // setIsEditorReady(true);
    setInterval(() => {
      setIsEditorReady(true);
    }, 500);
  }, []);

  if (!isEditorReady) {
    return <></>;
  }
  return (
    <S.Wrap className={className}>
      <ReactQuill ref={quillRef} theme='snow' modules={modules} formats={formats} value={editorContentState} onChange={setEditorContentState} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
  `,
};
