import { useState } from 'react';
import styled from 'styled-components';

import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';

import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgIconTrash } from 'assets/img/svg/icon_trash_01.svg';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import TubletDeleteModal from './_components/TubletDeleteModal';
import PopBack from 'components/_common/popups/_PopBack';
import AddTutorAccount from '../../modals/AddTutorAccount';

let alertAttributeValue = {
  visible: false,
  alertMessage: '',
  alertType: 'alert',
  returnValue: () => {},
  id: '',
};

/** student main - Table_Header_버튼영역 */
export default function TopBtnArea({ checkedTutor, setCheckedTutor, tutorListReload, selectedTutor, setEditTutorModal }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  /** 튜터 삭제 로딩 State */
  const [isLoadingDeleteTutor, setIsLoadingDeleteTutor] = useState(false);
  // 학원의 학생 회원가입 모달 관련
  const [isBack, setBack] = useState(false);
  const [showAddTutorModal, setShowAddTutorModal] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  // 삭제 모달 State
  const [deleteModalInfo, setDeleteModalInfo] = useState({
    visible: false,
    checkedTutorLength: 0,
    callback: () => {},
  });

  // 삭제 팝업 열기
  const handleDeleteModalOpen = () => {
    if (userInfo.userLevel !== USER_LEVELS.TUBLET_ADMIN) return;

    if (checkedTutor.length > 0) {
      setDeleteModalInfo({ visible: true, checkedTutorLength: checkedTutor.length, callback: deleteTutor });
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check at least one tutor to delete.',
        };
      });
    }
  };

  // 알림창 닫기
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  // 수정 창
  const setEditPopup = () => {
    if (userInfo.userLevel !== USER_LEVELS.TUBLET_ADMIN) return;

    if (checkedTutor.length > 1 || checkedTutor.length === 0) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'You can only edit one tutor at a time.',
        };
      });
    }

    if (checkedTutor.length === 1) {
      setEditTutorModal(true);
    }
  };

  // 삭제 api 호출
  const deleteTutor = () => {
    setIsLoadingDeleteTutor(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        setDeleteModalInfo((prev) => ({ ...prev, visible: false }));
        tutorListReload();
        setCheckedTutor([]);
      }

      setIsLoadingDeleteTutor(false);
    };

    request.del(`/api/v2/tublet/tutor/${checkedTutor.join(',')}`, null, successHandler).catch((error) => {
      console.error('TubletDeleteModal 삭제 API 에러', error);
      setIsLoadingDeleteTutor(false);
    });
  };

  return (
    <>
      <S.Wrap className='btn_area'>
        <button type='button' title='Add Student' className='btn_add_student' onClick={() => setShowAddTutorModal(true)}>
          <ImgSvgIconPlus />
          Add Tutor
        </button>
        <MiniSvgIconBtn text='Edit' svgClassName='svg_icon icon_write' onClick={setEditPopup} radius='medium' dataHover />
        <button type='button' title='Delete' onClick={handleDeleteModalOpen} className='btn_icon'>
          <ImgSvgIconTrash />
        </button>
      </S.Wrap>

      {/* 🪟 student main - 학생 회원가입 모달 🪟 */}
      {showAddTutorModal && <AddTutorAccount setBack={setBack} setShowAddTutorModal={setShowAddTutorModal} callback={tutorListReload} />}
      {isBack && <PopBack closePopBack={() => setBack(false)} confirmCallback={() => setShowAddTutorModal(false)} />}
      {/* 튜블릿 튜터 삭제 팝업 */}
      <TubletDeleteModal
        deleteModalInfo={deleteModalInfo}
        closeModal={() => setDeleteModalInfo((prev) => ({ ...prev, visible: false }))}
        isLoading={isLoadingDeleteTutor}
      />

      {/* 알림창 */}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {
  Wrap: styled.article`
    button {
      transition: 0.2s;
      &:hover {
        filter: brightness(95%);
      }
    }
    .btn_add_student {
      background-color: #f4f8ff;
      color: #0068bd;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      svg {
        width: 1.125rem;
      }
    }
    .btn_icon {
      border: 1px solid #eaeaea;
      border-radius: 0.5rem;
      width: 2rem;
      height: 2rem;
      transition: 0.2s;
      background-color: #ffffff;
      svg {
        width: 1rem;
        color: #4b5563;
      }
    }
  `,
};
