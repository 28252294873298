import styled from 'styled-components';

import FreeResponseQuestionItem from './_components/QuestionItem';

/** ap/com/score/grading/submission 페이지의 "Free Response Question" 영역 컴포넌트 */
export default function FreeResponseQuestionArea({
  partSeq,
  questionsData = [
    {
      questionNum: 0,
      isAnswered: false,
      score: 0,
    },
  ],
}) {
  return (
    <S.Wrap>
      <ul className='question_list'>
        {questionsData.map((item, index) => {
          return (
            <li key={`key_${index}`}>
              <FreeResponseQuestionItem item={item} partSeq={partSeq} />
            </li>
          );
        })}
      </ul>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    padding: 0 1.25rem;
    .question_list {
      & > li {
        & > button {
          width: 100%;
        }
        & + li {
          border-top: 1px solid #c5ccd2;
        }
      }
    }
  `,
};
