// Packages
import { useEffect, useState } from 'react';
// Components
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import ExamTutorialBody from './_components/examTutorialLayout/examTutorialBody/ExamTutorialBody';
import ExamTutorialFooter from './_components/examTutorialLayout/examTutorialFooter/ExamTutorialFooter';
import Progress from './_components/Progress';

/** 인트로 다음 튜토리얼 페이지 : 시험보는 방법 설명 */
export default function ExamTutorialPage() {
  useEffect(() => {
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener('contextmenu', preventContextMenu);

    return () => {
      window.removeEventListener('contextmenu', preventContextMenu);
    };
  }, []);
  const navigate = useNavigate();

  const [isProgress, setIsProgress] = useState(false);

  const handleNext = () => {
    // localStorage 에서 uthSeq 가져오기
    const currentTest = localStorage.getItemJsonParse('currentTest').uthSeq;
    // 튜토리얼 다음이라 무조건 0번째 인덱스
    const partInfo = localStorage.getItemJsonParse(`apTest_${currentTest}`).testInfo.partInfo[0];

    setIsProgress(true);
    updateTestStatus(currentTest);
    insertQuestion(currentTest, partInfo.partSeq);
  };

  // 시험 상태 업데이트
  const updateTestStatus = (uthSeq) => {
    const params = {
      testStatus: 'I',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        // 부모 창의 함수 호출
        if (window.opener && typeof window.opener.getUpcomingTestList === 'function') {
          window.opener.getUpcomingTestList();
        } else {
          console.error('부모창의 함수를 찾을 수 없습니다.');
        }
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/status`, params, successHandler);
  };

  // 문제 생성
  const insertQuestion = (uthSeq, partSeq) => {
    const params = {
      uthSeq: uthSeq,
      partSeq: partSeq,
    };

    const successHandler = (response) => {
      if (response.code === 201) {
        getQuestion(uthSeq, partSeq);
      }
    };

    request.apPost('/api/v1/student/test', params, successHandler);
  };

  // 문제 가져오기
  const getQuestion = (uthSeq, partSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { sectionName, partName, questionList, totalQuestionCount, totalTime } = response.result.getTestSectionQuestionList;

        const currentKey = `apTest_${uthSeq}`;
        // localStorage.setItem(`apTest_${uthSeq}`, 'sessionOrder', sessionOrder);
        localStorage.setItem(currentKey, 'partSeq', partSeq);
        localStorage.setItem(currentKey, 'sectionName', sectionName);
        localStorage.setItem(currentKey, 'partName', partName);
        localStorage.setItem(currentKey, 'questionList', questionList);
        localStorage.setItem(currentKey, 'totalQuestionCount', totalQuestionCount);
        localStorage.setItem(currentKey, 'currentNum', 1);
        localStorage.setItem(currentKey, 'totalTime', totalTime);
        // 시험 시작 시간 저장
        localStorage.setItem(currentKey, 'startTime', Date.now());

        navigate('/ap/exam/test', { replace: true });
      }
      setIsProgress(false);
    };

    request.apGet(`/api/v1/student/test?uthSeq=${uthSeq}&partSeq=${partSeq}`, null, successHandler);
  };

  // 부모창 upcomingTestSection 업데이트
  useEffect(() => {
    const parentWindow = window.parent;
    if (parentWindow) {
      parentWindow.postMessage('updateUpcomingTestSection', '*');
    }
  }, []);

  return (
    <>
      <div className='com_popup pop_tutorial active'>
        {/*addClass 'active' 시 노출*/}
        <div className='pop_container'>
          <ExamTutorialBody />
          <ExamTutorialFooter handleNext={handleNext} />
        </div>
      </div>
      {isProgress && <Progress />}
    </>
  );
}
