import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import { ReactComponent as ImgSvgIconSearch } from 'assets/img/svg/icon_search.svg';

/** Set Test - Select Test 페이지의 "검색" 영역 컴포넌트 */
export default function SearchArea({ searchInputValue, setSearchInputValue, customSetInfo }) {
  const { setBulkObfuscatedValues } = useObfuscatedSearchParam();

  /** 시험 세트 이름 목록 (시험 세트 목록에서 추출) */
  const [testNames, setTestNames] = useState([]);
  /** 시험 세트 이름 목록 (시험 세트 목록에서 추출) */
  /** 입력창 자동 완성 셀렉트 영역 노출 여부 */
  const [isOpen, setIsOpen] = useState(false);

  /** Form 제출 핸들러 */
  const handleSubmit = (event) => {
    event.preventDefault();
    setBulkObfuscatedValues({ paramValues: { search: searchInputValue } });
  };

  /** 입력창 변경 핸들러 */
  const handleChange = (event) => {
    setSearchInputValue(event.target.value);
    if (event.target.value.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const testNames = customSetInfo.list.map((item) => {
      return item.testSetName;
    });
    setTestNames(testNames);
  }, [customSetInfo]);

  return (
    <S.Wrap>
      <form onSubmit={handleSubmit}>
        <div className='select_box'>
          <label htmlFor='searchKeywords'>Search Keywords</label>
          <input type='text' id='searchKeywords' placeholder='Enter keywords' value={searchInputValue} onChange={handleChange} />
          <ul className={`option_wrap ${isOpen ? 'active' : ''} custom_scroll`}>
            {testNames &&
              testNames
                .filter((item) => {
                  const isMatch = item.toLowerCase().includes(searchInputValue.toLowerCase());
                  return isMatch;
                })
                .map((item, index) => {
                  return (
                    <li className='option' key={`location_${index}`}>
                      <button
                        type='button'
                        onClick={() => {
                          setSearchInputValue(item);
                          setIsOpen(false);
                        }}>
                        {item}
                      </button>
                    </li>
                  );
                })}
          </ul>
        </div>
        <button type='submit' className='search_submit_button'>
          <ImgSvgIconSearch />
          Search
        </button>
      </form>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    form {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-bottom: 0.5rem;
    }

    label {
      overflow: hidden;
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
      line-height: 0;
      padding: 0;
      margin: 0;
    }
    input {
      width: 14.625rem;
      height: 1.25rem;
      box-sizing: content-box;
      padding: 0.625rem 0.75rem;
      border-radius: 0.5rem;
      border: 1px solid #d2dbe2;
      font-weight: 400;
      font-size: 0.875rem;
    }
    .search_submit_button {
      height: 1.25rem;
      box-sizing: content-box;
      font-weight: 400;
      font-size: 0.875rem;
      color: #0068bd;
      background-color: #f4f8ff;
      border-radius: 0.5rem;
      padding: 0.65rem 0.95rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      svg {
        width: 1.125rem;
      }
      &.active {
        font-weight: 600;
        color: #0068bd;
        border-color: rgba(0, 104, 189, 1);
      }
    }

    .select_box {
      position: relative;
      .option_wrap {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
        width: 100%;
        max-height: 9.375rem;
        overflow-y: auto;
        border-bottom: 1px solid #d2dbe2;
        border-radius: 0.25rem;
        box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.1490196078);
        margin-top: 1px;
        text-align: left;
        display: none;
        &.active {
          display: block;
        }
        .option {
          font-size: 0.875rem;
          line-height: 0.75rem;
          border-bottom: 1px solid #d2dbe2;
          background-color: #fff;
          button {
            width: 100%;
            padding: 0.75rem 0.75rem;
            background-color: none;
            text-align: left;
            &:focus {
              border: 1px solid #0068bd;
            }
          }
        }
      }
    }
  `,
};
