import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import useOutsideClick from 'hooks/useOutsideClick';

import CollapsibleList from 'components/com/tests/_components/_common/_CollapsibleList/_CollapsibleList';
import CollapsibleContentItemContainer from 'components/com/tests/_components/_common/_CollapsibleList/CollapsibleContentItemContainer';
import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';
import { ReactComponent as ImgSvgTrashcan3 } from 'assets/img/svg/icon_trashcan_3.svg';

/** ByClass에서 클래스 별 테스트 리스트 컴포넌트 - Class 단위 */
export default function ByClassListSection({ testData, setAlertLayerPopup, handleEditTest, classDeleteClick }) {
  const [isShowPdfPopup, setIsShowPdfPopup] = useState(false);
  const [selectedCustomSetSeqs, setSelectedCustomSetSeqs] = useState([]);
  const [selectedClassInfo, setSelectedClassInfo] = useState({
    0: {
      headerInfo: {
        classSeq: 0,
        className: '',
        customSetSeq: 0,
        testName: '',
        testStartDate: '',
        testEndDate: '',
        customSetSeqStr: '',
      },
      contentInfo: [
        {
          userName: '',
          userSeq: 0,
          upFileSeq: 0,
          testDate: '',
          uthSeq: 0,
        },
      ],
    },
  });
  /** PDF 팝업 내 버튼 클릭 핸들러 */
  const handleViewBtnClick = (showAnswer) => {
    const selectedClassInfoList = Object.values(selectedClassInfo)[0];
    setIsShowPdfPopup(false);
    window.open(`/test/test/view?viewTest=${encodeURIComponent(selectedClassInfoList.headerInfo.customSetSeqStr)}&showAnswer=${showAnswer}`, '_blank');
  };
  /** PDF 팝업 외부 클릭 핸들러 */
  const viewPdfPopupRef = useRef(null);
  useOutsideClick(viewPdfPopupRef, () => setIsShowPdfPopup(false), isShowPdfPopup);
  const getFormattedDate = (date) => isoTimeToDayjsTZ(date).format('YYYY.MM.DD');

  /** PDF 버튼 클릭 핸들러 - 인쇄 페이지로 이동 ( Test 미선택시 alert ) */
  const handleClickPdfBtn = () => {
    const selectedClassInfoList = Object.values(selectedClassInfo);

    if (selectedClassInfoList.length === 0) {
      setAlertLayerPopup((prev) => ({
        ...prev,
        visible: true,
        alertMessage: 'Please check the test you want to view pdf.',
      }));
    } else if (selectedClassInfoList.length > 1) {
      setAlertLayerPopup((prev) => ({
        ...prev,
        visible: true,
        alertMessage: 'You can only view one pdf at a time.',
      }));
    } else if (selectedClassInfoList.length === 1) {
      setIsShowPdfPopup((prev) => !prev);
    }
  };
  /** 편집 버튼 클릭 핸들러 - 편집 모달 노출 ( Test 미선택시 alert ) */
  const handleClickEditBtn = () => {
    const selectedClassInfoList = Object.values(selectedClassInfo);

    if (selectedClassInfoList.length === 0) {
      setAlertLayerPopup((prev) => ({
        ...prev,
        visible: true,
        alertMessage: 'Please check the test you want to edit.',
      }));
    } else if (selectedClassInfoList.length > 1) {
      setAlertLayerPopup((prev) => ({
        ...prev,
        visible: true,
        alertMessage: 'You can only edit one test at a time.',
      }));
    } else {
      const firstClassInfo = selectedClassInfoList[0];
      const toEditClassInfo = {
        testStartDate: firstClassInfo.headerInfo.testStartDate,
        testEndDate: firstClassInfo.headerInfo.testEndDate,
        customSetSeq: firstClassInfo.headerInfo.customSetSeq,
        classSeq: firstClassInfo.headerInfo.classSeq,
        seqStr: firstClassInfo.headerInfo.seqStr,
        testName: firstClassInfo.headerInfo.testName,
        className: testData.className,
        studentList: firstClassInfo.contentInfo,
      };
      handleEditTest(toEditClassInfo, 'class');
    }
  };
  /** 삭제 버튼 클릭 핸들러 - 삭제 모달 노출 ( Test 미선택시 alert ) */
  const handleClickDeleteBtn = () => {
    const selectedClassInfoList = Object.values(selectedClassInfo);
    if (selectedClassInfoList.length === 0) {
      setAlertLayerPopup((prev) => ({
        ...prev,
        visible: true,
        alertMessage: 'Please check the test you want to delete.',
      }));
    }

    // 해당 class에 속한 학생들의 uth 추출
    else {
      const toDeleteUthSeqs = selectedClassInfoList.flatMap((classInfo) => classInfo.contentInfo).map((content) => content.uthSeq);

      classDeleteClick(toDeleteUthSeqs);
    }
  };

  // 고유 키 생성 함수
  const generateUniqueKey = (item) => {
    return `${item.customSetSeq}_${item.testStartDate}-${item.testEndDate}`; // 시간 정보를 포함한 고유 키 생성
  };

  useEffect(() => {
    setSelectedClassInfo({});
  }, [testData]);

  return (
    <S.ClassTestListSection>
      <div className='class_header'>
        <h2 className='class_header_title'>{testData.className}</h2>

        <div className='class_header_btn_wrap'>
          <S.PdfBtnWrap ref={viewPdfPopupRef}>
            <CommonTestBtn hoverText='Print PDF' onClick={handleClickPdfBtn}>
              PDF
            </CommonTestBtn>

            {isShowPdfPopup && (
              <ul className='test_view_selector' ref={viewPdfPopupRef}>
                <li className='selector_item'>
                  <button onClick={() => handleViewBtnClick('Y')}>With answers</button>
                </li>
                <li className='selector_item'>
                  <button onClick={() => handleViewBtnClick('N')}>Without answers</button>
                </li>
              </ul>
            )}
          </S.PdfBtnWrap>

          <CommonTestBtn hoverText='Edit' onClick={handleClickEditBtn} iconBtn>
            <i className='svg_icon icon_write'>&nbsp;</i>
          </CommonTestBtn>
          <CommonTestBtn hoverText='Delete' onClick={handleClickDeleteBtn} iconBtn>
            <ImgSvgTrashcan3 />
          </CommonTestBtn>
        </div>
      </div>

      <CollapsibleList
        className='class_list_wrap custom_scroll'
        collapsibleListId={testData.classSeq} // input, label의 고유 id 생성을 위한 고유 값
        items={testData.customSetList}
        itemId='customSetSeq'
        expandedContentInfo={{
          contentKey: 'studentList',
          itemId: 'uthSeq',
        }}
        headerSelectable={true}
        contentSelectable={true}
        selectedDataInfo={selectedClassInfo}
        setSelectedDataInfo={setSelectedClassInfo}
        getUniqueKey={generateUniqueKey} // 고유 키 생성 함수 전달
        renderHeader={(itemInfo) => (
          <div className='title_wrapper'>
            <p className='title'>{itemInfo.testName}</p>
            <p className='sub_title'>{`${getFormattedDate(itemInfo.testStartDate)} - ${getFormattedDate(itemInfo.testEndDate)}`}</p>
          </div>
        )}
        renderContent={(studentInfo) => {
          return (
            <CollapsibleContentItemContainer
              userInfo={{
                userName: studentInfo.userName,
                userSeq: studentInfo.userSeq,
                upFileSeq: studentInfo.upFileSeq,
              }}
              title={studentInfo.userName}
              subTitle={isoTimeToDayjsTZ(studentInfo.testDate).format('YYYY.MM.DD hh:mm A')}
            />
          );
        }}
      />
    </S.ClassTestListSection>
  );
}

const S = {
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    max-width: calc(50% - 0.5rem);
    min-width: 0;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn_wrap {
        display: flex;
        gap: 0.5rem;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
  PdfBtnWrap: styled.div`
    position: relative;

    .test_view_selector {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #d2dbe2;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
      background-color: #fff;
      position: absolute;
      left: -5px;
      top: 110%;

      z-index: 1;
      &.to_left {
        left: auto;
        right: 0;
      }

      .selector_item {
        padding: 0.5rem 0.6rem;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid #d2dbe2;
        }

        > button {
          width: 100%;
          white-space: nowrap;
          color: #7f8998;
          font-weight: 400;
        }
      }
    }
  `,
};
