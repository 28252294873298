import { useState, useRef } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import useOutsideClick from 'hooks/useOutsideClick';

import CollapsibleList from 'components/com/tests/_components/_common/_CollapsibleList/_CollapsibleList';
import CollapsibleContentItemContainer from 'components/com/tests/_components/_common/_CollapsibleList/CollapsibleContentItemContainer';
import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';

/** ByTest에 있는 클래스 목록 리스트 */
export default function ByTestClassListSection({ testData }) {
  const [isShowPdfPopup, setIsShowPdfPopup] = useState(false);
  /** PDF 버튼 클릭 핸들러 - 인쇄 페이지로 이동 ( Test 미선택시 alert ) */
  const handleClickPdfBtn = () => {
    setIsShowPdfPopup((prev) => !prev);
  };

  /** PDF 팝업 내 버튼 클릭 핸들러 */
  const handleViewBtnClick = (showAnswer) => {
    setIsShowPdfPopup(false);
    window.open(`/test/test/view?viewTest=${encodeURIComponent(selectedTestInfo.testInfo.customSetSeqStr)}&showAnswer=${showAnswer}`, '_blank');
  };
  /** PDF 팝업 외부 클릭 핸들러 */
  const viewPdfPopupRef = useRef(null);
  useOutsideClick(viewPdfPopupRef, () => setIsShowPdfPopup(false), isShowPdfPopup);
  const getFormattedDate = (date) => isoTimeToDayjsTZ(date).format('YYYY.MM.DD');

  function transformTestData(testData) {
    if (!testData.classMemberList || testData.classMemberList.length === 0) return;
    // classMemberList의 각 클래스 데이터를 처리
    const classInfoList = testData.classMemberList?.map((classGroup) => {
      // 각 클래스 그룹의 첫 번째 항목에서 공통 정보 추출
      const firstMember = classGroup[0];

      return {
        className: firstMember.className,
        customSetSeq: firstMember.customSetSeq,
        classSeq: firstMember.classSeq,
        seqStr: firstMember.seqStr,
        testStartDate: firstMember.testStartDate,
        testEndDate: firstMember.testEndDate,
        studentList: classGroup.map((student) => ({
          userName: student.userName,
          userSeq: student.userSeq,
          upFileSeq: student.upFileSeq,
          testDate: student.testStartDate,
          uthSeq: student.uthSeq,
        })),
      };
    });

    // 최종 형식으로 변환 (전부 동일한 Test 이므로 첫 번째 클래스의 정보 사용)
    const formattedData = {
      testInfo: {
        testName: testData.testName,
        customSetSeqStr: classInfoList[0].seqStr,
        testStartDate: classInfoList[0].testStartDate,
        testEndDate: classInfoList[0].testEndDate,
      },
      // 클래스 별 데이터
      classInfoList,
    };

    return formattedData;
  }

  // 컴포넌트 내에서 사용
  const selectedTestInfo = transformTestData(testData);

  return (
    <S.ClassTestListSection>
      <div className='class_header'>
        <h2 className='class_header_title'>{testData.testName}</h2>

        <S.PdfBtnWrap ref={viewPdfPopupRef}>
          <CommonTestBtn hoverText='Print PDF' onClick={handleClickPdfBtn}>
            PDF
          </CommonTestBtn>
          {isShowPdfPopup && (
            <ul className='test_view_selector to_left'>
              <li className='selector_item'>
                <button onClick={() => handleViewBtnClick('Y')}>With answers</button>
              </li>
              <li className='selector_item'>
                <button onClick={() => handleViewBtnClick('N')}>Without answers</button>
              </li>
            </ul>
          )}
        </S.PdfBtnWrap>
      </div>

      <CollapsibleList
        className='class_list_wrap scroll'
        collapsibleListId={selectedTestInfo?.testInfo.testName} // input, label의 고유 id 생성을 위한 고유 값
        items={selectedTestInfo?.classInfoList}
        itemId='customSetSeq'
        expandedContentInfo={{
          contentKey: 'studentList',
          itemId: 'uthSeq',
        }}
        renderHeader={(itemInfo) => (
          <div className='title_wrapper'>
            <p className='title'>{itemInfo.className}</p>
            <p className='sub_title'>{`${getFormattedDate(itemInfo.testStartDate)} ~ ${getFormattedDate(itemInfo.testEndDate)}`}</p>
          </div>
        )}
        renderContent={(studentInfo) => {
          return (
            <CollapsibleContentItemContainer
              userInfo={{
                userName: studentInfo.userName,
                userSeq: studentInfo.userSeq,
                upFileSeq: studentInfo.upFileSeq,
              }}
              title={studentInfo.userName}
              subTitle={isoTimeToDayjsTZ(studentInfo.testDate).format('MM/DD/YYYY hh:mm A')}
            />
          );
        }}
      />
    </S.ClassTestListSection>
  );
}

// --- --- ---

const S = {
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    min-width: 24.9375rem;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn {
        font-size: 0.875rem;
        font-weight: 700;
        color: #4b5563;
        border: 1px solid #ebebeb;
        background: white;
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
  PdfBtnWrap: styled.div`
    position: relative;

    .test_view_selector {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.15);
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 110%;

      z-index: 1;
      &.to_left {
        left: auto;
        right: 0;
      }

      .selector_item {
        padding: 0.5rem 0.6rem;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid #d2dbe2;
        }

        > button {
          width: 100%;
          white-space: nowrap;
          color: #7f8998;
          font-weight: 400;
        }
      }
    }
  `,
};
