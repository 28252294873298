import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

// 🧰 기획 요청으로 1phase에서는 미사용
// import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon/icon_check_square_01.svg';
// import { ReactComponent as ImgSvgIconHelp } from 'assets/img/svg/icon/icon_help_circle_01.svg';
// import { ReactComponent as ImgSvgIconLogout } from 'assets/img/svg/icon/icon_log_out_01.svg';

// import DropDownButtonWrap from './_components/DropDownButtonWrap';

import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';

/** Grading Submission Header 컴포넌트 */
export default function GradingHeader() {
  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  const [testInfo, setTestInfo] = useState({
    uthSeq: 0,
    subjectName: '',
    testSetName: '',
    studentName: '',
  });

  useEffect(() => {
    const successHandler = (response) => {
      if (response.code === 200) {
        setTestInfo(response.result.testInfo);
      }
    };

    request.apGet(`/api/v1/academy/score/grading/test-info?uthSeq=${uthSeq}`, null, successHandler);
  }, [uthSeq]);

  const leftSection = (
    <S.LeftArea>
      <h1>Grading</h1>
    </S.LeftArea>
  );
  const centerSection = (
    <S.CenterArea>
      <div className='user_name'>{testInfo.studentName}</div>
      <div className='sub_info_wrap'>
        <div>{testInfo.subjectName}</div>
        <div>{testInfo.testSetName}</div>
      </div>
    </S.CenterArea>
  );
  // 🧰 기획 요청으로 1phase에서는 미사용
  // const rightSection = (
  //   <S.RightArea>
  //     <DropDownButtonWrap
  //       title={'More'}
  //       className='more_options'
  //       dropdownChildren={
  //         <ul>
  //           <li>
  //             <button type='button' title='' onClick={() => {}} className=''>
  //               <i className='icon_wrap'>
  //                 <ImgSvgIconHelp />
  //               </i>
  //               Help
  //             </button>
  //             <button type='button' title='' onClick={() => {}} className=''>
  //               <i className='icon_wrap'>
  //                 <ImgSvgIconLogout />
  //               </i>
  //               Save and Exit
  //             </button>
  //             <button type='button' title='' onClick={() => {}} className=''>
  //               <i className='icon_wrap'>
  //                 <ImgSvgIconCheck />
  //               </i>
  //               Complete
  //             </button>
  //           </li>
  //         </ul>
  //       }
  //     />
  //   </S.RightArea>
  // );

  return <ApExamHeaderBase leftSection={leftSection} centerSection={centerSection} />;
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    h1 {
      color: #000;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  CenterArea: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .user_name {
      color: #000;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2rem;
    }
    .sub_info_wrap {
      margin-top: 0.75rem;
      color: #000;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      display: flex;
      & > * {
        & + * {
          position: relative;
          margin-left: 1.5rem;
          &::before {
            content: '';
            display: inline-block;
            width: 0.125rem;
            height: 0.875rem;
            background-color: #eaeaea;
            margin-right: 1.5rem;
          }
        }
      }
    }
  `,

  RightArea: styled.div`
    visibility: hidden; // 🧰 기획 요청으로 인해 완성 전 임시로 숨김 처리

    display: flex;
    gap: 2.5rem;
    .more_options {
      .dropdown_area {
        left: auto;
        right: 0;
        button {
          color: #000;
          font-size: 0.875rem;
          font-weight: 500;
          width: 100%;
          min-width: 10.125rem;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;
          padding: 0.375rem 0.625rem;
          border-radius: 0.3125rem;
          .icon_wrap {
            svg {
              width: 1.5rem;
            }
          }
          &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), #fff;
          }
        }
      }
    }
  `,
};
