import { useEffect, useState } from 'react';
import styled from 'styled-components';

import TestPreviewHeader from './_components/TestPreviewHeader';
import ApExamFooter from '../_components/layout/ApExamFooter';

import ExamAnswerList from './_components/ExamAnswerList';

import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';

const channel = new BroadcastChannel('editor-channel');

/** Preview 페이지 */
export default function TestPreviewPage() {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [selectedAnswer, setSelectedAnswer] = useState(0); // number  => 선택된 답안
  const [undoAnswer, setUndoAnswer] = useState([]); // number[]  => undo 상태인 답안

  const [currentQuestion, setCurrentQuestion] = useState({
    part_seq: 0, // number  => 파트 seq
    section_name: '',
    part_name: '',
    test_name: '',
    subject_name: '',
    // Source
    source_directions: '', // 미사용
    source_order: 0, // string  => source 순서
    source_content: '', // string  => source 내용
    // Question
    question_order: 0, // number  => 실질 문제 순서
    custom_question_order: '', // string  => 유저가 커스텀 한 문제 순서
    question_type: '', // string  => FRQ, MCQ
    question_content: '', // string  => 문제 내용
    score: 0, // number  => 문제 점수
    question_code: '', // string  => 문제 코드
    question_context: '', // 미사용
    // Answer
    answer_options: {
      option1: '',
      total: 0, // options length
      answerCount: 0, // 정답 갯수
    },
    answer: [], // number[]  => 정답 번호
  });
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  useEffect(() => {
    // 컴포넌트가 마운트되면 준비 완료 신호 전송
    channel.postMessage('PREVIEW_WINDOW_READY');

    channel.onmessage = (event) => {
      setCurrentQuestion(event.data.currentQuestion);
    };

    return () => channel.close();
  }, []);

  // const isEssayQuestion = true;
  const isEssayQuestion = false;

  return (
    <S.QuestionBankTestEditorPageWrapper>
      <TestPreviewHeader />
      <S.ExamContainerWrapper className='custom_scroll'>
        <S.ExamContainer className='left'>
          <S.ExamContainerInner>
            <p className='passage'>
              <div
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.source_content?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                }}
              />
            </p>
          </S.ExamContainerInner>
        </S.ExamContainer>

        <S.VerticalDivider />

        <S.ExamContainer className='right'>
          {isEssayQuestion ? (
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea />

                <div className='text'>
                  <p>객관식 문제 질문</p>
                </div>

                <S.SectionTitle>Your answer:</S.SectionTitle>

                <S.EssayQuestionContainer className='custom_scroll'>
                  <p>
                    I have written this text as a sample. I love Gateplus. But come on. Let’s do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let’s do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let’s do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let’s do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz
                  </p>
                </S.EssayQuestionContainer>
              </S.QuestionItem>
            </S.ExamContainerInner>
          ) : (
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={currentQuestion.custom_question_order} />

                <div className='text'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question_content?.replace(
                        '/common/commonImageView.do',
                        `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                      ),
                    }}
                  />
                </div>

                <ExamAnswerList
                  questionOptions={Object.entries(currentQuestion.answer_options)
                    .filter(([key]) => key.startsWith('option'))
                    .sort(([keyA], [keyB]) => {
                      // option{number} 에서 숫자만 추출하여 정렬
                      const numA = parseInt(keyA.replace('option', ''));
                      const numB = parseInt(keyB.replace('option', ''));
                      return numA - numB;
                    })
                    .map(([_, value], index) => ({
                      id: index + 1,
                      choiceType: 'Text Input',
                      content: value,
                    }))}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  undoAnswer={undoAnswer}
                  setUndoAnswer={setUndoAnswer}
                />
              </S.QuestionItem>
            </S.ExamContainerInner>
          )}
        </S.ExamContainer>
      </S.ExamContainerWrapper>
      <ApExamFooter />
    </S.QuestionBankTestEditorPageWrapper>
  );
}

const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  ExamContainerWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  SingleExamContainer: styled.div`
    padding-top: 4.375rem;
    width: 70%;
    margin: 0 auto;

    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }
  `,

  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.left {
      align-items: flex-end;
    }
    &.right {
      align-items: flex-start;
    }
  `,
  ExamContainerInner: styled.div`
    padding: 0 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    // 문제 지문
    .passage {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }
  `,

  QuestionItem: styled.div`
    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,

  EssayQuestionContainer: styled.div`
    padding: 12px 8px;
    background-color: #f1f1f1;
    height: 23.25rem;

    p {
      color: #000000;
      font-family: 'Noto Sans';
      letter-spacing: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
    }
  `,

  /** 수직 구분선 */
  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
  /** 수평 구분선 */
  HorizontalDivider: styled.div`
    height: 2px;
    width: 100%;
    background-color: #767676;
  `,
};
