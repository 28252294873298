import dayjs from 'dayjs';
// 타임존 사용을 위한 기본 플러그인
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const setUserTimezone = (userTimezone) => {
  if (userTimezone !== '') {
    dayjs.tz.setDefault(userTimezone);
  } else {
    // 유저 타임존이 없는 경우 클라이언트 시간대 사용
    console.log('타임존 안세팅!');
    dayjs.tz.setDefault();
  }
};

/** 로컬리이즈 확장 플러그인이 적용된 format 문자열을 return 하는 함수 */
export const getLocalizedFormat = (date, format) => {
  return dayjs(dayjs.tz(date).toDate()).format(format);
};

/** 커스텀 format 확장 플러그인이 적용된 format 문자열을 return 하는 함수 */
export const getCustomParseFormat = (date, currentFormat, updatedFormat) => {
  return dayjs.tz(dayjs(date, currentFormat).toDate()).format(updatedFormat);
};

/** 복잡한 format의 날짜 문자열을 dayjs 객체로 바꿔서 return 하는 함수 */
export const dateStringToDayjs = (dateStr, currentFormat) => {
  return dayjs(dateStr, currentFormat);
};
/** 복잡한 format의 날짜 문자열을 커스텀 타임존이 적용된 dayjs 객체로 바꿔서 return 하는 함수 */
export const dateStringToDayjsTZ = (dateStr, currentFormat) => {
  const dayjsObj = dayjs(dateStr, currentFormat);

  // 타임존 관련 정보를 삭제하고 커스텀 타임존 적용버전으로 return
  return dayjs.tz(dayjsObj.format('YYYY-MM-DDTHH:mm:ss'));
};

/** 유닉스 타임 스탬프값을 지정된 타임존을 적용한 dayjs 객체로 return 하는 함수 */
export const convertTZ = (unixDate, targetTimezone) => {
  const currentDate = dayjs(unixDate);
  /** 커스텀 타임존의 타임 offset */
  const customTzOffset = dayjs.tz().format('Z');
  /** 클라이언트 타임존의 타임 offset */
  const localTzOffset = dayjs().format('Z');

  const customDiff = parseInt(customTzOffset.slice(0, 3)) * 60 + parseInt(customTzOffset.slice(4));
  const localDiff = parseInt(localTzOffset.slice(0, 3)) * 60 + parseInt(localTzOffset.slice(4));
  /** 커스텀 타임존과 클라이언트 타임존의 시간 차이 ( 분 ) */
  const offsetDiff = customDiff - localDiff;

  /** 목표 타임존에 맞게 조정된 날짜 ( Date 객체를 만들때마다 타임존 데이터가 사라져 시간이 적용된 date 객체를 생성함) */
  if (targetTimezone === 'custom') {
    return currentDate.add(offsetDiff, 'minute');
  } else if (targetTimezone === 'local') {
    return currentDate.subtract(offsetDiff, 'minute');
  }
};
/** 타임존 객체를 지정된 타임존을 적용한 dayjs 객체로 return 하는 함수 */
export const convertDayjsTZ = (dayjsObj, targetTimezone, debug = false) => {
  const currentDate = dayjsObj;
  /** 커스텀 타임존의 타임 offset */
  const customTzOffset = dayjs.tz().format('Z');
  /** 클라이언트 타임존의 타임 offset */
  const localTzOffset = dayjs().format('Z');

  const customDiff = parseInt(customTzOffset.slice(0, 3)) * 60 + parseInt(customTzOffset.slice(4));
  const localDiff = parseInt(localTzOffset.slice(0, 3)) * 60 + parseInt(localTzOffset.slice(4));
  /** 커스텀 타임존과 클라이언트 타임존의 시간 차이 ( 분 ) */
  const offsetDiff = customDiff - localDiff;

  if (debug) {
    console.log('currentDate', currentDate.format());
    console.log('offsetDiff', offsetDiff);

    console.log(
      `\n┏━━━ 💡 💡 currentDate.add(offsetDiff, 'minute') 💡 💡 ━━━\n`,
      currentDate.add(offsetDiff, 'minute').format(),
      `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`
    );
    console.log(
      `\n┏━━━ 💡 💡 currentDate.subtract(offsetDiff, 'minute') 💡 💡 ━━━\n`,
      currentDate.subtract(offsetDiff, 'minute').format(),
      `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`
    );
  }

  /** 목표 타임존에 맞게 조정된 날짜 ( Date 객체를 만들때마다 타임존 데이터가 사라져 시간이 적용된 date 객체를 생성함) */
  if (targetTimezone === 'custom') {
    return currentDate.add(offsetDiff, 'minute');
  } else if (targetTimezone === 'local') {
    return currentDate.subtract(offsetDiff, 'minute');
  }
};
/** 클라이언트(유저)의 타임존을 반환하는 함수  */
export const getClientTimezone = () => {
  return dayjs.tz.guess();
};

/** ISO 시간 문자열을 dayjs 객체로 변환하는 함수 ( 받은 iso 형식의 문자열에서 시간을 제거하고 Dayjs객체로 반환 ) */
export const isoTimeToDayjs = (isoStr) => {
  return isoStr ? dayjs(isoStr.slice(0, 19)) : dayjs();
};
/** ISO 시간 문자열을 dayjs 객체로 변환하는 함수 ( 받은 iso 형식의 문자열에서 시간을 제거하고 Dayjs객체로 반환  (타임존 적용 버전) ) */
export const isoTimeToDayjsTZ = (isoStr) => {
  return isoStr ? dayjs.tz(isoStr.slice(0, 19)) : dayjs.tz();
};

/** 시간 문자열을 받아서 시간을 제거한 dayjs 객체로 반환하는 함수 */
export const getDateOnlyDayjs = (dateStr) => {
  return dayjs(dateStr).startOf('day');
};
/** 시간 문자열을 받아서 시간을 제거한 dayjs 객체로 반환하는 함수 (타임존 적용 버전) */
export const getDateOnlyDayjsTZ = (dateStr) => {
  return dayjs.tz(dateStr).startOf('day');
};

export default dayjs.tz;
