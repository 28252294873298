import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
//components
import MiniCalendarSection from 'components/academy/dashboard/_components/MiniCalendarSection';

import request from 'utils/Request.utils';
import Box from './_components/common/Box';
import OuterBox from './_components/common/OuterBox';
import TimeLineSection from './_components/layouts/sections/setTimeLine/TimeLineSection';
import TestList from './_components/layouts/sections/testList/TestList';
import UpcomingTestSection from './_components/layouts/sections/upcomingTest/UpcomingTestSection';
import VisualArea from './_components/layouts/visualArea/VisualArea';
import VisualTextArea from './_components/layouts/visualArea/VisualTextArea';

/** 대시보드 페이지 (Student, Parent 라우트에서 공유하는 페이지) */
export default function ApDashboardPage() {
  useEffect(() => {
    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    getTestList();

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
  }, []);

  const [testList, setTestList] = useState([]);
  const getTestList = () => {
    const successHandler = (response) => {
      setTestList(response.result.tests);
    };

    request.apGet('/api/v1/student/dashboard/test-list', null, successHandler);
  };

  return (
    <>
      <S.Wrap className='p_student_dashboard'>
        <VisualArea />
        <div className='com_center_wrap'>
          <VisualTextArea />
          <div className='dashboard_wrap'>
            <OuterBox className='com_container left'>
              {/*CALENDAR*/}
              <Box className='full'>
                <MiniCalendarSection />
              </Box>
            </OuterBox>
            <OuterBox className='com_container right'>
              <Box className='full upcoming_test_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    Upcoming Test
                  </h2>
                </article>
                <UpcomingTestSection />
              </Box>
              <Box className='full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    AP Timeline
                  </h2>
                </article>
                <TimeLineSection />
              </Box>
            </OuterBox>
            <OuterBox className='full'>
              <Box className='full'>
                <TestList testList={testList} />
              </Box>
            </OuterBox>
            {/* <OuterBox className='full custom_flex'>
              <SelectedTitle selectedTest={selectedTest} setSelectedTest={setSelectedTest} testListAreaPosition={testListAreaPosition} />

              <div className='flex_row'>
                <Box className='scores_chart_section_box'>
                  <OverallAverageScore childSeqState={childSeqState} />
                </Box>
                <Box className='average_weekly_scores_box'>
                  <ScoreTrend currentClassTestData={[...testList].reverse()} />
                </Box>
              </div>
              <Box>
                <div className='com_container full'>
                  <AdvancedReportSection
                    selectedTest={selectedTest}
                    testResult={testResult}
                    barChartData={barChartData}
                    barChartData2={barChartData2}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    setLoading={setLoading}
                    setTestResult={setTestResult}
                    testList={testList}
                    childSeqState={childSeqState}
                  />
                </div>
              </Box>
            </OuterBox> */}
            {/* <OuterBox className='com_container'>
              <DsatInfoArea />
            </OuterBox> */}
          </div>
        </div>
      </S.Wrap>
    </>
  );
}

const S = {
  Wrap: styled.div`
    .full {
      width: 100%;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
      height: 29rem;
    }
    .custom_flex {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .scores_chart_section_box {
      width: calc(100% - 31.25rem);
    }
    .average_weekly_scores_box {
      width: 31.25rem;
    }
  `,
};
