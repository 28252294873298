import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import getCurrentIndex from '../../../_hooks/getCurrentIndex';

import { updateReduxStateSelectedUserList, updateReduxStateTotalGateKey } from 'reducers/setTests/action';

/////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
/** 단위 */
const UNIT = 'Key';
/** 시험 당 (영어 or 수학) 가격 */
const pricePerExam = 1;
/////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

/** 주문 상세 내역 컴포넌트 */
const OrderDetails = ({ isB2c, classSeq, selectedClassName, selectedStudents, selectTest, testOrderInfo }) => {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const dispatch = useDispatch();
  /** "Set Tests 전역 상태" */
  const stateSetTests = useSelector((state) => state.stateSetTests.setTests);
  /** 사용자의 Gate Key 전역 상태 */
  const stateGateKeys = useSelector((state) => state.stateGateKeys);
  const currentIndex = getCurrentIndex();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  //////////////// 컴포넌트에서 사용 할 값 영역 시작 ///////////////////
  //// 이 컴포넌트는 동적 렌더링 컴포넌트가 아니므로, 상태 값을 갱신 할 필요가 없다.
  /** 현재 선택 된 configuration */
  const currentlySelectedConfiguration = stateSetTests.selectedConfig.testConfiguration;
  /** 시험 세트 당 가격(Key) */
  const pricePerSelectedExamSet = currentlySelectedConfiguration == 'F' ? pricePerExam * 2 : pricePerExam;
  /** 선택 된 학생 수 */
  const currentlySelectedStudents = selectedStudents ? selectedStudents.length : stateSetTests.selectedStudents.length;
  /** 선택 된 모듈 갯 수 */
  const currentlySelectedModules = selectTest ? selectTest.length : stateSetTests.selectedModules.length;
  /** 선택 한 모듈 가격 총합 */
  const subTotal = isB2c ? pricePerSelectedExamSet * currentlySelectedModules : pricePerSelectedExamSet * currentlySelectedModules * currentlySelectedStudents;
  /** 유저가 가지고 있는 Key */
  const availableGateKey = stateGateKeys.remainingKey;
  /** 차감 되어야 할 key */
  const toBeDeducted = subTotal;
  /** 차감 되고 남은 유저의 Key */
  const balance = availableGateKey - toBeDeducted;
  /** Review 단계에서 존재해야 하는 선택 항목이 비어있는가? (시험 셋트 목록 or 학생 목록) */
  const isEmpty = isB2c ? currentlySelectedModules <= 0 : Boolean((currentlySelectedModules <= 0) | (currentlySelectedStudents <= 0));
  //////////////// 컴포넌트에서 사용 될 값 영역 끝 ///////////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////
  /** 스크롤 시 addClass */
  const [scroll, setScroll] = useState(false);
  /////////////////// React useState 선언 영역 끝 ////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (nvlNumber(classSeq) !== 0) {
      const successHandler = (response) => {
        if (response.code === 200) {
          dispatch(updateReduxStateSelectedUserList(response.result.studentList));
        }
      };

      request.get(`/api/academy/${userInfo.ecSeq}/classes/${classSeq}/students`, {}, successHandler).catch((error) => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classSeq]);

  useEffect(() => {
    dispatch(updateReduxStateTotalGateKey(subTotal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <div className={`order_detail__wrap ${scroll ? 'fixed' : ''}`}>
      <S.OrderDetail className='order_detail'>
        <h3>ORDER DETAILS</h3>
        <div className='detail_summary_area'>
          {!isB2c &&
            (currentIndex > 0 ? (
              <div id='detailClassInfoArea'>
                <h4>{stateSetTests.selectedUserType}</h4>
                <dl>
                  <dt>
                    {stateSetTests.selectedUserType === 'Student'
                      ? 'Selected student(s)'
                      : nvl(selectedClassName) !== ''
                        ? selectedClassName
                        : stateSetTests.selectedClass.className}
                  </dt>
                </dl>
              </div>
            ) : null)}
          {!isB2c &&
            (currentIndex > 1 ? (
              <div id='detailClassInfoArea'>
                <h4>Student</h4>
                <dl>
                  <dd>
                    <i className='svg_icon icon_person gray'>&nbsp;</i>
                    {nvl(selectedStudents) !== '' ? selectedStudents.length : nvl(stateSetTests.selectedStudents) && stateSetTests.selectedStudents.length}
                  </dd>
                </dl>
              </div>
            ) : null)}
          <>
            <div id='detailTestSetArea'>
              {/* 선택 된 시험 세트 보여주는 영역 */}
              {currentIndex === 3 &&
                selectTest.length > 0 &&
                selectTest.map((item, index) => (
                  <Fragment key={`test_${index}`}>
                    &nbsp;
                    <input type='checkbox' className={`com_chk circle ${isB2c ? 'isB2c' : ''}`} id={`select-test${index}`} checked={false} readOnly />
                    <label htmlFor={`select-test${index}`}>
                      <div className='test_card'>
                        <p className='numbering'>
                          {`Test ${index + 1}`} <span>{item.testSetName}</span>
                        </p>
                        <p className='test_name'>{item.testSetName}</p>
                      </div>
                    </label>
                  </Fragment>
                ))}
            </div>
          </>
          {!isEmpty && (
            <div id='detailSubTotalArea'>
              <dl className='total_area'>
                <dt>Subtotal</dt>
                <dd>
                  {subTotal} {UNIT}
                </dd>
              </dl>
            </div>
          )}
        </div>
      </S.OrderDetail>
    </div>
  );
};

export default OrderDetails;

const S = {};

/** "주문 상세 내역" 컴포넌트의 styled-components */
S.OrderDetail = styled.div`
  background-color: #f4f8ff;
  border-radius: 0.625rem;
  padding: 1rem;
  width: 286px;
  h3 {
    font-weight: 700;
    color: #889cc1;
    letter-spacing: -0.02em;
    text-align: center;
    padding-bottom: 1rem;
    font-size: 0.875rem;
  }
  .detail_summary_area {
    background-color: #fff;
    border-radius: 0.625rem;
    padding: 1.5rem 0.625rem 0.625rem;
    border: 1px solid #becdda;
    padding-inline: 0.625rem;
    color: #314250;
    & > div ~ div {
    }
    h4 {
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 0.375rem;
    }
    dl {
      margin-bottom: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: first baseline;
      color: #314250;
      font-weight: 500;
      dt {
        font-size: 0.875rem;
      }
      dd {
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        text-align: right;
        line-height: 1.8;
        &.member {
          font-size: 0.75rem;
          color: #7f8998;
          font-weight: 500;
          .svg_icon.icon_person {
            margin-right: 0.375rem;
            vertical-align: -2px;
            background-size: 0.875rem auto;
          }
        }
      }
    }
    .com_chk {
      position: relative;
      & + label {
        position: relative;
        padding-left: 0;
        display: block;
        &::before {
          right: 0.625rem;
          top: 0.625rem;
          position: absolute;
          z-index: 2;
        }
        .test_card {
          background-color: #f4f8ff;
          border: 2px solid #f4f8ff;
          border-radius: 0.625rem;
          padding: 0.5rem;
          position: relative;
          width: 100%;
          .numbering {
            color: #818f9b;
            font-size: 0.625rem;
            font-weight: 700;
            margin-bottom: 4px;
          }
          .test_name {
            color: var(--point-color);
            font-weight: 500;
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
          }
          .date {
            color: #314250;
            font-weight: 500;
            font-size: 0.8125rem;
          }
          & + .test_card {
            margin-top: 1rem;
          }
          .start_time {
            font-weight: 500;
            font-size: 0.8125rem;
            .value {
              margin-left: 0.65rem;
            }
          }
        }
      }
      &.isB2c + label::before {
        display: none;
      }
      &.selected-test {
        & + label {
          .test_card {
            border-color: var(--point-color);
          }
        }
      }
    }
    .total_area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 700;
      margin-block: 1rem;
      color: var(--point-color) dt {
        font-weight: 700;
      }
      dd {
        text-align: right;
      }
    }
  }
  &__wrap {
    &.fixed {
      position: sticky;
      top: 96px;
      //bottom:278px;
      z-index: 2;
    }
    .com_btn_wrap.bottom {
      flex-wrap: wrap;
      gap: 1rem;
      .com_btn {
        width: calc((100% - 1rem) / 2);
        &.full {
          width: 100%;
        }
      }
    }
  }

  #detailClassInfoArea {
    h4 {
      text-transform: uppercase;
      color: var(--SubText-color);
    }
    dl {
      dd {
        color: var(--SubText-color);
      }
    }
  }
  #detailTestDifficultyArea {
    border-bottom: 1px solid #c9d5e9;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 0.75rem;
    }
    div {
      flex-direction: column;
      dl {
        display: block;
        text-align: right;
        dt {
          display: inline-block;
          margin-right: 1rem;
        }
        dd {
          display: inline-block;
        }
      }
    }
  }
  #detailModuleArea {
    h4 {
      text-transform: uppercase;
      color: var(--SubText-color);
    }
    dl {
      dt {
        font-size: 0.75rem;
      }
      dd {
        font-size: 0.875rem;
      }
    }
  }
  #detailSubTotalArea {
    dl {
      color: var(--point-color);
      font-weight: 600;
      dt {
        font-size: 1rem;
      }
      dd {
        font-size: 1rem;
      }
    }
  }
  #detailAvailableGateKeyArea {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    &.border-top {
      border-top: 1px solid #c9d5e9;
    }
    h4 {
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    dl {
      gap: 2rem;
      dt {
        font-size: 0.625rem;
        color: var(--SubText-color);
        text-transform: uppercase;
        flex: 1;
        display: flex;
        justify-content: right;
      }
      dd {
        font-size: 0.875rem;
      }
    }
  }
  #detailBalanceArea {
    border-top: 1px solid #c9d5e9;
    padding-top: 1rem;
    dl {
      color: var(--sub-red);
      font-weight: bold;
      dt {
        font-size: 1rem;
        text-transform: uppercase;
      }
      dd {
        font-size: 1rem;
      }
    }
  }
`;
