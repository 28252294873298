import { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';

//components
import Paging from 'layout/Paging'; //페이징

import MainLink from 'components/_common/button/_MainLinks.jsx';

//img
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam.js';
import PopEdit from './_components/popup/PopEdit.jsx';
import SearchArea from './_components/tables/studentsMainTableArea/SearchArea.jsx';
import TopBtnArea from './_components/tables/studentsMainTableArea/TopBtnArea.jsx';
import TutorMainTable from './_components/tables/studentsMainTableArea/TutorMainTable.jsx';

/** tutor 리스트 페이지 */
export default function TutorListPage() {
  const [userInfo, _] = useState(request.tokenDecoder());

  const [tutorInfo, setTutorInfo] = useState({
    tutorList: [],
    filteredTutorList: [],
  });

  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    setSearchField: 'name',
    searchKeyword: '',
  });

  const [pagingInfo, setPagingInfo] = useState({
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  // edit 창 on / off
  const [editTutorModal, setEditTutorModal] = useState(false);

  /** API 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);

  const { getObfuscatedValue } = useObfuscatedSearchParam();

  useEffect(() => {
    getTutorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // tutor 불러 오는 함수
  const getTutorList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const tutorList = response.result.tutors || [];
        setTutorInfo((prev) => ({ ...prev, tutorList: tutorList }));
      }
    };

    request.get(`/api/v2/tublet/tutor?searchField=${searchInfo.setSearchField}&searchKeyword=${searchInfo.searchKeyword}`, null, successHandler);
  };

  // 체크된 tutor seq
  const [checkedTutor, setCheckedTutor] = useState([]);
  const [selectedTutor, setSelectedTutor] = useState(null);

  // 체크한 tutor 의 정보를 리스트에서 필터링
  useEffect(() => {
    if (checkedTutor.length === 1) {
      const filterCheckedTutor = () => {
        return tutorInfo.tutorList.filter((tutor) => checkedTutor.includes(String(tutor.userSeq)));
      };
      // filterCheckedTutor() 결과는 배열이므로, 첫 번째(유일한) tutor 정보만 꺼냄
      const singleTutor = filterCheckedTutor()[0];

      setSelectedTutor(singleTutor);
    } else {
      setSelectedTutor(null);
    }
  }, [checkedTutor, tutorInfo.tutorList]);

  // paging
  const nextPageMove = (value) => {
    setPagingInfo({ ...pagingInfo, currentPage: value });
  };

  const filterTestList = () => {
    return tutorInfo.tutorList.slice((pagingInfo.currentPage - 1) * pagingInfo.numPerPage, pagingInfo.currentPage * pagingInfo.numPerPage);
  };

  useEffect(() => {
    setTutorInfo((prev) => ({ ...prev, filteredTutorList: filterTestList() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingInfo, tutorInfo.tutorList]);

  return (
    <>
      <div className='p_student_list'>
        <MainLink />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              <section className='top'>
                <TopBtnArea
                  checkedTutor={checkedTutor}
                  setCheckedTutor={setCheckedTutor}
                  tutorListReload={getTutorList}
                  selectedTutor={selectedTutor}
                  setEditTutorModal={setEditTutorModal}
                />

                <SearchArea setSearchInfo={setSearchInfo} searchInfo={searchInfo} tutorListReload={getTutorList} setPagingInfo={setPagingInfo} />
              </section>

              <TutorMainTable
                tutorInfo={tutorInfo}
                checkedTutor={checkedTutor} // 체크된 tutor seq
                setCheckedTutor={setCheckedTutor} // 체크된 tutor seq set
                setSelectedTutor={setSelectedTutor} // 선택된 tutor 정보 set
                setEditTutorModal={setEditTutorModal} // edit 창 on / off
                pagingInfo={pagingInfo}
              />
            </div>

            <Paging
              totalCount={tutorInfo.tutorList.length}
              currentPage={pagingInfo.currentPage}
              numPerPage={pagingInfo.numPerPage}
              pagePerBlock={pagingInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
            {/* edit 창 */}
            {editTutorModal && <PopEdit selectedTutor={selectedTutor} setEditTutorModal={setEditTutorModal} callback={getTutorList} />}
          </div>
        </div>
      </div>
    </>
  );
}

const S = {
  Wrap: styled.div``,
};
