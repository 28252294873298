import React from 'react';

// banner img
import StuBanner1 from 'assets/img/banner/student-dashboard/stu_dash_banner_01.jpg';
import StuBanner2 from 'assets/img/banner/student-dashboard/stu_dash_banner_02.jpg';
import StuBanner3 from 'assets/img/banner/student-dashboard/stu_dash_banner_03.jpg';
import StuBanner4 from 'assets/img/banner/student-dashboard/stu_dash_banner_04.jpg';
import StuBanner9 from 'assets/img/banner/student-dashboard/stu_dash_banner_09.jpg';
import StuBanner10 from 'assets/img/banner/student-dashboard/stu_dash_banner_10.png';
import StuBanner11 from 'assets/img/banner/student-dashboard/stu_dash_banner_11.png';

import BoardPreView from 'components/board/_components/_boardPreView';
import SwiperContainer from 'components/_common/package-components/SwiperContainer';

/** 학생 대시보드 페이지의 "DSAT info" 영역 컴포넌트 */
export default function DsatInfoArea() {
  return (
    <>
      <BoardPreView type='dsat' user='student' />
      <SwiperContainer
        containerClass={'banner'}
        itemClass={''}
        bannerContent={[
          {
            link: 'https://blog.gateplus.org/blog-articles/analysis-of-the-december-2024-sat-exam',
            image: StuBanner11,
            visibleTo: 'ALL',
          },
          {
            link: 'https://blog.naver.com/gateplus24/223667130781',
            image: StuBanner9,
            visibleTo: 'B2B',
          },
          {
            link: 'https://product.kyobobook.co.kr/detail/S000213501792',
            image: StuBanner1,
            visibleTo: 'ALL',
          },
          {
            link: 'https://blog.naver.com/gateplus24/223669393442',
            image: StuBanner10,
            visibleTo: 'ALL',
          },
          { link: 'https://www.youtube.com/watch?v=YhTTOtyFlAU', image: StuBanner2, visibleTo: 'ALL' },
          { link: 'https://pf.kakao.com/_prxbCG', image: StuBanner3, visibleTo: 'ALL' },
          { link: 'https://www.gateplus.org', image: StuBanner4, visibleTo: 'ALL' },
        ]}
      />
    </>
  );
}
