import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import request from 'utils/Request.utils';
import { USER_LEVELS } from 'utils/constants';

import Login from 'components/user/sign-in/Page';
import Footer from 'layout/Footer'; //Footer
import Gnb from 'layout/Gnb';
import Lnb from 'layout/Lnb'; //Lnb

import AcademyDashboardPage from 'components/academy/dashboard/Page';
import Profile from 'components/user/profile/Page';

const AcademyRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)
  if (userInfo?.userLevel === USER_LEVELS.STUDENT) {
    window.location.href = '/student/dashboard';

    return;
  } else if (userInfo?.userLevel === USER_LEVELS.PARENT) {
    window.location.href = '/parent/dashboard';

    return;
  } else if (userInfo?.userLevel === USER_LEVELS.TUBLET_TUTOR) {
    window.location.href = 'tublet/tutor/dashboard';

    return;
  }

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        { path: '/', element: <Navigate to='/academy/dashboard' /> },
        { path: '/dashboard', element: <AcademyDashboardPage /> },
        { path: '/profile', element: <Profile /> }, // component 수정해야 함

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  const PathProfile = pathname.toLowerCase().startsWith('/academy/profile');

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathProfile) return <Lnb />;
    else return <></>;
  };

  return (
    <>
      <Gnb />
      {renderLnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default AcademyRoute;
