import { useState } from 'react';
import styled from 'styled-components';
import CollapsibleContentItemContainer from './CollapsibleContentItemContainer';

/**
 * @typedef {Object} CollapsibleListProps
 * @property {Array<any>} [itemList] - 표시할 아이템 목록
 * @property {boolean} [headerSelectable=false] - 헤더 선택 가능 여부
 * @property {boolean} [contentSelectable=false] - 컨텐츠 선택 가능 여부
 * @property {Function} [handleSelectHeader=null] - 헤더 선택 시 호출될 콜백 함수
 * @property {Function} [handleSelectContent=null] - 컨텐츠 선택 시 호출될 콜백 함수
 * @property {Function} [renderHeader=null] - 헤더를 커스텀하게 렌더링하는 함수
 * @property {Function} [renderContent=null] - 컨텐츠를 커스텀하게 렌더링하는 함수
 * @property {String} [itemId] - 아이템의 고유 ID
 * @property {Object} [expandedContentInfo] - 확장된 컨텐츠 정보의 정보 ( 데이터에 접근하는 key 정보 )
 */
export default function CollapsibleListItem({
  itemList,
  listIndex,
  headerSelectable,
  contentSelectable,
  handleSelectHeader = null,
  handleSelectContent = null,
  renderHeader = null,
  renderContent = null,
  itemId,
  expandedContentInfo = {
    contentKey: '',
    itemId: '',
  },
  getUniqueKey = null,
  selectedDataInfo = {},
  setSelectedDataInfo = null,
  maxHeaderCount = null,
  maxContentCount = null,
}) {
  /** 확장된 컨텐츠 노출 여부 */
  const [isShowExpandedContent, setIsShowExpandedContent] = useState(false);

  // 현재 아이템의 고유 키 생성 ( 상위에서 받아온 고유 키 생성 함수 사용 )
  const currentUniqueKey = getUniqueKey ? getUniqueKey(itemList) : null;
  /** 현재 선택된 아이템 배열 */
  const currentSelectedItems = selectedDataInfo[currentUniqueKey]?.contentInfo || [];
  /** 현재 선택된 아이템의 아이디 목록 */
  const selectedItemIds = currentSelectedItems.map((item) => item[expandedContentInfo.itemId]);

  /** 헤더의 선택 상태를 확인하는 함수 */
  const isHeaderSelected = () => {
    const childItems = itemList[expandedContentInfo.contentKey];
    if (!childItems?.length) return false;

    /** 전체 하위 요소의 아이디 목록 */
    const childItemIds = childItems.map((item) => item[expandedContentInfo.itemId]);

    // 전체 요소의 아이디가 모두 선택된 아이디 목록에 포함되어 있는 경우 ( 모든 요소가 선택된 상태인 경우 )
    return childItemIds.every((itemId) => selectedItemIds.includes(itemId));
  };
  /** 하위 요소의 선택 상태를 확인하는 함수 */
  const isContentSelected = (content) => {
    /** 현재 하위 요소의 아이디 */
    const currentItemId = content[expandedContentInfo.itemId];
    // 현재 하위 요소의 아이디가 선택된 아이디 목록에 포함되어 있는 경우
    return selectedItemIds.includes(currentItemId);
  };

  /** Header 선택 핸들러 */
  const handleSelectHeaderItem = (item, isSelected) => {
    // 선택된 상태일 경우 - 선택된 데이터 정보 추가
    if (isSelected) {
      const { [expandedContentInfo.contentKey]: contentList, ...restItemList } = item;
      const targetInfo = {
        headerInfo: restItemList,
        contentInfo: contentList,
      };

      setSelectedDataInfo((prev) => {
        // 최대 선택 가능 개수 체크
        if (maxHeaderCount) {
          const currentSelectedCount = Object.keys(prev).length;

          // 최대 선택 개수를 초과하는 경우
          if (currentSelectedCount >= maxHeaderCount) {
            // 가장 먼저 선택된 항목을 제거하고 새로운 항목 추가
            const entries = Object.entries(prev);
            const [, ...restEntries] = entries;
            const updatedPrev = Object.fromEntries(restEntries);

            return {
              ...updatedPrev,
              [currentUniqueKey]: targetInfo,
            };
          }
        }

        return {
          ...prev,
          [currentUniqueKey]: targetInfo,
        };
      });
    } else {
      setSelectedDataInfo((prev) => {
        // 선택 해제시 해당 클래스 정보 제거
        const { [currentUniqueKey]: _, ...rest } = prev;
        return rest;
      });
    }

    // 커스텀 핸들러 호출
    handleSelectHeader && handleSelectHeader(item, isSelected);
  };
  /** 하위 요소 선택 핸들러 */
  const handleSelectContentItem = (content, isSelected) => {
    setSelectedDataInfo((prev) => {
      // 이미 해당 클래스의 정보가 있는 경우
      if (prev[currentUniqueKey]) {
        if (isSelected) {
          // 최대 선택 가능 개수 체크
          if (maxContentCount) {
            const currentContentCount = prev[currentUniqueKey].contentInfo.length;

            // 최대 선택 개수를 초과하는 경우
            if (currentContentCount >= maxContentCount) {
              // 첫 번째 항목을 제거하고 새로운 항목 추가
              const [, ...restContents] = prev[currentUniqueKey].contentInfo;
              return {
                ...prev,
                [currentUniqueKey]: {
                  ...prev[currentUniqueKey],
                  contentInfo: [...restContents, content],
                },
              };
            }
          }

          // 기존 headerInfo는 유지하고 contentInfo에 항목 추가
          return {
            ...prev,
            [currentUniqueKey]: {
              ...prev[currentUniqueKey],
              contentInfo: [...prev[currentUniqueKey].contentInfo, content],
            },
          };
        } else {
          // 선택 해제된 데이터만 제거
          const updatedContentInfo = prev[currentUniqueKey].contentInfo.filter(
            (prevContent) => prevContent[expandedContentInfo.itemId] !== content[expandedContentInfo.itemId]
          );

          // 남은 데이터가 없으면 해당 데이터 정보 전체 제거
          if (updatedContentInfo.length === 0) {
            const { [currentUniqueKey]: _, ...rest } = prev;
            return rest;
          }

          // 남은 데이터가 있으면 업데이트된 contentInfo 반영
          return {
            ...prev,
            [currentUniqueKey]: {
              ...prev[currentUniqueKey],
              contentInfo: updatedContentInfo,
            },
          };
        }
        // 해당하는 데이터가 없을 경우 ( 새로운 데이터 선택시 )
      } else {
        // 새로운 헤더 추가 시
        const { [expandedContentInfo.contentKey]: _, ...restItemList } = itemList;

        if (isSelected) {
          // 최대 헤더 선택 개수 체크
          if (maxHeaderCount) {
            const currentHeaderCount = Object.keys(prev).length;

            if (currentHeaderCount >= maxHeaderCount) {
              // 가장 먼저 선택된 헤더를 제거
              const entries = Object.entries(prev);
              const [, ...restEntries] = entries;
              const updatedPrev = Object.fromEntries(restEntries);

              return {
                ...updatedPrev,
                [currentUniqueKey]: {
                  headerInfo: restItemList,
                  contentInfo: [content],
                },
              };
            }
          }

          return {
            ...prev,
            [currentUniqueKey]: {
              headerInfo: restItemList,
              contentInfo: [content],
            },
          };
        }
        return prev;
      }
    });

    // 커스텀 핸들러 호출
    handleSelectContent && handleSelectContent(content, isSelected);
  };

  const uniqueHeaderInputId = `${itemList[itemId]}-${listIndex}-checkbox`;
  const uniqueContentInputId = `${itemList[itemId]}-${listIndex}-content`;

  return (
    <S.ListItem key={itemList[itemId]} className={`${isShowExpandedContent ? 'list_item_expanded' : ''}`}>
      <S.Header
        className={`${headerSelectable ? '' : 'no_select_able'}`}
        onClick={() => setIsShowExpandedContent((prev) => !prev)}
        isSelectedItem={isHeaderSelected(itemList)}
        isExpanded={isShowExpandedContent}>
        {headerSelectable && (
          <S.CheckboxWrapper onClick={(e) => e.stopPropagation()}>
            <input
              type='checkbox'
              id={uniqueHeaderInputId}
              checked={isHeaderSelected(itemList)}
              onChange={(e) => handleSelectHeaderItem(itemList, e.target.checked)}
            />
            <label htmlFor={uniqueHeaderInputId} />
          </S.CheckboxWrapper>
        )}
        {renderHeader ? (
          renderHeader(itemList)
        ) : (
          <div className='title_wrapper'>
            <p className='title'>{itemList.title}</p>
            <p className='sub_title'>{itemList.subTitle}</p>
          </div>
        )}
        <S.ExpandIcon isExpanded={isShowExpandedContent}>
          <i className='svg_icon icon_select gray'>&nbsp;</i>
        </S.ExpandIcon>
      </S.Header>

      <S.Content className={`${isShowExpandedContent ? 'expanded custom_scroll' : ''}`}>
        {itemList[expandedContentInfo.contentKey]?.map((contentItem, index) => {
          const inputId = `${contentItem[expandedContentInfo.itemId]}-${index}_${uniqueContentInputId}`;
          return (
            <S.ContentItem className={`${contentSelectable ? '' : 'no_select_able'}`} key={inputId} isSelectedItem={isContentSelected(contentItem)}>
              {contentSelectable && (
                <S.CheckboxWrapper>
                  <input
                    type='checkbox'
                    id={inputId}
                    checked={isContentSelected(contentItem)}
                    onChange={(e) => handleSelectContentItem(contentItem, e.target.checked)}
                  />
                  <label htmlFor={inputId} />
                </S.CheckboxWrapper>
              )}

              <S.ContentItemContainer>
                {renderContent ? (
                  renderContent(contentItem)
                ) : (
                  <CollapsibleContentItemContainer
                    userInfo={{
                      userName: contentItem.expandedItemTitle,
                      userSeq: contentItem.additionalInfo?.userSeq,
                      upFileSeq: contentItem.additionalInfo?.upFileSeq,
                    }}
                    title={contentItem.expandedItemTitle}
                    subTitle={contentItem.expandedItemSubTitle}
                  />
                )}
              </S.ContentItemContainer>
            </S.ContentItem>
          );
        })}
      </S.Content>
    </S.ListItem>
  );
}

const S = {
  ListItem: styled.section`
    display: block;
    margin-bottom: 0.75rem;
    padding-right: 0.125rem;
  `,

  Header: styled.article`
    display: flex;
    height: 3rem;
    border: 1px solid #d2dbe2;
    border-radius: ${({ isExpanded }) => (isExpanded ? '0.25rem 0.25rem 0 0' : '0.25rem')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ isSelectedItem }) => (isSelectedItem ? '#edf7ff' : 'white')};

    &.no_select_able {
      padding-left: 0.75rem;
    }
    .title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .title {
        font-weight: 700;
        width: 6.75rem;
        flex: 1;
      }

      .sub_title {
        color: #6c7685;
        font-size: 0.813rem;
      }
    }
  `,

  CheckboxWrapper: styled.div`
    display: flex;
    width: 3rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    label {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      width: 1rem;
      height: 1rem;

      &:before {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.25rem;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0.3125rem;
        top: 0.125rem;
        width: 0.375rem;
        height: 0.5625rem;
        border: solid #fff;
        border-width: 0 0.125rem 0.125rem 0;
        transform: rotate(45deg);
        opacity: 0;
      }
    }

    input[type='checkbox']:checked + label {
      &:before {
        background: #0068bd;
        border-color: #0068bd;
      }

      &:after {
        opacity: 1;
      }
    }
  `,

  ExpandIcon: styled.div`
    width: 2.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
  `,

  Content: styled.article`
    background: #f8f8f8;
    max-height: 0;
    transition: all 0.3s;
    pointer-events: none;
    overflow: hidden;
    border: 1px solid transparent;

    &.expanded {
      max-height: 30rem;
      pointer-events: auto;
      overflow-y: auto;
      border-radius: 0 0 0.25rem 0.25rem;
      border: 1px solid #d2dbe2;
      border-top: none;
    }
  `,

  ContentItem: styled.div`
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ isSelectedItem }) => (isSelectedItem ? '#edf7ff' : 'white')};
    &.no_select_able {
      padding-left: 0.75rem;
    }
  `,

  ContentItemContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: 0.5rem;
    .expanded_title_wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
    .expanded_sub_title {
      color: #6c7685;
      font-size: 0.875rem;
      padding-right: 0.75rem;
    }
  `,
};
