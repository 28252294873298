import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import request from 'utils/Request.utils';
import { getRestTimeFromLocalStorage } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';
import usePressESC from 'hooks/usePressESC';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

import { ReactComponent as ImgAlertTriangle } from 'assets/img/svg/icon_alert_triangle_02.svg';

/** "수학 시험" 페이지의 "Exit" 팝업 컴포넌트 */
export default function ExitPopup({ isLoading, setIsLoading }) {
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();

  // ESC 키 입력 시 모달 닫기
  usePressESC(() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } }));

  /** 시험 그만 하기 버튼 핸들러 */
  const interruptedExam = () => {
    let params = {
      uthSeq: stateExamInfo.uthSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } });
        if (stateExamComponents.exitExamDisplay && restTime > 1) exitExam();
      }
    };

    if (!isLoading) {
      setIsLoading(true);
      // 중단한 시험 처리
      request.post('/api/exam/interruptedExam', params, successHandler).catch((error) => {
        console.error(error);
        alert('수학 시험 - 시험 그만 하기 버튼 API 응답 실패');
      });
    }
  };

  /** 시험 종료 및 페이지 닫기 실제 동작 */
  const exitExam = () => {
    let params = {
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch({ type: 'clearExamInfo' });
        window.self.close();
        setIsLoading(false);

        if (window.opener) window.opener.location.reload();
      }
    };

    request.put(`/api/exam/userTestHistory/${stateExamInfo.uthSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('수학 시험 - 시험 종료 및 페이지 닫기 실제 동작 API 응답 실패');
    });
  };

  return (
    <SimpleModalBase p='1.25rem' gap='0'>
      <div className='modal_title_container'>
        <button className='modal_title_btn' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } })}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </div>
      <S.ExitPopupContainer>
        <S.Content>
          <S.MainContent>
            <div className='warning-text-container'>
              <S.WarningIcon role='img' aria-label='Warning' title='Warning'>
                <ImgAlertTriangle />
              </S.WarningIcon>
              <div className='warning-text'>
                <h2>Do you really want to exit the exam?</h2>
                <p>
                  If you exit now, you will be graded on what you have solved until this point, <br />
                  and will <strong>NOT</strong> be able to return to the test.
                </p>
              </div>
            </div>

            <S.NoticeBox>
              <p>
                <span className='highlight'>NOTE:</span> All Gate Key that have been used for this test will <strong>NOT BE REFUNDED</strong> <br />
                once you click on "I understand. Exit."
              </p>
            </S.NoticeBox>
          </S.MainContent>
        </S.Content>

        <S.ButtonGroup>
          <button className='red' onClick={interruptedExam}>
            I understand. Exit.
          </button>
          <button onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } })}>Continue the exam</button>
        </S.ButtonGroup>
      </S.ExitPopupContainer>
    </SimpleModalBase>
  );
}

const S = {
  ExitPopupContainer: styled.div`
    background-color: #ffffff;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.25rem;
    padding-top: 0;
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  `,

  WarningIcon: styled.div`
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem;
    border-radius: 50%;
    background-color: #fdf4f4;
    color: #d14443;
  `,

  MainContent: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .warning-text-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .warning-text {
        text-align: center;

        h2 {
          color: #111111;
          font-family: 'Roboto-SemiBold', Helvetica;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
        }

        p {
          color: #505050;
          font-family: 'Roboto-Regular', Helvetica;
          font-size: 0.875rem;
          line-height: 1.25rem;

          strong {
            font-family: 'Roboto-Bold', Helvetica;
            font-weight: 700;
          }
        }
      }
    }
  `,

  NoticeBox: styled.div`
    background-color: #fdf4f4;
    border-radius: 0.625rem;
    padding: 0.75rem;
    text-align: center;

    p {
      color: #505050;
      font-family: 'Roboto-Regular', Helvetica;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .highlight {
      color: #d14443;
      font-weight: 700;
    }
    strong {
      font-weight: 700;
    }
  `,

  ButtonGroup: styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 520px;

    button {
      width: 10.625rem;
      height: 3rem;
      border-radius: 0.375rem;
      border: 1px solid #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;

      &.red {
        background-color: #d14443;
        color: #ffffff;
      }
    }
  `,
};
