import { useEffect, useRef, useState } from 'react';

import useUserLevels from 'hooks/useUserLevels.js';
import { USER_LEVELS } from 'utils/constants.js';
import request from 'utils/Request.utils.js';

import jwt_decode from 'jwt-decode';
import localStorage from 'utils/LocalStorage.utils';

import ProfileImage from 'components/_common/ProfileImage';

// ID에서 사용되는 Profile image section
// Edit 개인 프로필 이미지
function EditPersonalProfileImageSection({ accountInfo, setReloadData }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  /** 토큰 갱신 api */
  const refreshAccessToken = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { accessToken, refreshToken } = response.result;

        localStorage.setItem('userSession', 'accessToken', accessToken);
        localStorage.setItem('userSession', 'refreshToken', refreshToken);
        setUserInfo(jwt_decode(accessToken));
      }
    };

    const userSession = localStorage.getItemJsonParse('userSession');
    request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
      console.log('refresh token error! : ', error);
    });
  };

  const [uploadFile, setUploadFile] = useState();
  const uploadFileRef = useRef();

  const selectUploadFile = () => uploadFileRef.current.click();

  const uploadFileHandler = (e) => {
    let currentValue = e.target.value;

    setUploadFile(e.target.files[0]);
  };

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append('fileCate', 'userProfile');
    formData.append('uploadFiles', uploadFile);

    const successHandler = (response) => {
      if (response.code === 200) uploadFileInit();
    };

    request.postMultipart(`/api/member/profile/upload/userProfile/${accountInfo.userSeq}`, formData, successHandler);
  };

  const uploadFileInit = () => {
    uploadFileRef.current.value = '';

    setUploadFile(null);

    refreshAccessToken();
    setReloadData((prev) => !prev);
  };

  useEffect(() => {
    if (uploadFile != null) submitUploadFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  const profileInfo = {
    userName: userInfo?.userName,
    userSeq: userInfo?.userSeq,
    upFileSeq: userInfo?.upFileSeq,
  };

  return (
    <>
      {/*이미지 수정 버튼*/}
      <input type='file' id='uploadFile' name='files' className='input' ref={uploadFileRef} style={{ display: 'none' }} onChange={uploadFileHandler} />
      <button className='btn_edit_img' onClick={selectUploadFile}>
        <i className='svg_icon icon_camera white'>&nbsp;</i>
        <ProfileImage userInfo={profileInfo} isB2B={useLevels.isAcademy} size='5.625rem' />
      </button>
    </>
  );
}

// Edit 학원 프로필 이미지
function EditAcademyProfileImageSection({ accountInfo, setReloadData }) {
  const [uploadFile, setUploadFile] = useState();
  const uploadFileRef = useRef();

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const selectUploadFile = () => uploadFileRef.current.click();

  const uploadFileHandler = (e) => {
    let currentValue = e.target.value;

    setUploadFile(e.target.files[0]);
  };

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append('fileCate', 'academyProfile');
    formData.append('uploadFiles', uploadFile);

    const successHandler = (response) => {
      if (response.code === 200) uploadFileInit();
    };

    request.postMultipart(`/api/member/profile/upload/academyProfile/${accountInfo.ecSeq}`, formData, successHandler);
  };

  /** 토큰 갱신 api */
  const refreshAccessToken = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { accessToken, refreshToken } = response.result;

        localStorage.setItem('userSession', 'accessToken', accessToken);
        localStorage.setItem('userSession', 'refreshToken', refreshToken);
        setUserInfo(jwt_decode(accessToken));
      }
    };

    const userSession = localStorage.getItemJsonParse('userSession');
    request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
      console.log('refresh token error! : ', error);
    });
  };

  const uploadFileInit = () => {
    uploadFileRef.current.value = '';

    setUploadFile(null);

    refreshAccessToken();
    setReloadData((prev) => !prev);
  };

  useEffect(() => {
    if (uploadFile != null) submitUploadFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  // hook을 사용한 권한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  const profileInfo = {
    academyName: userInfo?.companyName,
    ecSeq: userInfo?.ecSeq,
    upFileSeq: userInfo?.academyUpFileSeq,
  };

  return (
    <>
      {/*이미지 수정 버튼*/}
      <input type='file' id='uploadFile' name='files' className='input' ref={uploadFileRef} style={{ display: 'none' }} onChange={uploadFileHandler} />
      <button className='btn_edit_img' onClick={selectUploadFile}>
        <i className='svg_icon icon_camera white'>&nbsp;</i>
        <ProfileImage userInfo={profileInfo} isB2B={useLevels.isAcademy} isAcademyImg size='5.625rem' />
      </button>
    </>
  );
}

function ViewProfileImageSection({ viewType, accountInfo }) {
  // hook을 사용한 권한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  const academyProfileInfo = {
    academyName: accountInfo.companyName,
    ecSeq: accountInfo.ecSeq,
    upFileSeq: accountInfo.academyUpFileSeq,
  };
  const personalProfileInfo = {
    userName: accountInfo.userName,
    userSeq: accountInfo.userSeq,
    upFileSeq: accountInfo.upFileSeq,
  };

  return (
    <>
      {viewType === 'academy' ? (
        <ProfileImage userInfo={academyProfileInfo} isB2B={useLevels.isAcademy} isAcademyImg size='5.625rem' />
      ) : (
        <ProfileImage userInfo={personalProfileInfo} isB2B={useLevels.isAcademy} size='5.625rem' />
      )}
    </>
  );
}

function PersonalImageSection({ accountInfo, profileType, setReloadData }) {
  // 유저(개인) 프로필 이미지 section
  if (profileType === 'edit') {
    // Edit profile
    return <EditPersonalProfileImageSection accountInfo={accountInfo} setReloadData={setReloadData} />;
  } else {
    // View profile
    return <ViewProfileImageSection viewType='personal' accountInfo={accountInfo} />;
  }
}

/**  */
function AcademyImageSection({ accountInfo, profileType, setReloadData }) {
  // 아카데미(학원) 프로필 이미지 section

  if (profileType === 'edit') {
    // Edit profile
    return <EditAcademyProfileImageSection accountInfo={accountInfo} setReloadData={setReloadData} />;
  } else {
    // View profile
    return <ViewProfileImageSection viewType='academy' accountInfo={accountInfo} />;
  }
}

/** 사용자 유형 문자열 리터럴 */
const memberType = {
  [USER_LEVELS.STUDENT]: 'Student',
  [USER_LEVELS.TEACHER]: 'Academy Teacher', // 선생님
  [USER_LEVELS.TUTOR]: 'Private Tutor', // 강사
  [USER_LEVELS.ACADEMY]: 'Academy', // 학원 관리자
  [USER_LEVELS.PARENT]: 'Parent', // 학부모
  [USER_LEVELS.TUBLET_ADMIN]: 'Company Admin', // 투블릿 관리자
  [USER_LEVELS.TUBLET_TUTOR]: 'Tutor', // 투블릿 강사
};

/** 프로필 좌상단 간략 프로필 영역 컴포넌트 */
export default function ID(props) {
  const { accountInfo } = props;

  /** 현재 로그인 된 사용자 유형 */
  const userType = memberType[accountInfo.userLevel] || 'Unknown';

  /** 아카데미 프로필인지 확인 */
  const isAcademy = userType === memberType[USER_LEVELS.ACADEMY];

  // 멀티프로필 코드 - 다음 버전에 적용
  // const [idActive, setIdActive] = useState(false) //switch account

  // 아카데미에 속하면서 개인 사용자 일때 Academy Admin 외에는 userType
  const displayUserType = isAcademy
    ? (accountInfo.infoMode === 'personal' && isAcademy) || accountInfo.companyName === ''
      ? 'Academy Admin'
      : userType
    : userType;

  return (
    <div className={`id ${props.size}`}>
      {accountInfo.infoMode === 'personal' || accountInfo.companyName === '' ? (
        <PersonalImageSection accountInfo={accountInfo} profileType={props.type} setReloadData={props.setReloadData} />
      ) : (
        <AcademyImageSection accountInfo={accountInfo} profileType={props.type} setReloadData={props.setReloadData} />
      )}
      <div className='info'>
        <p className='type'>{displayUserType}</p>
        {props.type === 'edit' ? ( // 프로필 수정
          <>
            <input
              type='text'
              className='input'
              onChange={accountInfo.userName ? props.handleUserNameChange : props.handleCompanyNameChange}
              value={accountInfo.userName ? accountInfo?.userName : accountInfo?.companyName ? accountInfo?.companyName : ''}
            />
          </>
        ) : (
          <p className='name'>{accountInfo.infoMode === 'personal' ? accountInfo.userName : accountInfo.companyName}</p>
        )}
      </div>
    </div>
  );
}
