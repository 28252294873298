import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import { ReactComponent as ImgSvgLogo } from 'assets/img/svg/logo_gateplus_vertical_01.svg';

/** 아카데미 대시보드의 Students 페이지에서 "Score History" 목록을 인쇄하는 페이지 */
export default function StudentsScoreHistoryPrintPage() {
  /** 현재 userSeq - Route Parameter */
  const { userSeq: encUserSeq } = useParams();
  const userSeq = deobfuscateUrlParam(encUserSeq);

  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurUserName = getObfuscatedValue('userName');

  /** API로 받아 올 "Score History" 데이터 */
  const [scoreHistory, setScoreHistory] = useState([{ class: '', testDate: '', testName: '', rw: null, math: null, totalScore: null }]); // [ { class: '6월 시험 대비반', testDate: '12 / 29 / 2024', testName: 'level test', rw: 500, math: 700, totalScore: 1200 }
  /** 로딩 중 인지 여부 상태 */
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 화면 열고 나서 바로 실행되는 함수
    const successHandler = (response) => {
      if (response.code === 200) {
        let studentList = response.result.scoreHistory;

        setScoreHistory(studentList);
      }
      setIsLoading(false);
    };
    request.get(`/api/member/student/score/history?userSeq=${userSeq}`, null, successHandler).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  }, [userSeq]);

  useEffect(() => {
    document.body.classList.add('blank_page'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('blank_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrap>
      <div className='wrapper'>
        <div className='title_area'>
          <h2 className='main_title'>{queryCurUserName}</h2>
          <p className='sub_title'>Gateplus Academy</p>
        </div>
        <table>
          <thead>
            <tr>
              <th className='col_01'>No.</th>
              <th className='col_02'>Class</th>
              <th className='col_03'>Data Taken</th>
              <th className='col_04'>Test Name</th>
              <th className='col_05'>RW</th>
              <th className='col_06'>Math</th>
              <th className='col_07'>Total </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              scoreHistory.length > 0 ? (
                scoreHistory.map((item, index) => (
                  <tr key={`score_${index}`}>
                    <td className='col_01'>{scoreHistory.length - index}</td>
                    <td className='col_02'>{item.className}</td>
                    <td className='col_03'>{isoTimeToDayjs(item.testTakenEndDate).format('YYYY-MM-DD')}</td>
                    <td className='col_04'>{item.testName}</td>
                    <td className='col_05'>{item.rwScore === 0 ? '-' : item.rwScore}</td>
                    <td className='col_06'>{item.mathScore === 0 ? '-' : item.mathScore}</td>
                    <td className='col_07'>{item.rwScore + item.mathScore}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>목록이 존재하지 않습니다.</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={7}>Loading...</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {!isLoading ? (
              <tr>
                <td className='col_01'></td>
                <td className='col_02'></td>
                <td className='col_03'></td>
                <td className='col_04'>Average</td>
                <td className='col_05'>
                  {Array.isArray(scoreHistory) && scoreHistory.length > 0
                    ? Math.ceil(
                        scoreHistory.reduce((accumulator, currentObject) => {
                          return accumulator + currentObject.rwScore;
                        }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                      )
                    : 0}
                </td>
                <td className='col_06'>
                  {Array.isArray(scoreHistory) && scoreHistory.length > 0
                    ? Math.ceil(
                        scoreHistory.reduce((accumulator, currentObject) => {
                          return accumulator + currentObject.mathScore;
                        }, 0) / scoreHistory.filter((S) => S.mathScore > 0).length
                      )
                    : 0}
                </td>
                <td className='col_07'>
                  {Array.isArray(scoreHistory) && scoreHistory.length > 0
                    ? Math.ceil(
                        scoreHistory.reduce((accumulator, currentObject) => {
                          const totalScore = currentObject.rwScore + currentObject.mathScore;
                          return accumulator + totalScore;
                        }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                      )
                    : 0}
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={7}>Loading...</td>
              </tr>
            )}
          </tfoot>
        </table>
        <div className='logo_area'>
          <ImgSvgLogo />
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    min-height: 100vh;
    .wrapper {
      width: 210mm;
      margin: auto;
    }
    .title_area {
      padding: 2rem 1.25rem;
      text-transform: uppercase;
      text-align: center;
      .main_title {
        font-weight: 700;
        font-size: 1.125rem;
      }
      .sub_title {
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
    table {
      font-size: 0.625rem;
      tr {
        th,
        td {
          vertical-align: middle;
          padding: 0 0.65rem;
          &.col_01 {
            width: 2.5rem;
          }
          &.col_02 {
            width: auto;
          }
          &.col_03 {
            width: 8rem;
          }
          &.col_04 {
            width: 12rem;
          }
          &.col_05 {
            width: 2.8rem;
          }
          &.col_06 {
            width: 2.8rem;
          }
          &.col_07 {
            width: 5rem;
          }
        }
        th {
          background-color: #000;
          color: #fff;
          height: 2.5rem;
          text-align: center;
          font-weight: 500;
          font-size: 0.875rem;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            overflow: hidden;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            overflow: hidden;
          }
          &.col_08,
          &.col_09 {
            border-left: 1px solid #ffffff;
          }
        }
        td {
          height: 3rem;
          text-align: center;
          font-weight: 400;
          font-size: 0.875rem;
          color: #4b5563;
          border-bottom: 1px solid #ebebeb;

          &.col_02 {
            text-align: left;
          }
        }
        background-color: #fff;
        &:nth-child(even) {
          background-color: #fafafa;
        }
        &.active {
          background-color: #edf7ff;
        }
        &:has(.com_chk):hover {
          background-color: #f2f2f2;
          //color:#4B5563 ;
        }
      }
      tbody {
        tr:last-child {
          td {
            border-bottom: 1px solid #000000;
          }
        }
      }
      tfoot {
        tr:first-child {
          td {
            border-top: 1px solid #000000;
          }
        }
        .col_04 {
          text-align: right;
          font-weight: 600;
        }
      }
    }

    .logo_area {
      display: flex;
      justify-content: right;
      padding: 0.75rem 1.5rem;
      svg {
        color: #000000;
        width: 1.875rem;
      }
    }
  `,
};
