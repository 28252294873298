/** 아카데미 회원의 "Students" 페이지의 순서 정렬 관련 파라미터 문자열 */
export const STUDENTS_LIST_ORDER_FILTER_PARAMS = {
  grade: {
    KEY: 'grade', // "학년" 정렬 상태
    value: {
      ASCENDING: 'asc', // 오름차순
      DESCENDING: 'desc', // 내림차순
    },
  },
};
