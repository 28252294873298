import React from 'react';
import styled from 'styled-components';

import FavoriteButton from './FavoriteButton';
import TestLabel from './TestLabel';

/** 시험 모듈 버튼 컴포넌트 */
export default function TestButton({
  isB2c,
  tit,
  seq,
  rw,
  math,
  rwTakenNum,
  maTakenNum,
  rwTakenUser,
  maTakenUser,
  isLike,
  viewAverageSubject,
  selectModules,
  setSelectModules,
  setCustomSetInfo,
}) {
  /** 시험 선택 활성화 토글 핸들러 */
  const handleToggleActive = ({ event, seq }) => {
    event.stopPropagation(); // 버튼 요소가 겹쳐 있기 때문에, 이벤트 전파 막기
    if (!selectModules.includes(seq)) {
      const updateSelectList = [...selectModules, seq];
      setSelectModules(updateSelectList);
    } else {
      const updateSelectList = selectModules.filter((key) => key !== seq);
      setSelectModules(updateSelectList);
    }
  };

  return (
    <S.Button
      className={`${selectModules.includes(seq) ? 'active' : ''}`}
      onClick={(event) => {
        handleToggleActive({ event, seq });
      }}>
      <div className='top_info'>
        <div className='label_wrap'>
          {viewAverageSubject !== 'M' && rwTakenNum > 0 && (
            <TestLabel subject={'RW'} studentsNumber={isB2c ? null : rwTakenNum} isB2c={isB2c} takenUserDataArray={rwTakenUser} />
          )}
          {viewAverageSubject !== 'RW' && maTakenNum > 0 && (
            <TestLabel subject={'MA'} studentsNumber={isB2c ? null : maTakenNum} isB2c={isB2c} takenUserDataArray={maTakenUser} />
          )}
        </div>
        <FavoriteButton active={isLike} seq={seq} isB2c={isB2c} setCustomSetInfo={setCustomSetInfo} />
      </div>
      <div className='text_area'>
        <span className='tit_field'>{tit}</span>
        {viewAverageSubject === 'F' ? (
          <span className='txt_field'>
            {rw}
            <br />
            {math}
          </span>
        ) : viewAverageSubject === 'RW' ? (
          <span className='txt_field'>
            {rw}
            <br />
          </span>
        ) : (
          <span className='txt_field'>
            {math}
            <br />
          </span>
        )}
      </div>
    </S.Button>
  );
}

const S = {
  Button: styled.div`
    border: 1px solid #d2dbe2;
    padding: 0.25rem 0.25rem 2rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
    border-radius: 0.625rem;
    align-self: stretch;
    width: calc((100% - 30px) / 3);
    min-width: calc((100% - 30px) / 3);
    max-width: calc((100% - 30px) / 3);
    position: relative;
    cursor: pointer;
    &:hover {
      border-color: #8cabf0;
      background-color: #fbfbff;
      color: #11456f;
    }
    .top_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.25rem;
      min-height: 2rem;
      width: 100%;
      .label_wrap {
        display: flex;
        gap: 0.25rem;
      }
    }
    .text_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      text-align: center;
    }
    .tit_field {
      font-size: 1rem;
      font-weight: 500;
    }
    .txt_field {
      font-size: 0.625rem;
      line-height: 1rem;
      color: $emphasis;
      & + .com_chk_wrap {
        margin-top: 0.625rem;
      }
    }
    &.active {
      border-color: #477bff;
      background-color: #f7f8ff;
      & :is(.tit_field, .txt_field) {
        color: #0068bd;
      }
      .layer_pop_area {
        color: #2c85cf;
        background-color: #fff;
        border-color: #d1deff;
        &:hover {
          border-color: var(--point-color);
          color: var(--point-color);
        }
      }
    }
  `,
};
