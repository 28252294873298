import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import LoadingBar from 'utils/LoadingBar';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import useUserLevels from '../hooks/useUserLevels';
import request from '../utils/Request.utils';

// 이미지 임포트
import { ReactComponent as ImgLnbClasses } from 'assets/img/svg/icon_classes_01.svg';
import { ReactComponent as ImgLnbStudents } from 'assets/img/svg/icon_users_01.svg';
import ImgLnbCalendar from '../assets/img/lnb_calendar.png';
import ImgLnbHome from '../assets/img/lnb_home.png';
import ImgLnbNote from '../assets/img/lnb_note.png';
import ImgLnbSchedule from '../assets/img/lnb_schedule.png';
import ImgLnbTest from '../assets/img/lnb_test.png';
import { ReactComponent as ImgLnbGrading } from '../assets/img/svg/nav/lnb_grading.svg';
import { ReactComponent as ImgLnbScore } from '../assets/img/svg/nav/lnb_score.svg';
import { ReactComponent as ImgLnbTestStatus } from '../assets/img/svg/nav/lnb_test_status.svg';
import { ReactComponent as ImgLnbTestTaken } from '../assets/img/svg/nav/lnb_test_taken.svg';

import FreezingKeyModal from 'components/_common/modals/FreezingKeyModal';
import ReviewNotes from 'components/student/_components/modals/ReviewNotes';
import { NavItem } from './_lnb-components/NavItem';

/** 사용자 레벨 상수 - 오타 방지 및 안정성 보장 */
const USER_LEVEL = {
  student: 'STUDENT',
  parent: 'PARENT',
  teacher: 'TEACHER',
  tutor: 'TUTOR',
  academy: 'ACADEMY',
  tubletAdmin: 'TUBLET_ADMIN',
  tubletTutor: 'TUBLET_TUTOR',
  b2b: ['ACADEMY', 'TEACHER', 'TUTOR'],
};

export default function Lnb() {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const { permissions: useLevels, isB2B } = useUserLevels(userInfo?.userLevel);
  const { pathname } = useLocation();
  const [reviewNotesActive, setReviewNotesActive] = useState(false);
  const [showFreezingPopup, setShowFreezingPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // 현재 경로가 특정 경로를 포함하는지 여부
  const pathnameIncludes = (path) => pathname.includes(path);

  /**
   * 네비게이션 요소를 랜더링 하기 위한 설정 객체
   * path - string ( 네비게이션 경로 )
   * onClick - function ( 네비게이션 아이템 클릭 시 실행할 함수 )
   * icon - string | object ( 아이콘 이미지 경로 또는 SVG 컴포넌트 )
   * label - string ( 사용자에게 보일 네비게이션 라벨 )
   * showFor - array ( 표시할 사용자 레벨 ) / 해당 인자 없을시 모든 사용자에게 표시
   * showOnNavItem - boolean ( 네비게이션 아이템이 보여지는 조건 - 함수로 자세한 조건 설정 ) / 해당 인자 없을시 항상 표시
   * activeCondition - boolean ( 네비게이션 아이템이 활성화 되는 조건 )
   * disabled - boolean (메뉴가 보이되, 클릭 불가)
   */
  const getNavigationConfig = () => ({
    home: {
      path: `${pathnameIncludes('/ap/') ? `/ap/com/score/${obfuscateUrlParam('0')}` : useLevels.isStudent ? '/student/dashboard' : useLevels.isParent ? '/parent/dashboard' : '/academy/dashboard'}`,
      icon: ImgLnbHome,
      label: 'Home',
      activeCondition: pathnameIncludes('/student/dashboard') || pathnameIncludes('/parent/dashboard') || pathnameIncludes('/academy/dashboard'),
    },
    setTests: {
      onClick: goToSetTest,
      icon: ImgLnbTest,
      label: 'Set Tests',
      showFor: [USER_LEVEL.student, USER_LEVEL.b2b, USER_LEVEL.tubletAdmin],
      showOnNavItem: !pathnameIncludes('ap/student'),
      activeCondition: pathnameIncludes('tests/set'),
    },
    reviewNotes: {
      onClick: () => setReviewNotesActive(true),
      icon: ImgLnbNote,
      label: 'Review Notes',
      showFor: [USER_LEVEL.student, USER_LEVEL.parent],
      activeCondition: reviewNotesActive,
      disabled: pathnameIncludes('/ap/'),
    },
    calendar: {
      path: '/com/tests/calendar',
      icon: ImgLnbCalendar,
      label: 'Calendar',
      activeCondition: pathnameIncludes('/com/tests/calendar') && !reviewNotesActive,
      disabled: pathnameIncludes('/ap/'),
    },
    // 단순 구분선
    divider: {},
    students: {
      path: '/com/students',
      icon: ImgLnbStudents,
      label: 'Students',
      showFor: [USER_LEVEL.b2b],
      showOnNavItem: pathnameIncludes('/student'),
      activeCondition: pathnameIncludes('/com/students/students'),
    },
    classes: {
      path: '/com/students/classes',
      icon: ImgLnbClasses,
      label: 'Classes',
      showFor: [USER_LEVEL.b2b, USER_LEVEL.tubletAdmin],
      showOnNavItem: pathnameIncludes('/student'),
      activeCondition: pathnameIncludes('/com/students/classes'),
    },
    testScheduled: {
      path: '/com/tests/scheduled',
      icon: ImgLnbSchedule,
      label: 'Test Scheduled',
      showOnNavItem: !pathnameIncludes('/ap/com/tests') && !pathnameIncludes('/tests/set') && pathnameIncludes('/com/tests'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/com/tests/scheduled'),
    },
    testStatus: {
      path: '/com/tests/status',
      icon: ImgLnbTestStatus,
      label: 'Test Status',
      showOnNavItem: !pathnameIncludes('/ap/com/tests') && !pathnameIncludes('/tests/set') && pathnameIncludes('/com/tests'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/com/tests/status'),
      disabled: pathnameIncludes('/ap/'),
    },
    testTaken: {
      path: '/com/tests/taken',
      icon: ImgLnbTestTaken,
      label: 'Test Taken',
      showOnNavItem: !pathnameIncludes('/ap/com/tests') && !pathnameIncludes('/tests/set') && pathnameIncludes('/com/tests'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/com/tests/taken'),
      disabled: pathnameIncludes('/ap/'),
    },
    apTestScheduled: {
      path: '/ap/com/tests/scheduled',
      icon: ImgLnbSchedule,
      label: 'Test Scheduled',
      showOnNavItem: !pathnameIncludes('/ap/tests/set') && pathnameIncludes('/ap/com/tests'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/ap/com/tests/scheduled'),
    },
    score: {
      path: `/ap/com/score/${obfuscateUrlParam('0')}`,
      icon: ImgLnbScore,
      label: 'Score',
      showOnNavItem: pathnameIncludes('/ap/com/score'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/ap/com/score') && !pathnameIncludes('/ap/com/score/grading'),
    },
    grading: {
      path: '/ap/com/score/grading',
      icon: ImgLnbGrading,
      label: 'Grading',
      showOnNavItem: pathnameIncludes('/ap/com/score'),
      showFor: [USER_LEVEL.b2b],
      activeCondition: pathnameIncludes('/ap/com/score/grading'),
    },
    tutor: {
      path: 'tublet/tutor',
      icon: ImgLnbStudents,
      label: 'Tutors',
      showOnNavItem: pathnameIncludes('/tublet/tutor'),
      showFor: [USER_LEVEL.tubletAdmin],
      activeCondition: pathnameIncludes('/tublet/tutor'),
    },
  });

  const goToSetTest = () => {
    // url에 /ap/ 포함되면 ap
    let url;
    if (pathname.includes('/ap/')) {
      url = !isB2B ? '/ap/com/tests/set/selection/config' : '/ap/com/tests/set/selection/type';
    } else {
      url = !isB2B ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';
    }

    if (isB2B) {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const { isFreezing } = response.result;

          if (!isFreezing) navigate(url);
          else setShowFreezingPopup(true);
        }
      };

      request
        .get('/api/academy/gatekey/freezing', null, successHandler)
        .catch((error) => {
          console.log('key freezing 에러 발생! : ', error);
        })
        .finally(() => setIsLoading(false));
    } else navigate(url);
  };

  /** 네비게이션 아이템 랜더링 */
  const renderNavItems = () => {
    const navigationConfig = getNavigationConfig();

    return Object.entries(navigationConfig).map(([key, item]) => {
      if (key === 'divider') return <hr key={`lnb-divider-${key}`} className='divider' />; // 구분선 랜더링

      if (item.showFor !== undefined && !item.showFor.flat().includes(useLevels.userType)) return null; // showFor에 유저 권한이 포함되지 않으면 표시 안함 ( showFor가 없다면 모든 유저에게 노출 )
      if (item.showOnNavItem !== undefined && item.showOnNavItem === false) return null; // 현재 경로에서 표시 여부 ( showOnNavItem가 없다면 제한 없이 노출 )

      return <NavItem key={`lnb-${key}`} {...item} isActive={item.activeCondition} onClick={item.onClick} disabled={item.disabled} />;
    });
  };

  return (
    <>
      <S.Wrap className='com_lnb'>{renderNavItems()}</S.Wrap>
      {reviewNotesActive && <ReviewNotes setReviewNotesActive={setReviewNotesActive} />}
      {showFreezingPopup && <FreezingKeyModal hideModal={() => setShowFreezingPopup(false)} />}
      {isLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
}

const S = {
  Wrap: styled.nav`
    background: var(--point-color);
    height: 100vh;
    position: fixed;
    z-index: 6;
    left: 0;
    top: 0;
    width: 3.75rem;
    text-align: center;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .logo {
      margin-top: auto;
      img {
        width: 35px;
      }
    }
    .menu {
      display: block;
      color: #fff;
      font-size: 11px;
      min-height: 61px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      svg,
      .icon {
        width: 1.75rem;
        margin-bottom: 6px;
      }
      &.message {
        margin-top: auto;
      }
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        box-shadow: 0px 3px 5px 0px #0000001a;
        border-left: 3px solid #fff;
        width: calc(100% + 3px);
        margin-right: -3px;
        background-color: #107fd9;
        padding-block: 10px;
      }
      &:disabled,
      &.disabled {
        cursor: not-allowed;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .divider {
      border: 0;
      margin-inline: 16.25px;
      opacity: 0.3;
      height: 1px;
      width: calc(100% - 16.25px * 2);
      background-color: #fff;
    }
  `,
};
