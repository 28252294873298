import { ReactComponent as ImgSvgIconScoreReport } from 'assets/img/svg/icon_score_report.svg';
import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

/** 시험 목록 컴포넌트 */
export default function TestList({ testList }) {
  const { setBulkObfuscatedValues, getObfuscatedValue } = useObfuscatedSearchParam();

  // /** 위치를 파악하고 싶은 요소 */
  const targetElementRef = useRef(null);

  useLayoutEffect(() => {
    if (targetElementRef.current) {
      const rect = targetElementRef.current.getBoundingClientRect();
      // setTestListAreaPosition({ top: rect.top, left: rect.left });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToReportCard = (uthSeq) => {
    window.open(`/ap/com/report/${obfuscateUrlParam(uthSeq)}`, '_blank');
  };

  return (
    <S.Wrap ref={targetElementRef}>
      <div className='top_area' id='testListArea'>
        <div className='title_area'>
          <h4>Test List</h4>
          <p>Click on a test to see score analysis for a specific test.</p>
        </div>
      </div>
      <div className='table_wrap'>
        <div className='table'>
          <div className='thead'>
            <div className='tr'>
              <div>No.</div>
              <div>Class</div>
              <div>Test set</div>
              <div>Taken Date</div>
              <div>Total</div>
              <div className='center'>Report Card</div>
            </div>
          </div>
          <ul className='tbody'>
            {testList && testList.length ? (
              testList.map((item, index) => {
                const newDate = new Date(item.testTakenEndDate);
                /** 화면에 표시 될 날짜 */
                const displayDate = `${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(newDate)} ${newDate.getDate()}, ${newDate.getFullYear()}`;
                /** 화면에 표시 될 순서 */
                const displayIndex = testList.length - index;

                return (
                  <li
                    // className={`tr ${String(selectedUthSeq) === String(item.uthSeq) ? 'active' : ''}`}
                    className='tr'
                    key={item.uthSeq}
                    title='Test List'
                    onClick={() => {
                      // handleClick(item);
                    }}>
                    <div>{displayIndex}</div>
                    <div>{item.className}</div>
                    <div>{item.testSetName}</div>
                    <div>{displayDate}</div>
                    <div>{item.isCompleted ? item.total : '-'}</div>
                    <div className='center'>
                      {item.isCompleted ? (
                        <button type='button' title='' className='button_report' onClick={() => goToReportCard(item.uthSeq)}>
                          <ImgSvgIconScoreReport />
                        </button>
                      ) : (
                        'In-progress'
                      )}
                    </div>
                  </li>
                );
              })
            ) : (
              <div className='no_data_area'>
                No test available. <br />
                Please complete a test to see in-depth analysis.
              </div>
            )}
          </ul>
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .top_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_area {
        h4 {
          font-weight: 600;
          font-size: 1.25rem;
        }
        p {
          font-weight: 400;
          font-size: 0.875rem;
          color: #999999;
          margin-top: 0.25rem;
        }
      }
      button {
        background-color: #dff1ff;
        color: #4692d1;
        font-weight: 400;
        font-size: 0.75rem;
        border-radius: 0.375rem;
        padding: 0.5rem 0.625rem;
        height: 2.25rem;
      }
    }
    .table_wrap {
      margin-top: 0.75rem;
      position: relative;
      .table {
        border-radius: 0.625rem;
        border: 1px solid #f4f4fc;
        overflow: hidden;
        .tr {
          display: flex;
          & + .tr {
            border-top: 1px solid #f4f4fc;
          }
          & > div {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 3rem;
            color: #111111;
            font-weight: 400;
            font-size: 0.875rem;
            text-align: left;
            cursor: pointer;

            &.center {
              justify-content: center;
            }

            &:nth-child(1) {
              flex: 0 0 5%;
            }
            &:nth-child(2) {
              flex: 1 1 auto;
            }
            &:nth-child(3) {
              flex: 0 0 24%;
            }
            &:nth-child(4) {
              flex: 0 0 18%;
            }
            &:nth-child(5) {
              flex: 0 0 8%;
            }
            &:nth-child(6) {
              flex: 0 0 12%;
            }
          }
        }
        .thead {
          width: 100%;
          .tr {
            width: 100%;
            & > div {
              background-color: #f8f8f9;
              padding: 0.625rem 0.75rem;
            }
          }
        }
        .tbody {
          display: block;
          height: 17.5rem;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #2f3542;
          }
          .tr {
            width: 100%;
            & > div {
              padding: 0.75rem 0.75rem;
              border-bottom: 1px solid #f4f4fc;
              border-style: solid;
              border-top-width: 1px;
              border-bottom-width: 1px;
              border-left-width: 0;
              border-right-width: 0;
              border-color: #ffffff;
              &:first-child {
                border-top-left-radius: 0.625rem;
                border-bottom-left-radius: 0.625rem;
                border-left-width: 1px;
              }
              &:last-child {
                border-top-right-radius: 0.625rem;
                border-bottom-right-radius: 0.625rem;
                border-right-width: 1px;
              }
              &:nth-child(5),
              &:nth-child(6) {
                font-weight: 600;
              }
            }
            &.active,
            &:hover {
              & > div {
                background-color: #edf7ff;
                border-color: #477bff;
              }
            }
          }
        }
        .button_report {
          svg {
            color: #111111;
            width: 1.5rem;
          }
        }
      }
    }

    .no_data_area {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #4b5563;
      height: 100%;
    }
  `,
};
