import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

////////// Components
import Layout from 'components/com/tests/_components/_common/Layout';
import useUserLevels from 'hooks/useUserLevels';
import TabButtons, { TAB_DATA } from './_components/TabButtons';
import TestButton from './_components/testButton/TestButton';
import MainTitleArea from '../../_components/title/MainTitleArea';
import MainTitle from '../../_components/title/MainTitle';
import SubTitle from '../../_components/title/SubTitle';
import SearchArea from './_components/SearchArea';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

/** Set Tests : "시험 모듈 선택" 페이지 */
export default function TestsSetSelectTestMorePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const [userInfo, _] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);

  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 Tab */
  const selectedTab = getObfuscatedValue('tab');
  /** 현재 입력 된 검색어 */
  const selectedSearchKeyword = getObfuscatedValue('search');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 검색어 입력창 문자열 실시간 갱신(& 비교) 용 상태 */
  const [searchInputValue, setSearchInputValue] = useState(selectedSearchKeyword ? selectedSearchKeyword : '');
  const [isB2c, setIsB2c] = useState(null);
  /** API에서 받아온 전체 시험 세트 목록 */
  const [customSetInfo, setCustomSetInfo] = useState({
    list: [], // 가공할 데이터(데이터 구조는 아래 주석 참고)
    // {
    //   customSetSeq: 11,
    //   englishAvg: 310,
    //   englishCnt: 0,
    //   mathAvg: 280,
    //   mathCnt: 1,
    //   isAcademyExclusive: 1,
    //   testName: 'Level Test',
    //   isLike: true,
    //   likeDate: '2024-12-29T20:21:19-06:00',
    //   englishStudentList: [],
    //   mathStudentList: [
    //     {
    //       userSeq: 350,
    //       isScheduled: 0,
    //       isTaken: 1,
    //       userName: '0530tester',
    //     },
    //   ],
    // }[]
  });

  const [selectModules, setSelectModules] = useState(nvl(stateSetTest.selectedModules) === '' ? [] : stateSetTest.selectedModules.map((i) => i.customSetSeq));

  /** 이 페이지에서 사용자가 실시간으로 선택하는 시험 세트 상태 */
  const [selectTestSet, setSelectTestSet] = useState([]);

  /** API 로딩 상태 */
  const [isLoading, setIsLoading] = useState(true);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const getCustomSet = () => {
    const {
      selectedConfig: { testConfiguration, testType, testRwDiff, testMathDiff },
      selectedStudents,
    } = stateSetTest;

    const subjectsToFetch = testConfiguration === 'RW' ? 'E' : testConfiguration;

    let difficultyToFetch = '';
    const difficultyMapping = {
      EE: 'E', // testRwDiff, testMathDiff 둘 다 'E'
      HH: 'H', // testRwDiff, testMathDiff 둘 다 'H'
      EH: 'Y', // testRwDiff == 'E', testMathDiff == 'H'
      HE: 'D', // testRwDiff == 'H', testMathDiff == 'E'
    };

    if (testType) difficultyToFetch = 'F';
    else if (testConfiguration === 'RW') difficultyToFetch = testRwDiff;
    else if (testConfiguration === 'M') difficultyToFetch = testMathDiff;
    else difficultyToFetch = difficultyMapping[testRwDiff + testMathDiff] || '난이도선택안됨!';

    let pms = {
      subject: subjectsToFetch,
      difficulty: difficultyToFetch,
      seqStr: selectedStudents.map((item) => item.userSeq).join('|'),
      isExclusive: 'Y', // 학원 전용 세트 보여주도록 함 일단은 Y
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const customSetList = response.result.customSetList;

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: customSetList,
          };
        });
      }
    };

    request
      .get(
        `/api/dsat/customset/list?subject=${pms.subject}&difficulty=${pms.difficulty}&seqStr=${encodeURIComponent(pms.seqStr)}&isExclusive=${pms.isExclusive}`,
        null,
        successHandler
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getCustomSet();
    setBulkObfuscatedValues({ paramValues: { tab: TAB_DATA.ALL.value, search: '' }, replace: true }); // 최초 텝 상태 설정

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 사용자가 시험 세트 선택하는 동작
    const filterTest = [];

    customSetInfo.list.forEach((item) => {
      if (selectModules.includes(item.customSetSeq)) {
        filterTest.push(item);
      }
    });
    setSelectTestSet(filterTest);
  }, [selectModules, customSetInfo.list]);

  useEffect(() => {
    if (!useLevelsIsLoading) {
      // 사용자 유형 분석 로딩이 끝난 후 상태 값 갱신
      setIsB2c(useLevels.isStudent);
    }
  }, [useLevelsIsLoading, useLevels]);

  useEffect(() => {
    if (!isLoading) {
      if (selectedTab === TAB_DATA.FAVORITES.value) {
        // "Favorites" 텝

        /** 정렬 된 데이터 */
        const sortedCustomSetList = customSetInfo.list.sort((a, b) => new Date(b.likeDate) - new Date(a.likeDate));

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: sortedCustomSetList,
          };
        });
      } else {
        // "All Tests" 텝

        /** 추출 함수: RW/MA 뒤의 숫자 추출 */
        const extractNumber = (testName) => {
          const match = testName.match(/(RW|MA)(\d+)/);
          return match ? parseInt(match[2], 10) : -1; // 숫자가 없으면 -1 반환
        };

        /** 정렬 된 데이터 */
        const sortedCustomSetList = customSetInfo.list.sort((a, b) => {
          // 항상 "Level Test"를 포함하는 항목을 최상단으로
          if (a.testName.includes('Level Test') && !b.testName.includes('Level Test')) return -1;
          if (b.testName.includes('Level Test') && !a.testName.includes('Level Test')) return 1;

          // 3순위: 학생 리스트가 있는 경우 우선 처리
          const aListCount = (a.englishStudentList?.length || 0) + (a.mathStudentList?.length || 0);
          const bListCount = (b.englishStudentList?.length || 0) + (b.mathStudentList?.length || 0);
          if (aListCount !== bListCount) return aListCount - bListCount; // 요소 수 기준 정렬

          // 2순위: RW/MA 형식인 경우, 숫자로 비교 (내림차순)
          const aNum = extractNumber(a.testName);
          const bNum = extractNumber(b.testName);
          return bNum - aNum;
        });

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: sortedCustomSetList,
          };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, isLoading]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectModules={selectModules} selectTestSet={selectTestSet}>
      <S.Wrap>
        <MainTitleArea>
          <MainTitle>Available Tests</MainTitle>
          <SubTitle>Select one or more tests</SubTitle>
        </MainTitleArea>
        <div className='main_top_area'>
          <TabButtons />
          <SearchArea searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} customSetList={customSetInfo.list} />
        </div>
        <div className='btn_test__wrap'>
          {isB2c !== null &&
            nvl(customSetInfo.list) !== '' &&
            customSetInfo.list.map((item, index) => {
              /** 텝 조건 필터링 */
              const filteredConditionForTab = selectedTab === TAB_DATA.FAVORITES.value ? item.isLike : true;

              /** 검색어 조건 */
              const conditionForSearchKeyword =
                searchInputValue === null || searchInputValue === '' ? true : item.testName.toLowerCase().includes(searchInputValue.toLowerCase());

              return isLoading
                ? 'Loading...'
                : filteredConditionForTab && conditionForSearchKeyword && (
                    <TestButton
                      key={`set_${index}`}
                      isB2c={isB2c}
                      tit={item.testName}
                      seq={item.customSetSeq}
                      rw={`Average RW score : ${item.englishAvg}/800`}
                      math={`Average Math score : ${item.mathAvg}/800`}
                      rwTakenNum={item.englishCnt}
                      maTakenNum={item.mathCnt}
                      rwTakenUser={item.englishStudentList}
                      maTakenUser={item.mathStudentList}
                      isLike={item.isLike}
                      viewAverageSubject={stateSetTest.selectedConfig.testConfiguration}
                      selectModules={selectModules}
                      setSelectModules={setSelectModules}
                      setCustomSetInfo={setCustomSetInfo}
                    />
                  );
            })}
        </div>
      </S.Wrap>
    </Layout>
  );
}

const S = {
  Wrap: styled.div`
    .main_top_area {
      border-bottom: 1px solid #eaeaea;
      margin: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .btn_test__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  `,
};
