import { useState } from 'react';
import styled from 'styled-components';

import LabelFileUpload from 'components/user/sign-up/_components/pages/upload/LabelFileUpload';
import UploadedFileItem from 'components/user/sign-up/_components/pages/upload/UploadedFileItem';

import DeleteModal from 'components/user/sign-up/_components/pages/upload/DeleteModal';

import { allowImages, allowPdfFiles } from 'utils/Common.utils';

import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';

/** 컴포넌트 JSDoc */
export default function FileUpload({ uploadFileList, setUploadFileList }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 현재 선택 된 파일 아이템  */
  const [currentFileItem, setCurrentFileItem] = useState(false);
  /** 삭제 확인 모달 노출 여부 상태 */
  const [isViewDeleteModal, setIsViewDeleteModal] = useState(false);
  /** 간단한 알림 모달 상태 */
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: '',
    visible: false,
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleDragOver = (event) => event.preventDefault();
  const handleDrop = (event) => {
    event.preventDefault();

    const fileList = event.dataTransfer.files;
    getFileListInfo(fileList);
  };
  const handleFileInput = (e) => {
    const fileList = e.target.files;
    getFileListInfo(fileList);

    e.target.value = '';
  };

  const getFileListInfo = (fileList) => {
    let tempFileList = [...uploadFileList];

    for (const file of fileList) {
      const { name, size, type } = file;
      const mbSize = (size / (1024 * 1024)).toFixed(2) + 'mb';

      if (!allowImages(type) && !allowPdfFiles(type)) {
        setSimpleAlert((prev) => ({
          ...prev,
          visible: true,
          modalText: 'Please upload image file or pdf file.',
        }));

        return;
      }

      // ----------------------------------------------
      const fileExists = tempFileList.some((existingFile) => existingFile.fileName === name);

      if (!fileExists) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const readerResult = reader.result;

          tempFileList.push({
            fileName: name,
            fileSize: mbSize,
            mimeType: type,
            fileTextData: readerResult,
            fileData: file,
          });

          setUploadFileList(tempFileList);
        };
      }
    }
  };

  /** 현재 선택 된 파일 삭제 버튼 핸들러 */
  const handleDeleteButton = (fileItem) => {
    setIsViewDeleteModal(true);
    setCurrentFileItem(fileItem);
  };
  /** 파일 삭제 확인 버튼 후 실제 로직 핸들러 */
  const handleDeleteFile = (fileItem) => {
    const deleteFileList = uploadFileList.filter((i) => i.fileName !== fileItem.fileName);
    setUploadFileList(deleteFileList);
    setIsViewDeleteModal(false);
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const isUploaded = uploadFileList.length > 0;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <>
      <S.FileUploadArea>
        <input type='file' className='upload_file' multiple id='fileInput' onChange={handleFileInput} />
        <>
          <LabelFileUpload htmlFor={'fileInput'} onDragOver={handleDragOver} onDrop={handleDrop} isUploaded={false} />
          <p className='guile-txt'>Image and/or PDF copy would suffice</p>
        </>

        <div className='uploaded_files'>
          {isUploaded
            ? uploadFileList?.map((fileItem, index) => <UploadedFileItem key={`fileInfo_${index}`} fileItem={fileItem} handleDeleteFile={handleDeleteButton} />)
            : null}
        </div>
      </S.FileUploadArea>

      {/* 모달 */}
      {isViewDeleteModal && <DeleteModal setIsViewModal={setIsViewDeleteModal} callback={() => handleDeleteFile(currentFileItem)} />}
      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

const S = {
  FileUploadArea: styled.div`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    .guile-txt {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
      padding: 0.75rem 0;
      text-align: center;
    }

    input[type='file'].upload_file {
      display: none;
    }
  `,
};
