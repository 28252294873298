import React from 'react';
import ID from './common/_Id';
//component

const MultiProfile = ({ setOpenSwitchModal, switchAccountInfo, tit }) => {
  return (
    <div className='com_popup type_dim pop_switch_account active'>
      <div className='pop_container m'>
        <section className='pop_header'>
          <h1 className='pop_tit'>{tit}</h1>
          <button className='svg_icon btn_pop_close gray' onClick={() => setOpenSwitchModal(false)}>
            &nbsp;
          </button>
        </section>
        <section className='pop_body'>
          {switchAccountInfo.map((account) => (
            <ID accountInfo={account} />
          ))}
        </section>
      </div>
    </div>
  );
};

export default MultiProfile;
