import { useEffect, useState } from 'react';
import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants.js';
import request from 'utils/Request.utils';
import StudentInfoList from '../_StudentList';

const StudentInformation = ({ setLinkPop, reloadData }) => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [linkedStudent, setLinkedStudent] = useState([]);

  const getStudent = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let students = response.result.students;

        if (nvl(students) !== '') {
          students.forEach((child) => {
            let imageUrl = null;
            if (child.upFileSeq > 0) {
              imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${child.userSeq}?${Date.now()}`;
            }

            child.profile = imageUrl;
          });
        }

        setLinkedStudent(students || []);
      }
    };
    request.get('/api/v2/tublet/tutor/students', null, successHandler);
  };

  useEffect(() => {
    getStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className='tit'>Student(s) Information</h2>
      <p className='sub_tit'>View your student's profile and switch to the student whose scores you want to check.</p>
      {nvl(linkedStudent) !== '' && linkedStudent.length === 0 ? (
        <div className='no_academy'>
          <i className='svg_icon icon_no_link gray'>&nbsp;</i>
          No linked student.
          <br />
          학생과 연결이 필요합니다.
        </div>
      ) : (
        linkedStudent.map((item, idx) => {
          return <StudentInfoList userInfo={userInfo} key={`${item}-${idx}`} info={item} userLevel={USER_LEVELS.STUDENT} reloadData={getStudent} />;
        })
      )}
    </>
  );
};

export default StudentInformation;
