import { nvl } from 'utils/Common.utils';
import localStorage from 'utils/LocalStorage.utils';

import styled from 'styled-components';

/** 시험 마지막에 푼것을 확인하는 ReviewPopup ( 마치 페이지처럼 시험 body를 모두 차지함 ) */
export default function ReviewPopup({ stateExamInfo, setStateExamInfo }) {
  const questionInfo = localStorage.getItemJsonParse(`apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`).questionList;

  const gotoChoiceQuestion = (page) => {
    setStateExamInfo((prev) => ({ ...prev, currentNum: page, isReviewPopupOpen: false }));
  };

  return (
    <S.ReviewPopupContainer>
      <div className='review_title'>Check Your Work</div>
      <div className='review_text'>
        On test day, you won't be able to move on to the next module until time expires.
        <br />
        For these practice questions, you can click <b>Next</b> when you're ready to move on.
      </div>
      <S.QuestionContainer>
        <S.QuestionInfo>
          <p className='question_title'>
            {stateExamInfo.sectionName} {stateExamInfo.partName}
          </p>

          <div className='review_icon_info'>
            <div className='review_icon'>
              <i className='svg_icon icon_unanswered'>&nbsp;</i>Unanswered
            </div>
            <div className='review_icon'>
              <i className='svg_icon icon_bookmark'>&nbsp;</i>For Review
            </div>
          </div>
        </S.QuestionInfo>

        <S.QuestionField>
          {Array.from({ length: stateExamInfo.totalQuestion }, (_, index) => (
            <button
              key={`review_${index + 1}`}
              className={`
                  question_btn
                  ${questionInfo[index]?.selectAnswer.length > 0 || nvl(questionInfo[index]?.answerContent?.replace(/<[^>]*>/g, '').trim()) != '' ? 'answered' : ''}
                  ${questionInfo[index]?.isBookmarked ? 'review' : ''}
                `}
              onClick={() => gotoChoiceQuestion(index + 1)}>
              {index + 1}

              <i className='svg_icon icon_bookmark' aria-hidden='true'>
                &nbsp;
              </i>
            </button>
          ))}
        </S.QuestionField>
      </S.QuestionContainer>
    </S.ReviewPopupContainer>
  );
}

const S = {
  ReviewPopupContainer: styled.article`
    .review_title {
      font-size: 1.875rem;
      text-align: center;
      margin-block: 2.5rem 1.875rem;
    }
    .review_text {
      font-size: 0.9375rem;
      line-height: 2;
      text-align: center;
    }
  `,

  QuestionContainer: styled.section`
    width: 45.625rem;
    margin: 1.875rem auto 0;
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &::after {
      transform: rotate(180deg) translateX(calc(0% - 50% + 2.5rem));
      left: 50%;
      bottom: -1.25rem;
    }
    .question_title {
      font-size: 1.25rem;
      font-weight: 700;
      padding-left: 0.9375rem;
      text-align: center;
    }
  `,

  QuestionInfo: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .review_icon_info {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      gap: 1.25rem;
      font-size: 0.9375rem;
      font-weight: 500;
      .review_icon {
        display: flex;
        align-items: center;
        .svg_icon {
          margin-right: 5px;
        }
      }
    }
  `,

  QuestionField: styled.div`
    width: 100%;
    border-top: 1px solid #ddd;
    margin-top: 0.625rem;
    padding-top: 1.5625rem;
    gap: 1.875rem;
    flex-wrap: wrap;

    display: flex;
    align-items: center;
    .question_btn {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.25rem;
      font-weight: 500;
      border: 1px dashed #aaa;
      color: #0047de;
      text-align: center;
      position: relative;

      // 해당 문제 정답 입력함
      &.answered {
        border: 1px solid #0047de;
        color: #fff;
        background: #0047de;
        &:hover {
          background-color: #355d96;
        }
      }

      .icon_bookmark {
        position: absolute;
        right: -5px;
        top: -6px;
        width: 0.625rem;
        height: 0.9375rem;
        display: none;
      }
      // 해당 문제 북마크함
      &.review {
        .icon_bookmark {
          display: block;
        }
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  `,
};
