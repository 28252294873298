import React, { useEffect } from 'react';

import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
//img
import LogoImg from 'assets/img/logo.png';

/** 학생 프린트 페이지 */
export default function StudentPrintPage() {
  const userInfo = request.tokenDecoder();
  const printCard = SessionStorage.getItemJsonParse('printCard');

  const studentList = printCard?.studentList;

  useEffect(() => {
    document.body.classList.add('p_student_print'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('p_student_print'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <>
      <h2 className='tit'>Student list</h2>
      <h3 className='sub_tit'>{userInfo.companyName}</h3>
      <div className='com_list'>
        <table className='list'>
          <colgroup>
            <col width='53px' />
            <col width='15%' />
            <col width='10%' />
            <col width='20%' />
            <col width='6%' />
            <col width='6%' />
            <col width='12%' />
            <col width='' />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>School Name</th>
              <th>School Type</th>
              <th>Location</th>
              <th>Grade</th>
              <th>Students No.</th>
              <th>Class</th>
            </tr>
          </thead>
          <tbody>
            {studentList &&
              studentList.map((item, index) => {
                /** "Selected" 버튼으로 해당 페이지에 접근 했는지 여부 */
                const isSelected = item.profile;
                /** classList를 파싱하여 모든 class_name 추출 */
                const classList = item.classList ? (typeof item.classList === 'string' ? JSON.parse(item.classList) : [item.classList]) : [];
                const classNames = classList.length !== 0 ? classList.map((cls) => cls.class_name).join('<br />') : 'Individual';

                return (
                  <tr key={`print_${index}`}>
                    <td>{index + 1}</td>
                    <td className='name'>{isSelected ? item.profile.userName : item.userName}</td>
                    <td>{isSelected ? item.profile.studentSchool : item.studentSchool}</td>
                    <td>{isSelected ? item.profile.schoolTypeName : item.schoolTypeName}</td>
                    <td>{isSelected ? item.profile.schoolLocationName?.toUpperCase() : item.schoolLocationName?.toUpperCase()}</td>
                    <td>{isSelected ? item.profile.studentGrade : item.studentGrade}</td>
                    <td>{isSelected ? item.profile.userMobile : item.userMobile}</td>
                    <td className='class'>
                      {isSelected ? (
                        item.profile.classLists.map((item, index) => {
                          return <span key={`key_${index}`}>{item.className}</span>;
                        })
                      ) : (
                        <span dangerouslySetInnerHTML={{ __html: classNames }} />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className='logo'>
        <img src={LogoImg} alt='logo' />
      </div>
    </>
  );
}
