import styled from 'styled-components';

import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

/** AP 프로젝트 - 학생 report 페이지 - 유저 정보 리스트 */
export default function UserInfoList({ reportUserInfo }) {
  return (
    <S.InfoProfile>
      <div className='info-container'>
        <div className='info-list'>
          <S.InfoRow>
            <span className='label'>NAME</span>
            <span>{reportUserInfo.name}</span>
          </S.InfoRow>
          <S.InfoRow>
            <span className='label'>GRADE</span>
            <span>{reportUserInfo.grade}</span>
          </S.InfoRow>
          <S.InfoRow>
            <span className='label'>TESTED ON</span>
            <span>{isoTimeToDayjsTZ(reportUserInfo.testedOn).format('MMM D, YYYY')}</span>
          </S.InfoRow>
          <S.InfoRow>
            <span className='label'>SUBJECT</span>
            <span>{reportUserInfo.subject}</span>
          </S.InfoRow>
          <S.InfoRow>
            <span className='label'>TEST NAME</span>
            <span>{reportUserInfo.testName}</span>
          </S.InfoRow>
        </div>
      </div>
    </S.InfoProfile>
  );
}

const S = {
  InfoProfile: styled.div`
    display: flex;
    gap: 3.125rem;
  `,
  InfoRow: styled.div`
    display: flex;
    align-items: center;
    font-size: 1.375rem;
    .label {
      font-weight: 700;
      width: 12.1875rem;
    }
  `,
};
