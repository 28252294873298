import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';

import TestEditComBtn from 'components/ap/com/ap-components/_components/TestEditComBtn';
import SourcesForReview from 'components/ap/com/ap-components/_components/SourcesForReview';
import SimpleEditorBtn from 'components/ap/com/ap-components/_components/SimpleEditorBtn';

import QuestionEditor from './_components/QuestionEditor';

import AnswerChoiceList from './_components/AnswerChoiceList';

import TestEditorHeader from './_components/TestEditorHeader';
import ApExamFooter from '../_components/layout/ApExamFooter';
import { useDispatch, useSelector } from 'react-redux';

import ApSettingSelectBox from 'components/ap/com/ap-components/_components/SourceSettingPopup/_components/ApSettingSelectBox';

import { getPartData, formatOptionData } from './_utils';

import { addQuestion, updateQuestion, deleteQuestion, updateApTestSeq, updateSectionMetadata, upodateCurrentQuestionIndex } from 'reducers/apTestEditor/action';
import request from 'utils/Request.utils';

// 목업 데이터
const reviewData = [
  { id: 1, data: '그럴듯한_데이터1' },
  { id: 2, data: '그럴듯한_데이터2' },
  { id: 3, data: '그럴듯한_데이터3' },
  { id: 4, data: '그럴듯한_데이터4' },
];

const initialQuestion = {
  part_seq: 0, // number  => 파트 seq
  // Source
  source_directions: '', // 미사용
  source_order: 0, // string  => source 순서
  source_content: '', // string  => source 내용
  // Question
  question_order: 0, // number  => 실질 문제 순서
  custom_question_order: '', // string  => 유저가 커스텀 한 문제 순서
  question_type: '', // string  => FRQ, MCQ
  question_content: '', // string  => 문제 내용
  score: 0, // number  => 문제 점수
  question_code: '', // string  => 문제 코드
  question_context: false, // 미사용
  // Answer
  answer_options: {
    // 프론트에서 배열로 사용
    options: [],
    // 백엔드에서 아래 option key로 사용
    // option1: '',
    // option2: '',
    // option3: '',
    // option4: '',

    total: 0, // answerChoices 갯수
    answerCount: 0, // 정답 갯수
  },
  answer: [], // number[]  => 정답 번호

  // section_name: '',
  // part_name: '',
  // test_name: '',
  // subject_name: '',
};
/** QuestionBank에서 Test를 Edit 하는 페이지 */
export default function QuestionBankTestEditorPage() {
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  ////////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestion);

  const initAnswerChoiceInfo = {
    currentMode: 'single',
    single: {
      multipleChoiceType: 'Single-select',
      correctAnswer: [null],
    },
    multiple: {
      multipleChoiceType: 'Multi-select',
      noOfCorrectAnswer: 1,
      correctAnswer: [null],
    },
    answerChoices: [
      {
        id: 1,
        choiceType: 'Text Input',
        content: '',
      },
    ],
  };
  // answerChoice 정보
  const [answerChoiceInfo, setAnswerChoiceInfo] = useState(initAnswerChoiceInfo);

  const getInitQuestionInfo = (index = 1) => {
    return {
      questionOrder: index, // number  => 실질 문제 순서 ( 🧰 일단 1로 )
      customQuestionOrder: index.toString(), // string  => 유저가 커스텀 한 문제 순서
      questionType: 'MCQ', // string  => FRQ, MCQ
      questionContent: '', // string  => 문제 내용
      questionDifficulty: null, // null | 'Easy' | 'Medium' | 'Hard'  => 문제 난이도
      score: 0, // number  => 문제 점수
      questionCode: '2222', // string  => 문제 코드 ( 기획의 Prefix가 뭔지 몰라서 패스)
      questionContext: false, // 미사용
    };
  };
  // 단일 문제에 대한 State
  const [questionInfo, setQuestionInfo] = useState(getInitQuestionInfo());

  const [sourceInfo, setSourceInfo] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [dropdownPartData, setDropdownPartData] = useState([{ value: { sectionSeq: 0, partSeq: 0 }, label: '' }]); // 🧰 파트 변경 State
  const [selectedPart, setSelectedPart] = useState({ sectionSeq: 0, partSeq: 0 }); // 🧰 파트 변경 State
  const [triggerSaveAll, setTriggerSaveAll] = useState(false); // 전체 저장 State
  const [triggerReload, setTriggerReload] = useState(false); // 전체 초기화 State
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  const questionData = useMemo(
    () => ({
      ...currentQuestion,
      part_seq: selectedPart.partSeq,
      source_order: 1,
      // source_content: sourceInfo, // currentQuestion에서 저장

      answer_options: {
        ...formatOptionData(answerChoiceInfo),

        total: answerChoiceInfo.answerChoices.length,
        answerCount: answerChoiceInfo.answerChoices.length,
      },
      answer: answerChoiceInfo[answerChoiceInfo.currentMode].correctAnswer,
      question_order: questionInfo.questionOrder,
      custom_question_order: questionInfo.customQuestionOrder,
      question_type: questionInfo.questionType,
      question_content: questionInfo.questionContent,
      score: questionInfo.score,
      question_code: questionInfo.questionCode,
      question_context: questionInfo.questionContext,
    }),
    [currentQuestion, answerChoiceInfo, questionInfo, selectedPart]
  );
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  const getTestInfo = async (test_seq) => {
    // /api/ap/question-bank/test/:test_seq GET
    console.log('getTestInfo');
    const successHandler = async (response) => {
      console.log('getTestInfo successHandler:', response);
      const fetchedSectionMetadata = response.result;

      dispatch(updateSectionMetadata(fetchedSectionMetadata)); // redux에 저장
      // getTestInfo 는 첫번째만 로드하면 된다.
      setCurrentQuestion((prev) => ({
        ...prev,
        section_name: fetchedSectionMetadata.sections[0].section_name,
        part_name: fetchedSectionMetadata.sections[0].parts[0].name,
        test_name: fetchedSectionMetadata.test_info.test_set_name,
        subject_name: fetchedSectionMetadata.test_info.subject_name,
      }));

      // 파트데이터 설정
      const formattedDropdownPartData = getPartData(fetchedSectionMetadata);
      setDropdownPartData(formattedDropdownPartData);
      setSelectedPart(formattedDropdownPartData[0].value);
    };
    const errorHandler = async (error) => {
      console.error('getTestInfo errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    await request.get(`/api/ap/question-bank/test/detail/${test_seq}`, { isNew: true }, await successHandler, await errorHandler);
  };
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const submitHandler = () => {
    console.log('Submit');
    if (questions.length === 0) {
      alert('There are no questions to submit.');
      return;
    }
    const questions = useSelector((state) => state.questions);
    const payload = { questions };
    console.log('🚀 ~ submitHandler ~ payload:', payload);
  };
  const saveHandler = () => {
    // question을 redux에 저장한다.
    dispatch(addQuestion(questionData));
    console.log('Save');
  };
  const backHandler = () => {
    handleSaveAllContent();
    setCurrentQuestionIndex((prev) => prev - 1);
  };
  const nextHandler = () => {
    handleSaveAllContent();
    setCurrentQuestionIndex((prev) => prev + 1);
  };
  // section handler
  const handleSectionChange = () => {
    console.log('handleSectionChange');
  };
  // part handler
  const handlePartChange = () => {
    console.log('handlePartChange');
  };
  const handleQuestionChange = () => {};
  const handleClickReviewItem = (item) => {
    setSelectedItemId(item.id);
    console.log(item);
  };

  const handleSaveAllContent = () => {
    // 전체 저장 State 토글 ( 하위 컴포넌트 저장 로직 실행 )
    setTriggerSaveAll((prev) => !prev);

    handleSaveSourceContent();

    const currentPartSeqQuestions = apTestEditorReducer.questions.filter((question) => question.part_seq === selectedPart.partSeq);
    // console.log(`\n┏━━━ 💡 💡 currentPartSeqQuestions 💡 💡 ━━━\n`, currentPartSeqQuestions, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
    // console.log(`\n┏━━━ 💡 💡 selectedPart.partSeq 💡 💡 ━━━\n`, selectedPart.partSeq, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
    // console.log(currentPartSeqQuestions.find((question) => question.question_order === currentQuestionIndex));

    // 모든 데이터 리덕스에 저장
    if (currentPartSeqQuestions && currentPartSeqQuestions.find((question) => question.question_order === currentQuestionIndex)) {
      updateQuestion(selectedPart.partSeq, currentQuestionIndex, questionData);
    } else {
      saveHandler();
    }
  };

  const handleSaveSourceContent = () => {
    setCurrentQuestion((prev) => ({ ...prev, source_content: sourceInfo }));
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // query string에서 test_seq를 받아와서 해당 데이터를 불러온다.
    const urlParams = new URLSearchParams(window.location.search);
    const test_seq = urlParams.get('test_seq');

    // test_seq 가 없으면 새로운 테스트를 만들어야 한다.
    if (!test_seq) {
      alert('test_seq is not exist');
      location.href = '/ap/question-bank/create-test';
    }
    dispatch(updateApTestSeq({ test_seq })); // 초기값 설정
    // 테스트 데이터 불러오기 & 파트데이터 설정
    getTestInfo(test_seq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // questionData 변경시 프리뷰 페이지로 데이터 전달
  useEffect(() => {
    const channel = new BroadcastChannel('editor-channel');
    const data = {
      currentQuestion: questionData,
    };
    channel.postMessage(data);

    return () => {
      channel.close();
    };
  }, [questionData]);

  useEffect(() => {
    const hasQuestion = apTestEditorReducer.questions.find(
      (question) => question.question_order === currentQuestionIndex && question.part_seq === selectedPart.partSeq
    );

    // 문제가 있으면 문제 데이터를 가져온다.
    if (hasQuestion) {
      setCurrentQuestion(hasQuestion);
      setSourceInfo(hasQuestion.source_content);
      setQuestionInfo((prev) => ({
        ...prev,
        questionContent: hasQuestion.question_content,
        questionOrder: hasQuestion.question_order,
        customQuestionOrder: hasQuestion.custom_question_order,
      }));

      // questionOrder: 1, // number  => 실질 문제 순서 ( 🧰 일단 1로 )
      // customQuestionOrder: '1', // string  => 유저가 커스텀 한 문제 순서
      // questionType: 'MCQ', // string  => FRQ, MCQ
      // questionContent: '', // string  => 문제 내용
      // questionDifficulty: null, // null | 'Easy' | 'Medium' | 'Hard'  => 문제 난이도
      // score: 0, // number  => 문제 점수
      // questionCode: '2222', // string  => 문제 코드 ( 기획의 Prefix가 뭔지 몰라서 패스)
      // questionContext: false, // 미사용
    } else {
      // 문제가 없으면 새로운 문제 데이터를 생성한다.
      setCurrentQuestion({
        ...initialQuestion,
        part_seq: selectedPart.partSeq,
        question_order: currentQuestionIndex,
        section_name: currentQuestion.section_name,
        part_name: currentQuestion.part_name,
        test_name: currentQuestion.test_name,
        subject_name: currentQuestion.subject_name,
      });
      setAnswerChoiceInfo(initAnswerChoiceInfo);
      setSourceInfo('');
      setQuestionInfo(getInitQuestionInfo(currentQuestionIndex));
    }

    setTriggerReload((prev) => !prev);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex]);

  console.log(`\n┏━━━ 💡 💡 apTestEditorReducer 💡 💡 ━━━\n`, apTestEditorReducer, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
  console.log(`\n┏━━━ 💡 💡 currentQuestion 💡 💡 ━━━\n`, currentQuestion, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.QuestionBankTestEditorPageWrapper>
      <TestEditorHeader currentQuestion={questionData} />

      <S.TestEditorPageWrap className='custom_scroll'>
        <S.ExamContainer className='left'>
          <ApSettingSelectBox
            className='full_width'
            options={dropdownPartData}
            onSelect={(value) => {
              setSelectedPart(value);
            }}
            defaultValue={selectedPart}
          />

          <S.EditorContainer>
            {/* <TestEditComBtn title='Add a Question' onClick={() => console.log('콜백 주세영')} /> */}

            <S.SourcesForReviewContainer>
              <S.SectionTitle>Sources for Review{currentQuestion.part_name}</S.SectionTitle>
              <SourcesForReview data={reviewData} selectedItemId={selectedItemId} callback={handleClickReviewItem} />
            </S.SourcesForReviewContainer>

            <S.QuillEditorContainer>
              <S.SectionTitle>Sources 1</S.SectionTitle>
              <ReactQuillEditor className='quill_editor' editorContentState={sourceInfo} setEditorContentState={setSourceInfo} />
              <div className='btn_container'>
                <SimpleEditorBtn className='normal'>Delete</SimpleEditorBtn>
                <SimpleEditorBtn className='filled' onClick={() => setCurrentQuestion((prev) => ({ ...prev, source_content: sourceInfo }))}>
                  Save
                </SimpleEditorBtn>
                {/* const handleSaveContent = () => setQuestionInfo((prev) => ({ ...prev, questionContent: editorContentState })); */}
              </div>
            </S.QuillEditorContainer>

            {/* <div className='source_btn_container'>
              <TestEditComBtn title='Add a Question' onClick={() => console.log('콜백 주세영')} variant='blue' />
              <TestEditComBtn title='Add a Question' onClick={() => console.log('콜백 주세영')} />
            </div> */}
          </S.EditorContainer>
        </S.ExamContainer>

        <S.VerticalDivider />

        <S.ExamContainer className='right'>
          <S.EditorContainer>
            <TestEditComBtn title='Add a Question' onClick={() => console.log('콜백 주세영')} />

            <QuestionEditor questionInfo={questionInfo} setQuestionInfo={setQuestionInfo} />

            <S.HorizontalDivider />

            <AnswerChoiceList answerChoiceInfo={answerChoiceInfo} setAnswerChoiceInfo={setAnswerChoiceInfo} onSaveAll={triggerSaveAll} />
          </S.EditorContainer>
        </S.ExamContainer>
      </S.TestEditorPageWrap>
      <ApExamFooter saveHandler={handleSaveAllContent} backHandler={backHandler} nextHandler={nextHandler} currentQuestionIndex={currentQuestionIndex} />
    </S.QuestionBankTestEditorPageWrapper>
  );
}

const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,

  TestEditorPageWrap: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
  `,

  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.left {
      align-items: flex-end;
    }
    &.right {
      align-items: flex-start;
    }

    .full_width {
      width: 100%;

      padding: 20px;
    }
  `,

  EditorContainer: styled.div`
    padding: 0 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .source_btn_container {
      display: flex;
      gap: 0.75rem;
      flex-direction: column;
    }
  `,

  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
  HorizontalDivider: styled.div`
    height: 2px;
    width: 100%;
    background-color: #767676;
  `,

  SourcesForReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  QuillEditorContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    // quill editor 커스텀 스타일 적용
    .quill_editor {
      .ql-editor {
        height: 10.125rem;
      }
    }

    .btn_container {
      display: flex;
      gap: 0.75rem;
      justify-content: flex-end;
    }
  `,

  AnswerChoiceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,
  AnswerChoiceList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
