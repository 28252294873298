import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import { ReactComponent as ImgSvgIconPrinter } from 'assets/img/svg/icon_printer_02.svg';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** 아카데미 대시보드의 Classes 페이지 : "Score History" 모달 컴포넌트 */
export default function ScoreHistoryModal({ setShowModal, classSeq, className, companyName }) {
  const { navigateWithParams } = useObfuscatedSearchParam();

  const [isLoading, setIsLoading] = useState(true);
  const [scoreHistory, setScoreHistory] = useState([{ class: '', testDate: '', testName: '', rw: null, math: null, totalScore: null }]); // [ { class: '6월 시험 대비반', testDate: '12 / 29 / 2024', testName: 'level test', rw: 500, math: 700, totalScore: 1200 }
  /** 리포트 카드 열기 */
  const [reportCardActive, setReportCardActive] = useState(false);

  /** "Score History" 인쇄 페이지 이동 버튼 핸들러  */
  const handlePrintScoreHistoryPage = ({ classSeq }) => {
    if (classSeq && className) {
      const destinationUrl = navigateWithParams(`/com/students/classes/score-history-print/${obfuscateUrlParam(String(classSeq))}`, {
        newParams: {
          className,
          companyName,
        },
        isNavigate: false,
      });

      // "classSeq" 를 암호화 하여 라우터 파라미터로 전달
      window.open(destinationUrl, '_blank');
    } else {
      alert('classSeq 가 없습니다.');
    }
  };

  useEffect(() => {
    // 화면 열고 나서 바로 실행되는 함수
    const successHandler = (response) => {
      if (response.code === 200) {
        const scoreHistory = response.result.scoreHistory;

        setScoreHistory(scoreHistory);
      }
    };
    request
      .get(`/api/v2/academy/class/${classSeq}/score-history`, null, successHandler)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [classSeq]);

  useEffect(() => {
    if (reportCardActive) {
      window.open(`/com/reports`, '_blank');
      setReportCardActive(false);
    }
  }, [reportCardActive]);

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} p='0 1.25rem 1.25rem' w='60rem' gap='0'>
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          <S.ModalTitleContainer className='modal_title_container'>
            <div className='left modal_title_area'>
              <S.ModalTitle>{className}</S.ModalTitle>
              {Array.isArray(scoreHistory) && scoreHistory.length > 0 && (
                <button
                  type='button'
                  title='Open in a New Tab'
                  className='btn_main_print'
                  onClick={() => {
                    handlePrintScoreHistoryPage({ classSeq });
                  }}>
                  <ImgSvgIconPrinter />
                </button>
              )}
            </div>
            <button className='modal_title_btn' onClick={() => setShowModal(false)}>
              <i className='svg_icon btn_pop_close black'>&nbsp;</i>
            </button>
          </S.ModalTitleContainer>

          <S.TableScrollWrapper className='custom_scroll'>
            <S.Table>
              <thead>
                <S.HeaderTableRow>
                  <th className='col_01'>No.</th>
                  <th className='col_02'>Assigned Date </th>
                  <th className='col_03'>Test Name</th>
                  <th className='col_04'>RW</th>
                  <th className='col_05'>Math</th>
                  <th className='col_06'>Total Score</th>
                  <th className='col_07'>PDF</th>
                </S.HeaderTableRow>
              </thead>

              <tbody>
                {Array.isArray(scoreHistory) && scoreHistory.length > 0 ? (
                  scoreHistory.map((item, index) => {
                    return (
                      <Fragment key={`score_${index}`}>
                        <S.MainTableRow>
                          <td>{scoreHistory.length - index}</td>
                          <td>
                            {isoTimeToDayjs(item.testStartDate).format('YYYY / MM / DD')} ~ {isoTimeToDayjs(item.testEndDate).format('YYYY / MM / DD')}
                          </td>
                          <td>{item.testName}</td>
                          <td>{item.rwScore === null ? '-' : item.rwScore}</td>
                          <td>{item.mathScore === null ? '-' : item.mathScore}</td>
                          <td>{item.rwScore + item.mathScore}</td>
                          <td className='btn-item'>
                            <div>
                              <Link
                                target='_new'
                                to={`/test/test/view?viewTest=${encodeURIComponent(item.customSetSeqStr)}`}
                                className='svg_icon icon_report_pdf'></Link>
                            </div>
                          </td>
                        </S.MainTableRow>
                      </Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan='7' style={{ padding: '3rem' }}>
                      No data
                    </td>
                  </tr>
                )}
              </tbody>

              {
                <S.TableFooter>
                  <tr>
                    <td colSpan={2}>&nbsp;</td>
                    <td className='col_04'>Average</td>
                    <td className='col_05'>
                      {Array.isArray(scoreHistory) && scoreHistory.length > 0
                        ? Math.ceil(
                            scoreHistory.reduce((accumulator, currentObject) => {
                              return accumulator + currentObject.rwScore;
                            }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                          )
                        : 0}
                    </td>
                    <td className='col_06'>
                      {Array.isArray(scoreHistory) && scoreHistory.length > 0
                        ? Math.ceil(
                            scoreHistory.reduce((accumulator, currentObject) => {
                              return accumulator + currentObject.mathScore;
                            }, 0) / scoreHistory.filter((S) => S.mathScore > 0).length
                          )
                        : 0}
                    </td>
                    <td className='col_07'>
                      {Array.isArray(scoreHistory) && scoreHistory.length > 0
                        ? Math.ceil(
                            scoreHistory.reduce((accumulator, currentObject) => {
                              const totalScore = currentObject.rwScore + currentObject.mathScore;
                              return accumulator + totalScore;
                            }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                          )
                        : 0}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </S.TableFooter>
              }
            </S.Table>
          </S.TableScrollWrapper>
        </>
      )}
    </SimpleModalBase>
  );
}

const S = {
  ModalTitleContainer: styled.div`
    .left {
      display: flex;
      align-items: center;
    }
    .btn_main_print {
      width: 2rem;
      height: 2rem;
      border: 1px solid #f1f1f5;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      svg {
        width: 1.5rem;
        color: #505050;
      }
      &:hover {
        filter: brightness(95%);
        svg {
          color: #808080;
        }
      }
    }
  `,
}; // S-dot 문법용 객체

S.ModalTitle = styled.h2`
  color: #111111;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.2rem 1rem;
`;

S.TableScrollWrapper = styled.div`
  max-height: 23.5rem;
  background-color: white;
  overflow: auto;
`;
S.Table = styled.table`
  width: 100%;
  text-align: center;
  th,
  td {
    &.col_01 {
      width: 3.38rem;
    }
    &.col_02 {
      width: 15.6rem;
    }
    &.col_03 {
      width: auto;
    }
    &.col_04 {
      width: 4.3rem;
    }
    &.col_05 {
      width: 4.3rem;
    }
    &.col_06 {
      width: 6rem;
    }
    &.col_07 {
      width: 3.5rem;
    }
  }
`;

S.HeaderTableRow = styled.tr`
  position: sticky;
  z-index: 2;
  margin-bottom: 1px;
  th {
    background-color: #000;
    color: #fff;
    height: 2.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: uppercase;
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      overflow: hidden;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      overflow: hidden;
    }
  }
`;
S.MainTableRow = styled.tr`
  border-top: 1px solid #eff1f3;

  td {
    font-size: 0.875rem;
    height: 3rem;
    color: #4b5563;
  }
  .btn-item {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

S.TableFooter = styled.tfoot`
  height: 3rem;
  border-bottom: 1px solid #eff1f3;

  td {
    border-top: 1px solid #505050;
  }

  .col_05,
  .col_06,
  .col_07 {
    font-weight: 700;
  }
`;
