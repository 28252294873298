import { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function SourcesForReview({ source, currentQuestionOrder }) {
  const [activeTab, setActiveTab] = useState(1);

  // source가 변경될 때 activeTab을 1로 초기화
  useEffect(() => {
    setActiveTab(1);
  }, [currentQuestionOrder]);

  return (
    <S.Container>
      {source.length > 1 && (
        <>
          <h1>Sources for Review</h1>
          <S.Tabs>
            {source.map((tab) => (
              <S.TabLabel key={tab.sourceOrder} className={activeTab === tab.sourceOrder ? 'active' : ''}>
                <input type='radio' name='tabs' checked={activeTab === tab.sourceOrder} onChange={() => setActiveTab(tab.sourceOrder)} />
                {tab.sourceOrder}
              </S.TabLabel>
            ))}
          </S.Tabs>
        </>
      )}
      {/* source 뒤에 몇번째 탭인지 */}
      SOURCE {activeTab}
      <div>
        {source.map(
          (tab) =>
            activeTab === tab.sourceOrder && (
              <S.TabContent key={tab.sourceOrder}>
                <div dangerouslySetInnerHTML={{ __html: tab.sourceContent }} />
              </S.TabContent>
            )
        )}
      </div>
    </S.Container>
  );
}

// Styled Components
const S = {
  Container: styled.div`
    margin: 20px auto;
    padding: 20px;
  `,
  Tabs: styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  `,
  TabLabel: styled.label`
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;

    input {
      display: none;
    }

    &.active {
      background-color: #007bff;
      color: #fff;
      font-weight: bold;
    }
  `,
  TabContent: styled.div`
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
};
