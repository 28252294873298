import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import TestList from 'components/com/tests/_components/_TestListClassLayer';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import Layout from '../../../_components/_common/Layout';
import MainTitleArea from '../../_components/title/MainTitleArea';
import MainTitle from '../../_components/title/MainTitle';
import SubTitle from '../../_components/title/SubTitle';

/** AP Set Tests : 학원 선택 페이지 */
export default function ApTestsSetSelectClassPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 /////
  const userInfo = request.tokenDecoder();
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////

  /////////////////// React useState 선언 영역 시작 ////////////////////
  const [classesInfo, setClassesInfo] = useState({
    classesList: [],
    currentPage: 1,
    numPerPage: 10, // line 수
    pagePerBlock: 10,
    selectedClassSeq: nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0,
    selectedClassName: nvl(stateSetTest.selectedClass.className) !== '' ? stateSetTest.selectedClass.className : '',
  });

  const [searchInfo, setSearchInfo] = useState({
    searchStartDate: '',
    searchEndDate: '',
    searchField: '',
    searchKeyword: '',
  });
  /////////////////// React useState 선언 영역 끝 ////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const onChangeLineHandler = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  /** 최초 Class 목록 얻어오기 API 호출 */
  const getClassesList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const classesList = response.result.classList;

        setClassesInfo((prev) => {
          return { ...prev, classesList };
        });
      }
    };

    request.apGet(`/api/v1/academy/class`, null, successHandler);
  };

  const nextPageMove = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  /** 테이블 열 선택 체크박스 핸들러 */
  const onCheckHandler = ({ event, item }) => {
    let parentNode = event.target.parentElement.parentElement; // tr

    parentNodeHandler(parentNode);

    setClassesInfo((prev) => {
      return {
        ...prev,
        selectedClassSeq: item.classSeq,
        selectedClassName: item.className,
      };
    });
  };

  /** 테이블 열 선택 체크박스 조작 함수 */
  const parentNodeHandler = (parentNode) => {
    for (let i = 0; i < parentNode.parentElement.children.length; i++) {
      parentNode?.parentElement?.children[i]?.classList.remove('active');

      if (parentNode?.parentElement?.children[i]?.children[0]?.children[0]) {
        parentNode.parentElement.children[i].children[0].children[0].checked = false;
      }
    }

    parentNode?.classList.add('active');

    if (parentNode?.children[0]?.children[0]) parentNode.children[0].children[0].checked = true;

    if (parentNode?.classList.contains('active')) {
      parentNode.parentElement.children[0].checked = true;
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => {
        return { ...prev, searchKeyword: e.target.value };
      });
    }
  };

  const searchClassList = (e) => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: searchKeywordRef.current.value };
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getClassesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout classSeq={classesInfo.selectedClassSeq} selectedClassName={classesInfo.selectedClassName}>
      <MainTitleArea>
        <MainTitle>Class List</MainTitle>
        <SubTitle>Select a teacher you want to view the class list of.</SubTitle>
      </MainTitleArea>
      <S.Wrap className='com_list'>
        {/* <section className='top'>
          <CustomSelect
            options={[
              { label: 'select teacher', value: null },
              { label: '25 line', value: 25 },
              { label: '50 line', value: 50 },
              { label: '100 line', value: 100 },
            ]}
            onSelect={onChangeLineHandler}
            className={'select_teacher'}
          />
          <article className='search_area'>
            <div className='search'>
              <input ref={searchKeywordRef} type='text' placeholder='Enter keywords' className='input search_area_input' onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={searchClassList}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={onChangeLineHandler}
            />
          </article>
        </section> */}
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='53px' />
            <col width='128px' />
            <col />
            <col width='120' />
            <col width='120' />
            <col width='100' />
          </colgroup>
          <thead>
            <tr>
              {/* <th><input type="checkbox"  className="com_chk" id="chkAll" /><label htmlFor="chkAll">&nbsp;</label></th> */}
              <th> </th>
              <th>No.</th>
              <th>Subject</th>
              <th>Classes Name</th>
              <th>Start Date </th>
              <th>End Date </th>
              <th>No. of Students</th>
            </tr>
          </thead>
          <tbody>
            {classesInfo.classesList == null || classesInfo.classesList.length < 1 ? (
              <tr>
                <td colSpan='7'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            ) : (
              classesInfo.classesList.map((item, index) => {
                return (
                  <tr key={`class_${index}`}>
                    <td>
                      <input
                        type='checkbox'
                        className='com_chk'
                        id={`class_${index}`}
                        value={`${item.classSeq}|${item.className}`}
                        onChange={(event) => onCheckHandler({ event, item })}
                        checked={Number(item.classSeq) === Number(classesInfo.selectedClassSeq)}
                      />
                      <label htmlFor={`class_${index}`}>&nbsp;</label>
                    </td>
                    <td>{classesInfo.classesList.length - index}</td>
                    <td className='tit_field'>
                      <span className='ellipsis'>{item.subjectName}</span>
                    </td>
                    <td className='tit_field alignL'>
                      <span className='ellipsis'>{item.className}</span>
                    </td>
                    <td>{dayjs(item.classStartDate).format('MM / DD / YYYY')}</td>
                    <td>{dayjs(item.classEndDate).format('MM / DD / YYYY')}</td>
                    <td>{item.studentCount}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {/* <Paging
          totalCount={classesInfo.totalCount}
          currentPage={classesInfo.currentPage}
          numPerPage={classesInfo.numPerPage}
          pagePerBlock={classesInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        /> */}
      </S.Wrap>
    </Layout>
  );
}

const S = {
  Wrap: styled.section`
    margin-top: 0.75rem;
    .top {
      .select_teacher {
        width: 15.125rem;
      }
    }
  `,
};
