import styled from 'styled-components';

/** Report Card - Section2 */
export default function FreeResponseSection({ part }) {
  return (
    <S.Section2>
      <S.QuestionList>
        {part.questions.map((question) => (
          <S.QuestionContainer key={`question-${question.questionNum}`}>
            <p className='question-text' dangerouslySetInnerHTML={{ __html: question.questionNum + '. ' + question.question }} />

            <div className='answer_container'>
              <div className='your_answers'>Your Answers :</div>

              <S.AnswerWrapper className='custom_scroll'>
                <p className='user_answer_text' dangerouslySetInnerHTML={{ __html: question.tryAnswer }} />
              </S.AnswerWrapper>

              <S.TotalScore>
                score:
                <span className='user_score_text'>{question.rawScore}</span>/ {question.maxScore}
              </S.TotalScore>
            </div>
          </S.QuestionContainer>
        ))}
      </S.QuestionList>
    </S.Section2>
  );
}

// --- --- ---

const S = {
  Section2: styled.div`
    .section_title {
      margin-bottom: 1.875rem;
      font-size: 1.5rem;
      font-weight: 700;
    }
  `,

  QuestionList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  `,

  QuestionContainer: styled.div`
    .question-text {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }

    .answer_container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .your_answers {
      font-size: 14px;
      font-weight: 500;
    }
  `,

  AnswerWrapper: styled.div`
    background-color: #f1f1f1;
    height: 286px;
    padding: 12px;
    width: 100%;
    .user_answer_text {
      font-size: 14px;
      width: 100%;
      height: 100%;
    }
  `,

  TotalScore: styled.p`
    text-align: right;
    font-size: 20px;
    font-weight: 700;
    .user_score_text {
      font-size: 32px;
    }
  `,
};