import styled from 'styled-components';
import { MathJax } from 'better-react-mathjax';
import { useState, useEffect } from 'react';

/**
 * ap의 시험에서 사용될 body base
 * 섹션 갯수에 따라 컴포넌트를 추가해서 사용
 * 각 header, body, footer를 조합한뒤 최상위 페이지에 아래 스타일 적용시 자연스러운 스크롤 구현됨
 *
 * width: 100%; height: 100vh; display: flex; flex-direction: column; overflow: hidden;
 */
export default function ApExamBodyBase({ examSection, children }) {
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);
  // 단일 컴포넌트인 경우 배열로 변환 && null 제거
  const sections = Array.isArray(examSection) ? examSection.filter((section) => section !== null) : [examSection].filter((section) => section !== null);

  // 초기 마운트시 MathJax가 정상적으로 랜더링 될수 있게 돕는 useEffect
  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 100);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ExamContainer className='custom_scroll'>
      {!examSection ? (
        <p>시험 섹션을 추가해주세요.</p>
      ) : sections.length === 1 ? (
        <S.ExamMathJaxContainer hideUntilTypeset='first'>{sections[0]}</S.ExamMathJaxContainer>
      ) : sections.length === 2 ? (
        <>
          <S.ExamMathJaxContainer hideUntilTypeset='first' className='left'>
            {sections[0]}
          </S.ExamMathJaxContainer>

          <S.ExamMathJaxContainer hideUntilTypeset='first' className='right'>
            {sections[1]}
          </S.ExamMathJaxContainer>
        </>
      ) : (
        <p>최대 2개의 섹션만 지원됩니다.</p>
      )}

      {sections.length === 2 && <S.VerticalDivider />}

      {children}
    </S.ExamContainer>
  );
}

const S = {
  ExamContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
  `,

  ExamMathJaxContainer: styled(MathJax)`
    display: flex !important; // MathJax 컴포넌트에 적용된 스타일 무시
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    height: fit-content;
    width: 100%;
    align-items: center;

    &.left {
      align-items: flex-end;
      max-width: 50%;
    }
    &.right {
      align-items: flex-start;
      max-width: 50%;
    }
  `,

  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
};
