import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import jwt_decode from 'jwt-decode';

import { nvl } from 'utils/Common.utils.js';
import request from 'utils/Request.utils.js';

// hook
import usePressESC from 'hooks/usePressESC.js';

import EditDropbox from '../common/EditDropbox';
import styled from 'styled-components';

import localStorage from 'utils/LocalStorage.utils';
import Cookies from 'js-cookie';

/** "프로필" 페이지의 "수정" 모달 */
export default function EditModal(props) {
  const {
    ID,
    setOpenEditModal,
    setAlert,
    setAlertLayerPopup,
    setReloadData,
    modifiedData,
    noPassword,
    userSeq,
    mcSeq,
    ecSeq,
    userLevel,
    upFileSeq,
    academyUpFileSeq,
    dbUserInfo,
  } = props;
  usePressESC(() => setOpenEditModal(false));

  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  // 학원 토큰 갱신
  const changeAcademyToken = () => {
    const param = { mcSeq: mcSeq };
    request.changeTokenReload(param);
  };
  // 유저 토큰 reload(change와 다름)
  const reloadUserToken = () => {
    const param = { userSeq: userSeq };
    request.updateUserToken(param);
  };

  const [schoolTypeList, setSchoolTypeList] = useState([{}]);
  const [locationList, setLocationList] = useState([]);

  // Edit 데이터 관리 코드
  const [editData, setEditData] = useState(
    modifiedData.reduce((acc, item) => {
      if (item.name === 'Registration Date' || item.name === 'Email') return acc;
      return { ...acc, [item.name]: item.value };
    }, {})
  );
  const handleChanges = (field) => (event) => {
    const { value } = event.target;
    setEditData({ ...editData, [field]: event.target.dataset.value || value });
  };

  // Search 데이터 관련 코드 (School Location)
  const searchData = ['School Location'];
  const searchDataMapping = {
    'School Location': locationList,
  };
  const schoolLocationAreaRef = useRef();
  const schoolLocationRef = useRef('');
  const [schoolLocationActive, setSchoolLocationActive] = useState(false);
  const [schoolLocation, setSchoolLocation] = useState(editData['School Location'] || '');

  const toggleSchoolLocation = () => setSchoolLocationActive(!schoolLocationActive);
  const changeHandleLocation = () => setSchoolLocation(schoolLocationRef.current.value);

  const handleLocationSelect = (selectedLocation) => {
    schoolLocationRef.current.value = selectedLocation;
    setEditData({ ...editData, 'School Location': selectedLocation });
    setSchoolLocation(selectedLocation);
    toggleSchoolLocation();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && schoolLocationRef.current.value.length > 0) {
      const filterList =
        locationList.filter((location) => location.schoolLocation.toLowerCase().includes(schoolLocationRef.current.value.toLowerCase()))[0] || [];
      handleLocationSelect(filterList.schoolLocation);
    }
  };

  // dropbox 관리 코드
  const dropboxData = ['School Type', 'Grade'];
  const optionsMapping = {
    'School Type': schoolTypeList,
    Grade: Array(12)
      .fill()
      .map((_, i) => String(i + 1))
      .map((item) => ({ name: item, value: item })),
  };

  // 전화번호 관리 코드
  const MOBILE_NUMBER_STRING = ['Mobile Number', 'Parent Mobile Number', 'Office Number'];
  const handlePhoneNumberChange = (field) => (event) => {
    const input = event.target?.value.replace(/\D/g, '');
    let formattedInput = '';

    if (input.startsWith('010') && input.length > 3) {
      if (input.length <= 7) {
        formattedInput = `${input.slice(0, 3)} - ${input.slice(3)}`;
      } else if (input.length <= 11) {
        formattedInput = `${input.slice(0, 3)} - ${input.slice(3, 7)} - ${input.slice(7, 11)}`;
      }
    } else {
      if (input.length <= 2) {
        formattedInput = input;
      } else if (input.length <= 6) {
        const firstDashPosition = input.startsWith('02') ? 2 : 3;
        formattedInput = `${input.slice(0, firstDashPosition)} - ${input.slice(firstDashPosition)}`;
      } else if (input.length > 6) {
        const firstDashPosition = input.startsWith('02') ? 2 : 3;
        formattedInput = `${input.slice(0, firstDashPosition)} - ${input.slice(firstDashPosition, 6)} - ${input.slice(6, 10)}`;
      }
    }

    setEditData({ ...editData, [field]: formattedInput });
  };

  // password 코드
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isCorrectPassword, setIsCorrectPassword] = useState({
    length: false,
    special: false,
    matched: false,
  });
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const handlePasswordChange = (field) => (event) => {
    const newPasswords = { ...passwords, [field]: event.target.value };
    if (field === 'confirmPassword') handleChanges('confirmPassword')(event);
    setPasswords(newPasswords);

    const patternSpecial = /[\W_]/;
    const patternNum = /[0-9]/;
    const { newPassword, confirmPassword } = newPasswords;
    setIsCorrectPassword({
      length: newPassword.length >= 8,
      special: patternSpecial.test(newPassword) && patternNum.test(newPassword),
      matched: newPassword === confirmPassword && confirmPassword.length > 0,
    });
  };
  const allConditionsMet = Object.values(isCorrectPassword).every((value) => value);

  // userName 코드
  const [userName, setUserName] = useState(editData.userName);
  const handleUserNameChange = (event) => {
    const newUserName = event.target.value;
    setUserName(newUserName);
    handleChanges('userName')(event);
  };
  // companyName 코드
  const [companyName, setCompanyName] = useState(editData.companyName);
  const handleCompanyNameChange = (event) => {
    const newCompanyName = event.target.value;
    setCompanyName(newCompanyName);
    handleChanges('companyName')(event);
  };

  // useEffect 코드(데이터 통신)
  useEffect(() => {
    const successHandler = (response) => {
      if (response.code !== 200) return;

      const commonCodeList = response.result.commonCodeList || [];

      setSchoolTypeList(
        commonCodeList
          .filter((item) => item.parentCode === '012000000000000')
          .map(({ commonCode, codeName }) => ({
            value: commonCode,
            name: codeName,
          }))
      );
      setLocationList(
        commonCodeList
          .filter((item) => item.commonCode.includes('011') && item.codeName.toLowerCase() !== 'country')
          .map(({ commonCode, codeName }) => ({
            value: commonCode,
            name: codeName.toUpperCase(),
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    };

    request.get(`/api/common/common-code?multiCode=${encodeURI('011|012')}`, null, successHandler);
  }, []);

  const keyMapping = {
    userName: 'userName',
    'Mobile Number': 'userMobile',
    'School Name': 'studentSchool',
    'School Location': 'schoolLocation',
    'School Type': 'schoolType',
    Grade: 'studentGrade',
    companyName: 'companyName',
    'Academy Code': 'academyCode',
    'Tutor Code': 'academyCode',
    'Office Number': 'officeNumber',
  };
  const handleSave = () => {
    const editedData = {};

    Object.keys(editData).forEach((key) => {
      if (editData[key] === undefined) return (editData[key] = '');

      const isMobileIncluded = MOBILE_NUMBER_STRING.some((str) => key.includes(str));
      const isDropbox = dropboxData.some((str) => key.includes(str));
      const isSearchData = searchData.some((str) => key.includes(str));

      if (isMobileIncluded) {
        return (editedData[key] = editData[key]?.replace(/\s*-\s*/g, '-'));
      }

      if (isDropbox) {
        return (editedData[key] = optionsMapping[key]?.find((item) => item.name === editData[key])?.value);
      }

      if (isSearchData) {
        return (editedData[key] = searchDataMapping[key]?.find((item) => item.name.toLowerCase() === editData[key].toLowerCase())?.value);
      }

      editedData[key] = editData[key];
    });

    let params = {
      userPasswd: editedData.userPassword || '',
      userNewPasswd: editedData.confirmPassword || '',
    };

    Object.keys(editedData).forEach((key) => {
      const mappedKey = keyMapping[key];
      const value = editedData[key];

      if (value !== '') {
        params[mappedKey] = value;
      }
    });

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        // console.log(response.result.accessToken);

        if (nvl(response.result.accessToken) !== '') {
          const newMemberInfo = jwt_decode(response.result.accessToken);

          if (Object.hasOwn(params, 'academyCode')) {
            dispatch({
              type: 'setMenuInfo',
              payload: {
                fieldName: 'academyName',
                data: newMemberInfo.companyName,
              },
            });
            setUserName(newMemberInfo.companyName);
          } else {
            dispatch({
              type: 'setMenuInfo',
              payload: { fieldName: 'userName', data: newMemberInfo.userName },
            });
            setUserName(newMemberInfo.userName);
          }

          setUserInfo(newMemberInfo);
        } else {
          if (Object.hasOwn(params, 'academyCode')) {
            dispatch({
              type: 'setMenuInfo',
              payload: { fieldName: 'academyName', data: companyName },
            });
          } else {
            dispatch({
              type: 'setMenuInfo',
              payload: { fieldName: 'userName', data: userName },
            });
          }
        }

        setOpenEditModal(false);
        setReloadData((prev) => !prev);

        /** 토큰 갱신 api */
        const refreshAccessToken = () => {
          const successHandler = (response) => {
            if (response.code === 200) {
              const { accessToken, refreshToken } = response.result;

              localStorage.setItem('userSession', 'accessToken', accessToken);
              localStorage.setItem('userSession', 'refreshToken', refreshToken);
              setUserInfo(jwt_decode(accessToken));

              setInfoCookieHandler();
            }
          };

          const userSession = localStorage.getItemJsonParse('userSession');
          request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
            console.log('refresh token error! : ', error);
          });
        };

        refreshAccessToken();
      }

      if (response.code === 401) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: "Your current password doesn't match.",
          };
        });
      }
    };

    if (Object.hasOwn(params, 'academyCode') || Object.hasOwn(params, 'tutor')) {
      const academyParams = {
        companyName: params.companyName,
        modiUserSeq: userInfo.userSeq,
        representativePhone: params.officeNumber,
      };

      request.put(`/api/member/profile/academy/${ecSeq}`, academyParams, successHandler);

      return;
    }

    request.put(`/api/member/profile/user/personal/${userSeq}`, params, successHandler);
  };

  const accountInfo = {
    userSeq: userSeq,
    userName: userName,
    companyName: editData.companyName || '',
    userLevel: userLevel,
    ecSeq: ecSeq,
    mcSeq: mcSeq,
    upFileSeq: upFileSeq,
    academyUpFileSeq: academyUpFileSeq,
  };

  const handelClose = () => {
    setReloadData((prev) => !prev);
    setOpenEditModal(false);
  };

  /** 정보 세팅후 쿠키 세팅 및 0.1초 뒤에 새로고침 */
  const setInfoCookieHandler = () => {
    // companyName이 없으면 개인 정보 세팅
    if (accountInfo?.companyName) {
      const IS_SET_PROFILE = 'isSetPersonalInfo';
      Cookies.set(IS_SET_PROFILE, 'true');
    }
    // companyName이 있으면 아카데미 정보 세팅
    if (accountInfo?.companyName !== '') {
      const IS_SET_ACADEMY_TUTOR = 'isSetAcademyTutor';
      Cookies.set(IS_SET_ACADEMY_TUTOR, 'true');
    }

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <S.Wrap className='com_popup type_dim pop_academy_info active'>
      <div className='pop_container xl'>
        <section className='pop_header'>
          <h1 className='pop_tit'>Edit</h1>
          <button className='svg_icon btn_pop_close gray' onClick={handelClose}>
            &nbsp;
          </button>
        </section>
        <section className='pop_body'>
          <article className='information_sec'>
            <ID
              size='big'
              type='edit'
              accountInfo={accountInfo}
              setReloadData={setReloadData}
              handleUserNameChange={handleUserNameChange}
              handleCompanyNameChange={handleCompanyNameChange}
            />
            <ul className='input_area'>
              {modifiedData.slice(1).map((item, idx) => {
                // Search 데이터 일 때
                if (searchData.some((dataName) => dataName === item.name)) {
                  return (
                    <li className='item' ref={schoolLocationAreaRef} key={item.name}>
                      <div className='tit_field'>{item.name}</div>
                      <div className='input_field com_select_layer active' onClick={toggleSchoolLocation}>
                        <input type='text' ref={schoolLocationRef} value={schoolLocation} onKeyDown={handleKeyDown} onChange={changeHandleLocation} />
                        <ul className='layer option_wrap grade_option_wrap' style={{ display: !schoolLocationActive && 'none' }}>
                          {locationList &&
                            locationList
                              .filter((location) => location.name.toLowerCase().includes(schoolLocation.toLowerCase()))
                              .map((item, index) => (
                                <li className='option' key={`location_${index}`} onClick={() => handleLocationSelect(item.name, item.value)}>
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </li>
                  );
                }

                // 드롭박스 데이터 일때
                if (dropboxData.some((dataName) => dataName === item.name)) {
                  const options = optionsMapping[item.name] || [];

                  return (
                    <EditDropbox key={item.name} optionName={item.name} inputValue={editData[item.name]} options={options} handleChanges={handleChanges} />
                  );
                }

                // Mobile Number 일때
                if (MOBILE_NUMBER_STRING.includes(item.name)) {
                  // if (item.name.includes(MOBILE_NUMBER_STRING)) {
                  return (
                    <li className='item' key={item.name}>
                      <div className='tit_field'>{item.name}</div>
                      <div className='input_field'>
                        <input type='text' value={editData[item.name]} onChange={handlePhoneNumberChange(item.name)} />
                      </div>
                    </li>
                  );
                }

                // 일반 데이터
                return (
                  <li className='item' key={item.name}>
                    <div className='tit_field'>{item.name}</div>
                    <div className='input_field'>
                      <input type='text' defaultValue={item.value} disabled={idx < 2 ? true : false} onChange={handleChanges(item.name)} />
                    </div>
                  </li>
                );
              })}

              {!noPassword &&
                dbUserInfo.loginType !== 'GOOGLE' &&
                (isChangePassword ? (
                  <>
                    <li className='item'>
                      <div className='tit_field'>Current Password</div>
                      <div className={`input_field ${incorrectPassword ? 'error' : ''}`}>
                        <input type='password' onChange={handleChanges('userPassword')} autocomplete='new-password' />
                        {incorrectPassword && <p className='error_message'>Incorrect password. Re-enter your old password</p>}
                      </div>
                    </li>
                    <li className='item'>
                      <div className='tit_field'>New password</div>
                      <div className={`input_field ${passwords.newPassword.length === 0 || allConditionsMet ? '' : 'error'}`}>
                        <input type='password' value={passwords.newPassword} onChange={handlePasswordChange('newPassword')} autocomplete='new-password' />
                      </div>
                    </li>
                    <li className='item'>
                      <div className='tit_field'>Confirm New password</div>
                      <div className={`input_field ${passwords.confirmPassword.length === 0 || allConditionsMet ? '' : 'error'}`}>
                        <input
                          type='password'
                          value={passwords.confirmPassword}
                          onChange={handlePasswordChange('confirmPassword')}
                          autocomplete='new-password'
                        />
                        <div className='com_password_message'>
                          <p className={`check ${isCorrectPassword.length ? 'valid' : ''}`}>Must be over 8 characters</p>
                          <p className={`check ${isCorrectPassword.special ? 'valid' : ''}`}>Must have a number and a special character (!, @ , ?)</p>
                          <p className={`check ${isCorrectPassword.matched ? 'valid' : ''}`}>Passwords must match</p>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  <li className='item'>
                    <div className='tit_field'>Password</div>
                    <div className='input_field'>
                      <button className='com_btn m point' onClick={() => setIsChangePassword(true)}>
                        Change Password
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </article>
        </section>
        <section className='pop_footer com_btn_wrap'>
          <button className='com_btn xl point line' onClick={() => setOpenEditModal(false)}>
            Cancel
          </button>
          <button className='com_btn xl point' onClick={handleSave}>
            Save
          </button>
        </section>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    input {
      border: 1px solid #d2dbe2;
    }
  `,
};
