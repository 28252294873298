import styled from 'styled-components';
import Box from '../../common/Box';

import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

import { ReactComponent as ImgSvgIconScoreReport } from 'assets/img/svg/icon_score_report.svg';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { useParams } from 'react-router-dom';
import { SCORE_PARAMS } from 'utils/constants';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

/**
 * ap/com/score/student에서 선택된 학생이 본 시험들을 보여주는 컴포넌트
 * @description
 * 🔍 검색 키워드 - #score #ap/com/score/:{studentSeq}
 */
export default function TestList({ selectedStudentTestList }) {
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue, setBulkObfuscatedValues, deleteBulkObfuscatedValues } = useObfuscatedSearchParam();
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);

  // Test 클릭시 작동하는 함수 - currentTest, currentUth, currentClass를 Query Parameter에 저장
  const handleClickTest = (testSetSeq, uthSeq, classSeq) => {
    setBulkObfuscatedValues({
      paramValues: {
        [SCORE_PARAMS.CUR_TEST]: testSetSeq,
        [SCORE_PARAMS.CUR_UTH]: uthSeq,
        [SCORE_PARAMS.SEL_TEST_CLASS_SEQ]: classSeq,
      },
    });
  };
  const handleClickBackToTotal = () => {
    deleteBulkObfuscatedValues({ paramValues: [SCORE_PARAMS.CUR_TEST, SCORE_PARAMS.CUR_UTH, SCORE_PARAMS.SEL_TEST_CLASS_SEQ] });
  };

  /** Report 버튼 클릭시 작동하는 함수 - com/reports로 이동 (학생 성적표) */
  const handleClickReport = (uthSeq) => {
    window.open(`/ap/com/report/${obfuscateUrlParam(uthSeq)}`, '_blank');
  };

  return (
    <S.Wrap>
      <Box className='main_box'>
        <div className='top_area'>
          <div>
            <h4>Test List</h4>
            <p className='table_hint'>Click on a test to see score analysis for a specific test.</p>
          </div>
          <button onClick={handleClickBackToTotal} type='button'>
            Back to Total
          </button>
        </div>
        <div className='table_wrap'>
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Class</th>
                <th>Test</th>
                <th>Taken Date</th>
                <th>Total</th>
                <th>Report Card</th>
              </tr>
            </thead>
            <tbody>
              {selectedStudentTestList ? (
                selectedStudentTestList.map((item, index) => {
                  const testIndex = selectedStudentTestList.length - index;
                  return (
                    <tr
                      onClick={() => handleClickTest(item.testSetSeq, item.uthSeq, item.classSeq)}
                      className={`${queryCurUthSeq === String(item.uthSeq) ? 'selected_test' : ''}`}
                      key={`${item.testSetSeq}-${testIndex}`}>
                      <td>{testIndex}</td>
                      <td>{item.className}</td>
                      <td>{item.testSetName}</td>
                      <td>{isoTimeToDayjsTZ(item?.testTakenEndDate).format('MMM D, YYYY')}</td>
                      <td>{item.score}</td>
                      <td className='center'>
                        {item.isCompleted ? (
                          <button onClick={() => handleClickReport(item.uthSeq)} type='button' title='' className='button_report'>
                            <ImgSvgIconScoreReport />
                          </button>
                        ) : (
                          <div type='button' title='' className='button_report'>
                            Grading
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className='no_data_tr'>
                  <td>
                    <div className='no_data_area'>
                      No test available. <br />
                      Please complete a test to see in-depth analysis.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 26.875rem;
      display: flex;
      flex-direction: column;
    }
    .top_area {
      display: flex;
      justify-content: space-between;
      h4 {
        font-weight: 600;
        font-size: 1.25rem;
      }
      .table_hint {
        font-size: 0.875rem;
        color: #999;
      }
      button {
        background-color: #dff1ff;
        color: #4692d1;
        font-weight: 400;
        font-size: 0.75rem;
        border-radius: 0.375rem;
        padding: 0.5rem 0.625rem;
        height: 1.875rem;
      }
    }
    .table_wrap {
      margin-top: 0.75rem;
      position: relative;
      display: flex;
      flex: 1;
      min-height: 0;
      table {
        border-radius: 0.625rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        thead {
          display: table;
          width: 100%;
          tr {
            width: 100%;
          }
        }
        tbody {
          display: flex;
          overflow-y: scroll;
          flex: 1;
          flex-direction: column;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #2f3542;
          }
          tr {
            display: table;
            width: 100%;
            &:hover {
              background-color: #f8f8f9;
            }
            &.selected_test {
              border-radius: 10px;
              background: #edf7ff;
              position: relative;
              &:before {
                content: '';
                position: absolute;
                border-radius: 10px;
                border: 2px solid #008cff;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;
              }
            }
          }
        }
        th,
        td {
          color: #111111;
          font-weight: 400;
          font-size: 0.875rem;
          text-align: left;
          &:nth-child(1) {
            width: 5%;
            text-align: center;
          }
          &:nth-child(2) {
            width: auto;
          }
          &:nth-child(3) {
            width: 25%;
          }
          &:nth-child(4) {
            width: 14%;
          }
          &:nth-child(5) {
            width: 8%;
            text-align: center;
          }
          &:nth-child(6) {
            width: 13%;
            text-align: center;
          }
        }
        th {
          background-color: #f8f8f9;
          padding: 0.625rem 0.75rem;
        }
        td {
          padding: 0.875rem 0.75rem;
          border-bottom: 1px solid #f4f4fc;
          &.center {
            text-align: center;
          }
          &:nth-child(5) {
            font-weight: 600;
          }
        }
        .button_report {
          svg {
            color: #111111;
            width: 1.5rem;
          }
        }
      }
    }

    .no_data_tr {
      height: 100%;
    }
    .no_data_area {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #4b5563;
      height: 100%;
    }
  `,
};
