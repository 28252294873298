import { useState } from 'react';
import usePressESC from 'hooks/usePressESC';

/** AP 시험 페이지의 "Help" 영역 */
export default function HelpModal({ setIsHelpModal }) {
  const [helpItem, setHelpItem] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
    item11: false,
    item12: false,
    item13: false,
  });

  /** 모든 항목 확장 ( helpItem의 key를 배열로 받아 [key, true] 형태로 반환 && 배열을 객체로 변환 ) */
  const helpExpandAll = () => {
    setHelpItem((prev) => Object.fromEntries(Object.keys(prev).map((key) => [key, true])));
  };
  /** 모든 항목 축소 ( helpItem의 key를 배열로 받아 [key, false] 형태로 반환 && 배열을 객체로 변환 ) */
  const helpCollapseAll = () => {
    setHelpItem((prev) => Object.fromEntries(Object.keys(prev).map((key) => [key, false])));
  };
  /** 각 항목 클릭 시 클릭한 항목의 확장/축소 상태 변경 */
  const helpTitleClick = (index) => {
    const item = `item${index}`;
    setHelpItem((prev) => {
      return { ...prev, [item]: !helpItem[item] };
    });
  };

  // ESC 키 입력 시 팝업 닫기
  usePressESC(() => setIsHelpModal(false));

  return (
    <div className={`com_popup type_dim pop_exam_help active`}>
      <div className='pop_container xl'>
        <div className='pop_header'>
          Help
          <button className='svg_icon btn_pop_close' onClick={() => setIsHelpModal(false)}>
            &nbsp;
          </button>
          <div className='btn_area'>
            <button className='btn' onClick={helpExpandAll}>
              Expand All
            </button>
            <button className='btn' onClick={helpCollapseAll}>
              Collapse All
            </button>
          </div>
        </div>
        <div className='pop_body'>
          <div className={`item ${helpItem.item1 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(1)}>
              Zoom In/Out
            </div>
            <div className='info_field'>
              Use keyboard shortcuts to zoom in or out on laptops and pinch to zoom on tablets. <br />
              Windows and Chromebooks: <b>Control + Plus (+)</b> to zoom in and <b>Control + Minus (-)</b> to zoom out. <br />
              Mac: <b>Command + Plus (+)</b> to zoom in and <b>Command + Minus (-)</b> to zoom out.
            </div>
          </div>
          <div className={`item ${helpItem.item2 ? 'active' : ''}`}>
            {/*클릭 시 addClass 'active' info_field 노출*/}
            <div className='tit_field' onClick={() => helpTitleClick(2)}>
              In-App Calculator
            </div>
            <div className='info_field'>
              <i className='svg_icon icon_calculator'>&nbsp;</i>
              <b>Calculator:</b>On math questions, you will have access to graphing calculator built into the webpage. You can also use your own approved
              calculator.{' '}
            </div>
          </div>
          <div className={`item ${helpItem.item3 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(3)}>
              Testing Timers
            </div>
            <div className='info_field'>
              During testing, a timer will let you know much time remains for each module or part of the test. <br />
              <br />
              At the end of section 1, you'll have a short break. During the break, we'll show you a timer counting down to the beginning of section 2. <br />
              <br />
              When time runs out on section 2, the test will end, and your answers will be automatically submitted.
            </div>
          </div>
          <div className={`item ${helpItem.item4 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(4)}>
              Annotate Tool
            </div>
            <div className='info_field'>
              <i className='svg_icon icon_annotate'>&nbsp;</i>
              <b>Annotation: </b>
              On all non-math questions, you can highlight a passage and write notes to yourself. Simply select some text, then select <b>Annotate</b> from the
              toolbar at the top right of the screen. Click <b>Save</b> to highlight the text, or add a note and then click <b>Save</b>. You can hover over the
              annotation to read your note, or click into it to edit further. If you don't want to keep an annotation, just open it up and click <b>Delete</b>.
              You can annotate text from questions, passages, stimuli, and source documents. You cannot annotate directions, pictures, buttons, or content from
              answer options. If the content you annotate is shared across multiple questions, your annotations will be available across all of those questions.
              Your annotations will not be graded.
            </div>
          </div>
          <div className={`item ${helpItem.item5 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(5)}>
              Option-Eliminator
            </div>
            <div className='info_field'>
              <i className='svg_icon icon_undo'>&nbsp;</i>
              <b>Option Eliminator:</b>
              On any multiple-choice question, you can cross out answer options you think are wrong. Above the answer, click the <b>ABC</b> button, then you'll
              see a button next to each answer option, allowing you to cross it out. You can always undo this action.
            </div>
          </div>
          <div className={`item ${helpItem.item6 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(6)}>
              Mark for Review
            </div>
            <div className='info_field'>
              <i className='svg_icon icon_bookmark_off'>&nbsp;</i>
              <b>Mark for Review:</b>Flag any questions you want to come back later.
            </div>
          </div>
          <div className={`item ${helpItem.item7 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(7)}>
              Question Menu
            </div>
            <div className='info_field'>
              At the bottom of each testing screen, you'll find a menu that allows you to navigate to any question in the section. You'll see which questions
              you've answered, which you haven't, and any that you've marked for review.
            </div>
          </div>
          <div className={`item ${helpItem.item8 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(8)}>
              No penalty for Guessing
            </div>
            <div className='info_field'>Points are never deducted for wrong answer. So, it's always better to guess than to leave an answer blank.</div>
          </div>
          <div className={`item ${helpItem.item9 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(9)}>
              Reloading the Webpage
            </div>
            <div className='info_field'>
              If your webpage crashes during your exam, just reload the webpage to get back to the question you were on. The testing timer will not be paused,
              however. Same applies if you accidentally close the webpage; just relaunch the webpage. In case you exit the exam through the <b>Exit</b> button,
              you will be graded on what you have solved until that point and will not be able to return to the exam.
            </div>
          </div>
          <div className={`item ${helpItem.item10 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(10)}>
              Submitting Your Answers
            </div>
            <div className='info_field'>
              During testing, the app will save all your work. When the exam is over, your answers will be submitted automatically. If you're still working when
              time expires, your responses will be automatically saved and submitted. If your device is offline or your submission fails for any reason, you'll
              have time to reconnect and submit your work.
            </div>
          </div>
          <div className={`item ${helpItem.item13 ? 'active' : ''}`}>
            <div className='tit_field' onClick={() => helpTitleClick(13)}>
              Screen Magnification
            </div>
            <div className='info_field'>
              Screen magnification technology enlarges the content displayed on the computer screen in order to assist students with visual impairments.
              Although the exam app supports some non-embedded screen magnifier tools from third parties, all users can zoom using their device's native
              controls (Control +/- on a PC; Command +/- on a Mac; pinch and zoom on an iPad).
            </div>
          </div>
        </div>
        <div className='pop_footer'>
          <button className='com_btn m yellow oval' onClick={() => setIsHelpModal(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
