import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

import MultipleChoiceQuestionItem from './_components/QuestionItem';

/** ap/com/score/grading/submission 페이지의 "Multiple Choice Question" 영역 컴포넌트 */
export default function MultipleChoiceQuestionArea({
  partSeq,
  questionsData = [
    {
      questionNum: 0,
      isAnswered: '[]',
      isCorrect: false,
    },
  ],
}) {
  const navigate = useNavigate();

  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  /** 각 개별 문제 클릭 핸들러 */
  const handleClick = ({ item, uthSeq, partSeq }) => {
    if (uthSeq && partSeq && item.questionDetailSeq) {
      navigate(
        `/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}/question-view/${obfuscateUrlParam(String(partSeq))}/${obfuscateUrlParam(String(item.questionNum - 1))}`
      );
    } else {
      console.error('uthSeq 또는 partSeq 또는 questionDetailSeq 를 확인해 주세요.');
    }
  };

  return (
    <S.Wrap>
      <ul className='question_list'>
        {questionsData.map((item, index) => {
          return (
            <li key={`key_${index}`}>
              <button type='button' title='Move to another Page' onClick={() => handleClick({ item, uthSeq, partSeq })}>
                <MultipleChoiceQuestionItem number={item.questionNum} isEnter={item.isAnswered} isCorrect={item.isCorrect} />
              </button>
            </li>
          );
        })}
      </ul>
      <div className='guide'>Click on the question number to see the question and the student's answer.</div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #f4f8ff;
    padding: 2rem 1.5rem;
    .question_list {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      justify-items: center;
      gap: 1.5rem;
    }
    .guide {
      margin-top: 1rem;
      text-align: center;
      color: #767676;
      font-family: Roboto;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  `,
};
