import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';
import { nvlNumber } from 'utils/Common.utils.js';
import LoadingBar from 'utils/LoadingBar.js';

//components
//chart
import MainTopArea from './_components/layouts/old-main-top-area/MainTopArea';
import Profile from './_components/sections/profile/Profile';
import TestList from './_components/sections/testList/TestList';
import OuterBox from './_components/common/OuterBox';
import BtoBMainTabFrameHOC from 'layout/commonFrame/BtoBMainTabFrameHOC';
import SelectDetailSection from './_components/layouts/main-top-area/SelectDetailSection';

import { useNavigate, useParams } from 'react-router-dom';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

/**
 * ap/com/score/ 페이지
 */
export default function ApScorePage() {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  // 학생 정보 List
  const [apStudentList, setApStudentList] = useState([
    {
      userSeq: 0, // number         => 학생 seq
      userName: '', // string       => 학생 이름
      userEmail: '', // string      => 학생 이메일
      studentSchool: '', // string  => 학생 학교
      // 학생이 속한 반 리스트
      classList: [
        {
          classSeq: 0, // number    => 학생 반 seq
          className: '', // string  => 학생 반 이름
        },
      ],
      // 학생이 치른 과목 리스트
      subjectList: [
        {
          subjectSeq: 0, // number    => 학생 과목 seq
          subjectName: '', // string  => 학생 과목 이름
        },
      ],
    },
  ]);
  // 선택된 단일 학생의 데이터
  const [selectedStudent, setSelectedStudent] = useState({
    userSeq: 0, // number         => 학생 seq
    userName: '', // string       => 학생 이름
    userEmail: '', // string      => 학생 이메일
    studentSchool: '', // string  => 학생 학교
    // 학생이 속한 반 리스트
    classList: [
      {
        classSeq: 0, // number    => 학생 반 seq
        className: '', // string  => 학생 반 이름
      },
    ],
    // 학생이 치른 과목 리스트
    subjectList: [
      {
        subjectSeq: 0, // number    => 학생 과목 seq
        subjectName: '', // string  => 학생 과목 이름
      },
    ],
  });
  // 선택된 단일 학생이 본 시험 리스트
  const [selectedStudentTestList, setSelectedStudentTestList] = useState([
    {
      testSetSeq: 0, // number         => 시험 세트 seq
      uthSeq: 0, // number             => 해당 시험 기록 seq
      classSeq: 0, // number           => 시험을 치른 반 seq
      className: '', // string         => 시험을 치른 반 이름
      testSetName: '', // string       => 시험 세트 이름
      subjectName: '', // string       => 시험 세트 과목 이름
      score: 0, // number              => 시험 세트 점수
      testTakenEndDate: '', // string  => 시험 세트 치른 날짜
      isCompleted: false, // boolean   => 시험 세트 완료 여부
    },
  ]);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 선택된 학생이 본 시험 리스트 받아오는 API - 인자를 못받아오는 경우 현재 선택된 학생의 시퀀스를 사용 */
  const getStudentTestList = (selectedStudentSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedTestList = response.result.testList;

        setSelectedStudentTestList(fetchedTestList);
      }
    };

    request
      .apGet(`/api/v1/academy/score?studentSeq=${selectedStudentSeq}`, null, successHandler)
      .catch((error) => console.error('ap/com/score/:studentSeq - "/api/v1/academy/score?studentSeq={}" API 응답 에러', error))
      .finally(() => console.log('데이터 호출 완료'));
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 초기 진입시 학생 데이터 세팅
  useEffect(() => {
    /** AP 학생 List 받아오는 API */
    const getApStudentList = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentList = response.result.studentList;

          setApStudentList(fetchedStudentList);

          // 세팅된 학생이 없을때 - 첫번째 학생을 선택하고 해당 학생의 시퀀스를 route 파라미터 설정
          if (studentSeq === '0' || selectedStudent.userSeq === 0) {
            setSelectedStudent(fetchedStudentList[0]);
            navigate(`/ap/com/score/${obfuscateUrlParam(fetchedStudentList[0].userSeq)}`, { replace: true });
          }

          getStudentTestList(fetchedStudentList[0].userSeq);
        }
      };

      request
        .apGet(`/api/v1/academy/score/student-list`, null, successHandler)
        .catch((error) => console.error('ap/com/score/:studentSeq - "/api/v1/academy/score/student-list" API 응답 에러', error))
        .finally(() => console.log('데이터 호출 완료'));
    };

    getApStudentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStudentTestList(studentSeq);

    setSelectedStudent(apStudentList.find((student) => student.userSeq === Number(studentSeq)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentSeq]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <BtoBMainTabFrameHOC>
      <S.Warp className='p_score_main'>
        <OuterBox className='main_wrap'>
          <MainTopArea>
            <SelectDetailSection variant='student' dataList={apStudentList} isModalActive={false} />
          </MainTopArea>
          <div className='flex_row'>
            <Profile studentInfo={selectedStudent} />
            <TestList selectedStudentTestList={selectedStudentTestList} />
          </div>
        </OuterBox>
      </S.Warp>
    </BtoBMainTabFrameHOC>
  );
}

const S = {
  Warp: styled.div`
    .main_wrap {
      width: 1194px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
    }
  `,
};
