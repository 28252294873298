import styled from 'styled-components';

//student list
export default function StudentList({ students }) {
  const studentList = !!students ? JSON.parse(students).filter((student) => student.studentSeq !== null) : [];
  return (
    <div style={{ width: '1.5rem', height: '1.5rem', borderRadius: '0.25rem', backgroundColor: '#E8F0FF' }} className='com_btn_report has_layer'>
      <i style={{ width: '1rem', height: '1rem' }} className='svg_icon icon_score_report s blue'>
        &nbsp;
      </i>
      <S.TransparentGap />
      <div className='com_list_layer'>
        <p className='tit_area'>Student List</p>
        <div className='list_area scroll'>
          {studentList.length > 0 ? (
            studentList.map((student, idx) => (
              <div className='item' key={`student_${student}_${idx}`}>
                <i className='svg_icon icon_report'>&nbsp;</i>
                {student.studentName}
              </div>
            ))
          ) : (
            <p className='option'>no students</p>
          )}
        </div>
      </div>
    </div>
  );
}

const S = {};

S.TransparentGap = styled.div`
  position: absolute;
  top: 100%;
  left: -10%;
  right: -10%;
  height: 10px;
  z-index: 0;

  display: none;
`;
