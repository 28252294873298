import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Footer from 'layout/Footer'; //Footer
import Gnb from 'layout/Gnb';
import Lnb from 'layout/Lnb'; //Lnb
import QuestionBankRootPage from 'components/ap/question-bank/Page';
import QuestionBankTestEditorPage from 'components/ap/question-bank/test-editor/Page';
import TestPreviewPage from 'components/ap/question-bank/test-preview/Page';
import ApQuestionBankCreateQuestionPage from 'components/ap/question-bank/create-test/Page';
/**
 * AP - "Question Bank" 라우트
 * /ap/question-bank
 */
const ApQuestionBankRoute = () => {
  const location = useLocation();
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      // 로그인 한 사람만 사용
      const authRoutes = [
        { path: '/', element: <QuestionBankRootPage /> },
        { path: '/create-test', element: <ApQuestionBankCreateQuestionPage /> },
        { path: '/test-editor', element: <QuestionBankTestEditorPage /> },
        { path: '/test-preview', element: <TestPreviewPage /> }, // ap/question-bank/test-preview

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unauthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unauthRoutes);
    }
  };

  const PathStudents = location.pathname.toLowerCase().startsWith('/ap/student/dashboard');
  const PathReview = location.pathname.toLowerCase().startsWith('/ap/student/review');
  const PathReportCard = location.pathname.toLowerCase().startsWith('/ap/student/report');
  const PathStudentsList = location.pathname.toLowerCase().startsWith('/ap/student');
  const PathQuestionBank = location.pathname.startsWith('/ap/question-bank/page-layout');
  const PathQuestionBankTestEditor = location.pathname.startsWith('/ap/question-bank/test-editor');
  const PathQuestionBankTestPreview = location.pathname.startsWith('/ap/question-bank/test-preview');
  const renderGnb = () => {
    if (PathReportCard || PathReview || PathQuestionBank || PathQuestionBankTestEditor || PathQuestionBankTestPreview) return null;
    return <Gnb />;
  };

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathReportCard || PathReview || PathQuestionBank || PathQuestionBankTestEditor || PathQuestionBankTestPreview) return null;
    return <Lnb />;
  };

  const renderFooter = () => {
    //경로에 따른 Footer 노출 설정
    if (PathReview || PathReportCard || PathQuestionBank || PathQuestionBankTestEditor || PathQuestionBankTestPreview) return null;
    return <Footer />;
  };

  return (
    <>
      {renderLnb()}
      {renderGnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default ApQuestionBankRoute;
