import React, { useState, useEffect } from 'react';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

//components
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import EditTestScheduleModal from './_components/EditTestScheduleModal'; //test 수정 모달
import DeleteModal from 'components/_common/modals/DeleteModal'; //test 삭제 모달
import ContentTopTabs from './_components/ContentTopTabs';
import ByTest from './_components/tapContents/ByTest';
import ByClass from './_components/tapContents/ByClass';
import MainLink from 'components/_common/button/_MainLinks';
import styled from 'styled-components';
import DeleteStudentAlert from './_components/DeleteStudentAlert';

import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';

/** com/tests/scheduled 경로의 Page */
export default function TestSchedulePage() {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [reloadData, setReloadData] = useState(false);

  /** tab */
  const [tabState, setTabState] = useState(0);
  /** mini alert */
  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  // 시험 편집 모달에 들어갈 정보
  const [editTestModalInfo, setEditTestModalInfo] = useState({
    active: false, // boolean      => 모달 열기 여부
    type: '', // "class" | "test"  => 모달 타입

    editByClassData: {
      // 시험 정보
      testName: '', // string        => 시험 이름
      className: '', // string       => 속한 class 이름
      testStartDate: '', // string   => 시험 시작 날짜
      testEndDate: '', // string     => 시험 종료 날짜
      // 시험에 대한 학생의 정보
      studentList: [
        {
          uthSeq: 0, // number                => 학생 시험 일정 Seq
          userSeq: 0, // number               => 학생 Seq
          upFileSeq: null, // number | null   => 학생 프로필 이미지 Seq
          userName: '', // string             => 학생 이름
          testDate: '', // string             => 학생이 test를 본 날짜
          testStartDateKst: '', // string     => 사용 안함
          testTakenEndDateKst: null, // null  => null 고정
        },
      ],
    },

    editByTestData: {
      className: '', // string         => 속한 class 이름
      classSeq: 0, // number           => 속한 class Seq
      userName: '', // string          => 학생 이름
      userSeq: 0, // number            => 학생 Seq
      testName: '', // string          => 시험 이름
      customSetSeq: 0, // number       => 학생이 본 시험 세트 Seq
      uthSeq: 0, // number             => 학생 시험 일정 Seq
      testStartDate: '', // string     => 시험 시작 날짜
      testEndDate: '', // string       => 시험 종료 날짜
      testTakenEndDate: null, // null  => null 고정
    },
  });
  /** 삭제 모달 */
  const [deleteTestModal, setDeleteTestModal] = useState({
    active: false,
    deleteData: [],
  });
  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
  /** 인피니티 스크롤 */
  const [isLoading, setIsLoading] = useState(true);
  /** simpleAlert 모달 제어 State */
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: 'SOME_MODAL_TEXT',
    visible: false,
    callback: () => console.log('모달_클릭'),
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  //#endregion //////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** byClass && byTest 수정 클릭 핸들러 */
  const handleEditTest = (toEditData, type) => {
    if (type === 'class') {
      setEditTestModalInfo({ active: true, editByClassData: toEditData, type: 'class' });
    }

    if (type === 'test') {
      setEditTestModalInfo({ active: true, editByTestData: toEditData, type: 'test' });
    }
  };

  //#region ////  ByClass 핸들러  ////
  const classDeleteClick = (item) => {
    setDeleteTestModal((prev) => {
      return { ...prev, active: true, deleteData: item };
    });
  };
  /** 시험 일정 삭제 API 호출 */
  const delUserTestSchedule = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        setIsShowDeleteAlert(true);
        setDeleteTestModal((prev) => ({ ...prev, active: false }));
        setReloadData((prev) => !prev);
      }
    };

    const seqStr = deleteTestModal.deleteData.join('|');
    request
      .del(`/api/test/schedules?seqStr=${encodeURI(seqStr)}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "시험 일정 삭제" API 응답 에러', error));
  };
  //#endregion ////  ByClass 핸들러  ////
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const testScheduleTabs = [
    { text: 'By Class', value: 0 },
    { text: 'By Test', value: 1 },
  ];
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <>
      <S.Wrap className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <ContentTopTabs tabData={testScheduleTabs} tabState={tabState} setTabState={setTabState} />
          <>
            {tabState === 0 ? (
              <ByClass handleEditTest={handleEditTest} reloadData={reloadData} classDeleteClick={classDeleteClick} />
            ) : tabState === 1 ? (
              <ByTest setSimpleAlert={setSimpleAlert} handleEditTest={handleEditTest} classDeleteClick={classDeleteClick} reloadData={reloadData} />
            ) : null}
          </>
        </div>
      </S.Wrap>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}
      {deleteTestModal.active && (
        <DeleteModal
          setIsViewModal={setDeleteTestModal}
          text={'You are removing scheduled test.\nYour Gate Key will be refunded.'}
          hideModal={() => setDeleteTestModal((prev) => ({ ...prev, active: false }))}
          callback={delUserTestSchedule}
        />
      )}
      {isShowDeleteAlert && <DeleteStudentAlert setDeleteStudentAlert={setIsShowDeleteAlert} />}

      <EditTestScheduleModal
        editTestModalInfo={editTestModalInfo}
        closeEditTestModal={() => setEditTestModalInfo((prev) => ({ ...prev, active: false }))}
        setSimpleAlert={setSimpleAlert}
        setIsLoading={setIsLoading}
        setMiniAlert={setMiniAlert}
        //
        setReloadData={setReloadData}
      />

      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};