import React from 'react';
import styled from 'styled-components';

export default function SourcesForReview({ data = [{ id: 1, data: '그럴듯한_데이터1' }], selectedItemId = 0, callback = null }) {
  const handleClickReviewItem = (item) => {
    if (callback) {
      callback(item);
    } else {
      console.log('콜백 주세영');
    }
  };

  return (
    <S.SourcesForReviewContainer>
      {data.map((item, index) => (
        <SourcesForReviewItem key={item.id} isSelected={selectedItemId === item.id} text={index + 1} onClick={() => handleClickReviewItem(item)} />
      ))}
      {/* <SourcesForReviewItem isSelected={false} text='+' /> */}
    </S.SourcesForReviewContainer>
  );
}

function SourcesForReviewItem({ isSelected = false, text = '1', onClick = null }) {
  return (
    <S.SourcesForReviewItem className={`${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <p className='element'>{text}</p>
    </S.SourcesForReviewItem>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  SourcesForReviewContainer: styled.div`
    align-items: center;
    border: 1px solid #999;
    border-radius: 8px;
    display: flex;

    width: fit-content;

    overflow: hidden;

    .component {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-right: 1px solid #999999;
      padding: 6px 24px;
      position: relative;
    }

    .text-wrapper {
      color: #0068bd;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    > button {
      border-right: 1px solid #999999;
      &:last-child {
        border-right: none;
      }
    }
  `,

  SourcesForReviewItem: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0068bd;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    width: 3.5rem;
    height: 1.875rem;
    cursor: pointer;

    .element {
      font-size: 13px;
      font-weight: 600;
      background-color: white;
    }
    &.selected {
      background-color: #004a9e;
      .element {
        color: white;
        background-color: #004a9e;
      }
    }
  `,
};
