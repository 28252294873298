import React from 'react';
import styled from 'styled-components';

/** Set Tests 페이지들에서 사용 할 타이틀 문구 영역 스타일 용 컴포넌트 */
export default function MainTitleArea({ children }) {
  return <S.Wrap>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    gap: 0.25rem;
  `,
};
