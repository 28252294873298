import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import commonLoadingAnimation from 'assets/lottie/common_loading_animation.json';

/** 공통 로딩 컴포넌트 */
export default function CommonLoadingAnimation({ baseTime = 0.2, className }) {
  const [isToLong, setIsToLong] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsToLong(true);
    }, baseTime * 1000);

    return () => {
      setIsToLong(false);
    };
  }, [baseTime]);

  return isToLong ? (
    <S.LoadingContainer className={className}>
      <Lottie animationData={commonLoadingAnimation} style={{ width: '26.5rem', height: '26.5rem' }} />
    </S.LoadingContainer>
  ) : (
    <S.ShortLoading />
  );
}

const S = {
  LoadingContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  `,

  /** 로딩중 더블클릭을 막기 위한 투명한 오버레이  */
  ShortLoading: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.01);
    filter: blur(0.6);
  `,
};
